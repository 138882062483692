import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PARENTAL_STATUS } from "constant";
import { FC, memo, useEffect, useMemo, useState } from "react";
import { getFormatDate } from "utilities";
import Edit from "../Modal/Edit";

type Props = {
  data: StudentData | null;
};

const Information: FC<Props> = ({ data }) => {
  const [show, setShow] = useState(false);
  const [detail, setDetail] = useState(data);
  const [parent, setParent] = useState(data?.parent);

  const parentRole = useMemo(() => {
    return PARENTAL_STATUS.find((val) => val.id === parent?.role)?.name;
  }, [parent?.role]);

  useEffect(() => {
    setDetail(data);
    setParent(data?.parent);
  }, [data]);

  const toggleEdit = () => setShow(!show);

  const handleEdit = (value: StudentData) => {
    setDetail({
      ...detail,
      ...value,
    });
    setParent(value.parent);
    toggleEdit();
  };

  return (
    <>
      <div className="w-80 bg-white rounded-xl">
        <div className="px-5 py-4 border-b flex items-center justify-between">
          <p className="font-bold">Informasi siswa</p>
          <FontAwesomeIcon
            icon={icon({ name: "pen-to-square", style: "regular" })}
            className="text-yellow-500 cursor-pointer"
            onClick={toggleEdit}
          />
        </div>
        <div className="p-6 space-y-4">
          <div className="flex items-center gap-x-2">
            <div className="w-16 h-16 rounded-full bg-gray-100 grid place-items-center">
              <FontAwesomeIcon
                icon={icon({ name: "user", style: "regular" })}
                size="xl"
              />
            </div>
            <div>
              <p className="font-bold">{detail?.name}</p>
              <p className="text-sm">{detail?.nik}</p>
            </div>
          </div>
          <p className="font-medium text-sm text-gray-500 mt-4">Sekolah</p>
          <p className="font-medium text-sm">{detail?.school_name ?? "-"}</p>
          <p className="font-medium text-sm text-gray-500 mt-4">Email</p>
          <p className="font-medium text-sm">{detail?.email}</p>
          <p className="font-medium text-sm text-gray-500 mt-4">
            No. handphone
          </p>
          <p className="font-medium text-sm">
            {detail?.phone_number ? `+62${detail?.phone_number}` : "-"}
          </p>
          <p className="font-medium text-sm text-gray-500 mt-4">
            Tanggal lahir
          </p>
          <p className="font-medium text-sm">
            {detail?.birthdate
              ? getFormatDate(detail?.birthdate, "DD/MM/YYYY")
              : "-"}
          </p>
          <p className="font-medium text-sm text-gray-500 mt-4">Alamat</p>
          <p className="font-medium text-sm">{detail?.address}</p>
          <h4 className="font-bold mt-8">Data orang tua</h4>
          <p className="font-medium text-sm text-gray-500 mt-4">
            Status orang tua
          </p>
          <p className="font-medium text-sm">{parentRole ?? "-"}</p>
          <p className="font-medium text-sm text-gray-500 mt-4">
            Nama orang tua
          </p>
          <p className="font-medium text-sm">{parent?.name ?? "-"}</p>
          <p className="font-medium text-sm text-gray-500 mt-4">Email</p>
          <p className="font-medium text-sm">{parent?.email ?? "-"}</p>
          <p className="font-medium text-sm text-gray-500 mt-4">
            No. handphone
          </p>
          <p className="font-medium text-sm">{parent?.phone_number ?? "-"}</p>
        </div>
      </div>
      {detail && (
        <Edit
          show={show}
          onClose={toggleEdit}
          onSuccess={handleEdit}
          data={detail}
        />
      )}
    </>
  );
};

export default memo(Information);
