import {FC, useEffect, useState} from 'react';
import {useParams, useNavigate} from 'react-router-dom';
import ReactQuill from 'react-quill';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import useModule from 'services/useModule';
import toast from 'react-hot-toast';
import {FormProvider, useForm} from 'react-hook-form';

import AddMaterial from './fragment/Modal/AddModule';
import AddQuestion from './fragment/Modal/Questions';
import SuccessModal from './fragment/Modal/Success';
import { Badge, Button } from 'components';

const Toolbar = [
  [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
  [{ 'align': [] }],
  ['bold', 'italic', 'underline', 'strike'],
  [{'list': 'bullet'}, {'list': 'ordered'}],
  ['link', 'image'],
  ['code-block', 'blockquote']
];

export const AddModule: FC = () => {
  const {directoryId} = useParams();
  const navigate = useNavigate();

  const {addModule} = useModule();

  const [description, setDescription] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [showQuestion, setShowQuestion] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [assets, setAssets] = useState<LocalAsset[]>([]);
  const [disabled, setDisabled] = useState(true);
  const [title, setTitle] = useState('');

  const methods = useForm({
    defaultValues: {
      question_package: {
        id: 0,
        n_essay: 0,
        n_multiple_choice: 0,
        name: '',
        test_count: 'all',
        total_n_essay: 0,
        total_n_multiple_choice: 0,
      }
    }
  });
  const {watch, setValue, getValues} = methods;
  const questionPackage = getValues('question_package');
  const questionId = watch('question_package.id');

  useEffect(() => {
    const localAsset = localStorage.getItem('assets');

    if (localAsset) {
      const parseAsset = JSON.parse(localAsset);

      if (directoryId !== parseAsset?.directoryId) {
        localStorage.removeItem('assets');
      } else {
        const dataAsset = parseAsset?.data || [];
        setAssets(dataAsset);
      }
    };
  }, []);

  useEffect(() => {
    const editorValue = description.replace(/<(.|\n)*?>/g, '').trim();
    if (!title || !editorValue || !assets.length) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [title, description, assets]);

  const removeAsset = (id: number) => {
    const filterAsset = assets.filter((item) => item.id !== id);
    const localAsset = {
      directoryId,
      data: filterAsset
    };
    
    localStorage.setItem('assets', JSON.stringify(localAsset));
    setAssets(filterAsset);
  };

  const handleSubmit = async () => {
    const ids = assets.map((item) => item.id);

    toast.promise(
      addModule({
        directory_id: Number(directoryId),
        description,
        name: title,
        asset_ids: ids,
        question_package: {
          id: questionPackage.id,
          n_multiple_choice: questionPackage.n_multiple_choice,
          n_essay: questionPackage.n_essay,
        }
      }), {
        loading: 'loading...',
        success: () => {
          localStorage.removeItem('assets');
          setShowSuccess(true);
          return ''
        },
        error: 'Gagal menambah!'
      }
    );
  };

  const closeSuccess = () => {
    setShowSuccess(false);
    navigate('/modul', {
      replace: true
    });
  };

  const removeQuestion = () => {
    setValue('question_package.id', 0);
  };

  return (
    <FormProvider {...methods}>
      <div className='bg-white rounded-xl mb-5'>
        <div className='px-5 py-4 border-b border-gray-200'>
          <p className='font-bold text-xl'>Informasi modul</p>
        </div>
        <div className='px-5 py-4'>
          <p className='text-sm mb-2'>
            Judul
            <span className='text-red-500'> *</span>
          </p>
          <input
            type="text"
            placeholder='Masukan judul modul'
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className='py-2 border-b border-gray-200 w-2/4 mb-8'
          />
          <p className='text-sm mb-2'>
            Deskripsi
            <span className='text-red-500'> *</span>
          </p>
          <ReactQuill
            theme="snow"
            value={description}
            onChange={setDescription}
            modules={{toolbar: Toolbar}}
            className='rounded-xl border'
          />
        </div>
      </div>
      <div className='grid gap-y-5'>
        <div className='bg-white rounded-xl'>
          <div className='px-5 py-4 border-b border-gray-200 flex justify-between items-center'>
            <p className='font-bold text-xl'>Materi</p>
            <button
              className='bg-primary px-4 py-[0.625rem] rounded-xl'
              onClick={() => setShowModal(true)}
            >
              <FontAwesomeIcon icon={icon({name: 'plus'})} />
              <span className='ml-[0.625rem]'>Tambah materi</span>
            </button>
          </div>
          <div className='px-5 py-4'>
            {assets.length ? (
              assets.map((item, index) => {
                const sizeKB = item.size / 1000;
                let size = `${sizeKB.toFixed()}KB`;

                if (sizeKB > 1000) {
                  const sizeMB = sizeKB / 1000;
                  size = `${sizeMB.toFixed()}MB`;
                }

                return (
                  <div className='py-4 pl-3 pr-5 flex justify-between bg-gray-100 rounded' key={item.id}>
                    <div className='flex gap-x-9'>
                      <p>{`Modul ${index + 1}`}</p>
                      <div className='flex gap-x-2 items-center'>
                        <FontAwesomeIcon icon={icon({name: 'file-invoice'})} />
                        <p className='text-gray-800'>{item.name}</p>
                      </div>
                    </div>
                    <div className='flex items-center'>
                      <p className='mr-10 text-sm text-gray-500'>
                        {`${size}`}
                      </p>
                      <FontAwesomeIcon
                        icon={icon({name: 'eye', style: 'regular'})}
                        className='mr-3 cursor-pointer'
                        onClick={() => window.open(item.url, '_blank')}
                      />
                      <FontAwesomeIcon
                        icon={icon({name: 'trash-can', style: 'regular'})}
                        className='cursor-pointer'
                        onClick={() => removeAsset(item.id)}
                      />
                    </div>
                  </div>
                )
              })
            ) : (
              <p className='py-6 text-gray-400 text-sm'>Anda belum menambahkan modul apapun.</p>
            )}
          </div>
        </div>
        <div className='bg-white rounded-xl'>
          <div className='px-5 py-4 border-b border-gray-200 flex justify-between items-center'>
            <p className='font-bold text-xl'>Soal</p>
            <button
              className='bg-primary px-4 py-[0.625rem] rounded-xl'
              onClick={() => setShowQuestion(true)}
            >
              {questionId ? (
                <FontAwesomeIcon icon={icon({name: 'pen-to-square', style: 'regular'})} />
              ) : (
                <FontAwesomeIcon icon={icon({name: 'plus'})} />
              )}
              <span className='ml-[0.625rem]'>{`${questionId ? 'Ganti' : 'Tambah'} soal`}</span>
            </button>
          </div>
          <div className='px-5 py-4'>
            {questionId ? (
              <div className='py-4 pl-3 pr-5 flex justify-between'>
                <div className='flex gap-x-2 items-center'>
                  <FontAwesomeIcon icon={icon({name: 'file-invoice'})} />
                  <p className='text-gray-800'>{questionPackage.name}</p>
                </div>
                <div className='flex items-center gap-x-2'>
                  <Badge color='blue'>
                    {`${questionPackage.n_multiple_choice} PG`}
                  </Badge>
                  <Badge color='success' className='mr-6'>
                    {`${questionPackage.n_essay} essay`}
                  </Badge>
                  <FontAwesomeIcon
                    icon={icon({name: 'trash-can', style: 'regular'})}
                    className='cursor-pointer'
                    onClick={removeQuestion}
                  />
                </div>
              </div>
            ) : (
              <p className='py-6 text-gray-400 text-sm'>Anda belum menambahkan soal apapun.</p>
            )}
          </div>
        </div>
        <div className="flex justify-end gap-x-2">
          <Button.Cancel onButtonClick={() => navigate('/modul')} />
          <Button color='primary' onButtonClick={handleSubmit} isDisabled={disabled}>
            <FontAwesomeIcon
              icon={icon({name: 'floppy-disk', style: 'regular'})}
              className='mr-[0.625rem]'
            />
            {'Simpan'}
          </Button>
        </div>
      </div>
      <SuccessModal show={showSuccess} onClose={closeSuccess} />
      <AddMaterial
        show={showModal}
        onClose={() => setShowModal(false)}
        directoryId={directoryId}
        onSubmit={(data) => setAssets(data)}
      />
      <AddQuestion
        show={showQuestion}
        onClose={() => setShowQuestion(false)}
        directoryId={directoryId}
        onSubmit={(data) => setAssets(data)}
      />
    </FormProvider>
  );
};

export default AddModule;
