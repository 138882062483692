import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Listbox } from "@headlessui/react";
import { Button, Input, InputNumber, Label, TextArea } from "components";
import Modal from "components/Modal";
import { DatePicker } from "components/datepicker";
import { PARENTAL_STATUS } from "constant";
import { FC, Fragment, memo, useEffect, useMemo, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import useStudent from "services/useStudent";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { validateEmail } from "utilities";

type Props = {
  show: boolean;
  onClose: () => void;
  onSuccess: (data: StudentData) => void;
  data: StudentData;
};

type PARENT_ROLE = (typeof PARENTAL_STATUS)[0];

const Edit: FC<Props> = ({ show, onClose, data, onSuccess }) => {
  const { edit } = useStudent();
  const { pathname } = useLocation();
  const id = pathname.split("/siswa/")[1];

  const { parent } = data;

  const { control, setValue, watch, handleSubmit } = useForm<StudentPayload>({
    defaultValues: {
      name: data.name,
      nik: data.nik,
      email: data.email,
      phone_number: data.phone_number,
      birthdate: data.birthdate,
      address: data.address ?? "",
      parents: {
        role: parent?.role ?? "",
        name: parent?.name ?? "",
        email: parent?.email ?? "",
        phone_number: parent?.phone_number ?? "",
        password: "",
      },
      password: "",
      role: "student",
      phone_code: "+62",
    },
  });
  const { name, nik, email, phone_number, birthdate, address, parents } =
    watch();
  const {
    email: parentEmail,
    name: parentName,
    phone_number: parentPhone,
    role: parentRole,
  } = parents ?? {};

  const [role, setRole] = useState<PARENT_ROLE | null>(null);

  const validateParent = useMemo(() => {
    if (parentEmail || parentName || parentPhone || parentRole) {
      const validEmail = validateEmail(String(parentEmail));

      return Boolean(validEmail && parentName && parentPhone && parentRole);
    }

    return true;
  }, [parentEmail, parentName, parentPhone, parentRole]);

  const canSubmit = useMemo(() => {
    const validEmail = validateEmail(String(email));

    return Boolean(
      name &&
        nik &&
        validEmail &&
        phone_number &&
        birthdate &&
        address &&
        validateParent
    );
  }, [name, nik, email, phone_number, birthdate, address, validateParent]);

  useEffect(() => {
    const currentRole = PARENTAL_STATUS.find((val) => val.id === parent?.role);
    setRole(currentRole ?? null);
  }, [parent?.role]);

  const handleRole = (value?: PARENT_ROLE) => {
    setRole(value ?? null);
    setValue("parents.role", String(value?.id ?? ""));
  };

  const handleClose = () => {
    onClose();
    setValue("name", data.name);
    setValue("nik", data.nik);
    setValue("email", data.email);
    setValue("phone_number", data.phone_number);
    setValue("birthdate", data.birthdate);
    setValue("address", data.address);
    setValue("parents.role", data.parent?.role ?? "-");
    setValue("parents.name", data.parent?.name ?? "-");
    setValue("parents.email", data.parent?.email ?? "-");
    setValue("parents.phone_number", data.parent?.phone_number ?? "-");
  };

  const handleEdit = (params: StudentPayload) => {
    toast.promise(edit(id, params), {
      loading: "...",
      success: () => {
        const newData = {
          name: params.name,
          nik: params.nik,
          email: params.email,
          phone_number: params.phone_number,
          birthdate: params.birthdate,
          address: params.address,
          parent: params.parents,
        };
        onSuccess(newData as StudentData);
        return "Data berhasil diperbarui";
      },
      error: (err) => {
        return err;
      },
    });
  };

  return (
    <Modal
      title="Edit siswa"
      show={show}
      onClose={onClose}
      customFooter={
        <div className="float-right space-x-2">
          <Button.Cancel onButtonClick={handleClose} />
          <Button
            color="primary"
            isDisabled={!canSubmit}
            onButtonClick={handleSubmit((data) => handleEdit(data))}
          >
            Simpan
          </Button>
        </div>
      }
    >
      <div className="grid grid-cols-2 gap-x-[3.75rem]">
        <div className="space-y-5">
          <Controller
            control={control}
            name="name"
            render={({ field }) => (
              <Input
                label="Nama"
                placeholder="Nama siswa"
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="nik"
            render={({ field }) => (
              <InputNumber
                label="NISN"
                options={{ numericOnly: true }}
                placeholder="Masukkan NISN"
                className="w-full border border-gray-300 rounded-lg px-3.5 py-2.5"
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <Input.Email
                label="Email"
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="phone_number"
            render={({ field }) => (
              <div>
                <Label>No. handphone</Label>
                <div className="`w-full flex items-center gap-x-2 mt-2 border rounded-lg px-3.5 py-2.5 bg-white">
                  <span>+62</span>
                  <InputNumber
                    label=""
                    placeholder="Masukkan nomor handphone"
                    value={field.value}
                    onChange={field.onChange}
                    className="w-full"
                    options={{
                      phone: true,
                      phoneRegionCode: "ID",
                    }}
                  />
                </div>
              </div>
            )}
          />
          <Controller
            control={control}
            name="birthdate"
            render={({ field }) => (
              <DatePicker
                label="Tanggal lahir"
                placeholder="Masukkan tanggal lahir"
                minDate={new Date("1945-01-01")}
                value={field.value}
                onChange={(val) => field.onChange(val)}
              />
            )}
          />
          <Controller
            control={control}
            name="address"
            render={({ field }) => (
              <TextArea
                label="Alamat"
                placeholder="Masukkan alamat"
                className="border w-full resize-none rounded-lg px-3.5 py-3 placeholder:text-gray-500 min-h-[10rem]"
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </div>
        <div className="space-y-5">
          <div className="relative flex-1 min-w-[12rem]">
            <Label>Status orang tua</Label>
            <Listbox value={role} onChange={handleRole}>
              <Listbox.Button
                className={`relative flex w-full cursor-default items-center justify-between gap-x-1 rounded-lg border px-3.5 py-2.5 text-left bg-white`}
              >
                <span className="block truncate">
                  {role?.name || "Status orang tua"}
                </span>
                {role?.id ? (
                  <FontAwesomeIcon
                    icon={icon({ name: "close" })}
                    onClick={() => handleRole()}
                  />
                ) : (
                  <FontAwesomeIcon icon={icon({ name: "chevron-down" })} />
                )}
              </Listbox.Button>
              <Listbox.Options className="absolute z-10 mt-1 max-h-48 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {PARENTAL_STATUS.map((val, index) => (
                  <Listbox.Option
                    key={`${index}-${val.id}`}
                    as={Fragment}
                    value={val}
                  >
                    {({ active }) => (
                      <li
                        className={`relative flex items-center justify-between cursor-default select-none px-3.5 py-2.5 ${
                          active ? "bg-gray-50" : "text-gray-900"
                        }`}
                      >
                        <span className="block truncate">{val.name}</span>
                        {role && val.id === String(role?.id) && (
                          <FontAwesomeIcon
                            icon={icon({ name: "check" })}
                            color="#7F56D9"
                          />
                        )}
                      </li>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Listbox>
          </div>
          <Controller
            control={control}
            name="parents.name"
            render={({ field }) => (
              <Input
                label="Nama orang tua"
                placeholder="Masukkan nama orang tua"
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="parents.email"
            render={({ field }) => (
              <Input.Email
                label="Email"
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
          <Controller
            control={control}
            name="parents.phone_number"
            render={({ field }) => (
              <div>
                <Label>No. handphone</Label>
                <div className="w-full flex items-center gap-x-2 mt-2 border rounded-lg px-3.5 py-2.5 bg-white">
                  <span>+62</span>
                  <InputNumber
                    label=""
                    placeholder="Masukkan nomor handphone"
                    value={field.value}
                    onChange={field.onChange}
                    className="w-full"
                    options={{
                      phone: true,
                      phoneRegionCode: "ID",
                    }}
                  />
                </div>
              </div>
            )}
          />
        </div>
      </div>
    </Modal>
  );
};

export default memo(Edit);
