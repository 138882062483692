// React
import { Fragment, PropsWithChildren } from "react"

function Skeleton(props: PropsWithChildren<{}>) {
  return <Fragment>{props.children}</Fragment>
}

function Label() {
  return <div className="skeleton h-6 w-28 mb-1" />
}

function List() {
  return <div className="skeleton h-4 mb-1" />
}

Skeleton.Label = Label
Skeleton.List = List

export { Skeleton }