import useLiveClass from "../../../services/useLiveClass";
import {useCallback, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import {useDebounce} from "../../../utilities";
import {PAGE_LIMIT} from "../../../constant";
import {useNavigate} from "react-router-dom";

const defaultPagination: PaginationResponse = {
  current_page: 1,
  per_page: 10,
  last_page: 1,
  from: 1,
  to: 1,
  total: 1,
};

const LiveClassMonitoring = () => {
  const {getMonitoringLiveClass} = useLiveClass();
  const navigate = useNavigate();
  const [search, setSearch] = useState('');
  const [pagination, setPagination] = useState(defaultPagination);

  const [data, setData] = useState<LiveClassMonitoringResponse>();
  const debounceSearch = useDebounce(search);

  const getData = useCallback(async (params?: LiveClassMonitoringParams) => {
    const response = await getMonitoringLiveClass({page: pagination.current_page, per_page: pagination.per_page.toString(), search: debounceSearch, ...params});
    const { data, ...restData } = response?.data;
    setPagination(restData);
    setData(response?.data || []);
  }, [debounceSearch]);

  useEffect(()=>{
    void getData({ page: 1, per_page: pagination.per_page.toString()})
  },[debounceSearch])

  const onClickJoin = (programId: string, meetId: string) => {
    if (programId && meetId){
      navigate(`/live-class/program/${programId}/meet/${meetId}`)
    }
  }


  return (
    <div className='space-y-6 flex flex-1 flex-col'>
      <h2 className={'font-bold text-xl text-[#2C3131]'}>Monitoring</h2>
      <div className='flex justify-between items-center'>
        <p className='text-gray-600 font-bold'>{`Total ${data?.total ?? '-'} program berjalan`}</p>
        <div className='bg-white px-4 py-[0.625rem] rounded-lg flex gap-x-2 items-center border border-gray-300'>
          <FontAwesomeIcon icon={icon({name: 'search'})} className={'text-gray-500'}/>
          <input
            className='w-full'
            placeholder='Cari program...'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
      </div>
      <div className='flex-1 space-y-3'>
        {data?.data?.map((item)=>(
          <div className='grid grid-cols-7 space-x-6 justify-between flex-1 bg-white p-4 items-center rounded-md'>
            <div className='flex flex-col flex-2'>
              <p className='text-lg font-medium'>{item?.title}</p>
              <p className='text-gray-600 text-sm'>Senin, 30 November 2023</p>
            </div>
            <div className='flex flex-col flex-1'>
              <p className='text-gray-600 text-xs'>Waktu</p>
              <p className='text-gray-900 text-sm'>09:00 - 10:30</p>
            </div>
            <div className='flex flex-col flex-1'>
              <p className='text-gray-600 text-xs'>Mata pelajaran</p>
              <p className='text-gray-900 text-sm'>{item?.major_name ?? '-'}</p>
            </div>
            <div className='flex flex-col flex-1'>
              <p className='text-gray-600 text-xs'>Strata</p>
              <p className='text-gray-900 text-sm'>{item?.grade_name ?? '-'}</p>
            </div>
            <div className='flex flex-col flex-1'>
              <p className='text-gray-600 text-xs'>Jumlah siswa</p>
              <p className='text-gray-900 text-sm'>{item.max_students ?? '-'}</p>
            </div>
            <div className='flex flex-col flex-1'>
              <p className='text-gray-600 text-xs'>Pengajar</p>
              <p className='text-gray-900 text-sm'>{item.teacher_name ?? '-'}</p>
            </div>
            <button className='px-6 py-2 bg-[#FFE500] rounded-md text-gray-800 text-sm self-center' onClick={()=>onClickJoin(item.id.toString(), item.meet_class_id)}>
              Join
            </button>
          </div>
        ))}
      </div>
      <Footer pagination={pagination} onGetData={getData} />
    </div>
  )
}

type Props = {
  pagination: PaginationResponse;
  onGetData: (params: LiveClassMonitoringParams) => Promise<void>;
};

const Footer = ({ pagination, onGetData }: Props) => {
  const handleLimit = async (limit: string) => {
    await onGetData({
      page: 1,
      per_page: limit,
    });
  };

  const handlePagination = async (ctrl: "next" | "prev") => {
    const isNext = ctrl === "next";
    const page = isNext
      ? pagination.current_page + 1
      : pagination.current_page - 1;

    if (page > 0 && page <= pagination.last_page) {
      await onGetData({
        page,
        per_page: pagination.per_page.toString()
      });
    }
  };

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        <label htmlFor="limit">ROWS PER PAGE:</label>
        <div className="relative">
          <select
            id="limit"
            className="select bg-transparent border-none w-fit bg-none relative pr-6 z-[1]"
            onChange={(e) => handleLimit(e.target.value)}
            value={pagination.per_page}
          >
            {PAGE_LIMIT.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
          <FontAwesomeIcon
            className="absolute right-0 top-1/4"
            icon={icon({ name: "caret-down" })}
          />
        </div>
      </div>
      <div className="flex gap-3 justify-end items-center">
        <p>{`${pagination?.from ?? 0} - ${pagination?.to ?? 0} of ${
          pagination.total
        }`}</p>
        <FontAwesomeIcon
          icon={icon({ name: "chevron-left" })}
          onClick={() => handlePagination("prev")}
          className="cursor-pointer"
        />
        <FontAwesomeIcon
          icon={icon({ name: "chevron-right" })}
          onClick={() => handlePagination("next")}
          className="cursor-pointer"
        />
      </div>
    </div>
  );
};

export default LiveClassMonitoring
