import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import {useEffect, useState} from "react";
import useWithdrawal from "../../../services/useWithdrawal";

type WithdrawalDataKeyType = 'requested_count'|'success_count' |'failed_count' |'total'
const DATA_MAPPING: {title: string, key: WithdrawalDataKeyType, icon: IconDefinition}[] = [
  {
    title: 'Total pengajuan',
    key: 'total',
    icon: icon({ name: 'wallet' }),
  },
  {
    title: 'Dibayarkan',
    key: 'success_count',
    icon: icon({ name: 'check' }),
  },
  {
    title: 'Ditolak',
    key: 'failed_count',
    icon: icon({ name: 'times' }),
  },
  {
    title: 'Belum diproses',
    key: 'requested_count',
    icon: icon({ name: 'stopwatch' }),
  },
]

const WithdrawalSummary = () => {
  const {getSummary} = useWithdrawal();
  const [data, setData] = useState<WithdrawalSummaryResponse>();

  useEffect(()=>{
    getSummary().then((resp)=>{
      setData(resp)
    }).catch((err)=>console.log({err}))
  },[])
  if (!data) return null
  return(
    <div className='flex flex-row space-x-4'>
      {DATA_MAPPING.map(({title, key, icon})=>
        <SummaryCard
          title={title}
          value={(data[key]).toFixed(1).replace(/[.,]0$/, "").toString()}
          icon={icon}
        />
      )}
    </div>
  )
}

const SummaryCard = ({ title, value, icon} : {title: string; value: string, icon: IconDefinition}) => {
  return(
    <div className='flex flex-col bg-white py-5 px-8 rounded-lg space-y-2 flex-1 shadow'>
      <div className='flex flex-row space-x-2 items-center'>
        <div className="flex bg-[#FFE500] w-6 h-6 rounded-full justify-center items-center">
          <FontAwesomeIcon icon={icon} className="h-3 w-3 text-black" />
        </div>
        <span className='text-gray-600 text-sm'>{title}</span>
      </div>
      <span className={'text-3xl font-bold'}>{value}</span>
    </div>
  )
}

export default WithdrawalSummary
