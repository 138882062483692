// ** Auth Endpoints
const config = () => {
  return {
    // ** This will be prefixed in authorization header with token
    // ? e.g. Authorization: Bearer <token>
    tokenType: "Bearer",
  
    // ** Value of this property will be used as key to store JWT token in storage
    storageTokenKeyName: "SESSION",
    storageRefreshTokenKeyName: "refreshToken",
    storageUserKeyName: "userData"
  }
}

export default config