import { memo, FC, useMemo } from "react"

type Props = {
  meetings: LiveclassPayload['question_package']
}

const QuestionComposition: FC<Props> = ({meetings}) => {
  const questionComposition = useMemo(() => {
    const total = meetings.length;
    const totalQuestions = meetings.reduce((total, item) => {
      const questionLength = item.question ? item.question.length : 0;
      return total + questionLength;
    }, 0);


    return {
      total,
      totalQuestions
    };
  }, [meetings]);

  return (
    <div className="grid gap-y-4">
      <div className="flex gap-x-5">
          <span>Total pertemuan:</span>
          <span className="font-bold">{`${questionComposition.total}`}</span>
          <span>Total soal dipilih:</span>
          <span className="font-bold">{questionComposition.totalQuestions}</span>
      </div>
    </div>
  );
}

export default memo(QuestionComposition);
