// Components
import { Button, Card, Content, Datatable, Icon, Select } from "components"
import { CreateSection } from "./components"

export default function ListKelas(): JSX.Element {
  return (
    <Content>
      <Content.Header
        title={(
          <div>
            <section className="flex">
              <Icon.Folder className="w-5 mr-1 fill-primary" />
              <span>Kelas 12 (1)</span>
            </section>

            <section>
              <div className="breadcrumbs text-sm">
                <ul>
                  <li>Silabus</li>
                  <li>Program Lulus</li>
                  <li>
                    <div className="text-primary">
                      Kelas 12 (1)
                    </div>
                  </li>
                </ul>
              </div>
            </section>
          </div>
        )}
        rightElement={<CreateSection />}
      />

      <Content.Body>
        <Card>
          <Card.Header>
            <div className="flex flex-col md:flex-row justify-center md:justify-between items-center">
              <Card.Title>Daftar Materi Tersimpan</Card.Title>

              <Select
                options={[
                  { label: "Terakhir dibuat" }
                ]}
              />
            </div>
          </Card.Header>

          <Card.Body>
            <Datatable>
              <Datatable.Table
                columns={[
                  {
                    title: "#",
                    field: (_, key) => <div className="text-center">{`${key + 1}.`}</div>
                  },
                  {
                    title: "Judul",
                    field: (item) => item.judul
                  },
                  {
                    title: "Tanggal Diubah",
                    field: (item, key) => <div key={key} className="text-center">{item.tanggal}</div>
                  },
                  {
                    title: "Aksi",
                    field: (_, key) => (
                      <div key={key} className="flex flex-row justify-center gap-x-1">
                        <Button.Icon action="delete" />
                        <Button.Icon action="edit" />
                        <Button.Icon action="delete" />
                      </div>
                    )
                  }
                ]}
                data={[
                  {
                    id: 1,
                    judul: "Pembelajaran Dasar",
                    tanggal: new Date().toLocaleDateString('id-ID')
                  }
                ]}
              />

              <Datatable.Pagination
                current_page={0}
                data_length={{ from: 0, to: 0 }}
                onNextClick={() => {}}
                onPreviousClick={() => {}}
                onLengthChange={() => {}}
                rows_per_page={10}
              />
            </Datatable>
          </Card.Body>
        </Card>
      </Content.Body>
    </Content>
  )
}