import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Combobox } from "@headlessui/react";
import { Input, InputNumber, Label, TextArea } from "components";
import { DatePicker } from "components/datepicker";
import { FC, memo, useCallback, useEffect, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import useGrade from "services/useGrade";

type Props = {
  isReview?: boolean;
};

const StudentInfo: FC<Props> = ({ isReview }) => {
  const { getList } = useGrade();
  const { control, setValue, getValues } = useFormContext<StudentPayload>();
  const { grade } = getValues();

  const [gradeList, setGradeList] = useState<GradeItem[]>([]);
  const [selectedGrade, setSelectedGrade] = useState<GradeItem | null>(null);

  const getGrade = useCallback(async () => {
    const resp = await getList();

    setGradeList(resp);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getGrade();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (grade && isReview) {
      setSelectedGrade(grade);
    }
  }, [grade, isReview]);

  const handleGrade = (value: GradeItem) => {
    setSelectedGrade(value);
    setValue("grade_id", String(value.id));
    setValue("grade.id", value.id);
    setValue("grade.name", value.name);
  };

  return (
    <div className="bg-white rounded-xl">
      <p className="font-bold p-5 border-b">Informasi siswa</p>
      <div className="p-5 space-y-5">
        <Controller
          control={control}
          name="nik"
          render={({ field }) => (
            <InputNumber
              label="NISN"
              options={{ numericOnly: true }}
              disabled={isReview}
              placeholder="Masukkan NISN"
              className="w-full border border-gray-300 rounded-lg px-3.5 py-2.5"
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="name"
          render={({ field }) => (
            <Input
              label="Nama siswa"
              placeholder="Masukkan nama siswa"
              disabled={isReview}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />

        <div>
          <Label>Jenjang</Label>
          <Combobox
            disabled={isReview}
            value={selectedGrade}
            onChange={handleGrade}
          >
            <div className="relative">
              <Combobox.Button
                as="div"
                className={`flex border rounded-lg px-3.5 py-2.5 items-center gap-x-2 ${
                  isReview ? "bg-gray-50" : "bg-white"
                }`}
              >
                <span className="w-full">
                  {selectedGrade?.name || "Pilih jenjang"}
                </span>
                <FontAwesomeIcon icon={icon({ name: "chevron-down" })} />
              </Combobox.Button>
              <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black/5 z-10">
                {gradeList.map((grade, index) => (
                  <Combobox.Option
                    key={`${grade.id}-${index}`}
                    className={({ active }) =>
                      `relative cursor-default select-none py-2 pl-10 pr-4 text-gray-900 ${
                        active ? "bg-primary" : ""
                      }`
                    }
                    value={grade}
                  >
                    {({ selected }) => (
                      <>
                        <span
                          className={`block truncate ${
                            selected ? "font-medium" : "font-normal"
                          }`}
                        >
                          {grade.name}
                        </span>
                        {selected ? (
                          <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                            <FontAwesomeIcon icon={icon({ name: "check" })} />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Combobox.Option>
                ))}
              </Combobox.Options>
            </div>
          </Combobox>
        </div>
        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <Input.Email
              label="Email"
              disabled={isReview}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="phone_number"
          render={({ field }) => (
            <div>
              <Label>No. handphone</Label>
              <div
                className={`w-full flex items-center gap-x-2 mt-2 border rounded-lg px-3.5 py-2.5 ${
                  isReview ? "bg-gray-50" : "bg-white"
                }`}
              >
                <span>+62</span>
                <InputNumber
                  label=""
                  placeholder="Masukkan nomor handphone"
                  disabled={isReview}
                  value={field.value}
                  onChange={field.onChange}
                  className="w-full"
                  options={{
                    phone: true,
                    phoneRegionCode: "ID",
                  }}
                />
              </div>
            </div>
          )}
        />
        <Controller
          control={control}
          name="birthdate"
          render={({ field }) => (
            <DatePicker
              label="Tanggal lahir"
              placeholder="Masukkan tanggal lahir"
              minDate={new Date("1945-01-01")}
              disabled={isReview}
              value={field.value}
              onChange={(val) => field.onChange(val)}
            />
          )}
        />
        <Controller
          control={control}
          name="address"
          render={({ field }) => (
            <TextArea
              label="Alamat"
              placeholder="Masukkan alamat"
              disabled={isReview}
              className="border w-full resize-none rounded-lg px-3.5 py-3 placeholder:text-gray-500 min-h-[10rem]"
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="password"
          render={({ field }) => (
            <Input.Password
              label="Password"
              requirement="Minimal 8 karakter terdiri dari huruf kapital, huruf kecil, angka dan
          karakter spesial"
              disabled={isReview}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </div>
    </div>
  );
};

export default memo(StudentInfo);
