// React
import { PropsWithChildren } from "react"

// Components
import { Icon } from "components/Icon"
import { Button } from "components/buttons"
import { Select } from "components/forms"
import { Table } from "./Table"

function Datatable(props: PropsWithChildren<{}>) {
  return (
    <div className="border-[1px] rounded-lg">
      {props.children}
    </div>
  )
}

function Pagination(props: {
  current_page: number
  data_length: {
    from: number
    to: number
  }
  isLoading?: boolean
  onLengthChange: (value: number) => void
  onNextClick: (value: number) => void
  onPreviousClick: (value: number) => void
  rows_per_page: number
}) {
  if (!props.isLoading) {
    return (
      <div className="px-3 py-3 flex flex-col md:flex-row justify-center md:justify-between items-center">
        <section className="flex gap-x-3 items-center">
          <div className="text-sm text-content2 font-semibold whitespace-nowrap">ROWS PER PAGE</div>
  
          <Select
            size="sm"
            className="w-16"
            defaultValue={props.rows_per_page}
            options={[
              { label: 10 },
              { label: 50 },
              { label: 100 }
            ]}
            onChange={e => props.onLengthChange(parseInt(e.target.value ?? "10"))}
          />
        </section>
  
        <section className="flex gap-x-3 items-center bg-transparent">
          <div className="text-sm text-content2 font-semibold whitespace-nowrap">{props.data_length.from} OF {props.data_length.to}</div>
  
          <Button.Icon
            color="transparent"
            tooltipText="Previous"
            onButtonClick={() => {
              if (props.onPreviousClick) {
                props.onPreviousClick(props.current_page - 1)
              }
            }}
          >
            <div className="-rotate-90">
              <Icon.Chevron className="w-3 h-3 fill-content2" />
            </div>
          </Button.Icon>
  
          <Button.Icon
            color="transparent"
            tooltipText="Next"
            onButtonClick={() => {
              if (props.onNextClick) {
                props.onNextClick(props.current_page + 1)
              }
            }}
          >
            <div className="rotate-90">
              <Icon.Chevron className="w-3 h-3 fill-content2" />
            </div>
          </Button.Icon>
        </section>
      </div>
    )
  }

  return null
}

Datatable.Pagination = Pagination
Datatable.Table = Table

export { Datatable }