import React, { FC, Fragment, useRef } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'

type Props = {
  children: React.ReactNode
  show: boolean
  onClose: () => void
  title: string | React.ReactNode
  customFooter?: React.ReactNode
  customTitle?: string
}

const Modal: FC<Props> = ({children, show, onClose, title, customFooter, customTitle}) => {
  const refDiv = useRef(null);

  return (
    <Transition appear show={show} as={Fragment}>
      <Dialog as="div" className="relative z-20" initialFocus={refDiv} onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel ref={refDiv} className="w-full max-w-2xl max-h-[90vh] transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                <div className='flex justify-between items-baseline mb-4'>
                  <Dialog.Title className={customTitle? customTitle : "font-bold text-xl"}>
                    {title}
                  </Dialog.Title>
                  <FontAwesomeIcon
                    icon={icon({name: 'close'})}
                    onClick={onClose}
                    className='cursor-pointer'
                    size='lg'
                  />
                </div>
                <div className='my-4 max-h-[calc(100vh-20rem)] overflow-y-scroll'>
                  {children}
                </div>
                {customFooter ? customFooter : (
                  <button
                    className='float-right py-[0.625rem] px-4 rounded-xl bg-primary'
                    onClick={onClose}
                  >
                    Tutup
                  </button>
                )}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  )
};

export default Modal;
