import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components';
import BrowseDirectory from 'pages/Syllabus/fragment/Modal/BrowseDirectory';
import Breadcrumbs from 'components/Breadcrumbs';
import {FC, memo, useState} from 'react';
import {Disclosure} from '@headlessui/react';
import {useFormContext, useFieldArray, useWatch} from 'react-hook-form';
import {FormSyllabus, Module} from 'views/Silabus/types';
import Modules from './Modules';
import Questions from './Questions';

type GroupedItem = {
  directories: string[];
  directoryId: number;
  modules: Module[];
};

type Props = {
  packageIndex: number
  isPreview?: boolean
}

export type BrowseItemWithDirectory = BrowseItem & {
  directories: string[],
  directoryId: number
}

export type BrowseModuleWithDirectory = Module & {
  directories: string[],
  directoryId: number
}

export type SyllabusModule = {
  directories: string[],
  directoryId: number,
  modules: Module[]
}

const Material: FC<Props> = ({packageIndex, isPreview}) => {
  const [show, setShow] = useState(false);
  const {control} = useFormContext<FormSyllabus>();
  const directories = useWatch({ name: `packages.${packageIndex}.directories`, control });
  const {append, remove} = useFieldArray({
    control,
    name: `packages.${packageIndex}.directories`
  });
  const dirIds = directories.map((val) => val.directoryId);

  const handleSubmit = (submittedDirectories: SyllabusModule[]) => {
    const parsed = groupItems(submittedDirectories)
    const mergedModule = mergeModule(parsed)
    removeAll()
    mergedModule.forEach((parse)=>{
      append({
          crumbs: parse.crumbs,
          modules: parse.modules,
          questions: [],
          directoryId: parse.directoryId
        })
      })
    setShow(false);
  };

  const mergeModule = (newModules: GroupedItem[]) => {
    let newDirectories = directories
    for (let newModule of newModules) {
      let existingIds = newDirectories.map(existing => existing.directoryId);
      if (!existingIds.includes(newModule.directoryId)) {
        newDirectories.push({
          directoryId:newModule.directoryId,
          modules: newModule.modules,
          questions: [],
          crumbs: newModule.directories
        });
      } else {
        for (let existingItem of newDirectories) {
          if (existingItem.directoryId === newModule.directoryId) {
            existingItem.modules = newModule.modules
          }
        }
      }
    }
    return newDirectories
  }

  const removeAll = () => {
    const indexesToDelete = Array.from({ length: directories.length }, (_, index) => index)
    remove(indexesToDelete)
  }

  const groupItems = (originalData: SyllabusModule[]): GroupedItem[] => {
    const groupedData: GroupedItem[] = [];

    const directoryMap: Map<number, GroupedItem> = new Map();

    originalData.forEach((data)=>{
      const { directories, directoryId, modules } = data;
      const existingGroup = directoryMap.get(directoryId);

      if (existingGroup) {
        existingGroup.modules = modules;
      } else {
        const newGroup: GroupedItem = {
          directories,
          directoryId,
          modules: modules,
        };
        directoryMap.set(directoryId, newGroup);
        groupedData.push(newGroup);
      }
    })

    return groupedData;
  }

  return (
    <>
      <section className="grid gap-y-5 mb-20">
        {directories.length ? (
          directories.map((directory, index) => (
            <div
              key={`${packageIndex}-${directory.directoryId}-${index}-${directory.modules.length}`}
              className="bg-white rounded-xl border"
            >
              <Disclosure defaultOpen>
                {({ open }) => {
                  const itemIcon = open
                    ? icon({ name: "chevron-down" })
                    : icon({ name: "chevron-right" });

                  return (
                    <>
                      <div
                        className={
                          "flex w-full justify-between items-center px-5 py-4"
                        }
                      >
                        <Disclosure.Button className="flex items-center gap-x-4 w-full">
                          <FontAwesomeIcon icon={itemIcon} width={16} />
                          <Breadcrumbs crumbs={["Soal", ...directory.crumbs]} />
                        </Disclosure.Button>
                        {!isPreview && (
                          <FontAwesomeIcon
                            icon={icon({ name: "trash-can" })}
                            className="cursor-pointer"
                            onClick={() => remove(index)}
                          />
                        )}
                      </div>

                      <Disclosure.Panel
                        className={"border-t px-5 py-4 grid gap-y-5"}
                      >
                        <Modules
                          directoryId={directory.directoryId}
                          directoryIndex={index}
                          packageIndex={packageIndex}
                          isPreview={Boolean(isPreview)}
                        />
                        <Questions
                          directoryId={directory.directoryId}
                          directoryIndex={index}
                          packageIndex={packageIndex}
                          isPreview={Boolean(isPreview)}
                        />
                      </Disclosure.Panel>
                    </>
                  );
                }}
              </Disclosure>
            </div>
          ))
        ) : (
          <p className="text-gray-400">Anda belum menambahkan bab apapun.</p>
        )}

        {!isPreview && (
          <Button
            color="primary"
            className="gap-x-2 w-max fixed bottom-0 z-10 mb-5"
            onButtonClick={() => setShow(true)}
          >
            <FontAwesomeIcon icon={icon({ name: "rectangle-list" })} />
            <span>Tambah bab</span>
          </Button>
        )}
      </section>
      {!isPreview && (
        <BrowseDirectory
          show={show}
          onClose={() => setShow(false)}
          onSubmit={(item) => {handleSubmit(item); console.log('packageIndex', packageIndex)}}
          title="Pilih modul"
          packageIndex={packageIndex}
          directories={dirIds}
        />
      )}
    </>
  );
};

export default memo(Material);
