import { FC, memo, useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useLocation } from "react-router-dom";
import Chart from "react-apexcharts";
import useStudent from "services/useStudent";
import Summary from "./Summary";
import { getFormatDate } from "utilities";

type Props = {
  summary: StudentData | null;
};
const Performance: FC<Props> = ({ summary }) => {
  const { getPerformance } = useStudent();
  const { pathname } = useLocation();
  const id = pathname.split("/siswa/")[1];

  const [performance, setPerformance] =
    useState<Partial<StudentPerformance> | null>(null);
  const [categories, setCategories] = useState<string[]>([]);
  const [material, setMaterial] = useState<number[]>([]);
  const [question, setQuestion] = useState<number[]>([]);

  const getPerformanceData = useCallback(async () => {
    const response = await getPerformance(id);
    const { graph, ...restData } = response;
    const category = Object.keys(graph);
    const arrayGraph = Object.values(graph);
    const dataMaterial = arrayGraph.map((val) => val.last_seen_materi)
    const dataQuestion = arrayGraph.map((val) => val.last_do_question);

    setPerformance(restData);
    setCategories(category);
    setMaterial(dataMaterial);
    setQuestion(dataQuestion);
  }, []);

  useEffect(() => {
    if (id) {
      getPerformanceData();
    }
  }, [id]);

  return (
    <div className="space-y-8">
      <div className="grid grid-cols-2 gap-x-5">
        <div className="bg-white rounded-xl px-5 py-4">
          <div className="flex items-center gap-x-2">
            <FontAwesomeIcon
              icon={icon({ name: "bookmark", style: "regular" })}
              className="bg-primary p-1.5 rounded-full"
              size="sm"
            />
            <label className="text-sm">Jumlah program diikuti</label>
          </div>
          <span className="font-bold text-2xl">
            {performance?.total_program_ownerships ?? "-"}
          </span>
        </div>
        <div className="bg-white rounded-xl px-5 py-4">
          <div className="flex items-center gap-x-2">
            <FontAwesomeIcon
              icon={icon({ name: "comment", style: "regular" })}
              className="bg-primary p-1.5 rounded-full"
              size="sm"
            />
            <label className="text-sm">Jumlah Tanya PR</label>
          </div>
          <span className="font-bold text-2xl">
            {performance?.total_ask_homework_orders ?? "-"}
          </span>
        </div>
      </div>
      <Summary data={summary} />
      <div className="flex rounded-lg bg-white p-6">
        <div className="max-w-[18rem] p-4 flex flex-col justify-center">
          <p className="text-sm">Terakhir materi dilihat</p>
          <p className="mb-4 font-bold">
            {performance?.last_seen_materi
              ? getFormatDate(performance?.last_seen_materi, "DD/MM/YYYY HH:mm")
              : "-"}
          </p>
          <p className="text-sm">Terakhir mengerjakan soal</p>
          <p className="font-bold">
            {performance?.last_do_question
              ? getFormatDate(performance?.last_do_question, "DD/MM/YYYY HH:mm")
              : "-"}
          </p>
        </div>
        <div className="min-h-[18rem] flex-grow">
          <Chart
            options={{
              chart: {
                toolbar: {
                  show: false,
                },
              },
              dataLabels: {
                enabled: false,
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: 24,
                },
              },
              stroke: {
                show: true,
                width: 10,
                colors: ["transparent"],
              },
              xaxis: {
                categories: categories,
                labels: {
                  formatter: function (val) {
                    return `${getFormatDate(val, "DD/MM")}`;
                  },
                },
              },
            }}
            series={[
              {
                name: "Materi dilihat",
                data: material,
                color: "#F94144",
              },
              {
                name: "Soal dikerjakan",
                data: question,
                color: "#90BE6D",
              },
            ]}
            type="bar"
            height={"100%"}
            width={"100%"}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(Performance);
