/* eslint-disable @typescript-eslint/no-unused-vars */
// React
import { useEffect, useState } from "react"

// API
import { services } from "services"

// Components
import { Button, Card, Content, Datatable, Icon, Modal, Select } from "components"

// Third-Party Libraries
import { Link } from "react-router-dom"

// Utilities
import { AssignSiswa, baseData,JadwalKelasProgramDetail } from "../utilities"
import { SaveAsDraft } from "../components/SaveAsDraft"
export default function JadwalKelas() {
    const [isAssignSiswaModalOpen, setAssignSiswaModalOpen] = useState(false); 
    const [isOpenScheduleDetail, setIsOpenScheduleDetail] = useState(false); 
    const [isTetapkanSiswa, setIsTetapkanSiswa] = useState(false); 
    

  function handleBack(){
    window.location.href = '/program/kelas'
  }
  function handleNext(){
    window.location.href = '/program/jadwal-kelas'
  }

  function handleNextScheduleDetail(){
    setIsOpenScheduleDetail(false)
  }

  function handleAssignSiswaModalToggle() {
    setAssignSiswaModalOpen((prev) => !prev); // Toggle the state to show/hide the modal
  }
  function handleScheduleDetailModalToggle() {
    setIsOpenScheduleDetail((prev) => !prev); // Toggle the state to show/hide the modal
  }
  function handleTetapkanSiswaModal() {
    setIsTetapkanSiswa((prev) => !prev); // Toggle the state to show/hide the modal
  }
  
  return (
    <Content>
      <Content.Header
        title={`${JadwalKelasProgramDetail.title} (4/4)`}
        // rightElement={<SaveAsDraft />}
      />

      <Content.Body>
        <Card>
          <Card.Header>
            <div className="flex flex-col md:flex-row justify-center md:justify-between items-center text-black">
              <Card.Title>Daftar {JadwalKelasProgramDetail.title}</Card.Title>

              <Select
                options={[
                  { label: "Terakhir dibuat" }
                ]}
              />
            </div>
          </Card.Header>

          <Card.Body>
            <Datatable>
              <Datatable.Table
                columns={[
                  {
                    title: "#",
                    field: (_, key) => <div className="text-center text-black">{`${key + 1}.`}</div>
                  },
                  {
                    title: "Kelas",
                    field: (item, key) => (
                      <Link key={key} to={`${baseData.url}/list-detail/${item.id}`} className="link link-secondary flex justify-center h-[66px] flex-row items-center">
                        <Icon.Folder className="w-4 fill-primary" />
                        <span className="text-center">{item.program}</span>
                      </Link>
                    )
                  },
                  {
                    title: "Kuota Siswa",
                    field: (item, key) => <div key={key} className="text-center text-black">{item.kuotaSiswa}</div>
                  },
                  {
                    title: "Jadwal",
                    field: (item, key) =>{return item.jadwal === 'Jadwal belum diatur' ? <div key={key} className="text-center w-auto font-medium text-red-500">{item.jadwal}</div> : <button onClick={handleScheduleDetailModalToggle} key={key} className="text-center w-full font-medium text-blue-500">{item.jadwal}</button>}
                  },
                  {
                    title: "Aksi",
                    field: (item, key) => (
                      // <div key={key} className="flex flex-row gap-[16px] text-center">
                      //   <Button.Icon action="edit" />
                      //   <Button.Icon action="delete" />
                      // </div>
                      <div key={key} className="flex flex-row gap-[16px] text-center justify-center h-[66px] items-center">
                      {/* <Button.Icon action="edit"  /> */}
                      <button onClick={handleAssignSiswaModalToggle} className="w-[32px] h-[32px] bg-[#12B76A] flex justify-center items-center rounded-xl"><img src="/asset/users.png" alt="" /></button>
                      <Button.Icon action="delete" />
                      <Button.Icon action="show" /> 
                    </div>
                    )
                  }
                ]}
                data={[
                  {
                    id: 1,
                    program: '12  UTBK (1)',
                    tanggal: new Date().toLocaleDateString('id-ID'),
                    jadwal: 'Lihat jadwal',
                    kuotaSiswa: '-'
                  },
                  {
                    id: 2,
                    program: '12  UTBK (2)',
                    tanggal: new Date().toLocaleDateString('id-ID'),
                    jadwal: 'Jadwal belum diatur',
                    kuotaSiswa: '-'
                  },   {
                    id: 3,
                    program: '12  UTBK (3)',
                    tanggal: new Date().toLocaleDateString('id-ID'),
                    jadwal: 'Jadwal belum diatur',
                    kuotaSiswa: '-'
                  },   {
                    id: 4,
                    program: '12  UTBK (4)',
                    tanggal: new Date().toLocaleDateString('id-ID'),
                    jadwal: 'Jadwal belum diatur',
                    kuotaSiswa: '-'
                  }
                ]}
              />

              

              <Datatable.Pagination
                current_page={0}
                data_length={{ from: 0, to: 0 }}
                onNextClick={() => {}}
                onPreviousClick={() => {}}
                onLengthChange={() => {}}
                rows_per_page={10}
              />
              <div className="flex flex-row w-full justify-end items-center gap-4">
                    <button onClick={handleBack} className="px-[20px] py-[10px] text-[#8C8500] bg-[#FFFBB0] rounded-[4px]">Kembali</button>
                    <button onClick={handleNext} className="px-[20px] py-[10px] text-black bg-[#FFF200] rounded-[4px]">Lanjutkan</button>
                </div>
            </Datatable>
          </Card.Body>
        </Card>
      </Content.Body>

      {isAssignSiswaModalOpen && (
        <Modal size="full" isOpen title='' closeModal={()=> setAssignSiswaModalOpen(false)}>
          <Modal.Body>
          <Content>
      <Content.Body>
        <Card>
          <Card.Header>
            <div className="flex flex-col md:flex-row justify-center md:justify-between items-center text-black">
              <Card.Title>{AssignSiswa.title}</Card.Title>

              <Select
                options={[
                  { label: "Pendaftar Terbaru" }
                ]}
              />
            </div>
          </Card.Header>

          <Card.Body>
            <Datatable>
              <Datatable.Table
                columns={[
                  {
                    title: "#",
                    field: (_, key) => <div className="text-center text-black">{`${key + 1}.`}</div>
                  },
                  {
                    title: "Nama Siswa",
                    field: (item, key) => <div key={key} className="text-center text-black">{item.name}</div>
                  },
                 
                  {
                    title: "Aksi",
                    field: (item, key) => (
                      // <div key={key} className="flex flex-row gap-[16px] text-center">
                      //   <Button.Icon action="edit" />
                      //   <Button.Icon action="delete" />
                      // </div>
                      <div key={key} className="flex flex-row gap-[16px] text-center justify-center h-[66px] items-center">
                      {/* <Button.Icon action="show" />  */}
                      <button onClick={handleTetapkanSiswaModal} className="px-[16px] py-[10px] border-none bg-[#FFF200] flex justify-center items-center text-black rounded-[12px] font-semibold">+ Tetapkan Siswa</button>
                      
                    </div>
                    )
                  }
                ]}
                data={[
                  {
                    id: 1,
                    name: 'Terri Donin'
                  },
                  {
                    id: 2,
                    name: 'Terri Donin'
                  },   {
                    id: 3,
                    name: 'Terri Donin'
                  },   {
                    id: 4,
                    name: 'Terri Donin'
                  }
                ]}
              />

              

              <Datatable.Pagination
                current_page={0}
                data_length={{ from: 0, to: 0 }}
                onNextClick={() => {}}
                onPreviousClick={() => {}}
                onLengthChange={() => {}}
                rows_per_page={10}
              />
             
            </Datatable>
          </Card.Body>
        </Card>
      </Content.Body>
    </Content>
          </Modal.Body>

          <Modal.Footer className="flex justify-end gap-x-2">
          </Modal.Footer>
        </Modal>
      )}

{isTetapkanSiswa && (
        <Modal size="lg" isOpen title="Tetapkan Siswa" closeModal={()=> setIsTetapkanSiswa(false)}>
          <Modal.Body>
          <Content>
      <Content.Body>
        <Card>
          <Card.Body>
            <div className="flex flex-col w-full">
            <p className="text-black">Apakah anda yakin untuk mentapkan <span className="font-semibold">Nama Siswa</span> ke kelas <span className="font-semibold">12 UTBK (1)</span>? </p>
            <div className="flex flex-row gap-2 w-full justify-end items-center">
                <button onClick={()=>setIsTetapkanSiswa(false)} className="px-[16px] py-[10px] bg-white text-[#FFF200] flex justify-center items-center">
                    Tetapkan
                </button>
                <button onClick={()=>setIsTetapkanSiswa(false)} className="px-[16px] py-[10px] bg-white text-[#7A271A] flex justify-center items-center">
                    Batal
                </button>
            </div>
            </div>
          </Card.Body>
        </Card>
      </Content.Body>
    </Content>
          </Modal.Body>
        </Modal>
      )}

{isOpenScheduleDetail && (
        <Modal size="full" isOpen title="" closeModal={()=> setIsOpenScheduleDetail(false)}>
          <Modal.Body>
          <Content>
      <Content.Body>
        <Card>
          <Card.Header>
            <div className="flex flex-col md:flex-row justify-center md:justify-between items-center text-black">
              <Card.Title>{JadwalKelasProgramDetail.title}</Card.Title>
            </div>
          </Card.Header>

          <Card.Body>
            <Datatable>
              <Datatable.Table
                columns={[
                  {
                    title: "#",
                    field: (_, key) => <div className="text-center text-black">{`${key + 1}.`}</div>
                  },
                  {
                    title: "Judul Pertemuan",
                    field: (item, key) => <div key={key} className="text-center text-black">{item.name}</div>
                  },
                  {
                    title: "Tanggal Waktu",
                    field: (item, key) => <input className="h-[36px] w-full bg-gray-300 flex flex-row justify-between items-center rounded-[8px] px-[16px] text-black" type="date"/>
                  },
                  {
                    title: "Lokasi",
                    field: (item, key) => <select className="bg-white h-[36px] border-[1px] border-gray-400 w-full focus:border-b-[1px] focus:border-b-[#FFF200] text-black rounded-[8px]">  <option value="" disabled selected>
                    Pilih lokasi
                        </option>
                        <option value="lokasi1">Lokasi 1</option>
                        <option value="lokasi2">Lokasi 2</option>
                        <option value="lokasi3">Lokasi 3</option></select>
                  },
                ]}
                data={[
                  {
                    id: 1,
                    name: 'Pertemuan 1 - Dasar UTBK'
                  },
                  {
                    id: 2,
                    name: 'Pertemuan 1 - Dasar UTBK'
                  },   {
                    id: 3,
                    name: 'Pertemuan 1 - Dasar UTBK'
                  },   {
                    id: 4,
                    name: 'Pertemuan 1 - Dasar UTBK'
                  }
                ]}
              />

              

              <Datatable.Pagination
                current_page={0}
                data_length={{ from: 0, to: 0 }}
                onNextClick={() => {}}
                onPreviousClick={() => {}}
                onLengthChange={() => {}}
                rows_per_page={10}
              />
            </Datatable>
          </Card.Body>
        </Card>
      </Content.Body>
    </Content>
          </Modal.Body>

          <Modal.Footer className="flex justify-end gap-x-2">
             <div className="flex flex-row w-full justify-end items-center gap-4">
                    <button onClick={()=> setIsOpenScheduleDetail(false)} className="px-[20px] py-[10px] text-[#8C8500] bg-[#FFFBB0] rounded-[4px]">Kembali</button>
                    <button onClick={handleNextScheduleDetail} className="px-[20px] py-[10px] text-black bg-[#FFF200] rounded-[4px]">Lanjutkan</button>
                </div>
          </Modal.Footer>
        </Modal>
      )}
    </Content>
  )
}