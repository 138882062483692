// React
import { PropsWithChildren } from "react";

type Props = {
  id?: string;
  required?: boolean;
};

export function Label(props: PropsWithChildren<Props>): JSX.Element {
  const {id, required, children} = props;

  return (
    <div className="mb-2">
      <label htmlFor={id} className={`font-medium text-sm ${required ? 'after:content-["*"]' : ''} after:text-red-500`}>
        {children}
      </label>
    </div>
  );
}
