import { FC, memo } from "react";

type Props = {
  data: Common[];
  isColumn?: boolean;
  title: string;
};

const Level: FC<Props> = ({ data, isColumn = false, title }) => {
  return (
    <div
      className={`flex gap-x-2 gap-y-1.5 ${isColumn ? "flex-col" : "flex-row"}`}
    >
      <p className={`text-sm w-28 ${isColumn ? '' : 'py-1'}`}>{title}</p>
      <div className="flex gap-2 flex-wrap flex-1">
        {data.map((val, index) => (
          <span
            key={`level-${val.id}`}
            className="text-xs px-2 py-1.5 rounded-full border-gray-200 border bg-gray-50 h-fit"
          >
            {val.name}
          </span>
        ))}
      </div>
    </div>
  );
};

export default memo(Level);
