import { memo } from "react"
import ParentInfo from './ParentInfo';
import SchoolInfo from './SchoolInfo';
import StudentInfo from './StudentInfo';

const Review = () => {
  return (
    <div className="space-y-5">
      <StudentInfo isReview />
      <SchoolInfo isReview />
      <ParentInfo isReview />
    </div>
  );
}

export default memo(Review);
