import { useEffect, useState } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {FormProvider, useForm} from 'react-hook-form';
import {Button} from 'components';

import DetailProgram from './fragment/AddtionalSteps/DetailProgram';
import Syllabus from './fragment/AddtionalSteps/Syllabus';
import Summary from './fragment/AddtionalSteps/Summary';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import toast from 'react-hot-toast';
import { ErrorFetch } from 'types';
import useProgram from 'services/useProgram';
import Breadcrumbs from 'components/Breadcrumbs';
import ProgressBullet from 'components/ProgressBullet';

const crumbs = ['Program', 'Ubah program'];
const progress = ['Detail program', 'Silabus', 'Ringkasan'];

const Edit = () => {
  const {addProgram} = useProgram();
  const navigate = useNavigate();
  const {id} = useParams();

  const {getDetail} = useProgram();

  const [step, setStep] = useState(0);
  const [data, setData] = useState<ProgramDetail | null>(null);

  const methods = useForm({
    defaultValues: {
      description: data?.description || '',
      syllabus_id: data?.syllabus?.id || 0,
      title: data?.title || '',
      thumbnail_path: data?.thumbnail_path || '',
      price: data?.price ? Number(data.price) : 0,
      discount: data?.discount ? Number(data.discount) : 0,
      is_active: true,
      is_flat_disc: Boolean(data?.is_flat_disc),
      type: data?.type || 'learn'
    }
  });
  const {handleSubmit, setValue} = methods;

  useEffect(() => {
    if (!id) {
      navigate('/program', {
        replace: true
      });
    } else {
      getDetail(Number(id))
      .then((res) => {
        const data: ProgramDetail = res.data;

        if (data) {
          setData(data);
          setValue('description', data.description);
          setValue('syllabus_id', data.syllabus.id);
          setValue('title', data.title);
          setValue('thumbnail_path', data.thumbnail_path);
          setValue('price', data?.price ? Number(data.price) : 0);
          setValue('discount', data?.discount ? Number(data.discount) : 0);
          setValue('is_flat_disc', Boolean(data?.is_flat_disc));
          setValue('type', data.type);
          localStorage.setItem("syllabus", JSON.stringify(data.syllabus));
        }
      })
      .catch(() => navigate('/program', {
        replace: true
      }))
    }
  }, [id]);

  const onSubmit = (data: ProgramPayload, isDraft?: boolean) => {
    const payload: ProgramPayload = {
      ...data,
      is_draft: Boolean(isDraft),
      program_id: Number(id)
    };
    toast.promise(
      addProgram(payload),
      {
        loading: 'Loading...',
        success: () => {
          localStorage.removeItem("syllabus");
          navigate('/program', {
            replace: true
          });
          return ''
        },
        error: (err: ErrorFetch) => {
          const msg = err?.response?.data?.message || 'Gagal menambahkan.';
          return msg;
        }
      }
    )
  };

  const handleNext = () => {
    if (step < 3) {
      setStep(step + 1);
    }
  };

  const handleCancel = () => {
    if (step > 0) {
      setStep(step - 1);
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <div className='flex items-center gap-x-10 my-5'>
        <p className='font-bold text-xl basis-40'>E-Learning</p>
        <ProgressBullet
          data={progress}
          currentStep={step}
          onBack={() => setStep(step - 1)}
          onNext={() => setStep(step + 1)}
        />
      </div>
      <FormProvider {...methods}>
        {step === 0 && (
          <DetailProgram />
        )}
        {step === 1 && (
          <Syllabus />
        )}
        {step === 2 && (
          <Summary />
        )}
      </FormProvider>
      <div className='mt-5 flex justify-between items-center'>
        <Button color='primary' onButtonClick={handleSubmit((data) => onSubmit(data, true))}>
          <FontAwesomeIcon icon={icon({name: 'floppy-disk'})} />
          <span className='ml-3'>Simpan sebagai draft</span>
        </Button>
        <div>
          <Button.Cancel onButtonClick={handleCancel} />
          {step === 2 ? (
            <Button
              color='primary'
              className='ml-3'
              onButtonClick={handleSubmit((data) => onSubmit(data, false))}
            >
              Submit
            </Button>
          ) : (
            <Button
              color='primary'
              className='ml-3'
              onButtonClick={handleNext}
            >
              Lanjutkan
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default Edit;
