import { useCallback, useEffect, useState } from "react";
import Card from "./fragment/card";
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { Spinner } from "components";
import Table from "components/Table";
import DetailOrderModal from "./fragment/DetailOrder";
import useAskHomework, { orderParams } from "services/useAskHomework";
import { convertMinutesToTimeString } from "utilities";
import Filter from "./fragment/Filter";


const defaultPagination: PaginationResponse = {
  current_page: 1,
  per_page: 10,
  last_page: 1,
  from: 1,
  to: 1,
  total: 1,
};

const columns = [
  {
    title: 'Invoice ID',
    isSort: true,
    key: 'invoiceID'
  },
  {
    title: 'Nama paket',
    isSort: true,
    key: 'package'
  },
  {
    title: 'Tanggal',
    isSort: true,
    key: 'date'
  },
  {
    title: 'Tipe',
    isSort: true,
    key: 'type'
  },
  {
    title: 'durasi',
    isSort: true,
    key: 'duration'
  },
  {
    title: 'penjualan',
    isSort: true,
    key: 'order'
  }
];

const defaultParams: orderParams = {
  sort_by: "created_at",
  order_by: "desc",
};


const Order = () => {
  const { getOrderSummary, getOrderData } = useAskHomework();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<orderData[]>([]);
  const [pagination, setPagination] = useState<PaginationResponse>(defaultPagination);
  const [totalOrder, setTotalOrder] = useState(0)
  const [totalSesi, setTotalSesi] = useState(0)
  const [totalPendapatan, setTotalPendapatan] = useState(0)
  const [modalShow, setModalShow] = useState(false);
  const [selectedId, setSelectedId] = useState("0");
  const [sessionType, setSessionType] = useState<any>(null);
  const [sort, setSort] = useState<orderParams["sort_by"]>("created_at");
  const [order, setOrder] = useState<orderParams["order_by"]>("desc");

  const formatCurrency = (number: number) => {
    const formatted = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    }).format(number);
  
    return formatted;
  };

  const toggleModal = (id?: string) => {
    setModalShow(!modalShow); 
    if(id){
      setSelectedId(id)
    }
  };

  const getDataSummary = useCallback(async () => {
    const response: summaryOrderData = await getOrderSummary();
    setTotalOrder(response?.total_order)
    setTotalPendapatan(response?.total_profit)
    setTotalSesi(response?.total_session)
  }, [totalOrder, totalSesi, totalPendapatan])

  const getData = useCallback(async (params?: orderParams) => {
    const response = await getOrderData({...params});
    const { sort_by, order_by, session_type } = params ?? {};
    const { data: responseData, ...restResponse } = response;
    setData(responseData)
    setPagination(restResponse)
    session_type && setSessionType(session_type)
    order_by && setOrder(order_by)
    sort_by && setSort(sort_by)
  }, [data])

  useEffect(() => {
    getDataSummary()
    getData({sort_by: sort, order_by: order})
  },[])

  const handlePagination = async (ctrl: 'next' | 'prev') => {
    const isNext = ctrl === 'next';
    const page = isNext ? pagination.current_page + 1 : pagination.current_page - 1;

    if (page > 0 && page <= pagination.last_page) {
      await getData({page: page, per_page: pagination.per_page, session_type: sessionType, order_by: order, sort_by: sort });
    }
  };

  const handleLimit = async (limit: string) => {
    setPagination(prevPagination => ({
      ...prevPagination,
      per_page: parseInt(limit)
    }));
    await getData({page: 1, per_page: Number(limit), session_type: sessionType, order_by: order, sort_by: sort});
  };
  
  return (
  <>
    <div className="flex gap-5">
        <Card 
          title= {"Total penjualan"}
          icon= {icon({ name: "landmark" })}
          text= {totalOrder}
        />
        <Card 
          title= {"Total sesi"}
          icon= {icon({ name: "user-group" })}
          text= {totalSesi}
        />
        <Card 
          title= {"Total pendapatan"}
          icon= {icon({ name: "landmark" })}
          text= {totalPendapatan}
        />
    </div>
    <div className="bg-white mt-5 rounded-xl">
      <p className="font-bold px-5 py-4 border-b">Daftar Penjualan</p>
      <Filter onGetData= {getData} />
      <Table
        data={data}
        columns={columns}
        currentLimit={pagination?.per_page}
        currentPage={pagination.current_page}
        total={pagination?.total}
        handleFirstColumn="w-fit"
        handleLimit={handleLimit}
        handlePagination={handlePagination}
        handleSort={()=>{}}
      >
        <>
          {data.length ? data?.map((item, index) => {
            return (
              <tr key={index} className='border-b hover:bg-yellow-100 cursor-pointer' onClick={() => toggleModal(item.id)}>
                <td className='p-4 font-[16px]'>
                  <div className='flex gap-x-2 items-baseline'>
                    <span className='cursor-default text-start'>{item.invoice_no}</span>
                  </div>
                </td>
                <td className='p-4 text-gray-500'>{item.title}</td>
                <td className='p-4 text-gray-500'>{item.created_at}</td>
                <td className='p-4 text-gray-500'>{item.session_type}</td>
                <td className='p-4 text-gray-500'>{convertMinutesToTimeString(item.duration_minute)}</td>
                <td className='p-4'>{`${formatCurrency(item.price)}`}</td>
              </tr>
            )
          }) : (
            <tr>
              <td
                colSpan={columns.length}
                className="italic opacity-40 text-center pt-3"
              >
                {loading ? (
                  <Spinner />
                ) : (
                  <span>Tidak ada data.</span>
                )}
              </td>
            </tr>
          )}
        </>
      </Table>
    </div>
    {modalShow && (
        <DetailOrderModal
          show={modalShow}
          id={selectedId} 
          onClose={toggleModal}
        />
      )}
  </>
  );
};

export default Order;
