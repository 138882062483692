import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from 'components';
import {FC, memo} from 'react';
import { QUESTION_DIFFICULTY } from 'constant';

type Props = {
  data: SyllabusDetail['packages'][0]['directories'][0]['questions']
}

const Questions: FC<Props> = (props) => {
  const {data} = props;

  return (
    <>
      <section className='border rounded-xl overflow-hidden'>
        <div className='px-5 py-4 flex justify-between items-center border-b bg-slate-100'>
          <p className='font-bold'>Soal bab</p>
        </div>
        {data.length ? (
          <div className='px-5 py-4 grid gap-y-[0.625rem]'>
            {data.map((question, index) => {
              return (
                <div key={`question-${question.id}`} className='flex w-full justify-between items-center'>
                  <div className='flex items-center gap-x-2'>
                    <FontAwesomeIcon icon={icon({name: 'file-lines'})} />
                    <p>{question.name}</p>
                  </div>
                  <div className='flex items-center gap-x-4'>
                    <Badge color='blue'>
                      {`${question.n_multiple_choice} PG`}
                    </Badge>
                    <Badge color='success'>
                      {`${question.n_essay} essay`}
                    </Badge>
                    <Badge color='gray'>
                      {`${QUESTION_DIFFICULTY[Number(question.difficulty)]}`}
                    </Badge>
                  </div>
                </div>
              )
            })}
          </div>
        ) : (
          <div className='px-5 py-4'>
            <p className='my-6'>Anda belum menambahkan soal apapun.</p>
          </div>
        )}
      </section>
    </>
  );
};

export default memo(Questions);
