import useAvatar from "../../services/useAvatar";
import {useCallback, useEffect, useRef, useState} from "react";
import toast from "react-hot-toast";

const PoinPage = () => {
  const {getSettings, updateSettings} = useAvatar()
  const [settings, setSettings] = useState<GetAvatarSettingsResponse>([])
  const settingsRef = useRef<GetAvatarSettingsResponse>([])

  const getSettingsCallback = useCallback(async () => {
    const resp = await getSettings();
    settingsRef.current = resp.map(item => ({ ...item })); // to remove reference
    setSettings(resp.map(item => ({ ...item })));
  }, []);

  const onUpdateSettings = async () => {
    const res = await updateSettings({
      settings: settings.map(({id, point})=>({
        id: id,
        point: point
      }))
    })
    if (res === 'success'){
      getSettingsCallback()
      toast.success('Pengaturan poin berhasil diperbarui')
    }
  }

  const cancelUpdate = () => {
    const tempData = settingsRef.current
    setSettings([...tempData])
  }

  const isValueSame = JSON.stringify(settings) === JSON.stringify(settingsRef.current)

  useEffect(() => {
    getSettingsCallback();
  }, [getSettingsCallback]);

  const onChangeField = (index: number, value: string) => {
    let tempData = [...settings]
    tempData[index].point = Number(value)
    setSettings([...tempData])
  }

  return (
    <div>
      <div className='w-full bg-white rounded-md divide-y divide-zinc-200'>
        <h1 className='p-4 font-bold text-xl text-zinc-800'>Konfigurasi poin</h1>
        <div className='p-4 flex flex-col space-y-4'>
          {settings.map(({id, point, remark}, index) => (
            <div key={id} className='flex flex-col space-y-1'>
              <span className='text-gray-700 text-sm font-medium'>{remark}</span>
              <input className='rounded-md border border-gray-300 py-2 px-3 text-gray-900' value={point} onChange={(e)=>onChangeField(index, e.target.value)}/>
            </div>
          ))}
        </div>
      </div>
      <div className='flex fixed py-5 px-10 justify-end gap-x-3 right-0 bottom-0 mt-5 bg-white w-full font-semibold'>
        <button className='text-gray-700 bg-white border border-gray-300 px-6 py-1.5 rounded-md' onClick={cancelUpdate}>Batal</button>
        <button className={`${isValueSame? 'bg-gray-200': 'bg-[#FFE500]'} text-gray-800 px-6 py-1.5 rounded-md`} disabled={isValueSame} onClick={onUpdateSettings}>Simpan</button>
      </div>
    </div>
  )
}

export default PoinPage
