// React
import { useEffect, useState } from "react"

// API
import { services } from "services"

// Third-Party Libraries
import toast from "react-hot-toast"

// Types
import { ErrorFetch, SuccessFetch } from "types"
import { List } from "views/Silabus/types"

// Utilities
import { useListCache } from "utilities"

type Directory = {
  id: number
  path: string
}

export type SortField = 'name' | 'updated_at'
export type SortDir = 'asc' | 'desc'

type Sort = {
  sort_by?: SortField
  sort_dir?: SortDir
}

export interface Params extends Sort {
  directory_id?: number
  includes: 'syllabus'
  page: number
  per_page: number
}

interface Cache extends Params {
  directory: Directory[]
}

export function useList(props: {
  useCache?: boolean
}) {
  // Variables
  const param = {
    includes: "syllabus",
    page: 1,
    per_page: 10,
    directory: []
  }

  // List Cache
  const listCache = useListCache()
  const defaultParam: Cache = props.useCache ? listCache.getCache(param) : param

  // Hooks
  const [data, setData] = useState<List[]>([])
  const [isDirty, setDirty] = useState(false)
  const [isLoading, setLoading] = useState(false)
  const [params, setParams] = useState<Params>(defaultParam)
  const [dataResult, setResult] = useState({
    next: false,
    previous: false,
    from: 0,
    to: 0
  })
  const [directory, setDirectory] = useState<Directory[]>(defaultParam.directory ?? [])

  // Variables
  const latestDirectory = directory[directory.length - 1] ?? null

  useEffect(() => {
    refetch()

    if (!isDirty) {
      setDirty(true)
    }
  
    return () => {
      setData([])
      setLoading(false)
    }

    // eslint-disable-next-line
  }, [params])

  useEffect(() => {
    if (isDirty) {
      setParams(prev => {
        return {
          ...prev,
          page: 1,
          directory_id: latestDirectory?.id
        }
      })
    }

    // eslint-disable-next-line
  }, [directory])
  

  const onAddDirectory = (name: string, id: number) => {
    setDirectory(prev => [...prev, { path: name, id }])
  }

  const onDirectoryClick = (key?: number) => {
    if (key) {
      if (!(key + 1 === directory.length)) {
        // Variables
        const items = [...directory]
  
        items.splice(key + 1)
        setDirectory(items)
      }
    } else {
      setDirectory([])
    }
  }

  const refetch = () => {
    setLoading(true)

    services.get('/browse', { params }).then((res: SuccessFetch<{
      data: {
        data: List[]
        prev_page_url: string | null
        next_page_url: string | null
        from: number
        to: number
      }
    }>) => {
      // Variables
      const dataParams: any = {...params}
      dataParams.directory = directory

      setData(res.data.data.data)
      setResult({
        next: Boolean(res.data.data.next_page_url),
        previous: Boolean(res.data.data.prev_page_url),
        from: res.data.data.from,
        to: res.data.data.to
      })

      listCache.updateCache(dataParams)
    }).catch((err: ErrorFetch) => {
      toast.error(err.response.data.message)
    }).finally(() => {
      setLoading(false)
    })
  }

  const setLength = (value: number) => {
    setParams(prev => {
      return {
        ...prev,
        page: 1,
        per_page: value
      }
    })
  }

  const setPage = (value: number) => {
    setParams(prev => {
      return {
        ...prev,
        page: value
      }
    })
  }

  const setSort = (props: Sort) => {
    setParams(prev => {
      return {
        ...prev,
        ...props
      }
    })
  }

  return {
    data,
    dataResult,
    directoryList: directory,
    isLoading,
    latestDirectory,
    params,
    onAddDirectory,
    onDirectoryClick,
    refetch,
    setLength,
    setPage,
    setSort
  }
}