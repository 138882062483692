import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Elearning from "./Elearning";
import Tryout from "./Tryout";
import Class from "./LiveClass";

const ProgramType = {
  learn: <Elearning />,
  class: <Class />,
  tryout: <Tryout />,
};

type RenderType = keyof typeof ProgramType;

const Add = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  useEffect(() => {
    if (!state) {
      navigate("/program", {
        replace: true,
      });
    }
  }, [state, navigate]);

  return ProgramType[state?.type as RenderType];
};

export default Add;
