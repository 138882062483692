import { useRef } from "react";
import { services } from "services";
import { API_URL } from "constant";

const defaultParams: PromoParams = {
  page: 1,
  per_page: 10,
};

type Response = PaginationResponse & {
  data: PromoItem[];
};

type UsersList = PaginationResponse & {
  data: PromoUsers[];
};

const usePromo = () => {
  const lastPayload = useRef(defaultParams);

  const getList = async (params?: PromoParams): Promise<Response> => {
    try {
      const payload: PromoParams = {
        ...lastPayload.current,
        ...params,
      };

      lastPayload.current = payload;
      const response = await services.get(API_URL.promo, {
        params: payload,
      });

      return response.data;
    } catch (error) {
      return {
        current_page: 0,
        data: [],
        from: 0,
        last_page: 0,
        per_page: 0,
        to: 0,
        total: 0,
      };
    }
  };

  const add = async (params: PromoPayload) => {
    try {
      await services.post(API_URL.promo, params);
    } catch (error) {
      throw error;
    }
  };

  const detail = async (id: string): Promise<PromoDetail> => {
    try {
      const response = await services.get(`${API_URL.promo}/${id}`);

      return response.data?.data;
    } catch (error) {
      return {
        discount: "",
        end_period: "",
        quota: "",
        start_period: "",
        title: "",
        type: "promo_regular",
        promo_objects: [],
        voucher_code: "",
        status: "Nonaktif",
      };
    }
  };

  const users = async (
    id: string,
    params?: PromoParams
  ): Promise<UsersList> => {
    try {
      const response = await services.get(`${API_URL.promo}/${id}/usages`, {
        params: {
          keyword: params?.keyword || "",
          page: params?.page,
          "per-page": params?.per_page,
          "order-date": "desc",
        },
      });

      return response.data;
    } catch (error) {
      return {
        current_page: 0,
        data: [],
        from: 0,
        last_page: 0,
        per_page: 0,
        to: 0,
        total: 0,
      };
    }
  };

  const updatePromo = async (id: number, isActive: boolean) => {
    try {
      const status = isActive ? "active" : "inactive";
      await services.patch(`${API_URL.promo}/${id}/${status}`);
    } catch (error) {
      throw error;
    }
  };

  const deletePromo = async (id: number) => {
    try {
      await services.delete(`${API_URL.promo}/${id}`);
    } catch (error) {
      throw error;
    }
  };

  return {
    getList,
    updatePromo,
    deletePromo,
    add,
    detail,
    users,
  };
};

export default usePromo;
