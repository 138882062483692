import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Listbox } from "@headlessui/react";
import Table from "components/Table";
import { FC, Fragment, memo, useCallback, useEffect, useState } from "react";
import { getDuration, getFormatDate, useDebounce } from "utilities";
import Order from "./Modal/order";
import useHomework from "services/useAskHomework";

const TYPES = [
  {
    id: 0,
    value: "",
    name: "Semua Tipe",
  },
  {
    id: 1,
    value: "chat",
    name: "Chat",
  },
  {
    id: 2,
    value: "video",
    name: "Video",
  },
];

const columns = [
  {
    title: "Tanggal",
    isSort: false,
    isLeft: true,
  },
  {
    title: "Siswa",
    isSort: false,
    isLeft: true,
  },
  {
    title: "Tipe",
    isSort: false,
    isLeft: true,
  },
  {
    title: "Durasi",
    isSort: false,
    isLeft: true,
  },
];

const defaultPagination: PaginationResponse = {
  current_page: 1,
  per_page: 10,
  last_page: 1,
  from: 1,
  to: 1,
  total: 0,
};

type Props = {
  id: string;
};

type Params = {
  page?: number;
  per_page?: number;
  name?: string;
  type?: "chat" | "video" | "";
};

const History: FC<Props> = ({ id }) => {
  const { getHistory, getDetail } = useHomework();

  const [selectedGrade, setSelectedGrade] = useState<GradeItem>(TYPES[0]);
  const [data, setData] = useState<HomeworkSession[]>([]);
  const [pagination, setPagination] = useState(defaultPagination);
  const [detail, setDetail] = useState<HomeworkDetail | null>(null);
  const [showOrder, setShowOrder] = useState(false);
  const [search, setSearch] = useState("");

  const debounceSearch = useDebounce(search);

  const getData = useCallback(
    async (params?: Params) => {
      const response = await getHistory({ id, ...params });
      const { data: responseData, ...restResponse } = response;

      setData(responseData);
      setPagination(restResponse);
    },
    // eslint-disable-next-line
    [id]
  );

  useEffect(() => {
    const currentType = selectedGrade?.value ?? "";
    if (debounceSearch.length >= 3) {
      getData({
        page: 1,
        name: debounceSearch,
        type: currentType as Params["type"],
      });
    }

    if (!debounceSearch.length) {
      getData({ page: 1, name: "", type: currentType as Params["type"] });
    }
    // eslint-disable-next-line
  }, [debounceSearch]);

  const toggleOrder = () => setShowOrder(!showOrder);

  const handleType = async (value: (typeof TYPES)[0]) => {
    if (value.id !== selectedGrade.id) {
      setSelectedGrade(value);
      getData({ type: value.value as Params["type"], name: search });
    }
  };

  const handleHomework = () => {
    getDetail("1")
      .then((response) => {
        setDetail(response);
        toggleOrder();
      })
      .catch(() => console.error("Failed get detail homework!"));
  };

  const handleLimit = (limit: string) => {
    getData({ per_page: Number(limit), page: 1 });
  };

  const handlePagination = (control: string) => {
    const isNext = control === "next";
    const page = isNext
      ? pagination.current_page + 1
      : pagination.current_page - 1;

    if (page > 0 && page <= pagination.last_page) {
      getData({
        page,
        per_page: pagination.per_page,
      });
    }
  };

  return (
    <>
      <div className="bg-white rounded-xl h-fit">
        <p className="font-bold px-5 py-4 border-b">History sesi</p>
        <div className="space-y-4">
          <div className="flex items-center gap-x-5 px-5 pt-5">
            <div className="border rounded-lg px-3.5 py-2 flex items-center w-80">
              <FontAwesomeIcon icon={icon({ name: "search" })} />
              <input
                type="search"
                name=""
                id=""
                placeholder="Cari siswa..."
                className="ml-2 text-sm w-full"
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
            {/* type */}
            <div className="relative w-40">
              <Listbox value={selectedGrade} onChange={handleType}>
                <Listbox.Button className="relative flex w-full cursor-default items-center justify-between gap-x-1 rounded-lg border bg-white px-3.5 py-2.5 text-left text-sm">
                  <span className="block truncate">{selectedGrade.name}</span>
                  <FontAwesomeIcon icon={icon({ name: "chevron-down" })} />
                </Listbox.Button>
                <Listbox.Options className="absolute z-10 mt-1 max-h-48 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                  {TYPES.map((val, index) => (
                    <Listbox.Option key={`${index}`} as={Fragment} value={val}>
                      {({ active }) => (
                        <li
                          className={`relative flex items-center justify-between cursor-default select-none px-3.5 py-2.5 ${
                            active ? "bg-gray-50" : "text-gray-900"
                          }`}
                        >
                          <span className="block truncate">{val.name}</span>
                          {val.id === selectedGrade.id && (
                            <FontAwesomeIcon
                              icon={icon({ name: "check" })}
                              color="#7F56D9"
                            />
                          )}
                        </li>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Listbox>
            </div>
          </div>
          <Table
            data={data}
            columns={columns}
            currentLimit={pagination.per_page}
            total={pagination.total}
            handleFirstColumn="w-44"
            handleLimit={handleLimit}
            handlePagination={handlePagination}
          >
            <>
              {data.map((val, index) => (
                <tr
                  key={index}
                  onClick={handleHomework}
                  className="border-b cursor-pointer hover:bg-gray-50"
                >
                  <td className="p-4">
                    {getFormatDate(val.created_at, "DD/MM/YYYY HH:mm")}
                  </td>
                  <td className="p-4">{val.student_name}</td>
                  <td className="p-4">
                    {val.type === "chat" ? "Chat" : "Video"}
                  </td>
                  <td className="p-4">
                    {val.end_time
                      ? `${getDuration(val.start_time, val.end_time)} menit`
                      : "-"}
                  </td>
                </tr>
              ))}
            </>
          </Table>
        </div>
      </div>
      {detail && <Order show={showOrder} onClose={toggleOrder} data={detail} />}
    </>
  );
};

export default memo(History);
