import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge } from "components";
import ButtonDropdown from "components/dropdowns/ButtonDropdown";
import { FC, memo, useMemo } from "react";
import { currencyConverter, getFormatDate } from "utilities";
import { useNavigate } from "react-router-dom";

type Props = {
  data: BundleItem;
  onUpdate: (item: BundleItem) => void;
};

const Item: FC<Props> = ({ data, onUpdate }) => {
  const navigate = useNavigate();
  const { image, title, start_date, expired_date, bundle_price, status, id } =
    data;

  const isPublished = useMemo(() => {
    return Boolean(Number(status));
  }, [status]);

  const navigateDetail = () => {
    navigate(`${id}`, {
      relative: "path",
    });
  };

  return (
    <div className="bg-white flex flex-wrap gap-5 px-5 py-3 rounded-lg items-center">
      <img
        className="h-24 w-40 object-cover rounded"
        src={image}
        alt={`thumbnail-${title}`}
      />
      <div className="flex-grow md:w-72 max-sm:w-32 flex flex-col justify-between items-start">
        <p className="font-bold text-ellipsis line-clamp-3">{title}</p>
      </div>
      <div className="md:w-52 flex flex-grow flex-col justify-between items-start">
        <div>
          <p className="text-xs">Periode pendaftaran</p>
          <span className="text-sm">{`${getFormatDate(
            start_date,
            "DD/MM/YYYY"
          )} - ${getFormatDate(expired_date, "DD/MM/YYYY")}`}</span>
        </div>
      </div>
      <div className="md:w-36 flex-grow flex flex-col justify-between items-start">
        <div>
          <p className="text-xs">Harga bundling</p>
          <span className="text-sm">
            {currencyConverter(Number(bundle_price))}
          </span>
        </div>
      </div>
      <div className="md:w-28 w-32 flex-grow flex items-center justify-end">
        <Badge color={isPublished ? "success" : "warning-light"}>
          <p className="flex items-center gap-x-1 font-medium text-sm">
            {isPublished ? (
              <FontAwesomeIcon icon={icon({ name: "check-circle" })} />
            ) : (
              <FontAwesomeIcon icon={icon({ name: "pen" })} />
            )}
            <span>{isPublished ? "Published" : "Draft"}</span>
          </p>
        </Badge>
      </div>
      <div className="flex-grow flex items-center justify-end">
        <ButtonDropdown
          menuItems={[
            <button
              className="w-full flex items-center justify-start gap-x-2 px-3.5 py-2.5 hover:bg-gray-50"
              onClick={navigateDetail}
            >
              <FontAwesomeIcon
                icon={icon({ name: "arrow-up-right-from-square" })}
              />
              <p className="text-sm">Detail program</p>
            </button>,
            <button
              className="w-full flex items-center justify-start gap-x-2 px-3.5 py-2.5 hover:bg-gray-50"
              onClick={() => onUpdate(data)}
            >
              {isPublished ? (
                <>
                  <FontAwesomeIcon icon={icon({ name: "rotate-left" })} />
                  <p className="text-sm">Unpublish</p>
                </>
              ) : (
                <>
                  <FontAwesomeIcon
                    icon={icon({
                      name: "paper-plane",
                      style: "regular",
                    })}
                  />
                  <p className="text-sm">Publish</p>
                </>
              )}
            </button>,
            <button
              className="w-full flex items-center justify-start gap-x-2 px-3.5 py-2.5 hover:bg-gray-50"
              // onClick={() => handleDelete(val)}
            >
              <FontAwesomeIcon icon={icon({ name: "trash-alt" })} />
              <p className="text-sm">Remove</p>
            </button>,
          ]}
          widthItems="w-40"
        >
          <div className="text-end">
            <FontAwesomeIcon
              icon={icon({ name: "ellipsis-vertical" })}
              className="cursor-pointer"
            />
          </div>
        </ButtonDropdown>
      </div>
    </div>
  );
};

export default memo(Item);
