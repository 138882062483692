import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";

export interface DropdownProps {
  id?: number;
  label?: string;
  menuItems: React.ReactNode[];
  children: React.ReactNode;
  widthItems?: string;
}

const ButtonDropdown: React.FC<DropdownProps> = (props) => {
  const {menuItems, children, widthItems} = props;

  return (
    <Menu as="div" className="relative inline-block text-left text-black">
      <div>
        <Menu.Button>{children}</Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          unmount={false}
          className={`absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ${widthItems || 'w-full'}`}
        >
          <div className="py-1">
            {menuItems.map((item, index) => (
              <div key={index}>
                <Menu.Item>{item}</Menu.Item>
              </div>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default ButtonDropdown;
