import { FC, memo } from "react";

type Props = {
  image: string;
  size?: number;
};

const Avatar: FC<Props> = ({ image, size = 48 }) => {
  return (
    <img
      src={image || "/assets/logo.png"}
      alt="avatar"
      height={size}
      width={size}
      className={`rounded-full aspect-square object-cover border w-[${size}px] h-[${size}px]`}
    />
  );
};

export default memo(Avatar);
