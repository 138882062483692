import { memo, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { Button } from "components";

import DetailProgram from "./detail";
import Meetings from "./meeting";
import Summary from "./summary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import toast from "react-hot-toast";
import useProgram from "services/useProgram";
import ProgressBullet from "components/ProgressBullet";


const Liveclass = () => {
  const { addLiveclass, updateLiveclass } = useProgram();
  const navigate = useNavigate();
  const [triggerAddMeeting, setTriggerAddMeeting] = useState(false);
  const STEPS = [
    {
      label: "Detail program",
      component: <DetailProgram />,
    },
    {
      label: "Pengaturan",
      component: <Meetings triggerAddMeeting={triggerAddMeeting} />,
    },
    {
      label: "Ringkasan",
      component: <Summary />,
    },
  ];


  const [step, setStep] = useState(0);

  const methods = useForm<LiveclassPayload>({
    defaultValues: {
      description: "",
      discount: 0,
      grade_id: 1,
      is_active: true,
      is_draft: true,
      is_explanation_showed: true,
      is_flat_disc: true,
      is_flat_duration: false,
      is_flat_score: false,
      is_limited_participant: true,
      major_id: 1,
      number_of_participants: 0,
      price: 0,
      program_id: null,
      program_liveclass_id: null,
      question_package: [],
      subject_id: "1",
      syllabus_id: 1,
      teacher_id: "1",
      thumbnail_path: "",
      title: "",
      type: "class",
      variety_id: "",
      class_detail: {
        max_students: 20,
        is_group: 1,
        created_at: "2024-10-10 00:00:00",
        updated_at: "2024-10-10 00:00:00"
      },
      meetings: []
    },
  });
  const { handleSubmit, watch, setValue } = methods;
  const title = watch("title");
  const description = watch("description");
  const thumbnail = watch("thumbnail_path");
  const isLimited = watch("is_limited_participant");
  const participants = watch("number_of_participants");
  const registrationStart = watch("registration_period_start");
  const registrationEnd = watch("registration_period_end");
  const startDate = watch("start_date");
  const endDate = watch("end_date");
  const price = watch("price");
  const questionPerStudent = watch("question_each_student");
  const variety = watch("variety_id");
  const questions = watch("question_package");
  const isFlatScore = watch("is_flat_score");
  const easyScore = watch("score.easy");
  const mediumScore = watch("score.medium");
  const hardScore = watch("score.hard");
  const isFlatDuration = watch("is_flat_duration");
  const easyDuration = watch("duration.easy");
  const mediumDuration = watch("duration.medium");
  const hardDuration = watch("duration.hard");
  const programId = watch("program_id")

  const validateDetail = useMemo(() => {
    const hasParticipant = isLimited ? Boolean(participants) : true;

    return Boolean(
      title &&
        description
    );
  }, [
    title,
    description,
  ]);

  const validateQuestion = useMemo(() => {
    return Boolean(variety && questions.length);
  }, [variety, questions]);

  const validateConfig = useMemo(() => {
    let validScore = false;
    let validDuration = false;
    if (isFlatScore) {
      validScore = Boolean(easyScore);
    } else {
      validScore = Boolean(easyScore && mediumScore && hardScore);
    }

    if (isFlatDuration) {
      validDuration = Boolean(easyDuration);
    } else {
      validDuration = Boolean(easyDuration && mediumDuration && hardDuration);
    }

    return validScore && validDuration;
  }, [
    isFlatScore,
    easyScore,
    mediumScore,
    hardScore,
    isFlatDuration,
    easyDuration,
    mediumDuration,
    hardDuration,
  ]);

  const canContinue = useMemo(() => {
    switch (step) {
      case 0:
        return validateDetail;
      case 1:
        return validateQuestion;
      case 2:
        return validateConfig;
      default:
        return false;
    }
  }, [step, validateDetail, validateQuestion, validateConfig]);

  const onSubmit = (data: LiveclassPayload, isDraft?: boolean) => {
    setValue("is_draft", false);
    const payload: LiveclassPayload = {
      ...data,
      is_draft: Boolean(isDraft),
    };
      programId !== null &&
      toast.promise(updateLiveclass(parseInt(programId, 10), payload), {
        loading: "Loading...",
        success: (response) => {
          navigate("/program", { replace: true });
          return "";
        },
        error: "Terjadi kesalahan",
      });
  };

  const handleNext = (data: LiveclassPayload) => {
    switch (step) {
      case 0:
        programId === null ?
        toast.promise(addLiveclass(data), {
          loading: "Loading...",
          success: (response: any) => {
            setValue("program_id", response?.program_id);
            setValue("program_liveclass_id", response?.id);
            setStep(step + 1);
            return "";
          },
          error: "Terjadi kesalahan",
        }) 
        : 
        toast.promise(updateLiveclass(parseInt(programId, 10), data), {
          loading: "Loading...",
          success: (response: any) => {
            setStep(step + 1);
            return "";
          },
          error: "Terjadi kesalahan",
        });
        break;
      case 1:
        setTriggerAddMeeting(true);
        setTimeout(() => {
          setStep(step + 1);
        }, 1000);
        break;
      case 2:
        setValue("is_draft", false);
        programId !== null &&
        toast.promise(updateLiveclass(parseInt(programId, 10), data), {
          loading: "Loading...",
          success: (response) => {
            navigate("/program", { replace: true });
            return "";
          },
          error: "Terjadi kesalahan",
        });
        break;
      default:
        break;
    }
  };

  const handleNextTrial = () => {
    if (step < 3) {
      setStep(step + 1);
    }
  };

  const handleCancel = () => {
    if (step > 0) {
      setStep(step - 1);
    } else {
      navigate(-1);
    }
    setTriggerAddMeeting(false)
  };

  return (
    <>
      <section className="h-full overflow-y-scroll hide-scrollbar mt-[-1.5rem] pb-20">
        <div className="flex items-center gap-x-10 my-5">
          <p className="font-bold text-xl basis-40">Live Class</p>
          <ProgressBullet
            data={STEPS.map((val) => val.label)}
            currentStep={step}
            onBack={() => setStep(step - 1)}
            onNext={handleSubmit((data) => handleNext(data))}
            canContinue={canContinue}
          />
        </div>
        <FormProvider {...methods}>{STEPS[step].component}</FormProvider>
      </section>
      <div className="flex justify-between items-center absolute left-0 right-0 bottom-0 bg-white px-8 py-5 border-t">
        <Button
          color="outline-gray"
          onButtonClick={handleSubmit((data) => onSubmit(data, false))}
        >
          <FontAwesomeIcon icon={icon({ name: "pen" })} />
          <span className="ml-3">Simpan sebagai draft</span>
        </Button>
        <div className="">
          <Button color="outline-gray" onButtonClick={handleCancel}>
            Batal
          </Button>
          {step === STEPS.length - 1 ? (
            <Button
              color="primary"
              className="ml-3"
              onButtonClick={handleSubmit((data) => onSubmit(data, false))}
            >
              Submit
            </Button>
          ) : (
            <Button
              // isDisabled={!canContinue}
              color="primary"
              className="ml-3"
              onButtonClick={handleSubmit((data) => handleNext(data))}
              // onButtonClick={handleNextTrial}
            >
              Lanjutkan
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default memo(Liveclass);