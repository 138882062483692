import { API_URL } from "constant";
import { services } from "./services";

type Params = {
  page: number;
  name: string;
};

type List = PaginationResponse & {
  data: Common[];
};

const useSchool = () => {
  const getList = async (params?: Params): Promise<List> => {
    try {
      const { page = 1, name = "" } = params ?? {};
      const resp = await services.get(`${API_URL.school}s`, {
        params: {
          page,
          name,
        },
      });

      return resp.data?.data;
    } catch (error) {
      return {
        current_page: 1,
        data: [],
        from: 1,
        last_page: 1,
        per_page: 10,
        to: 1,
        total: 0,
      };
    }
  };

  const getClassBySchool = async (schoolId: string): Promise<Common[]> => {
    try {
      const response = await services.get(
        `${API_URL.school}/${schoolId}/class`
      );

      return response.data?.data;
    } catch (error) {
      return [];
    }
  };

  return {
    getList,
    getClassBySchool,
  };
};

export default useSchool;
