import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {ChangeEvent, FC, memo} from 'react';
import {useFormContext, Controller} from 'react-hook-form';
import {TextArea, InputNumber} from 'components';
import useAsset from 'services/useAsset';

const DetailProgram: FC = () => {
  const {upload} = useAsset();

  const {register, control, setValue} = useFormContext();

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const currentFile = e.target.files?.[0];

    if (currentFile) {
      const response = await upload({
        file: currentFile
      });

      if (response) {
        setValue('thumbnail_path', response.full_asset)
      }
    }
  };

  return (
    <>
      <div className='w-full bg-white rounded-xl mb-5'>
        <div className='px-5 py-4 border-b border-gray-200'>
          <p className='font-bold text-xl'>Informasi program</p>
        </div>
        <div className='px-5 py-4'>
          <p className='text-sm mb-2'>
            Judul
            <span className='text-red-500'> *</span>
          </p>
          <input
            type="text"
            placeholder='Masukan judul modul'
            className='py-2 border-b border-gray-200 w-2/4 mb-8'
            {...register('title')}
          />
          <Controller
            name="description"
            control={control}
            render={({ field }) => (
              <TextArea
                isRichText
                label="Deskripsi"
                required
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </div>
      </div>
      <div className='flex gap-x-5'>
        <div className='bg-white rounded-xl w-[60%]'>
          <div className='px-5 py-4 border-b border-gray-200'>
            <p className='font-bold text-xl'>Harga program</p>
          </div>
          <div className='px-5 py-4 grid gap-y-5'>
            <Controller
              control={control}
              name="price"
              render={({ field, fieldState }) => (
                <InputNumber
                  value={field.value}
                  label="Harga Program"
                  options={{ numeral: true, numeralDecimalMark: ',', delimiter: '.' }}
                  errors={fieldState.error?.message}
                  placeholder="Masukkan harga"
                  onChange={e => {
                    const rawValue = e.target.rawValue;

                    if (!rawValue.startsWith('0')) {
                      field.onChange(Number(rawValue))
                    }
                  }}
                />
              )}
            />

            <div>
              <p className='text-sm mb-2'>
                Diskon
              </p>
              <div className='w-full flex'>
                <Controller
                  control={control}
                  name="discount"
                  render={({ field, fieldState }) => (
                    <InputNumber
                      value={field.value}
                      label=""
                      options={{ numeral: true, numeralDecimalMark: ',', delimiter: '.' }}
                      errors={fieldState.error?.message}
                      placeholder="Masukkan diskon"
                      onChange={e => {
                        const rawValue = e.target.rawValue;

                        if (!rawValue.startsWith('0')) {
                          field.onChange(Number(rawValue))
                        }
                      }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name='is_flat_disc'
                  render={({ field }) => (
                    <select
                      name="isFlatDiscount"
                      id="isFlatDiscount"
                      value={Number(field?.value)}
                      onChange={(e) => {
                        const value = Number(e.target.value);
                        field.onChange(Boolean(value))
                      }}
                    >
                      <option value={1}>Rp</option>
                      <option value={0}>%</option>
                    </select>
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='bg-white rounded-xl w-[40%] flex flex-col'>
          <div className='px-5 py-4 border-b border-gray-200'>
            <p className='font-bold text-xl'>Thumbnail</p>
          </div>
          <Controller
            control={control}
            name='thumbnail_path'
            render={({field}) => (
              <div className='px-5 py-4 h-full'>
                {field.value ? (
                  <div className='relative w-[inherit] h-[inherit] flex justify-center'>
                    <img src={field.value} alt="thumbnail" className='absolute object-contain h-[inherit]' />
                    <FontAwesomeIcon
                      className='absolute top-0 right-0 mr-2 mt-2 cursor-pointer'
                      icon={icon({name: 'trash-can'})}
                      onClick={() => field.onChange('')}
                    />
                  </div>
                ) : (
                  <div className='border-dashed border text-center h-full flex flex-col justify-center'>
                    <FontAwesomeIcon icon={icon({name: 'cloud-arrow-up'})} size='2x' />
                    <p className='mt-4'>
                      <input
                        type='file'
                        id='addModule'
                        className='hidden'
                        accept='image/*'
                        onChange={handleFileChange}
                      />
                      <label
                        htmlFor='addModule'
                        className='text-blue-500 cursor-pointer'
                      >
                        Pilih file
                      </label>
                      {' yang akan di unggah.'}
                    </p>
                    <p className='italic text-sm text-gray-400'>Format file: jpg, png</p>
                  </div>
                )}
              </div>
            )}
          />
        </div>
      </div>
    </>
  );
};

export default memo(DetailProgram);
