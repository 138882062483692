import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge } from "components";
import ButtonDropdown from "components/dropdowns/ButtonDropdown";
import { FC, memo } from "react";
import { getFormatDate } from "utilities";
import { Link } from "react-router-dom";

type Props = {
  data: LeaveItem;
  onUpdate: (item: LeaveItem) => void;
};

const Item: FC<Props> = ({ data, onUpdate }) => {
  const {
    id,
    name,
    status,
    start_date,
    end_date,
    count,
    reason,
    attachment_url,
  } = data;

  type statuses = {
    name: string;
    value: string | null;
    color: BadgeColor;
  };
  const STATUS: statuses[] = [
    {
      name: "Requested",
      value: "requested",
      color: "warning",
    },
    {
      name: "Approved",
      value: "approved",
      color: "success",
    },
    {
      name: "Cancelled",
      value: "cancelled",
      color: "cancel-primary",
    },
    {
      name: "Rejected",
      value: "rejected",
      color: "error",
    },
    {
      name: "Semua",
      value: null,
      color: "primary",
    },
  ];

  return (
    <div
      key={id}
      className="bg-white flex flex-wrap gap-5 px-5 py-3 rounded-lg items-center"
    >
      <div className="flex-grow md:w-32 max-sm:w-32 flex flex-col justify-between items-start">
        <p className="font-bold text-ellipsis line-clamp-3">{name}</p>
      </div>
      <div className="md:w-52 flex flex-grow flex-col justify-between items-start">
        <div>
          <p className="text-xs">Periode Cuti</p>
          <span className="text-sm">{`${getFormatDate(
            start_date,
            "DD/MM/YYYY"
          )} - ${getFormatDate(end_date, "DD/MM/YYYY")}`}</span>
        </div>
      </div>
      <div className="md:w-36 flex-grow flex flex-col justify-between items-start">
        <div>
          <p className="text-xs">Jumlah Cuti</p>
          <span className="text-sm">{`${count} hari`}</span>
        </div>
      </div>
      <div className="md:w-36 flex-grow flex flex-col justify-between items-start">
        <div>
          <p className="text-xs">Alasan Cuti</p>
          <span className="text-sm">{reason}</span>
        </div>
      </div>
      <div className="md:w-36 flex-grow flex flex-col justify-between items-start">
        <div>
          <p className="text-xs">File Cuti </p>
          {attachment_url.length && attachment_url.startsWith("http") ? (
            <Link
              to={attachment_url}
              target="_blank"
              rel="noreferrer"
              download={true}
              className="text-base text-blue-400"
            >
              Lihat
            </Link>
          ) : (
            <span className="text-sm">-</span>
          )}
        </div>
      </div>
      <div className="md:w-28 w-32 flex-grow flex items-center justify-end">
        {STATUS.map((item) => {
          if (item.value === status) {
            return (
              <Badge key={item.name} color={item.color}>
                <p className="text-sm">{item.name}</p>
              </Badge>
            );
          }
          return null;
        })}
      </div>
      <div className="flex-grow flex items-center justify-end">
        {status === "requested" && (
          <ButtonDropdown
          menuItems={[
            <button
              className="w-full flex items-center justify-start gap-x-2 px-3.5 py-2.5 hover:bg-gray-50"
              onClick={() => {
                data.status = "approved";
                onUpdate(data);
              }}
            >
              <FontAwesomeIcon icon={icon({ name: "check" })} />
              <p className="text-sm">Approve</p>
            </button>,
            <button
              className="w-full flex items-center justify-start gap-x-2 px-3.5 py-2.5 hover:bg-gray-50"
              onClick={() => {
                data.status = "rejected";
                onUpdate(data);
              }}
            >
              <FontAwesomeIcon
                icon={icon({
                  name: "xmark",
                })}
              />
              <p className="text-sm">Reject</p>
            </button>,
          ]}
          widthItems="w-40"
        >
          <div className="text-end">
            <FontAwesomeIcon
              icon={icon({ name: "ellipsis-vertical" })}
              className="cursor-pointer"
            />
          </div>
        </ButtonDropdown>
        )}
      </div>
    </div>
  );
};

export default memo(Item);
