export function useListCache() {
  const getCache = (params: {[param in string]: any}) => {
    // Variables
    const listCache: {[param in string]: any} = JSON.parse(sessionStorage.getItem("list_cache") ?? "{}")
    const pathname: string = window.location.pathname

    return listCache[pathname] ?? params
  }
  
  const updateCache = (params: {[param in string]: any}) => {
    // Variables
    const listCache: {[param in string]: any} = JSON.parse(sessionStorage.getItem("list_cache") ?? "{}")
    const pathname: string = window.location.pathname
    
    listCache[pathname] = params

    return sessionStorage.setItem("list_cache", JSON.stringify(listCache))
  }

  return { getCache, updateCache }
}