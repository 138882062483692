// React
import { Fragment, PropsWithChildren } from "react";

// Components
import { Dialog, Transition } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

function Modal(
  props: PropsWithChildren<{
    closeModal?: () => void;
    isOpen: boolean;
    size?: "full" | "xl" | "lg" | "md";
    title: string;
  }>
): JSX.Element {
  // Variables
  const size = {
    full: "",
    xl: "xl:max-w-xl",
    lg: "lg:max-w-lg",
    md: "md:max-w-md",
  };

  return (
    <Transition appear show={props.isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => {
          if (props.closeModal) {
            props.closeModal();
          }
        }}
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-opacity-25" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center bg-gray-500  bg-opacity-75">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`w-full transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all ${
                  size[props.size ?? "md"]
                }`}
              >
                <div className="flex justify-between items-center">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6"
                  >
                    {props.title}
                  </Dialog.Title>
                  <FontAwesomeIcon
                    icon={icon({name: 'close'})}
                    onClick={props.closeModal}
                    className="cursor-pointer"
                  />
                </div>

                {props.children}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
}

function Body(
  props: PropsWithChildren<{
    className?: string;
  }>
): JSX.Element {
  return <div className={`mt-5 ${props.className}`}>{props.children}</div>;
}

function Footer(
  props: PropsWithChildren<{
    className?: string;
  }>
) {
  return (
    <Fragment>
      <div className="divider" />
      <div className={props.className ?? "text-right"}>{props.children}</div>
    </Fragment>
  );
}

Modal.Body = Body;
Modal.Footer = Footer;

export { Modal };
