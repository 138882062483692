import * as yup from "yup"

type TestCount = 'all' | 'part'

export const testValidation = yup.object().shape({
  name: yup.string().required(),
  type: yup.string().defined().nullable(),
  grade_name: yup.string().required(),
  n_essay: yup.number().required(),
  n_multiple_choice: yup.number().required(),
  question_package_id: yup.number().required(),
  test_count: yup.mixed<TestCount>().oneOf(['all', 'part']).required(),
  difficulty: yup.number().defined(),
})
