import { Badge, Spinner } from "components";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Listbox } from "@headlessui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { InView } from "react-intersection-observer";
import useHomework from "services/useAskHomework";
import useGrade from "services/useGrade";
import Level from "./fragment/level";
import Request from "./fragment/request";
import Avatar from "./fragment/avatar";
import Rating from "./fragment/rating";
import { getFormatDate } from "utilities";
import { askHomeworkChannel } from "libraries/pusher";

type Params = {
  requested?: boolean;
  page?: number;
  grade?: number;
  loadMore?: boolean;
  limit?: number;
  history?: boolean;
};

const allGrade = {
  id: 0,
  name: "Semua strata",
};

const defaultPagination: PaginationResponse = {
  current_page: 1,
  per_page: 10,
  last_page: 1,
  from: 1,
  to: 1,
  total: 1,
};

const Teacher = () => {
  const navigate = useNavigate();
  const { getTeacherRequest } = useHomework();
  const { getList } = useGrade();

  const [isRequest, setIsRequest] = useState(false);
  const [request, setRequest] = useState<TeacherRequest[]>([]);
  const [histories, setHistory] = useState<TeacherRequest[]>([]);
  const [teachers, setTeachers] = useState<TeacherRequest[]>([]);
  const [grades, setGrades] = useState<GradeItem[]>([]);
  const [selectedGrade, setSelectedGrade] = useState<GradeItem>(allGrade);
  const [pagination, setPagination] = useState(defaultPagination);
  const [totalRequest, setTotalRequest] = useState(0);
  const [loading, setLoading] = useState(false);

  const getGrade = useCallback(async () => {
    const response = await getList();
    setGrades(response);
  }, [getList]);

  const getData = useCallback(
    async (params?: Params) => {
      const {
        requested = false,
        page,
        grade,
        loadMore = false,
        limit,
        history = false,
      } = params ?? {};
      let status: Partial<AskHomeworkStatusType>[] = ["approved"];

      if (requested) {
        status = ["requested"];
      } else if (history) {
        status = ["approved", "rejected"];
      }

      const response = await getTeacherRequest({
        page,
        request_status: status,
        grade_id: grade,
        per_page: limit,
      });

      const { data: responseData, ...restResponse } = response;

      setIsRequest(Boolean(requested || history));
      if (requested) {
        setRequest(responseData);
      } else if (history) {
        if (loadMore) {
          setHistory([...histories, ...responseData]);
        } else {
          setHistory(responseData);
        }
        setPagination(restResponse);
      } else {
        if (loadMore) {
          setTeachers([...teachers, ...responseData]);
        } else {
          setTeachers(responseData);
        }
        setPagination(restResponse);
      }
      setLoading(false);
    },
    // eslint-disable-next-line
    [teachers, histories]
  );

  useEffect(() => {
    setLoading(true);
    getData();
    getGrade();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    askHomeworkChannel.bind(
      "counting_request_teacher_event",
      (event: PusherEvent) => {
        setTotalRequest(event.message?.total_request ?? 0);
        if (isRequest) {
          getData({
            requested: true,
            page: 1,
            limit: 100,
          });
        }
      }
    );

    askHomeworkChannel.bind(
      "status_online_backoffice_event",
      (event: PusherEvent) => {
        if (!isRequest && event.message?.status_refresh) {
          getData({
            page: 1,
          });
        }
      }
    );

    return () => {
      askHomeworkChannel.unbind();
    };
  }, [isRequest, getData]);

  const loadMore = () => {
    getData({
      history: isRequest,
      page: pagination?.current_page + 1,
      loadMore: true,
    });
  };

  const handleRequest = () => {
    getData({
      requested: true,
      page: 1,
      limit: 50,
    });
    getData({
      requested: false,
      history: true,
      page: 1,
    });
  };

  const handleGrade = async (value: typeof allGrade) => {
    if (value.id !== selectedGrade.id || isRequest) {
      setSelectedGrade(value);
      await getData({
        grade: value.id,
        page: 1,
      });
    }
  };

  return (
    <section className="space-y-6">
      <p>
        Pengajar yang tersedia pada tanggal{" "}
        <span className="font-bold">
          {getFormatDate(new Date(), "DD MMMM YYYY")}
        </span>
      </p>
      <div className="flex items-center gap-x-2">
        <button
          onClick={handleRequest}
          className={`bg-gray-200 rounded-md px-3 py-2 flex gap-x-2 items-center hover:bg-yellow-100 ${
            isRequest ? "bg-yellow-100" : ""
          }`}
        >
          Permintaan baru
          {Boolean(totalRequest) && (
            <span className="bg-white text-sm px-2.5 rounded-3xl">
              {totalRequest}
            </span>
          )}
        </button>
        {/* grades */}
        <div className="relative w-40">
          <Listbox value={selectedGrade} onChange={handleGrade}>
            <Listbox.Button className="relative flex w-full cursor-default items-center justify-between gap-x-1 rounded-lg border bg-white px-3.5 py-2.5 text-left text-sm">
              <span className="block truncate">{selectedGrade.name}</span>
              <FontAwesomeIcon icon={icon({ name: "chevron-down" })} />
            </Listbox.Button>
            <Listbox.Options className="absolute z-10 mt-1 max-h-48 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {[allGrade, ...grades].map((val, classIdx) => (
                <Listbox.Option
                  key={`grade-${classIdx}`}
                  as={Fragment}
                  value={val}
                >
                  {({ active }) => (
                    <li
                      className={`relative flex items-center justify-between cursor-default select-none px-3.5 py-2.5 ${
                        active ? "bg-gray-50" : "text-gray-900"
                      }`}
                    >
                      <span className="block truncate">{val.name}</span>
                      {val.id === selectedGrade.id && (
                        <FontAwesomeIcon
                          icon={icon({ name: "check" })}
                          color="#7F56D9"
                        />
                      )}
                    </li>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Listbox>
        </div>
      </div>
      {isRequest ? (
        <div className="grid gap-y-5">
          {request.map((val, index) => (
            <Request
              key={`requested-${index}`}
              data={val}
              onRefresh={() =>
                getData({
                  requested: true,
                  page: 1,
                })
              }
            />
          ))}
          <p className="font-semibold text-sm">Riwayat permintaan</p>
          {histories.map((val, index) => (
            <Request key={`history-${index}`} data={val} />
          ))}
        </div>
      ) : (
        <>
          {teachers.length ? (
            <div className="grid grid-cols-2 gap-5">
              {teachers.map((val, index) => (
                <div
                  key={`${val.id}-${index}`}
                  onClick={() =>
                    navigate(`${val.id}`, {
                      relative: "path",
                    })
                  }
                  className="bg-white p-5 rounded-lg cursor-pointer hover:bg-yellow-50"
                >
                  <div className="flex gap-x-3">
                    <Avatar image={val.avatar} />
                    <div className="space-y-1">
                      <p className="text-sm">{val.name}</p>
                      <Badge
                        className={`${
                          val.is_online
                            ? "bg-[#ECFDF3] border-[#ABEFC6]"
                            : "bg-gray-50 border-gray-700"
                        }`}
                      >
                        <p
                          className={`text-xs flex items-center gap-1 ${
                            val.is_online ? "text-[#067647]" : "text-gray-500"
                          }`}
                        >
                          <span
                            className={`block w-2 h-2 rounded-lg ${
                              val.is_online ? "bg-[#17B26A]" : "bg-gray-500"
                            }`}
                          />
                          {val.is_online ? "Online" : "Offline"}
                        </p>
                      </Badge>
                    </div>
                    <div className="space-y-1.5 ml-9">
                      <p className="text-sm">Rating</p>
                      <Rating rate={val.rating} />
                    </div>
                  </div>
                  <hr className="border-dashed my-4" />
                  <div className="space-y-3">
                    <Level title="Strata mengajar" data={val.grades} />
                    <Level title="Mata pelajaran" data={val.subjects} />
                  </div>
                </div>
              ))}
            </div>
          ) : loading ? (
            <Spinner />
          ) : (
            <p className="text-center italic">Tidak ada data</p>
          )}
        </>
      )}
      {pagination.current_page < pagination.last_page && (
        <InView
          as="div"
          onChange={(inView) => {
            if (inView) {
              loadMore();
            }
          }}
        >
          <Spinner />
        </InView>
      )}
    </section>
  );
};

export default Teacher;
