import {
  Button,
  Input,
  InputNumber,
  TextArea,
  Spinner,
  Label,
} from "components";
import { DatePicker } from "components/datepicker";
import Dropdown from "components/dropdowns/Dropdown";
import { useEffect, useMemo, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";
import toast from "react-hot-toast";
import useTeacher from "services/useTeacher";
import SuccessAdd from "./fragment/Modal/SuccessAdd";
import Breadcrumbs from "components/Breadcrumbs";

const defaultData = {
  name: "",
  biography: "",
  email: "",
  password: "",
  phone: "",
  sex: "",
  birthdate: "",
  hourly_tarrif: 0,
};

const genders = [
  {
    value: "l",
    name: "Laki-laki",
  },
  {
    value: "p",
    name: "Perempuan",
  },
];

const Add = () => {
  const { add, getDetail, edit } = useTeacher();
  const navigate = useNavigate();
  const location = useLocation();

  const pathId = location.pathname.split("/guru/")[1];

  const [showSuccess, setShowSuccess] = useState(false);
  const [detail, setDetail] = useState(defaultData);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    if (pathId !== "tambah") {
      setLoading(true);
      getDetail(pathId)
        .then((resp) => {
          setLoading(false);
          setIsEdit(true);
          setDetail({
            name: resp.name || "",
            biography: resp.teacher?.biography || "",
            birthdate: resp.birthdate || "",
            email: resp.email || "",
            password: resp.password || "",
            phone: resp.phone_number || "",
            sex: resp.jenis_kelamin || "",
            hourly_tarrif: Number(resp.teacher?.teacher_settings?.price_per_hour) || 0,
          });
        })
        .catch(() => {
          setLoading(false);
          toast.error("Data tidak ditemukan");
          navigate("/guru", {
            relative: "path",
          });
        });
    }
    // eslint-disable-next-line
  }, [pathId]);

  const methods = useForm<TeacherPayload>({
    values: detail,
  });
  const { control, watch, handleSubmit } = methods;
  const values = watch();

  const onCloseModal = () => {
    setShowSuccess(false);
    navigate("/guru", {
      replace: true,
    });
  };

  const isValid = useMemo(() => {
    const { name, biography, email, password, phone, sex, birthdate } = values;

    const commonField = Boolean(
      name && biography && email && phone && sex && birthdate
    );

    if (isEdit) {
      return commonField;
    }

    return Boolean(commonField && password);
  }, [values, isEdit]);

  const currentGender = useMemo(() => {
    const currentSex = genders.find((val) => val.value === detail.sex)?.name;

    return currentSex || "";
  }, [detail.sex]);

  const onSubmit = (data: TeacherPayload) => {
    if (isEdit) {
      toast.promise(edit(pathId, data), {
        loading: "Mengubah guru...",
        success: () => {
          setShowSuccess(true);
          return "";
        },
        error: (err) => {
          return err;
        },
      });
    } else {
      toast.promise(add(data), {
        loading: "Menambakan guru...",
        success: () => {
          setShowSuccess(true);
          return "";
        },
        error: (err) => {
          return err;
        },
      });
    }
  };
  const handleCrumb = (item: Crumb) => {
    navigate("/guru", {
      replace: true,
    });
  };

  return (
    <div className="space-y-5">
      <Breadcrumbs
        crumbs={["Guru", `${isEdit ? "Detail guru" : "Tambah guru"}`]}
        onClick={handleCrumb}
      />
      {loading ? (
        <Spinner />
      ) : (
        <div className="bg-white rounded-xl">
          <p className="font-bold p-5 border-b">{`${
            isEdit ? "Detail" : "Tambah"
          } guru`}</p>
          <div className="p-5 grid grid-cols-2 gap-x-10">
            <FormProvider {...methods}>
              <div className="space-y-5">
                <Controller
                  control={control}
                  name="name"
                  render={({ field }) => (
                    <Input
                      label="Nama guru"
                      placeholder="Masukkan nama guru"
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="biography"
                  render={({ field }) => (
                    <TextArea
                      label="Biografi"
                      placeholder="Masukkan biografi guru"
                      className="border w-full resize-none rounded-lg px-3.5 py-3 placeholder:text-gray-500 min-h-[10rem]"
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="password"
                  render={({ field }) => (
                    <Input.Password
                      label="Password"
                      placeholder={`${isEdit ? "Ganti" : "Masukkan"} password`}
                      requirement="Minimal 8 karakter terdiri dari huruf kapital, huruf kecil, angka dan
          karakter spesial"
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              </div>
              <div className="space-y-5">
                <Controller
                  control={control}
                  name="email"
                  render={({ field }) => (
                    <Input.Email
                      label="Email"
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="phone"
                  render={({ field }) => (
                    <div>
                      <Label>No. handphone</Label>
                      <div className="w-full flex items-center gap-x-2 mt-2 border rounded-lg px-3.5 py-2.5">
                        <span>+62</span>
                        <InputNumber
                          label=""
                          placeholder="Masukkan nomor handphone"
                          value={field.value}
                          onChange={field.onChange}
                          className="w-full"
                          options={{
                            phone: true,
                            phoneRegionCode: "ID",
                          }}
                        />
                      </div>
                    </div>
                  )}
                />
                <div className="w-full space-y-1.5">
                  <Label>Jenis kelamin</Label>
                  <Controller
                    control={control}
                    name="sex"
                    render={({ field }) => (
                      <Dropdown
                        label={currentGender || "Pilih jenis kelamin"}
                        id={1}
                        menuItems={genders}
                        onChange={(item) => field.onChange(item.value)}
                      />
                    )}
                  />
                </div>
                <Controller
                  control={control}
                  name="birthdate"
                  render={({ field }) => (
                    <DatePicker
                      label="Tanggal lahir"
                      displayFormat="DD-MM-YYYY"
                      placeholder="Masukkan tanggal lahir"
                      minDate={new Date("1945-01-01")}
                      value={field.value}
                      onChange={(val) => field.onChange(val)}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="hourly_tarrif"
                  render={({ field }) => (
                    <div>
                      <Label>Tarif Mengajar per jam</Label>
                      <div className="w-full flex items-center gap-x-2 mt-2 border rounded-lg px-3.5 py-2.5">
                        <span>Rp.</span>
                        <InputNumber
                          label=""
                          placeholder="50.000"
                          value={field.value}
                          onChange={e => {
                            const rawValue = e.target.rawValue;
        
                            if (!rawValue.startsWith('0')) {
                              field.onChange(Number(rawValue))
                            }
                          }}
                          className="w-full"
                          options={{ numeral: true, numeralDecimalMark: ',', delimiter: '.' }}
                        />
                      </div>
                    </div>
                  )}
                />
              </div>
            </FormProvider>
          </div>
        </div>
      )}
      <div className="space-x-3 float-right">
        <Button.Cancel onButtonClick={() => navigate(-1)} />
        <Button
          color="primary"
          isDisabled={!isValid}
          onButtonClick={handleSubmit((data) => onSubmit(data))}
        >
          Simpan
        </Button>
      </div>
      <SuccessAdd isEdit={isEdit} show={showSuccess} onClose={onCloseModal} />
    </div>
  );
};

export default Add;
