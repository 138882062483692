import { memo, FC, useState, useEffect } from "react";
import Modal from "components/Modal";
import { Button } from "components";
import List from "./List";

import useBrowse from "services/useBrowse";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useDebounce } from "utilities";

type Props = {
  show: boolean;
  onClose: () => void;
  onSubmit: (data: BrowseItem[]) => void;
  directoryId?: number;
  selectedQuestions: BrowseItem[];
  selectedKey?: "id" | "question_package_id";
};

const BrowseQuestion: FC<Props> = (props) => {
  const {
    show,
    onClose,
    onSubmit,
    directoryId,
    selectedQuestions,
    selectedKey,
  } = props;
  const { getListAdmin } = useBrowse("question");

  const [data, setData] = useState<BrowseItem[]>([]);
  const [search, setSearch] = useState("");
  const [selected, setSelected] = useState<BrowseItem[]>(selectedQuestions);

  const debounceSearch = useDebounce(search);

  useEffect(() => {
    setSelected(selectedQuestions);
  }, [selectedQuestions]);

  useEffect(() => {
    if (debounceSearch.length >= 3 || !debounceSearch.length) {
      getListAdmin({
        directory_id: directoryId,
        keyword: debounceSearch,
      })
        .then((response) => setData(response?.data || []))
        .catch((err) => console.log("Err", err));
    }
  }, [debounceSearch, getListAdmin, directoryId]);

  const handleSubmit = () => {
    if (selected) {
      onSubmit(selected);
    }
    onClose();
  };

  const handleClose = () => {
    onClose();
    setSearch("");
  };

  return (
    <Modal
      show={show}
      title={"Pilih materi"}
      onClose={handleClose}
      customFooter={
        <div className="float-right flex gap-x-2">
          <Button.Cancel onButtonClick={handleClose} />
          <Button color="primary" onButtonClick={handleSubmit}>
            Pilih soal
          </Button>
        </div>
      }
    >
      <div className="bg-white px-4 py-[0.625rem] rounded-xl flex gap-x-2 items-center border w-full mb-4">
        <FontAwesomeIcon icon={icon({ name: "search" })} />
        <input
          className="w-full"
          placeholder="Search..."
          value={search}
          onChange={(e) => setSearch(e.target.value)}
        />
        {Boolean(search.length) && (
          <FontAwesomeIcon
            icon={icon({ name: "close" })}
            onClick={() => setSearch("")}
            className="cursor-pointer"
          />
        )}
      </div>
      <p className="text-sm font-bold">Direktori</p>
      <List
        initialData={data}
        onSelect={(item) => setSelected(item)}
        selected={selected}
        selectedKey={selectedKey}
        keyword={search}
      />
    </Modal>
  );
};

export default memo(BrowseQuestion);
