import { Badge, Input } from "components";
import Table from "components/Table";
import { DEFAULT_PAGINATION, PROGRAM_TYPE } from "constant";
import { FC, memo, useCallback, useEffect, useState } from "react";
import {
  currencyConverter,
  getFormatDate,
  getProgramColor,
  useDebounce,
} from "utilities";
import usePromo from "services/usePromo";

type Props = {
  id: string;
};

const columns = [
  {
    title: "Nama siswa",
    left: true,
  },
  {
    title: "Program",
  },
  {
    title: "Jenis",
  },
  {
    title: "Tanggal",
  },
];

const UserList: FC<Props> = ({ id }) => {
  const { users } = usePromo();

  const [keyword, setKeyword] = useState("");
  const [list, setList] = useState<PromoUsers[]>([]);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);

  const debounceSearch = useDebounce(keyword);

  const getData = useCallback(async (params?: PromoParams) => {
    const response = await users(id, params);
    const { data, ...restResponse } = response;

    setList(data);
    setPagination(restResponse);
  }, []);

  useEffect(() => {
    if (debounceSearch.length >= 3) {
      getData({
        keyword: debounceSearch,
        page: 1,
        per_page: pagination.per_page,
      });
    } else {
      getData({
        page: 1,
        per_page: pagination.per_page,
        keyword: "",
      });
    }
  }, [debounceSearch]);

  const handleLimit = (limit: string) => {
    getData({
      per_page: Number(limit),
      page: 1,
      keyword,
    });
  };

  const handlePagination = (control: string) => {
    const isNext = control === "next";
    const page = isNext
      ? pagination.current_page + 1
      : pagination.current_page - 1;

    if (page > 0 && page <= pagination.last_page) {
      getData({
        per_page: pagination.per_page,
        page,
        keyword,
      });
    }
  };

  return (
    <div className="space-y-4">
      <div className="p-5 flex items-center justify-between">
        <p>
          Total pengguna:
          <span className="font-bold">
            {currencyConverter(pagination.total, true)}
          </span>
        </p>
        <Input.Search
          placeholder="Cari siswa"
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
      </div>
      <Table
        data={list}
        columns={columns}
        currentLimit={pagination.per_page}
        currentPage={pagination.current_page}
        total={pagination.total}
        handleLimit={handleLimit}
        handlePagination={handlePagination}
      >
        {list.map((val) => (
          <tr key={val.id} className="border-b">
            <td className="p-4">{val.student_name}</td>
            <td className="p-4 text-gray-500">{val.program_title}</td>
            <td className="p-4">
              <Badge color={getProgramColor(val.object)}>
                {PROGRAM_TYPE[val.object].toUpperCase()}
              </Badge>
            </td>
            <td className="p-4 text-gray-500">
              {getFormatDate(val.applied_at, "DD/MM/YYYY")}
            </td>
          </tr>
        ))}
      </Table>
    </div>
  );
};

export default memo(UserList);
