import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Breadcrumbs from 'components/Breadcrumbs';
import {FC, memo} from 'react';
import {Disclosure} from '@headlessui/react';
import Modules from './Modules';
import Questions from './Questions';

type Props = {
  data: SyllabusDetail['packages'][0]['directories']
}

const Material: FC<Props> = ({data}) => {

  return (
    <>
      <section className='grid gap-y-5'>
        {data.length ? (
          data.map((directory, index) => (
            <div key={directory.id} className='bg-white rounded-xl border'>
              <Disclosure defaultOpen>
                {({ open }) => {
                  const itemIcon = open ? icon({name: 'chevron-down'}) : icon({name: 'chevron-right'});

                  return (
                    <>
                      <div className={'flex w-full justify-between items-center px-5 py-4'}>
                        <Disclosure.Button className='flex items-center gap-x-4 w-full'>
                          <FontAwesomeIcon icon={itemIcon} width={16} />
                          <Breadcrumbs crumbs={['Soal', ...directory?.path?.split('/')]} />
                        </Disclosure.Button>
                      </div>

                      <Disclosure.Panel className={'border-t px-5 py-4 grid gap-y-5'}>
                        <Modules data={directory.modules} />
                        <Questions data={directory.questions} />
                      </Disclosure.Panel>
                    </>
                  )
                }}
              </Disclosure>
            </div>
          ))
        ) : (
          <p className='text-gray-400'>Anda belum menambahkan bab apapun.</p>
        )}
      </section>
    </>
  );
};

export default memo(Material);
