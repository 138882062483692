import { FC, memo, useCallback, useEffect, useState } from "react";
import { InView } from "react-intersection-observer";
import Modal from "components/Modal";
import useProgram from "services/useBundle";
import { Badge, Button, Input, Spinner } from "components";
import { PROGRAM_TYPE, DEFAULT_PAGINATION } from "constant";
import { currencyConverter, getProgramColor, useDebounce } from "utilities";
import GradeOption from "components/GradeOption";
import TypeOption from "components/TypeOption";
import Table from "components/Table";

type Props = {
  show: boolean;
  onClose: () => void;
  onAdd: (items: ProgramItem[]) => void;
  listSelected: ProgramItem[];
};

const columns = [
  {
    title: "",
  },
  {
    title: "Nama program",
    isSort: true,
    key: "title",
  },
  {
    title: "Jenis",
    isSort: true,
    key: "type",
  },
  {
    title: "Durasi",
    isSort: true,
    key: "duration",
  },
  {
    title: "Harga",
    isSort: true,
    key: "price",
  },
];

const AskHomeworks: FC<Props> = ({ show, onClose, onAdd, listSelected }) => {
  const { getListHomework } = useProgram();

  const [list, setList] = useState<ProgramItem[]>([]);
  const [selected, setSelected] = useState<ProgramItem[]>([]);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [keyword, setKeyword] = useState("");
  const [type, setType] = useState<HomeworkSession["type"] | null>(null);
  const [grade, setGrade] = useState("");

  const debounceSearch = useDebounce(keyword);

  const getData = useCallback(async (params: Partial<BundleListParam>) => {
    const response = await getListHomework({
      ...params,
      per_page: params.per_page ?? 5,
    });
    const { data, ...restResponse } = response;

    setList(data);
    setPagination(restResponse);
  }, []);

  useEffect(() => {
    if (debounceSearch.length >= 3) {
      getData({
        search: keyword,
        page: 1,
      });
    }

    if (!debounceSearch.length) {
      getData({
        search: "",
        page: 1,
      });
    }
  }, [debounceSearch]);

  useEffect(() => {
    setSelected(listSelected);
  }, [listSelected]);

  const handleGrade = (value: GradeItem) => {
    setGrade(String(value.id));
    getData({
      page: 1,
      grade_id: String(value.id),
      type,
      search: keyword,
    });
  };

  const handleType = (value: ProgramType | HomeworkSession["type"]) => {
    const newType = value as HomeworkSession["type"];
    setType(newType);
    getData({
      page: 1,
      type: newType,
      grade_id: grade,
      search: keyword,
    });
  };

  const handleLimit = (limit: string) => {
    getData({
      per_page: Number(limit),
      page: 1,
      type,
      grade_id: grade,
      search: keyword,
    });
  };

  const handlePagination = (control: string) => {
    const isNext = control === "next";
    const page = isNext
      ? pagination.current_page + 1
      : pagination.current_page - 1;

    if (page > 0 && page <= pagination.last_page) {
      getData({
        per_page: pagination.per_page,
        page,
        type,
        grade_id: grade,
        search: keyword,
      });
    }
  };

  const handleClose = () => {
    getData({
      search: "",
      page: 1,
      grade_id: null,
      type: null,
    });
    onClose();
  };

  const handleCheck = (item: ProgramItem) => {
    let tempSelected = [...selected];
    const isExisted = selected.find((val) => val.id === item.id);

    if (isExisted) {
      tempSelected = selected.filter((val) => val.id !== item.id);
    } else {
      tempSelected = [...selected, item];
    }

    setSelected(tempSelected);
  };

  const handleAddProgram = () => {
    onAdd(selected);
    handleClose();
  };

  return (
    <Modal
      title={
        <>
          <p className="font-bold text-xl">Pilih Program</p>
          <div className="flex gap-x-2 mt-4 font-normal">
            <Input.Search
              placeholder="Cari program"
              onChange={(e) => setKeyword(e.target.value)}
              value={keyword}
            />
            <GradeOption onChange={handleGrade} />
            <TypeOption onChange={handleType} isAskHomework />
          </div>
        </>
      }
      show={show}
      onClose={handleClose}
      customFooter={
        <div className="flex items-center justify-between">
          <p className="text-sm font-bold">{`${selected.length} program dipilih`}</p>
          <div className="flex justify-end gap-x-2">
            <Button.Cancel onButtonClick={handleClose} />
            <Button color="primary" onButtonClick={handleAddProgram}>
              Pilih
            </Button>
          </div>
        </div>
      }
    >
      <Table
        columns={columns}
        data={list}
        handleFirstColumn="8px"
        className="!p-0"
        currentLimit={pagination.per_page}
        currentPage={pagination.current_page}
        total={pagination.total}
        handleLimit={handleLimit}
        handlePagination={handlePagination}
      >
        {list.map((item) => (
          <tr key={item.id} className="border-b hover:bg-gray-50">
            <td className="p-4">
              <input
                type="checkbox"
                id={`program-${item.id}`}
                className="checkbox checked:bg-yellow-400 checked:border-primary text-gray-500"
                onChange={() => handleCheck(item)}
                checked={
                  Boolean(selected.find((val) => val.id === item.id)) || false
                }
              />
            </td>
            <td>
              <label
                className="block w-full h-auto p-4"
                htmlFor={`program-${item.id}`}
              >
                {item.title}
              </label>
            </td>
            <td>
              <label
                className="block w-full h-auto p-4"
                htmlFor={`program-${item.id}`}
              >
                {item.session_type.toUpperCase()}
              </label>
            </td>
            <td>
              <label
                className="block w-full h-auto p-4"
                htmlFor={`program-${item.id}`}
              >
                {item.name}
              </label>
            </td>
            <td className="text-right">
              <label
                className="block w-full h-auto p-4"
                htmlFor={`program-${item.id}`}
              >
                {currencyConverter(Number(item.price))}
              </label>
            </td>
          </tr>
        ))}
      </Table>
    </Modal>
  );
};

export default memo(AskHomeworks);
