import { useForm, FormProvider } from "react-hook-form";
import { Button } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import ProgressBullet from "components/ProgressBullet";
import { useMemo, useState } from "react";
import Steps from "./Steps";
import useBundle from "services/useBundle";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Success from "../fragment/Modal/Success";

export default function Add() {
  const navigate = useNavigate();
  const { addBundle } = useBundle();

  const [step, setStep] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [isDraftData, setIsDraft] = useState(false);

  const methods = useForm<BundleItem>({
    defaultValues: {
      bundle_price: "",
      description: "",
      detail: {
        programs: [],
        ask_homework_packets: [],
      },
      expired_date: "",
      image: "",
      start_date: "",
      title: "",
      total_price: "",
    },
  });

  const { getValues, handleSubmit } = methods;
  const values = getValues();

  const canContinue = useMemo(() => {
    const {
      title,
      description,
      image,
      grade_id,
      start_date,
      expired_date,
      total_price,
      bundle_price,
    } = values;

    switch (step) {
      case 0:
        return Boolean(
          title &&
            description &&
            image &&
            grade_id &&
            start_date &&
            expired_date
        );
      case 1:
        return Boolean(total_price && bundle_price);
      case 2:
        return true;
      default:
        return false;
    }
  }, [step, values]);

  const goBack = () => {
    setShowModal(false);
    navigate("/bundling", {
      replace: true,
    });
  };

  const handleBack = () => {
    setStep((val) => val - 1);
  };

  const handleNext = () => {
    setStep((val) => val + 1);
  };

  const handleSave = (data: BundleItem, isDraft?: boolean) => {
    if (step < 2 && !isDraft) {
      handleNext();
    } else {
      const {
        title,
        description,
        image,
        total_price,
        bundle_price,
        grade_id,
        start_date,
        expired_date,
        detail,
      } = data;
      const { programs, ask_homework_packets } = detail;
      const programIds = programs.map((val) => val.id);
      const homeworks = ask_homework_packets.map((val) => ({
        id: val.id,
        qty: val.qty,
      }));

      const payload = {
        title,
        image,
        description,
        total_price,
        bundle_price,
        grade_id,
        status: isDraft ? "0" : "1",
        start_date,
        expired_date,
        program_id: programIds,
        ask_homeworks: homeworks,
      };
      setIsDraft(isDraftData);
      toast.promise(addBundle(payload), {
        loading: "...",
        error: "Gagal menambahkan",
        success: () => {
          setShowModal(true);
          return "";
        },
      });
    }
  };

  return (
    <>
      <div className="absolute top-6 left-8 right-8 flex items-center gap-x-9">
        <p className="text-xl font-bold">Bundling</p>
        <ProgressBullet
          onBack={handleBack}
          onNext={handleNext}
          data={["Detail Program", "Penentuan program & harga", "Ringkasan"]}
          canContinue={canContinue}
          currentStep={step}
        />
      </div>
      <div className="absolute left-0 top-20 right-0 px-8 pb-28 h-[calc(100%-5rem)] overflow-y-auto">
        <FormProvider {...methods}>
          <Steps step={step} />
        </FormProvider>
      </div>
      <div className="flex items-center justify-between absolute bottom-0 right-0 left-0 px-8 py-5 bg-white">
        <Button
          color="outline-gray"
          isDisabled={!step}
          onButtonClick={handleSubmit((data) => handleSave(data, true))}
        >
          <FontAwesomeIcon icon={icon({ name: "pen" })} />
          <span className="ml-3">Simpan sebagai draft</span>
        </Button>
        <div className="space-x-3">
          <Button.Cancel onButtonClick={goBack} />
          <Button
            onButtonClick={handleSubmit((data) => handleSave(data))}
            isDisabled={!canContinue}
            color="primary"
          >
            {step < 2 ? "Lanjutkan" : "Simpan"}
          </Button>
        </div>
      </div>
      <Success show={showModal} onClose={goBack} isDraft={isDraftData} />
    </>
  );
}
