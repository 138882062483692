import { FC, memo } from "react";
import { useFormContext } from "react-hook-form";
import Score from "../../../fragment/TryOut/Settings/Score";
import Duration from "../../../fragment/TryOut/Settings/Duration";

type Props = {
  onSave: (data: TryoutPayload) => void;
};

const Classes: FC<Props> = ({ onSave }) => {
  const { getValues } = useFormContext<TryoutPayload>();
  const currentValue = getValues();

  const handleSave = (params: Partial<TryoutPayload>) => {
    const payload = {
      ...currentValue,
      ...params,
    };
    onSave(payload);
  };

  return (
    <div className="flex gap-x-5">
      <Score data={currentValue} canEdit onSave={handleSave} />
      <Duration data={currentValue} canEdit onSave={handleSave} />
    </div>
  );
};

export default memo(Classes);
