import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components";
import { memo, useState, useEffect, useCallback } from "react";
import BrowseQuestion from "../../fragment/Modal/BrowseQuestion";
import { useFormContext } from "react-hook-form";
import ListQuestion from "../../fragment/TryOut/Question/ListQuestion";
import QuestionComposition from "../../fragment/TryOut/Question/QuestionComposition";
import useProgram from "services/useProgram";
import Variety from "../../fragment/TryOut/Question/Variety";

const Question = () => {
  const { getVariationList, detailTryout } = useProgram();
  const [showQuestion, setShowQuestion] = useState(false);
  const [listVariety, setListVariety] = useState<Variety[]>([]);

  const { setValue, watch, getValues } = useFormContext<TryoutPayload>();
  const students = getValues("number_of_participants");
  const questionPerStudent = getValues("question_each_student");
  const questions = watch("question_package");
  const variety = watch("variety");
  const varietyId = getValues("variety_id");
  const programId = getValues("program_id");

  const getList = useCallback(async () => {
    const list = await getVariationList({
      total_students: Number(students),
      total_questions: questionPerStudent,
    });
    setListVariety(list);
    if (varietyId) {
      const selected = list.find((val) => val.id === Number(varietyId));
      setValue("variety", selected);
    } else {
      const currentVariety = list[0];
      setValue("variety", currentVariety);
      setValue("variety_id", currentVariety.id);
    }
  }, [students, questionPerStudent, getVariationList, setValue, varietyId]);

  const getDetail = useCallback(async () => {
    if (programId) {
      const response = await detailTryout(Number(programId));
      const { question_package } = response;
      const newPackage = question_package.map((val) => ({
        ...val,
        question_package_id: val.question_package_id ?? val.id,
      }));
      setValue("question_package", newPackage);
    }
    // eslint-disable-next-line
  }, [programId, detailTryout]);

  const handleVariety = (currentVariety: Variety) => {
    setValue("variety", currentVariety);
    setValue("variety_id", currentVariety.id);
  };

  useEffect(() => {
    getList();
    getDetail();
    // eslint-disable-next-line
  }, []);

  const toggleQuestion = () => setShowQuestion(!showQuestion);

  const onChangeQuestion = (value: number, index: number) => {
    const newSelected = questions.map((val, idx) => {
      if (index === idx) {
        val.n_question_value = value;
      }
      return val;
    });
    setValue("question_package", newSelected);
  };

  const removeQuestion = (item: TryoutPayload["question_package"][0]) => {
    const newQuestion = questions.filter(
      (val) => val.question_package_id !== item.question_package_id
    );
    setValue("question_package", newQuestion);
  };

  const handleSubmit = (items: BrowseItem[]) => {
    const newItems = items.map((item) => {
      const { id, ...restItem } = item;
      const existingQuestion = questions.find(
        (val) =>
          Number(val.question_package_id) ===
          Number(restItem.question_package_id)
      );
      const questionValue =
        existingQuestion?.n_question_value ??
        restItem.n_essay + restItem.n_multiple_choice;

      let newItem: Partial<TryoutPayload["question_package"][0]> = {
        ...restItem,
        n_question_value: questionValue,
        question_package_id: id,
      };

      if (existingQuestion) {
        newItem = {
          ...newItem,
          id: existingQuestion.id,
          question_package_id: existingQuestion.question_package_id,
        };
      }

      return newItem;
    });
    setValue("question_package", newItems as TryoutPayload["question_package"]);
  };

  return (
    <>
      <section className="grid gap-y-5">
        <div className="bg-white rounded-xl h-fit">
          <p className="font-bold px-5 py-4 border-b">Variasi soal</p>
          <Variety
            list={listVariety}
            onChange={handleVariety}
            selected={variety || listVariety[0]}
          />
        </div>
        <div className="bg-white rounded-xl h-fit">
          <p className="font-bold px-5 py-4 border-b">Daftar soal</p>
          <ListQuestion
            data={questions}
            isEdit
            removeQuestion={removeQuestion}
            onChange={onChangeQuestion}
          />
          <div
            className={`flex p-5 ${
              questions.length ? "justify-between" : "justify-end"
            } gap-x-12`}
          >
            {Boolean(questions.length) && (
              <QuestionComposition
                questions={questions}
                variety={variety || listVariety[0]}
                isEdit
              />
            )}
            <Button
              color="outline-gray"
              className="bg-yellow-100 hover:bg-yellow-200 hover:text-gray-800"
              onButtonClick={toggleQuestion}
            >
              <FontAwesomeIcon icon={icon({ name: "file-circle-plus" })} />
              <span className="ml-1.5">Tambah soal</span>
            </Button>
          </div>
        </div>
      </section>
      <BrowseQuestion
        show={showQuestion}
        onClose={toggleQuestion}
        onSubmit={handleSubmit}
        selectedQuestions={questions}
        selectedKey="question_package_id"
      />
    </>
  );
};

export default memo(Question);
