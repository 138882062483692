import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, memo, useEffect, useState } from "react";
import { currencyConverter } from "utilities";

const RecapData = [
  {
    id: "not_having_order",
    name: "Siswa/orang tua tidak melakukan transaksi",
    value: 0,
  },
  {
    id: "unpaid",
    name: "Siswa/orang tua tidak menyelesaikan transaksi",
    value: 0,
  },
  {
    id: "paid",
    name: "Siswa/orang tua menyelesaikan transaksi",
    value: 0,
  },
];

type Props = {
  data: RecapEngagement | null;
};

const TotalUser: FC<Props> = ({ data }) => {
  const [recap, setRecap] = useState(RecapData);

  useEffect(() => {
    if (data) {
      setRecap((oldRecap) =>
        oldRecap.map((val) => {
          if (val.id === "not_having_order") {
            val.value = data.not_having_order;
          } else {
            const id = val.id as keyof RecapEngagement["having_order"];
            val.value = data.having_order[id];
          }

          return val;
        })
      );
    }
  }, [data]);

  return (
    <div className="bg-white rounded-xl px-5 py-4 space-y-5">
      <div className="bg-yellow-100 rounded-xl px-5 py-4 space-y-2">
        <div className="flex items-center gap-x-2">
          <FontAwesomeIcon
            icon={icon({ name: "user-group" })}
            className="bg-primary rounded-full p-1.5 w-3.5 h-3.5"
          />
          <span className="text-sm font-medium">
            Total pengguna (Siswa dan orang tua)
          </span>
        </div>
        <span className="font-bold text-5xl">
          {currencyConverter(data?.all ?? 0, true)}
        </span>
      </div>
      {recap.map((val) => (
        <div
          key={val.id}
          className="bg-gray-50 rounded-xl px-5 py-4 grid grid-cols-[2fr,1fr] gap-x-4"
        >
          <span className="font-medium text-sm">{val.name}</span>
          <span className="font-bold text-2xl text-right">{val.value}</span>
        </div>
      ))}
    </div>
  );
};

export default memo(TotalUser);
