import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Listbox } from "@headlessui/react";
import { FC, Fragment, memo, useCallback, useEffect, useState } from "react";
import useGrade from "services/useGrade";

type Props = {
  onChange: (value: GradeItem) => void;
};

const allGrade = {
  id: 0,
  name: "Semua strata",
};

const GradeOption: FC<Props> = ({ onChange }) => {
  const { getList } = useGrade();

  const [grades, setGrades] = useState<GradeItem[]>([]);
  const [selectedGrade, setSelectedGrade] = useState<GradeItem>(allGrade);

  const getGrade = useCallback(async () => {
    const response = await getList();
    setGrades(response);
  }, [getList]);

  useEffect(() => {
    getGrade();
  }, [getGrade]);

  const handleChange = (value: GradeItem) => {
    if (value.id !== selectedGrade.id) {
      setSelectedGrade(value);
      onChange(value);
    }
  };

  return (
    <div className="relative flex-1 min-w-[12rem]">
      <Listbox value={selectedGrade} onChange={handleChange}>
        <Listbox.Button className="relative flex w-full cursor-default items-center justify-between gap-x-1 rounded-lg border bg-white px-3.5 py-2.5 text-left text-sm">
          <span className="block truncate">{selectedGrade.name}</span>
          <FontAwesomeIcon icon={icon({ name: "chevron-down" })} />
        </Listbox.Button>
        <Listbox.Options className="absolute z-10 mt-1 max-h-48 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {[allGrade, ...grades].map((val, classIdx) => (
            <Listbox.Option key={`${classIdx}-${val.id}`} as={Fragment} value={val}>
              {({ active }) => (
                <li
                  className={`relative flex items-center justify-between cursor-default select-none px-3.5 py-2.5 ${
                    active ? "bg-gray-50" : "text-gray-900"
                  }`}
                >
                  <span className="block truncate">{val.name}</span>
                  {val.id === selectedGrade.id && (
                    <FontAwesomeIcon
                      icon={icon({ name: "check" })}
                      color="#7F56D9"
                    />
                  )}
                </li>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  );
};

export default memo(GradeOption);
