import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, memo, useCallback, useEffect, useState } from "react";
import Table from "components/Table";
import { currencyConverter, getFormatDate, useDebounce } from "utilities";
import useProgram from "services/useProgram";
import { useParams } from "react-router-dom";

const columns = [
  {
    title: "Nama siswa",
    isSort: true,
    key: "student_name",
  },
  {
    title: "Sekolah",
    isSort: true,
    key: "school_name",
  },
  {
    title: "Tanggal daftar",
    isSort: true,
    key: "registered_at",
  },
];

const defaultPagination: PaginationResponse = {
  current_page: 1,
  per_page: 10,
  last_page: 1,
  from: 1,
  to: 1,
  total: 1,
};

const defaultParams: ProgramParams = {
  page: 1,
  sort_by: "student_name",
  order_by: "asc",
  keyword: "",
};

const ParticipantTab: FC = () => {
  const { id } = useParams();
  const { getProgramStudents } = useProgram();
  const [search, setSearch] = useState("");
  const [data, setData] = useState<ProgramStudentsResponse["data"]>([]);
  const [pagination, setPagination] = useState(defaultPagination);
  const [sortBy, setSortBy] =
    useState<ProgramParams["sort_by"]>("student_name");
  const [sortDir, setSortDir] = useState<ProgramParams["order_by"]>("desc");

  const debounceSearch = useDebounce(search);

  const getList = useCallback(async (params?: ProgramParams) => {
    const response = await getProgramStudents(params);
    const { data, ...restData } = response;

    setPagination(restData);
    setData(data);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (debounceSearch.length >= 3) {
      getList({
        keyword: debounceSearch,
        page: 1,
        id: Number(id),
      });
    }

    if (!debounceSearch.length) {
      getList({ ...defaultParams, id: Number(id) });
    }
    // eslint-disable-next-line
  }, [debounceSearch]);

  const clearSearch = async () => {
    setSearch("");
  };

  const handleSort = async (params: string) => {
    let direction: ProgramParams["order_by"] =
      sortDir === "asc" ? "desc" : "asc";

    if (params !== sortBy) {
      direction = "asc";
    }

    setSortBy(params as ProgramParams["sort_by"]);
    setSortDir(direction);
    await getList({
      sort_by: params as ProgramParams["sort_by"],
      order_by: direction,
    });
  };

  const handlePagination = async (ctrl: "next" | "prev") => {
    const isNext = ctrl === "next";
    const page = isNext
      ? pagination.current_page + 1
      : pagination.current_page - 1;

    if (page > 0 && page <= pagination.last_page) {
      await getList({
        page,
      });
    }
  };

  const handleLimit = async (limit: string) => {
    await getList({
      per_page: Number(limit),
    });
  };

  return (
    <div className="bg-white rounded-xl">
      <p className="font-bold px-5 py-4 border-b">Daftar peserta</p>
      <div className="space-y-4">
        <div className="flex items-center px-5 pt-5 justify-between">
          <p>
            Total peserta:{" "}
            <span className="font-bold">{`${currencyConverter(
              pagination.total,
              true
            )} siswa`}</span>
          </p>
          <div className="bg-white px-4 py-2.5 border rounded-xl flex gap-x-2 items-center min-w-[20rem]">
            <FontAwesomeIcon icon={icon({ name: "search" })} />
            <input
              className="w-full"
              placeholder="Cari siswa"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {Boolean(search.length) && (
              <FontAwesomeIcon
                icon={icon({ name: "close" })}
                onClick={clearSearch}
                className="cursor-pointer"
              />
            )}
          </div>
        </div>
        <Table
          columns={columns}
          data={data}
          total={pagination.total}
          currentLimit={pagination.per_page}
          handlePagination={handlePagination}
          handleSort={handleSort}
          handleLimit={handleLimit}
        >
          {data.map((val, index) => (
            <tr key={index} className="border-b">
              <td className="p-4">
                <p>{val.student_name || "-"}</p>
              </td>
              <td className="p-4">
                <p>{val.school_name || "-"}</p>
              </td>
              <td className="p-4">
                <p>{getFormatDate(val.register_at)}</p>
              </td>
            </tr>
          ))}
        </Table>
      </div>
    </div>
  );
};

export default memo(ParticipantTab);
