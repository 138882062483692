// Form
import * as yup from "yup"
import { testValidation } from "./testValidation"
import { Module, Test } from "../types"

export const validationSchema = yup.object().shape({
  grade_id: yup.number().label("Tingkatan").required().nullable(),
  subject_id: yup.number().defined().nullable(),
  major_id: yup.number().defined().nullable(),
  name: yup.string().label("Judul").required(),
  description: yup.string().label("Deskripsi").required(),
  packages: yup.array().label("Pertemuan").of(
    yup.object().shape({
      content_type: yup.string().required(),
      title: yup.string().label("Judul Pertemuan").required(),
      description: yup.string().label("Deskripsi").required(),
      directories: yup.array().label('Bab').of(
        yup.object().shape({
          directoryId: yup.number().required(),
          crumbs: yup.array().min(1).required(),
          modules: yup.array().label('Modul').of(
            yup.object().shape({
              module_id: yup.number().required(),
              name: yup.string().required()
            })
          ).when('questions', {
            is: (val: Test[]) => val?.length === 0,
            then: (schema) => schema.min(1),
            otherwise: schema => schema
          }).required(),
          questions: yup.array().label('Soal').of(
            testValidation
          ).when('modules', {
            is: (val: Module[]) => val?.length === 0,
            then: (schema) => schema.min(1),
            otherwise: schema => schema
          }).required(),
        }, [['modules', 'questions']])
      ).min(1).required()
    })
  ).min(1).required()
})
