import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Badge } from 'components';
import {FC, memo} from 'react';

type Props = {
  data: SyllabusDetail['packages'][0]['directories'][0]['modules']
}

const Modules: FC<Props> = (props) => {
  const {data} = props;

  return (
    <>
      <section className='border rounded-xl overflow-hidden'>
        <div className='px-5 py-4 flex justify-between items-center border-b bg-slate-100'>
          <p className='font-bold'>Sub bab</p>
        </div>
        {data.length ? (
          <div className='px-5 py-4 grid gap-y-[0.625rem]'>
            {data.map((module, index) => {
              return (
                <div key={`module-${module.id}`} className='flex w-full justify-between items-center'>
                  <div className='flex items-center gap-x-2'>
                    <FontAwesomeIcon icon={icon({name: 'file-lines'})} />
                    <p>{module.name}</p>
                  </div>
                  <Badge color='gray'>
                    <span>{`${module.assets} materi`}</span>
                  </Badge>
                </div>
              )
            })}
          </div>
        ) : (
          <div className='px-5 py-4'>
            <p className='my-6'>Anda belum menambahkan modul apapun.</p>
          </div>
        )}
      </section>
    </>
  );
};

export default memo(Modules);
