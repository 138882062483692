import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { MouseEvent, FC, memo, useState } from "react";
import Avatar from "./avatar";
import Level from "./level";
import Detail from "./Modal/detail";
import Validation from "./Modal/validation";
import { getFormatDate } from "utilities";
import useAskHomework from "services/useAskHomework";
import toast from "react-hot-toast";

type Props = {
  data: TeacherRequest;
  onRefresh?: () => void
};

const Request: FC<Props> = ({ data, onRefresh }) => {
  const { avatar, name, grades, subjects, created_at, request_status } = data;

  const { approvalRequest } = useAskHomework();
  const [show, setShow] = useState(false);
  const [showValidation, setShowValidation] = useState(false);
  const [isAccepted, setIsAccepted] = useState(false);

  const isPending = request_status === "requested";
  const isRejected = request_status === "rejected";
  const statusIcon = isRejected ? (
    <FontAwesomeIcon icon={icon({ name: "close" })} color="red" />
  ) : (
    <FontAwesomeIcon icon={icon({ name: "check" })} color="green" />
  );

  const handleDetail = () => {
    if (isPending) {
      setShow(true);
    }
  };

  const handleValidation = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (event.target instanceof Element) {
      const value = event.currentTarget.value;
      const isApproved = value === "approved";

      toast.promise(
        approvalRequest({
          id: data.request_id,
          status: value as ApprovalStatus,
        }),
        {
          loading: "Loading...",
          success: () => {
            setIsAccepted(isApproved);
            setShowValidation(true);

            return "";
          },
          error: "Terjadi kesalahan!",
        }
      );
    }
  };

  return (
    <>
      <div
        className={`flex items-center justify-between bg-white p-5 rounded-lg gap-x-4 ${
          isPending ? "cursor-pointer hover:bg-yellow-50" : "cursor-default"
        }`}
        onClick={handleDetail}
      >
        <div className="flex gap-x-8">
          <div className="flex items-center gap-x-3 w-40">
            <Avatar image={avatar} />
            <p className="text-sm">{name}</p>
          </div>
          <div className="lg:w-32 xl:w-80">
            <Level title="Strata mengajar" data={grades} isColumn />
          </div>
          <div className="lg:w-32 xl:w-80">
            <Level title="Mata pelajaran" data={subjects} isColumn />
          </div>
          <div className="space-y-2">
            <p className="text-sm">Tanggal</p>
            <p className="text-sm">
              {getFormatDate(created_at, "DD/MM/YYYY HH:mm")}
            </p>
          </div>
        </div>
        <div className="flex items-center gap-x-2">
          {isPending ? (
            <>
              <button
                value={"rejected"}
                onClick={handleValidation}
                className="border border-gray-300 rounded-lg p-2 w-9 h-9 grid place-items-center text-red-500 hover:bg-red-700 hover:text-white"
              >
                <FontAwesomeIcon icon={icon({ name: "close" })} size="lg" />
              </button>
              <button
                value={"approved"}
                onClick={handleValidation}
                className="bg-green-500 rounded-lg p-2 w-9 h-9 grid place-items-center"
              >
                <FontAwesomeIcon
                  icon={icon({ name: "check" })}
                  color="white"
                  size="lg"
                />
              </button>
            </>
          ) : (
            <>
              {statusIcon}
              <p className="text-sm">{isRejected ? "Ditolak" : "Diterima"}</p>
            </>
          )}
        </div>
      </div>
      <Detail
        show={show}
        onClose={() => setShow(false)}
        data={data}
        onValidate={handleValidation}
      />
      <Validation
        show={showValidation}
        onClose={() => {
          setShowValidation(false);
          onRefresh?.();
        }}
        isAccepted={isAccepted}
      />
    </>
  );
};

export default memo(Request);
