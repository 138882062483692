import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "components/Modal";
import { FC, memo } from "react";

type Props = {
  show: boolean;
  onClose: () => void;
  isDraft?: boolean;
  isVoucher: boolean;
};

const SuccessAdd: FC<Props> = ({ show, onClose, isDraft, isVoucher }) => {
  return (
    <Modal
      title=""
      show={show}
      onClose={onClose}
      customFooter={
        <div className="grid place-items-center">
          <button
            className="py-[0.625rem] px-4 rounded-xl bg-primary"
            onClick={onClose}
          >
            Tutup
          </button>
        </div>
      }
    >
      <div className="grid place-items-center gap-y-10 text-center">
        <FontAwesomeIcon
          icon={icon({ name: "check" })}
          className={"w-10 h-10 text-white rounded-full p-1.5 bg-green-500"}
        />
        <div className="space-y-4 w-96">
          <p className="font-bold text-2xl">{`${
            isVoucher ? "Voucher" : "Promo"
          } berhasil dibuat`}</p>
          {!isDraft && (
            <p className="text-gray-600">
              {`${
                isVoucher ? "Voucher" : "Promo"
              } akan secara otomatis dapat digunakan oleh pengguna.`}
            </p>
          )}
        </div>
      </div>
    </Modal>
  );
};

export default memo(SuccessAdd);
