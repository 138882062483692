import {FC, memo, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Modal from 'components/Modal';
import {Button} from 'components';
import { PROGRAM_CATEGORY } from 'constant';

type Props = {
  show: boolean
  onClose: () => void
}

type Category = 'learn' | 'class'

const Categories: FC<Props> = ({show, onClose}) => {
  const navigate = useNavigate();
  const [type, setType] = useState<Category>('learn');

  const handleNext = () => {
    navigate('tambah', {
      relative: 'path',
      state: {
        type
      }
    })
  };

  return (
    <Modal
      title='Pilih Kategori Program'
      show={show}
      onClose={onClose}
      customFooter={(
        <div className='flex justify-end gap-x-2'>
          <Button.Cancel onButtonClick={onClose} />
          <Button color='primary' onButtonClick={handleNext}>
            Lanjutkan
          </Button>
        </div>
      )}
    >
      <div className='grid gap-y-5'>
        {PROGRAM_CATEGORY.map((category) => (
          <label
            key={category.value}
            className={`px-5 py-4 rounded-lg border flex gap-x-3 items-start cursor-pointer ${category.disabled ? 'bg-gray-100' : 'bg-white'}`}
            htmlFor={category.value}
          >
            <input
              type="radio"
              id={category.value}
              value={category.value}
              name='category'
              className='radio checked:bg-primary checked:border-none'
              onChange={(e) => setType(e.target.value as Category)}
              checked={category.value === type}
              disabled={category.disabled}
            />
            <div>
              <p className='font-bold text-xl'>{category.label}</p>
              <p className='text-gray-600'>{category.description}</p>
            </div>
          </label>
        ))}
      </div>
    </Modal>
  )
};

export default memo(Categories);
