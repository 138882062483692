import { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import usePromo from "services/usePromo";
import { Button, Spinner } from "components";
import { DEFAULT_PAGINATION } from "constant";
import Filter from "./fragment/Filter";
import Item from "./fragment/Item";
import Footer from "./fragment/Footer";
import Categories from "./fragment/Modal/Categories";

const customPagination = {
  ...DEFAULT_PAGINATION,
  per_page: 9,
};

export default function Promo() {
  const navigate = useNavigate();
  const { getList, updatePromo, deletePromo } = usePromo();

  const [promoList, setPromoList] = useState<PromoItem[]>([]);
  const [pagination, setPagination] = useState(customPagination);
  const [showCategory, setShowCategory] = useState(false);
  const [loading, setLoading] = useState(false);

  const getData = useCallback(async (params?: PromoParams) => {
    setLoading(true);
    const resp = await getList(params);
    const { data, ...restResponse } = resp;

    setPromoList(data);
    setPagination(restResponse);
    setLoading(false);
  }, []);

  useEffect(() => {
    getData({
      per_page: 9,
      page: 1,
      latest: "desc",
    });
  }, []);

  const toggleCategory = () => setShowCategory(!showCategory);

  const handleSearch = (value: string) => {
    getData({
      keyword: value,
      page: 1,
      per_page: pagination.per_page,
    });
  };

  const handleChangeStatus = (value: PromoParams["status"] | null) => {
    getData({
      page: 1,
      per_page: pagination.per_page,
      status: value,
    });
  };

  const handleChangeType = (value: PromoType | null) => {
    getData({
      page: 1,
      per_page: pagination.per_page,
      type: value,
    });
  };

  const handleChangeLatest = (value: PromoParams["latest"] | null) => {
    getData({
      page: 1,
      per_page: pagination.per_page,
      latest: value ?? "desc",
    });
  };

  const handleIsActive = (item: PromoItem) => {
    const isActive = Boolean(item.is_active);

    toast.promise(updatePromo(item.id, !isActive), {
      loading: `${isActive ? "Nonaktifkan" : "Aktifkan"} ${item.title}...`,
      success: () => {
        getData();
        return `Berhasil ${isActive ? "Nonaktifkan" : "Aktifkan"} ${
          item.title
        }`;
      },
      error: `Gagal ${isActive ? "Nonaktifkan" : "Aktifkan"} ${item.title}`,
    });
  };

  const handleDelete = (item: PromoItem) => {
    toast.promise(deletePromo(item.id), {
      loading: `Menghapus ${item.title}...`,
      success: () => {
        getData({
          page: 1,
          per_page: 10,
        });
        return `Berhasil menghapus ${item.title}`;
      },
      error: `Gagal menghapus ${item.title}`,
    });
  };

  const handleDetail = (item: PromoItem) => {
    navigate(`${item.id}`, {
      relative: "path",
    });
  };

  return (
    <section className="space-y-5">
      <div className="flex items-center justify-between">
        <div>
          <p className="font-bold">{`Total ${pagination.total} promo & voucher`}</p>
          <p className="text-xs text-gray-600">
            Seluruh promo dan voucher yang dibuat
          </p>
        </div>
        <Button.Create onButtonClick={toggleCategory} />
      </div>
      <Filter
        onChange={handleSearch}
        onChangeStatus={handleChangeStatus}
        onChangeType={handleChangeType}
        onChangeLatest={handleChangeLatest}
      />
      {loading ? (
        <div className="text-center">
          <Spinner />
        </div>
      ) : promoList.length ? (
        <div className="grid grid-cols-3 gap-8">
          {promoList.map((val) => (
            <Item
              key={`promo-item-${val.id}`}
              data={val}
              onUpdate={handleIsActive}
              onDelete={handleDelete}
              onDetail={handleDetail}
            />
          ))}
        </div>
      ) : (
        <p className="italic text-center">Tidak ada data</p>
      )}
      <Footer pagination={pagination} onGetData={getData} />
      <Categories show={showCategory} onClose={toggleCategory} />
    </section>
  );
}
