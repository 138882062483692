import React from "react";

interface DifficultyBadgeProps {
  difficulty: number;
}

const DifficultyBadge: React.FC<DifficultyBadgeProps> = ({ difficulty }) => {
  let circleColor, difficultyText;

  switch (difficulty) {
    case 1:
      circleColor = "#22C55E";
      difficultyText = "Mudah";
      break;
    case 2:
      circleColor = "#FDE047";
      difficultyText = "Sedang";
      break;
    case 3:
      circleColor = "#EF4444";
      difficultyText = "Sulit";
      break;
    default:
      circleColor = "gray";
      difficultyText = "[-]";
  }

  const circleStyle = {
    backgroundColor: circleColor,
    width: "16px",
    height: "16px",
    borderRadius: "50%",
    display: "inline-block",
    // marginRight: "8px",
  };

  return (
    <div
      className={`flex gap-2 ${
        difficulty === 3
          ? "text-start items-start justify-center me-5"
          : "text-center items-center justify-center"
      }  `}
    >
      <div style={circleStyle}></div>
      {difficultyText}
    </div>
  );
};

export default DifficultyBadge;
