import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { InputNumber } from "components";
import { FC, memo, useEffect, useMemo, useState } from "react";
import { currencyConverter, priceByDiscount } from "utilities";

type Props = {
  data: Partial<TryoutPayload>;
  canEdit?: boolean;
  onSave?: (data: Partial<TryoutPayload>) => void;
};

const Price: FC<Props> = (props) => {
  const { canEdit = false, data, onSave } = props;
  const [price, setPrice] = useState(data.price || 0);
  const [isFlatDisc, setIsFlatDisc] = useState(data.is_flat_disc);
  const [discount, setDiscount] = useState(data.discount || 0);
  const [isEdit, setIsEdit] = useState(false);

  const finalPrice = useMemo(() => {
    if (isFlatDisc) {
      return currencyConverter(price - discount);
    } else {
      return priceByDiscount(price, discount);
    }
  }, [isFlatDisc, price, discount]);

  useEffect(() => {
    setDiscount(data?.discount || 0);
    setIsFlatDisc(data.is_flat_disc);
    setPrice(data?.price || 0);
  }, [data]);

  const toggleEdit = () => setIsEdit(!isEdit);

  const onCancel = () => {
    setDiscount(data?.discount || 0);
    setIsFlatDisc(data.is_flat_disc);
    setPrice(data?.price || 0);
    toggleEdit();
  };

  const handleSave = () => {
    if (onSave && canEdit) {
      onSave({
        price,
        is_flat_disc: isFlatDisc,
        discount,
      });
    }
    toggleEdit();
  };

  return (
    <div className="bg-white rounded-xl">
      <div className="flex items-center justify-between px-5 py-4 border-b">
        <p className="font-bold">Harga</p>
        {canEdit &&
          (isEdit ? (
            <div className="flex items-center gap-x-2">
              <FontAwesomeIcon
                color="#F04438"
                icon={icon({ name: "close" })}
                className="cursor-pointer"
                onClick={onCancel}
              />
              <FontAwesomeIcon
                color="#17B26A"
                icon={icon({ name: "floppy-disk", style: "regular" })}
                className="cursor-pointer"
                onClick={handleSave}
              />
            </div>
          ) : (
            <FontAwesomeIcon
              color="#B5A300"
              icon={icon({ name: "edit" })}
              className="cursor-pointer"
              onClick={toggleEdit}
            />
          ))}
      </div>
      <div className="grid gap-y-3 p-5">
        <div className="flex items-center gap-x-1.5">
          <p className="w-36 text-gray-500">Harga program</p>
          {isEdit ? (
            <div className="w-52">
              <InputNumber
                label=""
                className="px-3.5 py-2.5 border rounded-lg"
                placeholder="Masukkan harga"
                options={{
                  numeral: true,
                  numeralDecimalMark: ",",
                  delimiter: ".",
                }}
                value={price}
                onChange={(e) => setPrice(Number(e.target.rawValue))}
              />
            </div>
          ) : (
            <p>{currencyConverter(price)}</p>
          )}
        </div>
        <div className="flex items-center gap-x-1.5">
          <p className="w-36 text-gray-500">Diskon</p>
          {isEdit ? (
            <div className="w-52 px-3.5 py-2.5 border rounded-lg mt-1.5 flex">
              <InputNumber
                label=""
                className="w-full pr-3.5"
                placeholder="Masukkan diskon"
                options={{
                  numeral: true,
                  numeralDecimalMark: ",",
                  delimiter: ".",
                }}
                value={discount}
                onChange={(e) => setDiscount(Number(e.target.rawValue))}
              />
              <select
                name="isFlatDiscount"
                id="isFlatDiscount"
                value={Number(isFlatDisc)}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  setIsFlatDisc(Boolean(value));
                }}
              >
                <option value={1}>Rp</option>
                <option value={0}>%</option>
              </select>
            </div>
          ) : (
            <p className="text-red-500">
              {isFlatDisc ? `${currencyConverter(discount)}` : `${discount}%`}
            </p>
          )}
        </div>
        <div className="flex items-center gap-x-1.5">
          <p className="w-36 text-gray-500">Harga final</p>
          <p className="font-bold">{finalPrice}</p>
        </div>
        <p className="text-xs text-gray-500">
          * Harga final akan ditampilkan pada mobile dan web app
        </p>
      </div>
    </div>
  );
};

export default memo(Price);
