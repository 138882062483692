import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Disclosure } from '@headlessui/react';
import { Spinner } from 'components/Spinner';
import {FC, memo, useEffect, useState} from 'react';
import useBrowse from 'services/useBrowse';
import {FormSyllabus, Module} from "../../../../../views/Silabus/types";
import {SyllabusModule} from "../../Material";
import {useFormContext, useWatch} from "react-hook-form";

type Props = {
  directoryId?: number
  directories?: string[]
  initialData?: BrowseItem[]
  indent?: number
  onSelect: (item: SyllabusModule) => void
  selected?: SyllabusModule[]
  disabledIds?: number[],
  packageIndex: number
}

const List: FC<Props> = (props) => {
  const {
    directoryId,
    initialData = [],
  } = props;
  const {getList} = useBrowse('module');

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<BrowseItem[]>(initialData);

  useEffect(() => {
    if (directoryId) {
      setLoading(true);
      getList({
        directory_id: directoryId,
        per_page: 50
      })
      .then((response) => setData(response?.data || []))
      .catch((err) => console.log('Err', err))
      .finally(() => setLoading(false));
    }
  }, [directoryId]);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <RenderItem data={data} {...props}/>
      )}
    </>
  );
};

const RenderItem: FC<Props & {data: BrowseItem[]}> = memo((props) => {
  const {
    data,
    indent = 1,
    directories = [],
    onSelect,
    selected = [],
    disabledIds,
    directoryId,
    packageIndex
  } = props;

  const {control} = useFormContext<FormSyllabus>();
  const watchDirectories = useWatch({ name: `packages.${packageIndex}.directories`, control });
  const watchModules = watchDirectories.find((dir)=>dir.directoryId === directoryId)?.modules ?? []
  const [selectedModuleInDirectory, setSelectedModuleInDirectory] = useState<Module[]>(watchModules)
  const onClickModule = (item: BrowseItem) => {
    if (directoryId){
      const isExist = selectedModuleInDirectory?.find((val) => String(item.id) === String(val.module_id));
      if (isExist){
        const filterSelected = selectedModuleInDirectory.filter((val) => String(item.id) !== String(val.module_id));
        setSelectedModuleInDirectory(filterSelected)
      }else{
        setSelectedModuleInDirectory([...selectedModuleInDirectory, {name: item.name, module_id: item.id}])
      }
    }
  }

  useEffect(()=>{
    if (directoryId){
      const parsed: SyllabusModule = {
          directories: directories,
          directoryId:directoryId,
          modules: selectedModuleInDirectory.map((module)=>({
            name: module.name,
                module_id: module.module_id,
          }))
      }
      onSelect(parsed)
    }
  },[selectedModuleInDirectory])

  return (
    data.length ? (
      <>
        {data.map((item) => {
          let paddingLeft = 32 * (indent - 1);
          paddingLeft = paddingLeft ? paddingLeft : 4;
          const isModule = item.type === 'module'
          const isSelected = Boolean(selectedModuleInDirectory.find((val) => val.module_id === item.id));

          return (
            <Disclosure key={`directory-${item.id}`}>
              {({ open }) => {
                const itemIcon = open ? icon({name: 'chevron-down'}) : icon({name: 'chevron-right'});

                return (
                  <>
                    <div
                      className={`
                        flex 
                        w-full 
                        items-center 
                        gap-x-4 
                        pr-4 
                        py-2 
                        text-left 
                        font-medium
                        hover:bg-yellow-100 
                        border-b
                        ${isSelected && isModule ? "bg-yellow-100" : "bg-white"}
                        ${disabledIds?.includes(item.id) ? "bg-yellow-100" : "bg-white"}
                      `}
                      style={{
                        paddingLeft,
                      }}
                    >
                      {item.type === 'module'?
                        <input
                          type="checkbox"
                          id={`module-${item.id}`}
                          className="checkbox checked:bg-yellow-400 checked:border-primary text-gray-500"
                          onChange={() => onClickModule(item)}
                          checked={isSelected}
                        />
                      :
                        <Disclosure.Button>
                          <FontAwesomeIcon icon={itemIcon} width={16} />
                        </Disclosure.Button>
                      }
                      <div
                        className="flex items-center gap-x-3 w-full"
                      >
                        {item.type === 'module' ?
                          <FontAwesomeIcon icon={icon({ name: "file-lines" })} />:
                          <FontAwesomeIcon icon={icon({ name: "folder" })} />
                        }
                        <span>{item.name}</span>
                      </div>
                    </div>

                    {open && (
                      <Disclosure.Panel>
                        <List
                          directoryId={item.id}
                          directories={[...directories, item.name]}
                          indent={indent + 1}
                          onSelect={onSelect}
                          selected={selected}
                          packageIndex={packageIndex}
                        />
                      </Disclosure.Panel>
                    )}
                  </>
                );
              }}
            </Disclosure>
          )
        })}
      </>
    ) : (
      <p className="text-center" />
    )
  )
});

export default memo(List);
