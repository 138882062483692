import {FC, memo, useEffect, useState,} from 'react';
import DetailTab from "./fragment/DetailTab";
import MeetingsTab from "./fragment/MeetingsTab";
import StudentsTab from "./fragment/StudentsTab";
import ClassesTab from "./fragment/ClassesTab";
import { useParams } from 'react-router-dom';
import useLiveClass from 'services/useLiveClass';
import toast from 'react-hot-toast';

const TABS = ['Detail program', 'Pertemuan', 'Peserta', 'Kelas']
const LiveClassDetail: FC = () => {
  const {id} = useParams();
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(0)
  const {getDetail , updateDetail} = useLiveClass();
  const [data, setData] = useState<LiveClassDetail | null>(null);
  const [refresh, setRefresh] = useState(false);
  
  useEffect(() => {
    if (id) {
      setLoading(true);
      getDetail(Number(id))
        .then((response) => setData(response?.data))
        .catch((err) => toast.error(err?.message || 'Gagal memuat data.'))
        .finally(() => setLoading(false))
    }
  }, [id, getDetail, refresh]);

  const onRefresh = () => {
    setRefresh(!refresh)
  }

  return (
    <section className='flex flex-1 flex-col w-full space-y-4'>
      <div>
        <span className='font-bold text-lg mr-2'>{data?.title}</span>
        <span className="badge badge-flat-primary">LIVE CLASS</span>
      </div>

      <div className='space-x-2'>
        {TABS.map((item,index)=>
          <button className={`btn btn-ghost btn-sm ${activeTab === index? 'btn-solid-warning btn':'btn-ghost'}`} onClick={()=>setActiveTab(index)}>{item}</button>
        )}
      </div>

      <Page page={activeTab} onRefresh={onRefresh}/>
    </section>
  )
}

const Page = ({page, onRefresh}: {page: number; onRefresh?: () => void}) => {
  const handleRefresh = onRefresh || (() => {});

  switch (page){
    case 0:
      return <DetailTab onRefresh={handleRefresh}/>
    case 1:
      return <MeetingsTab/>
    case 2:
      return <StudentsTab/>
    case 3:
      return <ClassesTab/>
    default:
      return null
  }
}

export default memo(LiveClassDetail)
