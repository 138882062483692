// React
import { PropsWithChildren, FC, memo } from "react"

type Props = PropsWithChildren<{
  className?: string
  color?: BadgeColor
}>

const Badge: FC<Props> = (props) => {
  const {children, className, color} = props;
  // Variables
  const badgeColor = {
    error: "badge-error",
    primary: "badge-primary",
    success: "badge-success",
    warning: "badge-warning",
    secondary: "badge-secondary",
    blue: "bg-[#2E90FA] text-white",
    "cancel-primary": "bg-[#FFFBB0] text-[#B5AC00]",
    citrine: "bg-[#D5CB09] text-white",
    gray: "bg-[#999999] text-white",
    "warning-light": "bg-[#FFFAEB] border-[#FEDF89] text-[#B54708]",
    "blue-light": "bg-[#F0F9FF] border-[#B9E6FE] text-[#026AA2]",
    "purple-light": "bg-[#F4F3FF] border-[#D9D6FE] text-[#5925DC]",
    "green-light": "bg-[#57B371] text-white",
    "blue-jeans": "bg-[#60A6E6] text-white",
  };

  return (
    <div className={`badge ${color ? badgeColor[color] : ""} rounded-[6.25rem] ${className}`}>
      {children}
    </div>
  )
};

export default memo(Badge);
