import { Label } from "components";
import { FC, memo, useEffect, useState } from "react";

type Props = {
  list: Variety[];
  onChange: (variety: Variety) => void;
  selected: Variety
};

const Variety: FC<Props> = (props) => {
  const { list, onChange, selected } = props;

  const [currentValue, setCurrentValue] = useState(selected);

  useEffect(() => {
    setCurrentValue(selected);
  }, [selected]);

  const handleVariety = (data: Variety) => {
    setCurrentValue(data);
    onChange(data);
  };

  return (
    <div className="p-5 flex gap-x-8">
      <div className="flex-grow-[2]">
        <Label>Variasi soal</Label>
        <div className="flex flex-wrap gap-x-3 gap-y-6 mb-4">
          {list.map((val) => (
            <label key={val.id}>
              <input
                type="radio"
                name="variant"
                id={`${val.id}`}
                className="peer hidden"
                onChange={() => handleVariety(val)}
                checked={currentValue?.id === val.id}
              />
              <span />
              <span className="text-sm px-4 py-2.5 cursor-pointer border border-gray-300 rounded-lg peer-checked:border-primary peer-checked:bg-primary">
                {val.name}
              </span>
            </label>
          ))}
        </div>
        <Label>Deskripsi</Label>
        <p
          className="text-sm text-gray-700"
          dangerouslySetInnerHTML={{ __html: currentValue?.description }}
        />
      </div>
      <div className="px-5 py-4 flex flex-col h-fit gap-y-4 rounded-lg bg-yellow-50 min-w-[23rem]">
        <div className="flex items-center justify-between">
          <p className="text-sm">Jumlah soal per siswa</p>
          <p className="text-sm">{currentValue?.total_soal_per_siswa}</p>
        </div>
        <div className="flex items-center justify-between">
          <p className="text-sm">Jumlah siswa</p>
          <p className="text-sm">{currentValue?.total_siswa}</p>
        </div>
        <div className="flex items-center justify-between">
          <p className="text-sm">Total variasi soal dibutuhkan</p>
          <p className="text-sm font-bold">{currentValue?.total_variety}</p>
        </div>
      </div>
    </div>
  );
};

export default memo(Variety);
