import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import useLiveClass from "../../../../services/useLiveClass";
import {InView} from "react-intersection-observer";
import {Spinner} from "../../../../components";
import {DEFAULT_PAGINATION} from "../../../../constant";
import {useDebounce} from "../../../../utilities";

const Students = () => {
  const { program_id = '', meet_id = '' } = useParams();
  const {getLiveMeetStudents} = useLiveClass();
  const [keyword, setKeyword] = useState("");
  const debounceSearch = useDebounce(keyword);
  const [studentsData, setStudentsData] = useState<LiveClassStudentItem[]>([])
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);

  const getData = useCallback(
    async (params: LiveClassStudentsParams, loadMore?: boolean) => {
      const response = await getLiveMeetStudents(params);
      const { data, ...restResponse } = response.data;

      if (loadMore) {
        setStudentsData([...studentsData, ...data]);
      } else {
        setStudentsData(data);
      }

      setPagination(restResponse);
    },
    [studentsData]
  );

  useEffect(() => {
    if (debounceSearch.length >= 3) {
      getData({
        per_page: pagination.per_page,
        meet_class_id: meet_id,
        program_id,
        keyword,
        page: 1,
      });
    }

    if (!debounceSearch.length) {
      getData({
        per_page: pagination.per_page,
        meet_class_id: meet_id,
        program_id,
        keyword: "",
        page: 1,
      });
    }
  }, [debounceSearch]);

  const loadMore = () => {
    getData(
      {
        per_page: pagination.per_page,
        meet_class_id: meet_id,
        program_id,
        keyword: "",
        page: pagination?.current_page + 1,
      },
      true
    );
  };

  return(
    <div className='py-3 px-5 bg-white rounded-md flex flex-col space-y-3'>
      <p className='text-md font-bold text-gray-900'>Siswa</p>
      <div className='bg-white px-4 py-2 rounded-md w-full flex gap-x-2 items-center border'>
        <FontAwesomeIcon icon={icon({name: 'search'})} color="#667085"/>
        <input
          className='w-full text-gray-500'
          placeholder='Cari siswa'
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />
      </div>
      {studentsData.map((item)=>(
        <div key={item.student_id} className='flex space-x-2 items-center'>
          <div className='flex w-9 h-9 bg-gray-100 rounded-full border border-gray-200 items-center justify-center'>
            <FontAwesomeIcon icon={icon({ name: "user" })} color="#475467" />
          </div>
          <div className='flex flex-col'>
            <span className='text-gray-700 text-sm font-semibold'>{item.student_name}</span>
            <span className='text-gray-400 text-xs'>{item.school_name}</span>
          </div>
        </div>
      ))}
      {pagination.current_page < pagination.last_page && (
        <InView
          as="div"
          onChange={(inView) => {
            if (inView) {
              loadMore();
            }
          }}
        >
          <Spinner />
        </InView>
      )}
    </div>
  )
}

export default Students
