import {useCallback} from 'react';
import {services} from 'services';
import {API_URL} from 'constant';
import {AxiosError} from "axios";

const useAvatar = () => {
  const getSettings = useCallback(async (): Promise<GetAvatarSettingsResponse> => {
    try {
      const response = await services.get(`${API_URL.avatar}/settings`);
      return response?.data?.data;
    } catch (error) {
      return [];
    }
  }, []);

  const updateSettings = useCallback(async (body: UpdateAvatarBody): Promise<string> => {
    try {
      const response = await services.post(`${API_URL.avatar}/settings`, body);
      return response?.data?.data;
    } catch (error) {
      const err = error as AxiosError;
      throw err.response?.data;
    }
  }, []);

  return {
    getSettings,
    updateSettings
  }
};

export default useAvatar;
