import {FC, memo, useState, useEffect} from 'react';
import {useFormContext} from 'react-hook-form';
import { currencyConverter, priceByDiscount } from 'utilities';
import {Disclosure} from '@headlessui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import Material from './Material';

const Summary: FC = () => {
  const {getValues} = useFormContext();

  const [data, setData] = useState<SyllabusDetail | null>(null);

  useEffect(() => {
    const localData = localStorage.getItem('syllabus');

    if (localData) {
      setData(JSON.parse(localData));
    }
  }, []);

  const calculateTotal = () => {
    const isFlatDisc = getValues('is_flat_disc');
    const price = getValues('price');
    const discount = getValues('discount');
    let finalPrice = '';

    if (isFlatDisc) {
      finalPrice = `${currencyConverter(price - discount)}`;
    } else {
      finalPrice = priceByDiscount(price, discount);
    }

    return finalPrice;
  };

  return (
    <>
      <div className='w-full bg-white rounded-xl mb-5'>
        <div className='px-5 py-4 border-b border-gray-200'>
          <p className='font-bold text-xl'>Detail program</p>
        </div>
        <div className='flex flex-row gap-x-8'>
          <div className='px-5 py-4 w-[60%]'>
            <p className='text-sm mb-2'>
              Judul program
            </p>
            <p className='mb-5 text-sm text-gray-500'>{getValues('title')}</p>
            <p className='text-sm mb-2'>
              Deskripsi
            </p>
            <p className='mb-5 text-sm text-gray-500' dangerouslySetInnerHTML={{__html: getValues('description')}} />
            <p className='text-sm mb-2'>Thumbnail</p>
            <img src={getValues('thumbnail_path')} alt="thumbnail" width={324} height={185} />
          </div>
          <div className='w-[40%] pt-4 pr-5'>
            <div className='rounded border-gray-200 border'>
              <div className='px-5 py-4 border-b border-gray-200'>
                <p className='font-bold text-xl'>Harga program</p>
              </div>
              <div className='px-5 py-4 grid gap-y-5'>
                <div className='flex justify-between items-center'>
                  <p className='text-sm text-gray-500'>
                    Harga program
                  </p>
                  <p className='text-end text-sm'>
                    {currencyConverter(getValues('price'))}
                  </p>
                </div>
                <div className='flex justify-between items-center'>
                  <p className='text-sm text-gray-500'>
                    Diskon
                  </p>
                  <p className='text-end text-sm'>
                    {getValues('is_flat_disc') ? currencyConverter(getValues('discount')) : `${getValues('discount')}%`}
                  </p>
                </div>
                <hr />
                <div className='flex justify-between items-center'>
                  <p className='text-gray-500'>
                    Total
                  </p>
                  <p className='text-end font-bold'>
                    {calculateTotal()}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='w-full bg-white rounded-xl mb-5'>
        <div className='px-5 py-4 border-b border-gray-200 flex flex-row justify-between'>
          <p className='font-bold text-xl'>Silabus</p>
        </div>
        <div className='px-5 py-4'>
          {data ? (
            <>
              <div className='mb-8'>
                <p className='font-bold text-sm'>Judul silabus</p>
                <p className='text-base text-gray-500'>{data?.name}</p>
              </div>
              <div className='mb-8'>
                <p className='font-bold text-sm'>Deskripsi silabus</p>
                <p className='text-base text-gray-500' dangerouslySetInnerHTML={{__html: data?.description || ''}} />
              </div>
              <p className='font-bold text-sm mb-1'>Pertemuan</p>
              <div className='grid gap-y-5'>
                {data.packages?.map((item, index) => (
                  <Disclosure key={item.id}>
                    {({ open }) => {
                      const caret = open ? icon({name: 'chevron-down'}) : icon({name: 'chevron-left'});
                      return (
                        <div className='rounded-md bg-gray-100'>
                          <Disclosure.Button className="flex w-full justify-between items-center pl-3 pr-5 py-3 text-left font-bold focus:outline-none focus-visible:ring focus-visible:ring-opacity-75">
                            <span>{`Pertemuan ${index+1}`}</span>
                            <FontAwesomeIcon icon={caret} />
                          </Disclosure.Button>
                          <Disclosure.Panel className="ml-3 mr-5 py-4 text-sm text-gray-500 border-t">
                            <p className='font-medium text-sm mb-2'>Judul pertemuan</p>
                            <p className='text-sm text-gray-400 mb-4'>{item.title}</p>
                            <p className='font-medium text-sm mb-2'>Deskripsi pertemuan</p>
                            <p className='text-sm text-gray-400 mb-4' dangerouslySetInnerHTML={{__html: item.description}} />
                            <p className='font-medium mb-2 text-lg'>Bab</p>
                            <Material data={item.directories} />
                          </Disclosure.Panel>
                        </div>
                      )
                    }}
                  </Disclosure>
                ))}
              </div>
            </>
          ) : (
            <p>Anda belum menambahkan silabus apapun.</p>
          )}
        </div>
      </div>
    </>
  );
};

export default memo(Summary);
