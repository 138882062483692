import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Listbox } from "@headlessui/react";
import { PROGRAM_TYPE } from "constant";
import { FC, Fragment, memo, useEffect, useState } from "react";

type Props = {
  onChange: (value: ProgramType) => void;
};

const allType = {
  id: 0,
  name: "Semua jenis",
};

const TypeOption: FC<Props> = ({ onChange }) => {
  const [type, setType] = useState<Common[]>([]);
  const [selectedType, setSelectedType] = useState<Common>(allType);


  useEffect(() => {
    const tempArr = Object.entries(PROGRAM_TYPE).map((val) => ({
      id: val[0],
      name: val[1],
    }));

    setType(tempArr);
  }, []);

  const handleChange = (value: Common) => {
    if (value.id !== selectedType.id) {
      const currentType = value.id || null;
      setSelectedType(value);
      onChange(currentType as ProgramType);
    }
  };

  return (
    <div className="relative flex-1 min-w-[12rem]">
      <Listbox value={selectedType} onChange={handleChange}>
        <Listbox.Button className="relative flex w-full cursor-default items-center justify-between gap-x-1 rounded-lg border bg-white px-3.5 py-2.5 text-left text-sm">
          <span className="block truncate">{selectedType.name}</span>
          <FontAwesomeIcon icon={icon({ name: "chevron-down" })} />
        </Listbox.Button>
        <Listbox.Options className="absolute z-10 mt-1 max-h-48 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {[allType, ...type].map((val, classIdx) => (
            <Listbox.Option
              key={`${classIdx}-${val.id}`}
              as={Fragment}
              value={val}
            >
              {({ active }) => (
                <li
                  className={`relative flex items-center justify-between cursor-default select-none px-3.5 py-2.5 ${
                    active ? "bg-gray-50" : "text-gray-900"
                  }`}
                >
                  <span className="block truncate">{val.name}</span>
                  {val.id === selectedType.id && (
                    <FontAwesomeIcon
                      icon={icon({ name: "check" })}
                      color="#7F56D9"
                    />
                  )}
                </li>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  );
};

export default memo(TypeOption);
