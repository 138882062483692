import AlphaIcon from "../../../../icons/GreekLetters/alpha.svg";
import BetaIcon from "../../../../icons/GreekLetters/beta.svg";
import GammaIcon from "../../../../icons/GreekLetters/gamma.svg";
import DeltaIcon from "../../../../icons/GreekLetters/delta.svg";
import EpsilonIcon from "../../../../icons/GreekLetters/epsilon.svg";
import VarepsilonIcon from "../../../../icons/GreekLetters/varepsilon.svg";
import ZetaIcon from "../../../../icons/GreekLetters/zeta.svg";
import EtaIcon from "../../../../icons/GreekLetters/eta.svg";
import ThetaIcon from "../../../../icons/GreekLetters/theta.svg";
import VarthetaIcon from "../../../../icons/GreekLetters/vartheta.svg";
import IotaIcon from "../../../../icons/GreekLetters/iota.svg";
import KappaIcon from "../../../../icons/GreekLetters/kappa.svg";
import LambdaIcon from "../../../../icons/GreekLetters/lambda.svg";
import MuIcon from "../../../../icons/GreekLetters/mu.svg";
import NuIcon from "../../../../icons/GreekLetters/nu.svg";
import XiIcon from "../../../../icons/GreekLetters/xi.svg";
import PiIcon from "../../../../icons/GreekLetters/pi.svg";
import VarpiIcon from "../../../../icons/GreekLetters/varpi.svg";
import RhoIcon from "../../../../icons/GreekLetters/rho.svg";
import VarrhoIcon from "../../../../icons/GreekLetters/varrho.svg";
import SigmaIcon from "../../../../icons/GreekLetters/sigma.svg";
import VarsigmaIcon from "../../../../icons/GreekLetters/varsigma.svg";
import TauIcon from "../../../../icons/GreekLetters/tau.svg";
import UpsilonIcon from "../../../../icons/GreekLetters/upsilon.svg";
import PhiIcon from "../../../../icons/GreekLetters/phi.svg";
import VarphiIcon from "../../../../icons/GreekLetters/varphi.svg";
import ChiIcon from "../../../../icons/GreekLetters/chi.svg";
import PsiIcon from "../../../../icons/GreekLetters/psi.svg";
import OmegaIcon from "../../../../icons/GreekLetters/omega.svg";

const greekLetters = [
  {
    iconPath: AlphaIcon,
    label: '\\alpha'
  },
  {
    iconPath: BetaIcon,
    label: '\\beta'
  },
  {
    iconPath: GammaIcon,
    label: '\\gamma'
  },
  {
    iconPath: DeltaIcon,
    label: '\\delta'
  },
  {
    iconPath: EpsilonIcon,
    label: '\\epsilon'
  },
  {
    iconPath: VarepsilonIcon,
    label: '\\varepsilon'
  },
  {
    iconPath: ZetaIcon,
    label: '\\zeta'
  },
  {
    iconPath: EtaIcon,
    label: '\\eta'
  },
  {
    iconPath: ThetaIcon,
    label: '\\theta'
  },
  {
    iconPath: VarthetaIcon,
    label: '\\vartheta'
  },
  {
    iconPath: IotaIcon,
    label: '\\iota'
  },
  {
    iconPath: KappaIcon,
    label: '\\kappa'
  },
  {
    iconPath: LambdaIcon,
    label: '\\lambda'
  },
  {
    iconPath: MuIcon,
    label: '\\mu'
  },
  {
    iconPath: NuIcon,
    label: '\\nu'
  },
  {
    iconPath: XiIcon,
    label: '\\xi'
  },
  {
    iconPath: PiIcon,
    label: '\\pi'
  },
  {
    iconPath: VarpiIcon,
    label: '\\varpi'
  },
  {
    iconPath: RhoIcon,
    label: '\\rho'
  },
  {
    iconPath: VarrhoIcon,
    label: '\\varrho'
  },
  {
    iconPath: SigmaIcon,
    label: '\\sigma'
  },
  {
    iconPath: VarsigmaIcon,
    label: '\\varsigma'
  },
  {
    iconPath: TauIcon,
    label: '\\tau'
  },
  {
    iconPath: UpsilonIcon,
    label: '\\upsilon'
  },
  {
    iconPath: PhiIcon,
    label: '\\phi'
  },
  {
    iconPath: VarphiIcon,
    label: '\\varphi'
  },
  {
    iconPath: ChiIcon,
    label: '\\chi'
  },
  {
    iconPath: PsiIcon,
    label: '\\psi'
  },
  {
    iconPath: OmegaIcon,
    label: '\\omega'
  },
]

export default greekLetters
