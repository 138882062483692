// React
import { DetailedHTMLProps, InputHTMLAttributes, PropsWithChildren } from "react"

interface Type extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string
}

export function Radio(props: PropsWithChildren<Type>) {
  return (
    <label className={`flex cursor-pointer ${props.label && "gap-2"}`}>
      <input {...props} type="radio" className="radio checked:bg-primary checked:border-none"  />
      {props.label && <span className="font-bold">{props.label}</span>}
    </label>
  )
}