// API
import { QueryClient, QueryClientProvider } from "react-query";

// Components
import { Layout } from "components";

// Configs
import { route } from "configs";
import Login from "pages/Login";
import Logout from "pages/Logout";

// Router
import { RouterProvider, createBrowserRouter } from "react-router-dom";

// Moment
import moment from "moment";
import "moment/locale/id";

// Third-Party Libraries
import { Toaster } from "react-hot-toast";

import "react-quill/dist/quill.snow.css";
import "App.css";
import LiveClassMeet from "./pages/LiveClass/Meet";

function App() {
  moment().locale("id");

  // API
  const queryClient = new QueryClient();

  // Router
  const router = createBrowserRouter([
    {
      path: "/",
      element: <Layout />,
      children: route,
    },
    {
      path: "/login",
      element: <Login />,
    },
    {
      path: "/logout",
      element: <Logout />,
    },
    {
      path: "live-class",
      children: [
        {
          path: "program/:program_id/meet/:meet_id",
          element: <LiveClassMeet/>
        }
      ],
    },
  ]);

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
      <Toaster />
    </QueryClientProvider>
  );
}

export default App;
