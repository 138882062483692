/* eslint-disable @typescript-eslint/no-unused-vars */
// React
import { useEffect, useRef, useState } from "react"

// API
import { services } from "services"

// Components
import { Card, Content, Label} from "components"

// Utilities
import {programDetailData } from "../utilities"
import { SaveAsDraft } from "../components/SaveAsDraft"

interface BranchData {
  id: number;
  name: string;
}

export default function ProgramDetail() {
  const [programData, setProgramData] = useState<any[] | null[]>([])
  const [gradeId, setGradeId] = useState<number | null>(null);
  const [majorId, setMajorId] = useState<number | null>(null);
  const [subjectId, setSubjectId] = useState<number | null>(null);
  const [syllabusId, setSyllabusId] = useState<number | null>(null);
  const [teacherId, setTeacherId] = useState<number | null>(null);
  const [programId, setProgramId] = useState<number | null>(null);
  const [title, setTitle] = useState("");
  const [thumbnailPath, setThumbnailPath] = useState("");
  const [price, setPrice] = useState<number>(0);
  const [discount, setDiscount] = useState(0);
  const [isPromo, setIsPromo] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [type, setType] = useState("");
  const [tipePelaksanaanProgram, setTipePelaksanaanProgram] = useState("");
  const [isDraft, setIsDraft] = useState(false);
  const [expiredIn, setExpiredIn] = useState(1);
  const [expiredInType, setExpiredInType] = useState("d");
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [fileName, setFileName] = useState<string>('Choose File');
  const [totalPrice, setTotalPrice] = useState(0)
  const [description, setDescription] = useState('')
  const [lokasiPelaksanaanProgram, setLokasiPelaksanaanProgram] = useState("")
  const [lokasiPelaksanaanOptions, setLokasiPelaksanaanOptions] = useState<string[]>([]);
  const [postData, setPostData] = useState<any[]>([]);
  // alerts
  const [titleAlert, setTitleAlert] = useState(false);
  const [descriptionAlert, setDescriptionAlert] = useState(false)
  const [priceAlert, setPriceAlert] = useState(false);
  const [discountAlert, setDiscountAlert] = useState(false);
  const [fileNameAlert, setFileNameAlert] = useState(false);
  const [programTypeAlert, setProgramTypeAlert] = useState(false);
  const [tipePelaksanaanProgramAlert, setTipePelaksanaanProgramAlert] = useState(false);
  const [lokasiPelaksanaanProgramAlert, setLokasiPelaksanaanProgramAlert] = useState(false)
  const [formValid, setFormValid] = useState(true);

  const [discountType, setDiscountType] = useState('discount'); // Default value
  const [inputValue, setInputValue] = useState(0); 

  useEffect(() => {
    const PersonalInfoData = localStorage.getItem('program-kategori');
    if (PersonalInfoData) {
      setTipePelaksanaanProgram(PersonalInfoData);
    }
  }, [])

  const handleDiscountTypeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setDiscountType(event.target.value);
  };

  const handleInputValueChange = (event:any) => {
    const value = event.target.value;
    if (!isNaN(value) && value >= 0) {
      setInputValue(value);
    }
  };

  const calculateTotalPrice = () => {
    if (discountType === 'discount') {
      const priceAfterDiscount = Number(price) * (discount/100);
      const totalPriceAfterDiscount = Number(price) - priceAfterDiscount
      return Math.round(totalPriceAfterDiscount).toLocaleString('en-US', {
        minimumFractionDigits: 0,
      });
    } else if (discountType === 'flatPrice') {
      const priceAfterFlatDiscount = price- inputValue;
      return Math.round(Number(priceAfterFlatDiscount)).toLocaleString('en-US', {
        minimumFractionDigits: 0,
      });
    }
    return Math.round(Number(price)).toLocaleString('en-US', {
      minimumFractionDigits: 0,
    });
  };

  const currentPath = window.location.pathname;
  // Extract the program_id from the current URL
  const programIdMatch = currentPath.match(/\/program-detail\/(\d+)/);
  const programIdFromURL = programIdMatch ? programIdMatch[1] : null;

  const totalDiscountPrice = Number(price) * (discount/100)
  const totalPriceAfterDiscount = Number(price) - totalDiscountPrice
  
  async function GetBranch() {
    services.get("/dropdown/branch").then((resp) => {
      const response = resp.data.data
      if (Array.isArray(response)) {
        // Map the response to an array of strings representing the options
        const options = response.map((branch: BranchData) => branch.name);
        setLokasiPelaksanaanOptions(options);
      }
    }).catch(() => {})
  }

  useEffect(()=>{
    GetBranch()
  },[])
  
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const fileInput = event.target;
    if (fileInput.files && fileInput.files.length > 0) {
      const file = fileInput.files[0];
      const fileSizeInBytes = file.size;
      const maxSizeInBytes = 1048576; // 1MB
      if (fileSizeInBytes <= maxSizeInBytes) {
        setFileName(file.name);
      } else {
        setFileNameAlert(true); 
        setTimeout(() => {
          setFileNameAlert(false);
        }, 3000);
        // Optionally, you can reset the file input to clear the selected file
        fileInput.value = '';
        setFileName('Choose File');
      }
    } else {
      setFileName('Choose File');
    }
  };
  
  const handleTitleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTitle(e.target.value);
    setTitleAlert(false)
    setFormValid(true);
  };

  const handleDescInput = (e: any) => {
    setDescription(e.target.value);
    setDescriptionAlert(false)
    setFormValid(true);
  };

  function handleProgramPrice(event:any) {
    const value = event.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setPrice(value === '' ? 0 : parseFloat(value)); // Convert to a number if not empty, otherwise set to null
    }
  }

  function handleDiscount(event:any) {
    const value = event.target.value;
    if (!isNaN(value) && value >= 0) {
      setDiscount(value);
    }
  }

  const handleProgramType = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedProgramType = event.target.value
    setType(selectedProgramType);
    if(!selectedProgramType || selectedProgramType === "Pilih tipe program"){
      setProgramTypeAlert(true)
      setTimeout(() => {
        setProgramTypeAlert(false);
      }, 3000);
    }
  };

  useEffect(() => {
    if (tipePelaksanaanProgram === 'offline') {
      setLokasiPelaksanaanProgram('lokasi1');
    } else {
      setLokasiPelaksanaanProgram('');
    }
  }, [tipePelaksanaanProgram]);
  
  const handleLokasiPelaksanaanProgram = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setLokasiPelaksanaanProgram(event.target.value);
  };

  const handleTipePelaksanaanProgram= (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedTipePelaksanaanProgram = event.target.value
    setTipePelaksanaanProgram(selectedTipePelaksanaanProgram);
  };
  
  // useEffect(()=>{
  //   createProgram()
  // },[])

  const date = new Date();
  const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}:${date.getSeconds().toString().padStart(2, '0')}`;

  async function GetProgramDetail() {
    services.get("/program/active").then((resp) => {
      const response = resp.data.data.data
      setProgramData(response)
    }).catch(() => {})
  }

 const handleNext = async (e: React.MouseEvent<HTMLButtonElement>) => { 
    e.preventDefault();

    let valid = true;

    if(title.trim() === ''){
      setTitleAlert(true);
      setTimeout(() => {
        setTitleAlert(false);
      }, 3000);
      valid = false;
    }

    if(description.trim() === ''){
      setDescriptionAlert(true);
      setTimeout(() => {
        setDescriptionAlert(false);
      }, 3000);
      valid = false;
    }

    if(price === 0){
      setPriceAlert(true);
      setTimeout(() => {
        setPriceAlert(false);
      }, 3000);
      valid = false;
    }

    if(!type || type === "Pilih tipe program"){
      setProgramTypeAlert(true)
      setTimeout(() => {
        setProgramTypeAlert(false);
      }, 3000);
      valid = false;
    }

    if(!tipePelaksanaanProgram || tipePelaksanaanProgram === "Pilih tipe pelaksanaan program"){
      setTipePelaksanaanProgramAlert(true)
      setTimeout(() => {
        setTipePelaksanaanProgramAlert(false);
      }, 3000);
      valid = false;
    }

    if(lokasiPelaksanaanProgram === "Pilih lokasi pelaksanaan program" || !lokasiPelaksanaanProgram){
      setLokasiPelaksanaanProgramAlert(true)
      setTimeout(() => {
        setLokasiPelaksanaanProgramAlert
        (false);
      }, 3000);
      valid = false;
    }

    if (!valid) {
      setFormValid(false);
      setTimeout(() => {
        setFormValid(true);
      }, 3000);
      return;
    } else {
    try {
      if(type === 'class'){
      const postData = {
        program_id: programIdFromURL ? parseInt(programIdFromURL) : programId,
        grade_id: programIdFromURL ? gradeId : gradeId,
        major_id: programIdFromURL ? majorId : majorId,
        syllabus_id: programIdFromURL ? syllabusId : syllabusId,
        subject_id:programIdFromURL ? subjectId : subjectId,
        teacher_id:programIdFromURL? teacherId : teacherId,
        title:programIdFromURL? title : title,
        thumbnail_path: programIdFromURL? fileName : fileName,
        price: programIdFromURL? price : price,
        discount: programIdFromURL? discount : discount,
        is_promo: programIdFromURL ? isPromo : isPromo,
        is_active: programIdFromURL ? isActive : isActive,
        is_flat_disc: programIdFromURL ? false : discountType ==='discount' ? 0 : 1,
        type: programIdFromURL ? type : type,
        is_draft: programIdFromURL ? isDraft : isDraft,
        type_detail: {
          register_until:programIdFromURL?formattedDate:formattedDate,
          started_at:programIdFromURL?formattedDate:formattedDate,
          max_students: programIdFromURL ? 10 : 10,
          meet_time:programIdFromURL ? "eve" : "eve"
        }
      };

      // const resp = await services.post('/program', postData);
      // if(resp.data.success !== false){
        const existingData = localStorage.getItem('list-program-data');
        let dataArray: any[] = [];
        
        if (existingData) {
          dataArray = JSON.parse(existingData);
        }
      
        // // Append the new postData to the existing array
        // dataArray.push(postData);
        if (postData.program_id === null) {
          dataArray.push(postData); // Push the new 'data' to the end of the array
        } else {
          const programIndex = dataArray.findIndex((item) => item.program_id === postData.program_id);
        
          if (programIndex !== -1) {
            // Replace the data at the selected index
            dataArray[programIndex] = postData;
          } else {
            // Add the new 'data' to the end of the array
            dataArray.push(postData);
          }
        }
        // Store the updated array in local storage
        localStorage.setItem('list-program-data', JSON.stringify(dataArray));
        // window.location.href = '/program/silabus';
       
        
        // Construct the silabus URL with the extracted program_id
        const silabusUrl = programIdFromURL ? `/program/silabus/${programIdFromURL}` : '/program/silabus';
    
        // Update the window location to navigate to the silabus page
        window.location.href = silabusUrl;
        console.log('success');
      // }
      // else{
      //   console.log('wrong')
      // }
    } else {
      const learnData = {
        program_id: programIdFromURL ? parseInt(programIdFromURL) : programId,
        grade_id: programIdFromURL ? gradeId : gradeId,
        major_id: programIdFromURL ? majorId : majorId,
        syllabus_id: programIdFromURL ? syllabusId : syllabusId,
        subject_id:programIdFromURL ? subjectId : subjectId,
        teacher_id:programIdFromURL? teacherId : teacherId,
        title:programIdFromURL? title : title,
        thumbnail_path: programIdFromURL? fileName : fileName,
        price: programIdFromURL? price : price,
        discount: programIdFromURL? discount : discount,
        is_promo: programIdFromURL ? isPromo : isPromo,
        is_active: programIdFromURL ? isActive : isActive,
        is_flat_disc: programIdFromURL ? false : false,
        type: programIdFromURL ? type : type,
        is_draft: programIdFromURL ? isDraft : isDraft,
          type_detail: {
            expired_in: 1,
            expired_in_type: "d"
          }
      };
      const existingData = localStorage.getItem('list-program-data');
        let dataArray: any[] = [];
        
        if (existingData) {
          dataArray = JSON.parse(existingData);
        }
      
        // // Append the new postData to the existing array
        // dataArray.push(postData);
        if (learnData.program_id === null) {
          dataArray.push(learnData); // Push the new 'data' to the end of the array
        } else {
          const programIndex = dataArray.findIndex((item) => item.program_id === learnData.program_id);
        
          if (programIndex !== -1) {
            // Replace the data at the selected index
            dataArray[programIndex] = learnData;
          } else {
            // Add the new 'data' to the end of the array
            dataArray.push(learnData);
          }
        }
        // Store the updated array in local storage
        localStorage.setItem('list-program-data', JSON.stringify(dataArray));
        // window.location.href = '/program/silabus';
       
        console.log('program type learn success')
        
        // Construct the silabus URL with the extracted program_id
        const silabusUrl = programIdFromURL ? `/program/silabus/${programIdFromURL}` : '/program/silabus';
    
        // Update the window location to navigate to the silabus page
        window.location.href = silabusUrl;
        console.log('success');
    }
    } catch (error) {
      console.error('Failed to create program', error);
    }
    // window.location.href = '/program/silabus'
  }
  }

  const saveToLocalStorage = () => {
    if(type === 'class'){
      const existingData = localStorage.getItem('list-program-data');
      let dataArray: any[] = [];
  
      if (existingData) {
        dataArray = JSON.parse(existingData);
      }
      // Get the latest data (last item in the array)
      const latestData = dataArray[dataArray.length - 1];
      if(latestData){
        const dataToSave = {
          program_id: programIdFromURL ? parseInt(programIdFromURL) : programId,
          grade_id: programIdFromURL ? gradeId : gradeId,
          major_id: programIdFromURL ? majorId : majorId,
          syllabus_id: programIdFromURL ? syllabusId : syllabusId,
          subject_id:programIdFromURL ? subjectId : subjectId,
          teacher_id:programIdFromURL? teacherId : teacherId,
          title:programIdFromURL? title : title,
          thumbnail_path: programIdFromURL? fileName : fileName,
          price: programIdFromURL? price : price,
          discount: programIdFromURL? discount : discount,
          is_promo: programIdFromURL ? isPromo : isPromo,
          is_active: programIdFromURL ? isActive : isActive,
          is_flat_disc: programIdFromURL ? false : false,
          type: programIdFromURL ? type : type,
          is_draft: programIdFromURL ? isDraft : isDraft,
          type_detail: {
            register_until:programIdFromURL?formattedDate:formattedDate,
            started_at:programIdFromURL?formattedDate:formattedDate,
            max_students: programIdFromURL ? 10 : 10,
            meet_time:programIdFromURL ? "eve" : "eve"
          }
        };
        if (dataToSave.program_id === null) {
          dataArray.push(dataToSave); // Push the new 'data' to the end of the array
        } else {
          const programIndex = dataArray.findIndex((item) => item.program_id === dataToSave.program_id);
        
          if (programIndex !== -1) {
            // Replace the data at the selected index
            dataArray[programIndex] = dataToSave;
          } else {
            // Add the new 'data' to the end of the array
            dataArray.push(dataToSave);
          }
        }
        
        // Store the updated array in local storage
        localStorage.setItem('saved-draft-data', JSON.stringify(dataArray));
        // localStorage.setItem('saved-draft-data', JSON.stringify(dataToSave));
      }
    }
    else{
      const existingData = localStorage.getItem('list-program-data');
      let dataArray: any[] = [];
  
      if (existingData) {
        dataArray = JSON.parse(existingData);
      }
      // Get the latest data (last item in the array)
      const latestData = dataArray[dataArray.length - 1];
      if(latestData){
      const dataToSave = {
          program_id:programId,
          grade_id: gradeId,
          major_id: majorId,
          syllabus_id: syllabusId,
          subject_id:subjectId,
          teacher_id: teacherId,
          title: title,
          thumbnail_path: fileName,
          price: price,
          discount: discount,
          is_promo: isPromo,
          is_active: isActive,
          is_flat_disc: false,
          register_until:date,
          type: type,
          is_draft: isDraft,
          type_detail: {
            expired_in: 1,
            expired_in_type: "d"
          }
      };
      if (latestData.program_id === null) {
        dataArray.push(dataToSave); // Push the new 'data' to the end of the array
      } else {
        const programIndex = dataArray.findIndex((item) => item.program_id === dataToSave.program_id);
      
        if (programIndex !== -1) {
          // Replace the data at the selected index
          dataArray[programIndex] = dataToSave;
        } else {
          // Add the new 'data' to the end of the array
          dataArray.push(dataToSave);
        }
      }
      localStorage.setItem('saved-draft-data', JSON.stringify(dataArray));
    }
    }
  };


  function handleCancel(){
    window.location.href = '/program'
  }

   

  
  return (
    <Content>
      <Content.Header
        title={`${programDetailData.title} (1/4)`}
        rightElement={<SaveAsDraft onSave={saveToLocalStorage} />}
      />

      <Content.Body>
        <Card>
          {/* <Card.Header> 
            <div className="flex flex-col md:flex-row justify-center md:justify-between items-center text-black">
              <Card.Title>Daftar {programDetailData.title} (1/4)</Card.Title>

              <Select
                options={[
                  { label: "Terakhir dibuat" }
                ]}
              />
            </div>
          </Card.Header> */}

          <Card.Body>
            <div className="flex flex-col w-full text-black gap-8 bg-white">
                <div className="flex flex-col min-w-full">
                    <label>Judul*</label>
                    <input type="text" placeholder="Masukan judul" className="bg-white h-[36px] border-b-[1px] border-b-gray-500 focus:border-b-[1px] focus:border-b-[#FFF200]"  value={title}
                    onChange={handleTitleInput}/>
                    {titleAlert && <><label className="text-red-600">Judul harus di isi!</label></>}
                </div>

                <div className="flex flex-col min-w-full">
                    <label>Deskripsi*</label>
                <textarea
                placeholder="Masukan Deskripsi" value={description}
                onChange={handleDescInput}
                className="flex justify-start items-start bg-white h-[100px] border-b-[1px] border-b-gray-500 focus:border-b-[1px] focus:border-b-[#FFF200]"
                />
                 {descriptionAlert && <><label className="text-red-600">Deskripsi harus di isi!</label></>}
                </div>
                <div className="flex flex-row gap-8 w-full">
                    <div className="flex flex-col w-full gap-14">
                        <div className="flex flex-col w-full">
                            <label>Harga Program*</label>
                            <input value={price === null ? '' : price} onChange={handleProgramPrice} type="text" placeholder="Masukan harga program" className="bg-white h-[36px] border-b-[1px] border-b-gray-500 focus:border-b-[1px] focus:border-b-[#FFF200]"/>
                            <p>
                              {price !== null
                                ? Math.round(
                                  Number(price),
                                ).toLocaleString('id-ID', { style: 'currency', currency: 'IDR', minimumFractionDigits:0})
                                : 'Invalid input'}
                            </p>
                            {priceAlert && <><label className="text-red-600">Masukan harga program!</label></>}
                        </div>
                        <div className="flex flex-col w-full">
                            <label>Tipe Program*</label>
                            <select
                              value={type}
                              onChange={handleProgramType}
                              className="bg-white h-[36px] border-b-[1px] border-b-gray-500 focus:border-b-[1px] focus:border-b-[#FFF200]"
                            >
                              <option value="" disabled selected>
                                Pilih tipe program
                              </option>
                              <option value="class">Class</option>
                              <option value="private">Private</option>
                              <option value="material">material</option>
                            </select>
                            {programTypeAlert && <><label className="text-red-600">Silahkan pilih program terlebih dahulu!</label></>}
                        </div>
                        <div className="flex flex-col w-full">
                            <label>Tipe Pelaksanaan Program*</label>
                            <select value={tipePelaksanaanProgram} onChange={handleTipePelaksanaanProgram}  className="bg-white h-[36px] border-b-[1px] border-b-gray-500 focus:border-b-[1px] focus:border-b-[#FFF200]">  
                            
                            <option value="" disabled selected>
                            Pilih tipe pelaksanaan program
                                </option>
                                <option value="offline">Offline</option>
                                <option value="online">Online</option>
                                <option value="other">Other</option></select>
                                {tipePelaksanaanProgramAlert && <><label className="text-red-600">Silahkan pilih tipe pelaksanaan program terlebih dahulu!</label></>}
                        </div>
                    </div>
                    <div className="flex flex-col w-full gap-8">
                        <div className="flex flex-col w-full">
                            <label>Diskon</label>
                            {/* <input type="number" onChange={handleDiscount} placeholder="Masukan diskon" className="bg-white h-[36px] border-b-[1px] border-b-gray-500 focus:border-b-[1px] focus:border-b-[#FFF200]"/>
                            <label className="text-[#B4BAC6]">Total harga program ini: Rp {!discount ? Math.round(
                              Number(price),
                            ).toLocaleString('en-US', {
                              minimumFractionDigits: 0,
                            }) : Math.round(
                              Number(totalPriceAfterDiscount),
                            ).toLocaleString('en-US', {
                              minimumFractionDigits: 0,
                            }) }</label> */}
                             <select value={discountType} onChange={handleDiscountTypeChange} className="bg-white w-[250px] h-[36px] rounded-[8px] border-[1px] border-gray-500 focus:border-[1px] focus:border-[#FFF200] mb-[8px]" >
                              <option value="discount">Diskon (Dalam Persentase)</option>
                              <option value="flatPrice">Harga Tetap</option>
                            </select>
                            {discountType === 'discount' ? (
                             <input type="number" onChange={handleDiscount} 
                             value={discount}
                             placeholder="Masukan diskon" className="bg-white h-[36px] border-b-[1px] border-b-gray-500 focus:border-b-[1px] focus:border-b-[#FFF200]"/>
                            ) : (
                              <input
                                type="number"
                                onChange={handleInputValueChange}
                                placeholder="Masukan harga tetap"
                                value={inputValue}
                                className="bg-white h-[36px] border-b-[1px] border-b-gray-500 focus:border-b-[1px] focus:border-b-[#FFF200]"
                              />
                            )}
                            <label className="text-[#B4BAC6">Total harga program ini: Rp {calculateTotalPrice()}</label>
                            {discountAlert && <><label className="text-red-600">Diskon harus di isi!</label></>}
                        </div>
                        <div className="flex flex-col w-full">
                            <label>Lokasi Pelaksanaan Program*</label>
                            <select value={lokasiPelaksanaanProgram} onChange={handleLokasiPelaksanaanProgram} className="bg-white h-[36px] border-b-[1px] border-b-gray-500 focus:border-b-[1px] focus:border-b-[#FFF200]">
                                <option value="" disabled selected>
                                    Pilih lokasi pelaksanaan program
                                </option>
                                {/* <option value="lokasi1">Lokasi 1</option>
                                <option value="lokasi2">Lokasi 2</option>
                                <option value="lokasi3">Lokasi 3</option> */}
                                 {lokasiPelaksanaanOptions.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                            </select>
                            <label className="text-[#B4BAC6]">Otomatis dapat terpilih apabila memilih tipe pelaksanaan program Offline</label>
                            {lokasiPelaksanaanProgramAlert && <><label className="text-red-600">Mohon Pilih lokasi pelaksanaan program!</label></>}
                        </div>
                        <div className="flex flex-col w-full gap-2">
                        <label>Unggal Thumbnail</label>
                        <div className="flex flex-row items-center">
                        <label
                            htmlFor="file-input"
                            className="flex flex-row gap-2 items-center bg-[#FFFEE6] text-[#E8DC00] py-2 px-4 cursor-pointer border-2 border-none rounded-md hover:bg-[#FFFEE6] hover:text-[#E8DC00]"
                        >
                            <img src="/assets/Upload.png" alt="/" />
                            Upload File
                        </label>
                        <input
                            type="file"
                            id="file-input"
                            className="hidden"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                        />
                        <div
                            className="left-0 top-0 right-0 py-2 px-4 bg-white rounded-md"
                            style={{ visibility: fileName !== 'Choose File' ? 'visible' : 'hidden' }}
                        >
                            {fileName}
                        </div>
                        </div>
                       
                        <label className="text-[#B4BAC6]">Thumbnail berupa gambar dengan ekstensi .png .jpg .jpeg. Ukuran 343x200 px, berkas tidak boleh melebihi ukuran 1 mb.</label>
                        </div>
                        {fileNameAlert && <><label className="text-red-600">Ukuran melebihi 1 mb!</label></>}
                    </div>
                </div>
                <div className="flex flex-row w-full justify-end items-center gap-4">
                    <button onClick={handleCancel} className="px-[20px] py-[10px] text-[#8C8500] bg-[#FFFBB0] rounded-[4px]">Batal</button>
                    <button onClick={handleNext} className="px-[20px] py-[10px] text-black bg-[#FFF200] rounded-[4px]">Lanjutkan</button>
                </div>
            </div>
          </Card.Body>
        </Card>
      </Content.Body>
    </Content>
  )
}