import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Listbox } from "@headlessui/react";
import { Input, InputNumber, Label } from "components";
import { PARENTAL_STATUS } from "constant";
import { FC, Fragment, memo, useEffect, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";

type Props = {
  isReview?: boolean;
};

type PARENT_ROLE = (typeof PARENTAL_STATUS)[0];

const ParentInfo: FC<Props> = ({ isReview }) => {
  const { control, setValue, getValues } = useFormContext<StudentPayload>();

  const { parents } = getValues();

  const [role, setRole] = useState<PARENT_ROLE | null>(null);

  useEffect(() => {
    const currentRole = PARENTAL_STATUS.find((val) => val.id === parents?.role);
    setRole(currentRole ?? null);
  }, [parents?.role]);

  const handleRole = (value?: PARENT_ROLE) => {
    setRole(value ?? null);
    setValue("parents.role", String(value?.id ?? ""));
  };

  return (
    <div className="bg-white rounded-xl">
      <p className="font-bold p-5 border-b">Informasi orang tua (Opsional)</p>
      <div className="p-5 space-y-5">
        <div className="relative flex-1 min-w-[12rem]">
          <Label>Status orang tua</Label>
          <Listbox disabled={isReview} value={role} onChange={handleRole}>
            <Listbox.Button
              className={`relative flex w-full cursor-default items-center justify-between gap-x-1 rounded-lg border px-3.5 py-2.5 text-left ${
                isReview ? "bg-gray-50" : "bg-white"
              }`}
            >
              <span className="block truncate">
                {role?.name || "Status orang tua"}
              </span>
              {role?.id ? (
                <FontAwesomeIcon
                  icon={icon({ name: "close" })}
                  onClick={() => handleRole()}
                />
              ) : (
                <FontAwesomeIcon icon={icon({ name: "chevron-down" })} />
              )}
            </Listbox.Button>
            <Listbox.Options className="absolute z-10 mt-1 max-h-48 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {PARENTAL_STATUS.map((val, index) => (
                <Listbox.Option
                  key={`${index}-${val.id}`}
                  as={Fragment}
                  value={val}
                >
                  {({ active }) => (
                    <li
                      className={`relative flex items-center justify-between cursor-default select-none px-3.5 py-2.5 ${
                        active ? "bg-gray-50" : "text-gray-900"
                      }`}
                    >
                      <span className="block truncate">{val.name}</span>
                      {role && val.id === String(role.id) && (
                        <FontAwesomeIcon
                          icon={icon({ name: "check" })}
                          color="#7F56D9"
                        />
                      )}
                    </li>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Listbox>
        </div>
        <Controller
          control={control}
          name="parents.name"
          render={({ field }) => (
            <Input
              label="Nama orang tua"
              placeholder="Masukkan nama orang tua"
              disabled={isReview}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="parents.email"
          render={({ field }) => (
            <Input.Email
              label="Email"
              disabled={isReview}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="parents.phone_number"
          render={({ field }) => (
            <div>
              <Label>No. handphone</Label>
              <div
                className={`w-full flex items-center gap-x-2 mt-2 border rounded-lg px-3.5 py-2.5 ${
                  isReview ? "bg-gray-50" : "bg-white"
                }`}
              >
                <span>+62</span>
                <InputNumber
                  label=""
                  placeholder="Masukkan nomor handphone"
                  disabled={isReview}
                  value={field.value}
                  onChange={field.onChange}
                  className="w-full"
                  options={{
                    phone: true,
                    phoneRegionCode: "ID",
                  }}
                />
              </div>
            </div>
          )}
        />
        <Controller
          control={control}
          name="parents.password"
          render={({ field }) => (
            <Input.Password
              label="Password"
              requirement="Minimal 8 karakter terdiri dari huruf kapital, huruf kecil, angka dan
          karakter spesial"
              disabled={isReview}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </div>
    </div>
  );
};

export default memo(ParentInfo);
