import {memo, FC, useState, useEffect} from 'react';
import Modal from 'components/Modal';

import useModule from 'services/useModule';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';


type Props = {
  show: boolean
  onClose: () => void
  isEdit?: boolean
}

const Success: FC<Props> = (props) => {
  const {show, onClose, isEdit} = props;

  return (
    <Modal
      show={show}
      title=''
      onClose={onClose}
      customFooter={(
        <div className="flex justify-center">
          <button
            onClick={onClose}
            className="px-4 py-[0.625rem] rounded-xl bg-primary"
          >
            {'Tutup'}
          </button>
        </div>
      )}
    >
      <div className='w-96 text-center my-0 mx-auto'>
        <FontAwesomeIcon
          icon={icon({name: 'circle-check'})}
          size='3x'
          style={{color: '#12B76A'}}
        />
        <p className='font-bold text-2xl mb-4 mt-10'>
          {`Modul berhasil ${isEdit ? 'diubah' : 'dibuat'}!`}
        </p>
        <p className=''>
          Modul yang telah dibuat akan otomatis tersimpan pada direktori yang telah anda tentukan.
        </p>
      </div>
    </Modal>
  )
}

export default memo(Success);
