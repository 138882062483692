import DivIcon from "../../../../icons/MathOperations/div.svg";
import SqrtIcon from "../../../../icons/MathOperations/sqrt.svg";
import SqrtNIcon from "../../../../icons/MathOperations/sqrtn.svg";
import XbaIcon from "../../../../icons/MathOperations/xba.svg";
import XaIcon from "../../../../icons/MathOperations/xa.svg";
import XbIcon from "../../../../icons/MathOperations/xb.svg";
import XBarIcon from "../../../../icons/MathOperations/xbar.svg";
import XHatIcon from "../../../../icons/MathOperations/xhat.svg";
import BigCapIcon from "../../../../icons/MathOperations/bigcap.svg";
import BigCupIcon from "../../../../icons/MathOperations/bigcup.svg";
import ProdIcon from "../../../../icons/MathOperations/prod.svg";
import CoprodIcon from "../../../../icons/MathOperations/coprod.svg";
import BracketIcon from "../../../../icons/MathOperations/leftright.svg";
import SquareBracketIcon from "../../../../icons/MathOperations/bracket.svg";
import CurlyBracketIcon from "../../../../icons/MathOperations/curlybracket.svg";
import AbsoluteBracketIcon from "../../../../icons/MathOperations/absolutebracket.svg";
import IntegralIcon from "../../../../icons/MathOperations/integralba.svg";
import OverIntegralIcon from "../../../../icons/MathOperations/integralover.svg";
import SigmaIcon from "../../../../icons/MathOperations/sigma.svg";
import LimitAtoBIcon from "../../../../icons/MathOperations/limitatob.svg";

const mathOperator = [
  {
    iconPath: DivIcon,
    label: '\\frac{ }{ }'
  },
  {
    iconPath: SqrtIcon,
    label: '\\sqrt{}'
  },
  {
    iconPath: SqrtNIcon,
    label: '\\sqrt[]{}'
  },
  {
    iconPath: XbaIcon,
    label: 'x_a^b'
  },
  {
    iconPath: XaIcon,
    label: 'x_a'
  },
  {
    iconPath: XbIcon,
    label: 'x^b'
  },
  {
    iconPath: XBarIcon,
    label: '\\bar{x}'
  },
  {
    iconPath: XHatIcon,
    label: '\\hat{x}'
  },
  {
    iconPath: BigCapIcon,
    label: '\\bigcap_{a}^{b}'
  },
  {
    iconPath: BigCupIcon,
    label: '\\bigcup_{a}^{b}'
  },
  {
    iconPath: ProdIcon,
    label: '\\prod_{a}^{b}'
  },
  {
    iconPath: CoprodIcon,
    label: '\\coprod_{a}^{b}'
  },
  {
    iconPath: BracketIcon,
    label: '\\left (  \\right )'
  },
  {
    iconPath: SquareBracketIcon,
    label: '\\left [  \\right ]'
  },
  {
    iconPath: CurlyBracketIcon,
    label: '\\left \\{  \\right \\}'
  },
  {
    iconPath: AbsoluteBracketIcon,
    label: '\\left |  \\right |'
  },
  {
    iconPath: IntegralIcon,
    label: '\\int_{a}^{b}'
  },
  {
    iconPath: OverIntegralIcon,
    label: '\\oint_{a}^{b}'
  },
  {
    iconPath: SigmaIcon,
    label: '\\sum_{a}^{b}'
  },
  {
    iconPath: LimitAtoBIcon,
    label: '\\lim_{a\\to b}'
  }
]

export default mathOperator
