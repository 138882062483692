import { Input, TextArea, Button } from 'components';
import ProgressBullet from 'components/ProgressBullet';
import Material from './Material';
import {FC, memo, useState} from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormSyllabus } from 'views/Silabus/types';

type Props = {
  onCancel: () => void
  index: number
  onSubmit: () => void
}

const progress = ['Info pertemuan', 'Susun materi'];

const Meeting: FC<Props> = ({onCancel, index, onSubmit}) => {
  const [step, setStep] = useState(0);

  const {control, watch} = useFormContext<FormSyllabus>();

  const title = watch(`packages.${index}.title`);
  const description = watch(`packages.${index}.description`);
  const directories = watch(`packages.${index}.directories`);

  const handleSubmit = () => {
    if (step === 0) {
      setStep(1);
    } else {
      onSubmit();
    }
  };

  const handleCancel = () => {
    if (step > 0) {
      setStep(step - 1);
    } else if (step === 0) {
      onCancel();
    }
  };

  const isDisabled = () => {
    if (step === 0) {
      return !title || !description;
    } else {
      let checkDir = false;

      if (directories?.length) {
        checkDir = directories.every((dir) => {
          if (!dir?.modules?.length && !dir?.questions?.length) {
            return false;
          }

          return true;
        });
      }

      return !checkDir || !title || !description;
    }
  };

  const stickyFooterClass = 'flex fixed py-5 px-10 justify-end gap-x-3 right-0 bottom-0 mt-5 bg-white w-full'
  const normalFooterClass = 'flex justify-end gap-x-3 mt-5'

  return (
    <>
      <div className="mb-5 flex items-center gap-x-10">
        <p className="font-bold text-xl">Tambah pertemuan</p>
        <ProgressBullet
          data={progress}
          currentStep={step}
          onBack={() => setStep(step - 1)}
          onNext={() => setStep(step + 1)}
          hideChevron
        />
      </div>
      {step === 0 ? (
        <section className="w-full bg-white rounded-xl mb-5">
          <div className='px-5 py-4 border-b border-gray-200'>
            <p className='font-bold text-xl'>Informasi pertemuan</p>
          </div>
          <div className="px-5 py-4">
            <div className="grid grid-flow-col gap-8 mb-8">
              <Controller
                name={`packages.${index}.title`}
                control={control}
                render={({ field, formState }) => (
                  <Input
                    {...field}
                    label="Judul"
                    required
                    ref={null}
                    placeholder="Judul pertemuan"
                    errors={formState.errors.name?.message as string}
                  />
                )}
              />
            </div>

            <Controller
              name={`packages.${index}.description`}
              control={control}
              render={({ field, formState }) => (
                <TextArea
                  {...field}
                  isRichText
                  label="Deskripsi"
                  required
                  ref={null}
                  placeholder="Masukkan deskripsi silabus anda"
                  errors={formState.errors.description?.message as string}
                />
              )}
            />
          </div>
        </section>
      ) : (
        <Material packageIndex={index} />
      )}
      <div className={step === 0? normalFooterClass : stickyFooterClass}>
        <Button.Cancel onButtonClick={handleCancel} />
        <Button
          color='primary'
          isDisabled={isDisabled()}
          onButtonClick={handleSubmit}
        >
          {step === 0 ? 'Selanjutnya' : 'Submit pertemuan baru'}
        </Button>
      </div>
    </>
  );
};

export default memo(Meeting);
