import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { useFormContext } from "react-hook-form";
import useProgram from "services/useProgram";
import QuestionComposition from "../../../fragment/TryOut/Question/QuestionComposition";
import ListQuestion from "../../../fragment/TryOut/Question/ListQuestion";
import Variety from "../../../fragment/TryOut/Question/Variety";
import { Button } from "components";
import BrowseQuestion from "../../../fragment/Modal/BrowseQuestion";

type Props = {
  onSave: (data: TryoutPayload) => void;
};

const QuestionTab: FC<Props> = ({ onSave }) => {
  const { getVariationList } = useProgram();
  const { getValues, setValue } = useFormContext<TryoutPayload>();
  const questionPackage = getValues("question_package");
  const variety = getValues("variety");
  const varietyId = getValues("variety_id");
  const students = getValues("number_of_participants");
  const questionPerStudent = getValues("question_each_student");

  const [listVariety, setListVariety] = useState<Variety[]>([]);
  const [isEdit, setIsEdit] = useState(false);
  const [showQuestion, setShowQuestion] = useState(false);
  const [selectedVariety, setSelectedVariety] = useState(variety);
  const [questions, setQuestions] = useState<TryoutPayload["question_package"]>(
    []
  );

  const isValid = useMemo(() => {
    const totalSelected = questions?.reduce(
      (prev, current) => prev + current.n_question_value,
      0
    );
    const isValid = totalSelected >= (selectedVariety?.total_variety ?? 0);

    return Boolean(isValid);
  }, [selectedVariety?.total_variety, questions]);

  const getVariants = useCallback(async () => {
    const list = await getVariationList({
      total_students: Number(students),
      total_questions: questionPerStudent,
    });
    const selected = list.find((val) => val.id === Number(varietyId));
    setListVariety(list);
    if (!variety) {
      setSelectedVariety(selected);
      setValue("variety", selected);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getVariants();
    setQuestions(questionPackage);
    // eslint-disable-next-line
  }, []);

  const toggleQuestion = () => setShowQuestion(!showQuestion);

  const toggleEdit = () => setIsEdit(!isEdit);

  const onChangeQuestion = (value: number, index: number) => {
    const newSelected = questions.map((val, idx) => {
      if (index === idx) {
        val.n_question_value = value;
      }
      return val;
    });
    setQuestions(newSelected);
  };

  const removeQuestion = (item: TryoutPayload["question_package"][0]) => {
    const newQuestion = questions.filter(
      (val) => val.question_package_id !== item.question_package_id
    );
    setQuestions(newQuestion);
  };

  const addQuestion = (items: BrowseItem[]) => {
    const newItems = items.map((item) => {
      const { id, ...restItem } = item;
      const submittedQuestion = questionPackage.find(
        (val) => val.question_package_id === restItem.question_package_id
      );
      const existingQuestion = questions.find(
        (val) =>
          Number(val.question_package_id) ===
          Number(restItem.question_package_id)
      );
      const questionValue =
        existingQuestion?.n_question_value ??
        restItem.n_essay + restItem.n_multiple_choice;

      let newItem: Partial<TryoutPayload["question_package"][0]> = {
        ...restItem,
        n_question_value: questionValue,
        question_package_id: id ?? restItem.question_package_id,
      };

      if (submittedQuestion) {
        newItem = {
          ...newItem,
          id: submittedQuestion.id,
          question_package_id: submittedQuestion.question_package_id,
        };
      }

      return newItem;
    });
    setQuestions(newItems as TryoutPayload["question_package"]);
  };

  const onCancel = () => {
    setQuestions(questionPackage);
    setSelectedVariety(variety);
    toggleEdit();
  };

  const handleSave = () => {
    if (isValid) {
      const currentValue = getValues();
      const payload: TryoutPayload = {
        ...currentValue,
        question_package: questions,
        variety: selectedVariety,
        variety_id: Number(selectedVariety?.id),
      };
      onSave(payload);
      toggleEdit();
    }
  };

  return (
    <>
      <div className="flex flex-1 space-x-4">
        <div className="bg-white flex flex-col flex-1 rounded-md">
          <div className="px-4 pt-4 pb-2 content-between flex items-center">
            <span className="flex flex-1 font-semibold text-md text-black-100">
              Daftar soal & variasi
            </span>
            {isEdit ? (
              <div className="flex items-center gap-x-2">
                <FontAwesomeIcon
                  color="#F04438"
                  icon={icon({ name: "close" })}
                  className="cursor-pointer"
                  onClick={onCancel}
                />
                <FontAwesomeIcon
                  color={isValid ? "#17B26A" : "#777"}
                  icon={icon({ name: "floppy-disk", style: "regular" })}
                  className={isValid ? "cursor-pointer" : "cursor-not-allowed"}
                  onClick={handleSave}
                />
              </div>
            ) : (
              <FontAwesomeIcon
                color="#B5A300"
                icon={icon({ name: "edit" })}
                className="cursor-pointer"
                onClick={toggleEdit}
              />
            )}
          </div>
          <div className="divider my-0" />
          <div className="p-5 flex gap-x-8">
            {isEdit ? (
              <Variety
                list={listVariety}
                onChange={(val) => setSelectedVariety(val)}
                selected={selectedVariety || listVariety[0]}
              />
            ) : (
              <>
                <div className="flex flex-col gap-y-1.5">
                  <p className="text-sm font-medium text-gray-700">
                    Variasi soal
                  </p>
                  <p className="px-3.5 py-2.5 bg-primary rounded-lg min-w-[10rem] text-center font-semibold text-sm">
                    {variety?.name || "-"}
                  </p>
                </div>
                <QuestionComposition
                  questions={questions}
                  variety={selectedVariety || listVariety[0]}
                  isEdit={isEdit}
                />
              </>
            )}
          </div>
          <div>
            <ListQuestion
              data={questions}
              isEdit={isEdit}
              onChange={onChangeQuestion}
              removeQuestion={removeQuestion}
            />
            {isEdit && (
              <div
                className={`flex p-5 ${
                  questions.length ? "justify-between" : "justify-end"
                } gap-x-12`}
              >
                {Boolean(questions.length) && (
                  <QuestionComposition
                    questions={questions}
                    variety={selectedVariety || listVariety[0]}
                    isEdit={isEdit}
                  />
                )}
                <Button
                  color="outline-gray"
                  className="bg-yellow-100 hover:bg-yellow-200 hover:text-gray-800"
                  onButtonClick={toggleQuestion}
                >
                  <FontAwesomeIcon icon={icon({ name: "file-circle-plus" })} />
                  <span className="ml-1.5">Tambah soal</span>
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
      <BrowseQuestion
        show={showQuestion}
        onClose={toggleQuestion}
        onSubmit={(val) => addQuestion(val)}
        selectedQuestions={questions}
        selectedKey="question_package_id"
      />
    </>
  );
};

export default memo(QuestionTab);
