import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge } from "components";
import ButtonDropdown from "components/dropdowns/ButtonDropdown";
import { FC, memo, useMemo } from "react";
import { currencyConverter, getFormatDate } from "utilities";

type Props = {
  data: PromoItem;
  onUpdate: (value: PromoItem) => void;
  onDelete: (value: PromoItem) => void;
  onDetail: (value: PromoItem) => void;
};

const Item: FC<Props> = ({ data, onUpdate, onDelete, onDetail }) => {
  const {
    type,
    title,
    start_period,
    end_period,
    quota_used,
    quota,
    discount_type,
    discount_value,
    is_active,
    voucher_code,
    status,
  } = data;

  const isVoucher = useMemo(() => {
    return type === "voucher_code";
  }, [type]);

  const isActive = useMemo(() => {
    return Boolean(is_active);
  }, [is_active]);

  const discount = useMemo(() => {
    if (discount_type === "percentage") {
      return `${discount_value}%`;
    }

    return currencyConverter(discount_value);
  }, [discount_type, discount_value]);

  const progress = useMemo(() => {
    return Number(((quota_used / quota) * 100).toFixed(2));
  }, [quota, quota_used]);

  return (
    <div className="bg-white rounded-lg px-4 py-3 space-y-4">
      <div className="flex items-center gap-x-2">
        <div className="flex-1 flex items-center justify-between flex-wrap">
          <p
            className={`font-bold text-sm ${
              isVoucher ? "text-[#7F56D9]" : "text-[#DC6803]"
            }`}
          >
            {isVoucher ? "VOUCHER" : "PROMO"}
          </p>
          {isVoucher && (
            <span className="font-bold text-sm">
              {voucher_code?.code ?? ""}
            </span>
          )}
        </div>
        <ButtonDropdown
          menuItems={[
            <button
              onClick={() => onDetail(data)}
              className="w-full flex items-center justify-start gap-x-2 px-3.5 py-2.5 hover:bg-gray-50"
            >
              <FontAwesomeIcon
                icon={icon({ name: "arrow-up-right-from-square" })}
              />
              <p className="text-sm">Detail</p>
            </button>,
            <button
              onClick={() => onUpdate(data)}
              className="w-full flex items-center justify-start gap-x-2 px-3.5 py-2.5 hover:bg-gray-50"
            >
              {isActive ? (
                <>
                  <FontAwesomeIcon icon={icon({ name: "rotate-left" })} />
                  <p className="text-sm">Nonaktifkan</p>
                </>
              ) : (
                <>
                  <FontAwesomeIcon
                    icon={icon({
                      name: "paper-plane",
                      style: "regular",
                    })}
                  />
                  <p className="text-sm">Aktifkan</p>
                </>
              )}
            </button>,
            <button
              onClick={() => onDelete(data)}
              className="w-full flex items-center justify-start gap-x-2 px-3.5 py-2.5 hover:bg-gray-50"
            >
              <FontAwesomeIcon icon={icon({ name: "trash-alt" })} />
              <p className="text-sm">Hapus</p>
            </button>,
          ]}
          widthItems="w-40"
        >
          <div className="text-end">
            <FontAwesomeIcon
              icon={icon({ name: "ellipsis-vertical" })}
              className="cursor-pointer text-gray-400"
            />
          </div>
        </ButtonDropdown>
      </div>
      <div className="space-y-2">
        <p className="font-bold text-sm line-clamp-1 text-ellipsis">{title}</p>
        <p className="text-xs">
          <FontAwesomeIcon
            icon={icon({ name: "calendar" })}
            className="mr-1.5"
          />
          <span>{getFormatDate(start_period, "DD/MM/YYYY")}</span>
          <span> - </span>
          <span>{getFormatDate(end_period, "DD/MM/YYYY")}</span>
        </p>
      </div>
      <div className="space-y-1">
        <div className="w-full bg-gray-200 rounded h-2 dark:bg-gray-200">
          <div className={`bg-[#E8D000] h-2 rounded w-[${progress}%]`}></div>
        </div>
        <div className="flex items-center justify-between">
          <p className="text-xs">
            <FontAwesomeIcon
              icon={icon({ name: "user-group" })}
              className="mr-1.5"
            />
            <span>{currencyConverter(quota_used, true)}</span>
          </p>
          <p className="font-medium text-xs">
            {quota ? (
              currencyConverter(quota, true)
            ) : (
              <FontAwesomeIcon icon={icon({ name: "infinity" })} />
            )}
          </p>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <span className="font-bold text-xs text-red-600">{discount}</span>
        <Badge color={isActive ? "success" : "gray"}>{status}</Badge>
      </div>
    </div>
  );
};

export default memo(Item);
