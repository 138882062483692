import React, { FC, memo, useCallback, useEffect, useState } from "react";
import useBundle from "services/useBundle";
import { useLocation } from "react-router-dom";
import { DEFAULT_PAGINATION } from "constant";
import Table from "components/Table";
import { currencyConverter, useDebounce } from "utilities";
import { Input } from "components";

const columns = [
  {
    title: "Nama siswa",
    isLeft: true,
  },
  {
    title: "Sekolah",
    isLeft: true,
  },
  {
    title: "Tanggal daftar",
    isLeft: true,
  },
];

const Students: FC = () => {
  const location = useLocation();
  const id = location.pathname.split("/bundling/")[1];

  const { getListStudent } = useBundle();

  const [list, setList] = useState<BundleStudents[]>([]);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [keyword, setKeyword] = useState("");

  const debounceSearch = useDebounce(keyword);

  const getData = useCallback(async (params: Partial<BundleListParam>) => {
    const response = await getListStudent(id, params);
    const { data, ...restResponse } = response;

    setList(data);
    setPagination(restResponse);
  }, []);

  useEffect(() => {
    if (debounceSearch.length >= 3) {
      getData({
        search: keyword,
        page: 1,
      });
    }

    if (!debounceSearch.length) {
      getData({
        search: "",
        page: 1,
      });
    }
  }, [debounceSearch]);

  const handleLimit = (limit: string) => {
    getData({
      per_page: Number(limit),
      page: 1,
      search: keyword,
    });
  };

  const handlePagination = (control: string) => {
    const isNext = control === "next";
    const page = isNext
      ? pagination.current_page + 1
      : pagination.current_page - 1;

    if (page > 0 && page <= pagination.last_page) {
      getData({
        per_page: pagination.per_page,
        page,
        search: keyword,
      });
    }
  };

  return (
    <div className="bg-white rounded-xl">
      <p className="font-bold border-b px-4 py-5">Daftar peserta</p>
      <div className="flex items-center justify-between px-4 pt-4">
        <p>
          <span>Total peserta: </span>
          <span className="font-bold">{`${currencyConverter(
            pagination.total,
            true
          )} siswa`}</span>
        </p>
        <div className="w-80">
          <Input.Search
            placeholder="Cari siswa"
            onChange={(e) => setKeyword(e.target.value)}
            value={keyword}
          />
        </div>
      </div>
      <Table
        columns={columns}
        data={list}
        handleFirstColumn="w-36"
        total={pagination.total}
        currentLimit={pagination.per_page}
        currentPage={pagination.current_page}
        handleLimit={handleLimit}
        handlePagination={handlePagination}
      >
        <>
          {list.map((val) => (
            <tr key={val.student_id} className="border-b">
              <td className="p-4">{val.name}</td>
              <td className="p-4">{val.school_name}</td>
              <td className="p-4">{val.created_at}</td>
            </tr>
          ))}
        </>
      </Table>
    </div>
  );
};

export default memo(Students);
