import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, memo } from "react";

type Props = {
  data: StudentData | null;
};

const Summary: FC<Props> = ({ data }) => {
  return (
    <div className="grid grid-cols-3 gap-x-5">
      <div className="bg-white rounded-xl px-5 py-4 space-y-2">
        <div className="flex items-center gap-x-2">
          <FontAwesomeIcon
            icon={icon({ name: "layer-group" })}
            className="bg-primary p-1.5 rounded-full"
            size="sm"
          />
          <label className="text-sm">Materi dilihat</label>
        </div>
        <span className="font-bold text-2xl">
          {data?.materi_dilihat ?? "-"}
        </span>
      </div>
      <div className="bg-white rounded-xl px-5 py-4 space-y-2">
        <div className="flex items-center gap-x-2">
          <FontAwesomeIcon
            icon={icon({ name: "file-lines" })}
            className="bg-primary p-1.5 rounded-full"
            size="sm"
          />
          <label className="text-sm">Latihan soal</label>
        </div>
        <span className="font-bold text-2xl">{data?.latihan_soal ?? "-"}</span>
      </div>
      <div className="bg-white rounded-xl px-5 py-4 space-y-2">
        <div className="flex items-center gap-x-2">
          <FontAwesomeIcon
            icon={icon({ name: "award" })}
            className="bg-primary p-1.5 rounded-full"
            size="sm"
          />
          <label className="text-sm">Rata-rata skor</label>
        </div>
        <span className="font-bold text-2xl">
          {data?.rata_rata_skor ?? "-"}
        </span>
      </div>
    </div>
  );
};

export default memo(Summary);
