import { Spinner } from 'components';
import {FC, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import useAuth from 'services/useAuth';

const Logout: FC = () => {
  const navigate = useNavigate();
  const {logout} = useAuth();

  useEffect(() => {
    setTimeout(() => {
      logout();
      navigate('/login', {
        replace: true
      });
    }, 500);
  }, [navigate]);

  return (
    <div className='flex items-center justify-center h-screen'>
      <Spinner />
    </div>
  );
};

export default Logout;
