import {FC, memo, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import toast from "react-hot-toast";
import {useParams} from "react-router-dom";
import useLiveClass from "../../../../../services/useLiveClass";
import moment from "moment";
import {Button, Badge} from "../../../../../components";
import {QUESTION_DIFFICULTY} from "../../../../../constant";
import BrowseDirectory from "pages/Program/Add/LiveClass/fragment/Modal/Directory";
import BrowseQuestion from "pages/Program/Add/LiveClass/fragment/Modal/BrowseQuestion";
import { DatePicker } from "components/datepicker";
import { TimePicker } from "components/timepicker";
import MeetingModuleList from "./ListModule";
import useProgram from "services/useProgram";

type MeetItem = {
  date_end: string
  date_start: string
  duration: string
  id: number
  modules: {
    module_id: number
    module_name: string
  }[]
  questions: {
    difficulty: number
    n_essay: number
    n_multiple_choice: number
    question_package_id: number
    question_package_name: string
  }[]
}

type ClassRemainingMeet = {
  count: number,
  duration: {
    hour: number,
    minute: number
  }
}

const MeetingsTab: FC = () => {

  const {id} = useParams();
  const {getClassMeets, getClassResume} = useLiveClass();
  const {updateLiveClassMeet} = useProgram();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<MeetItem[]>([]);
  const [resumeData, setResumeData] = useState<ClassRemainingMeet>({count: 0, duration: {hour:0, minute: 0}})
  const [editingIndex, setEditingIndex] = useState<number | null>(null);
  const [saveTrigger, setSaveTrigger] = useState<number>(0);

  const calculateTotalDuration = ():number => {
    let totalDuration = 0;
    data.forEach((meeting) => {
      totalDuration += Number(meeting.duration);
    });
    return totalDuration;
  };

  const totalHour = Math.floor(calculateTotalDuration() / 60);
  const totalMinutes = calculateTotalDuration() % 60;

  const handleSaveItem = (editedMeet: MeetItem) => {
    const moduleIdsArray = editedMeet.modules.map((module) => module.module_id);
    const payload: updateLiveClassMeet = {
      date_start: editedMeet.date_start,
      date_end: editedMeet.date_end,
      duration: editedMeet.duration,
      question_package: editedMeet.questions,
      id: editedMeet.id,
      module_ids: moduleIdsArray,
    };
  
    setLoading(true);
  
    updateLiveClassMeet(Number(id), editedMeet.id, payload)
      .then((response) => {
        setEditingIndex(null);
        setSaveTrigger((prev) => prev + 1);
      })
      .catch((err) => toast.error(err?.message || 'Gagal update pertemuan.'))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      getClassMeets(Number(id))
        .then((response) => setData(response?.data))
        .catch((err) => toast.error(err?.message || 'Gagal memuat data.'))
        .finally(() => setLoading(false))
    }
  }, [id, getClassMeets, saveTrigger]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getClassResume(Number(id))
        .then((response) => {
          const { count, duration_in_minutes } = response?.data
          const duration = moment.duration(duration_in_minutes, 'minutes');
          const hours = Math.floor(duration.asHours());
          const remainingMinutes = duration.minutes();
          setResumeData({
            count,
            duration: {
              hour: hours,
              minute: remainingMinutes
            }
          })
        })
        .catch((err) => toast.error(err?.message || 'Gagal memuat data.'))
        .finally(() => setLoading(false))
    }
  }, [id, getClassResume, saveTrigger]);

  const onStartEdit = (index: number) => {
    setEditingIndex(index);
  };

  return (
    <div className='flex flex-1 space-x-4'>
      <div className='bg-white flex flex-col flex-1 rounded-md'>
        <div className='px-4 pt-4 pb-2 content-between flex items-center'>
          <span className='flex flex-1 font-semibold text-md text-black-100'>Pertemuan</span>
        </div>
        <div className="divider my-0"/>
        <div className='px-4 py-3'>
          <div className='space-x-4 flex flex-row'>
            <div>
              <span className='font-normal text-base text-gray-600'>Total pertemuan: </span>
              <span className='font-medium text-bold text-gray-600'>{resumeData.count}</span>
            </div>
            <div>
              <span className='font-normal text-base text-gray-600'>Total durasi: </span>
              <span className='font-medium text-bold text-gray-600'>{totalHour} jam {totalMinutes} menit</span>
            </div>

          </div>
          {data.map((meet, index) =>
            <Item
              isOnEdit={editingIndex === index}
              onStartEdit={() => onStartEdit(index)}
              onCancelEdit={()=>setEditingIndex(null)}
              index={index}
              meet={meet}
              onSaveItem={handleSaveItem}
            />
          )}
        </div>
      </div>

    </div>
  )
}

const Item = ({
    index,
    isOnEdit,
    onStartEdit,
    onCancelEdit,
    meet,
    onSaveItem
  }: { 
    index: number, 
    isOnEdit: boolean, 
    onStartEdit: () => void,
    onCancelEdit: ()=> void, 
    meet: MeetItem,
    onSaveItem: (editedMeet: MeetItem) => void }) => {

  const [editedMeet, setEditedMeet] = useState<MeetItem>(meet);

  const parsedScheduleDate = moment(meet.date_start).format('DD/MM/YYYY HH:mm');
  const editModeScheduleDate = moment(meet.date_start).format('DD-MM-YYYY HH:mm');
  const duration = moment.duration(meet.duration, 'minutes');
  const [hours, setHours] = useState(Math.floor(duration.asHours()));
  const [minutes, setMinutes] = useState(duration.minutes());
  const remainingMinutes = duration.minutes();
  const [showQuestion, setShowQuestion] = useState(false);
  const [showModule, setShowModule] = useState(false);
  const toggleQuestion = () => setShowQuestion(!showQuestion);
  const toggleModule = () => setShowModule(!showModule);
  const mappedModules = editedMeet.modules.map((module) => ({
    id: module.module_id,
    name: module.module_name
  }));

  const dateTime = meet.date_start;
  const [datePart, timePart] = dateTime.split(' '); 
  const [date, setDate] = useState<string>(datePart);
  const [time, setTime] = useState(timePart);

  useEffect(() => {
    if(!isOnEdit){
      setEditedMeet(meet);
      setHours(Math.floor(duration.asHours()));
      setMinutes(duration.minutes());
      setTime(timePart);
      setDate(datePart);
    }
  }, [isOnEdit, meet]);



  useEffect(() => {
    const totalMinutes = hours * 60 + minutes;
    const durationString = totalMinutes.toString();
    setEditedMeet((prevMeet) => ({
      ...prevMeet,
      duration: durationString,
    }));
  }, [hours, minutes]);

  useEffect(() => {
    setEditedMeet((prevMeet) => ({
      ...prevMeet,
      date_start: `${date} ${time}`,
      date_end: `${date} ${time}`
    }));
  }, [date, time]);

  const onDeleteModule = (indexToDelete: number)  => {
    setEditedMeet((prevMeet) => {
      const updatedModules = prevMeet.modules.filter((_, index) => index !== indexToDelete);
      return { ...prevMeet, modules: updatedModules };
    });
  };

  const onDeleteQuestion = (indexToDelete: number) => {
    setEditedMeet((prevMeet) => {
      const updatedQuestion = prevMeet.questions.filter((_, index) => index !== indexToDelete);
      return { ...prevMeet, questions: updatedQuestion };
    });
  };

  const addModule = (data: any[]) => {
    const transformedData = data.map(item => ({
      module_id: item.id,
      module_name: item.name
    }));
    setEditedMeet((prevMeet) => ({
      ...prevMeet,
      modules: [...transformedData]
    }));
  };

  const addQuestion = (data: BrowseItem) => {
    const transformedData = {
      difficulty: data.difficulty,
      n_essay: data.n_essay,
      n_multiple_choice: data.n_multiple_choice,
      question_package_id: data.id,
      question_package_name: data.name, 
    };
    setEditedMeet((prevMeet) => ({
      ...prevMeet,
      questions: [...prevMeet.questions, transformedData]
    }));
  };

  const onSave = () => {
    onSaveItem(editedMeet); 
  };

  if (isOnEdit) {
    return (
      <div className='flex flex-1 bg-gray-100 my-3 px-7 py-4 rounded-md flex-col space-y-6'>
        <div className='flex flex-row space-x-6'>
          <div className='flex-1 space-y-2'>
            <span>Materi</span>
            <label
              htmlFor={'materi'}
              className={'items-center cursor-pointer flex flex-1 rounded-lg bg-white flex-row divide-x divide-[#D0D5DD] border border-[#D0D5DD]'}>
              <label htmlFor="materi" className={'ml-4 text-gray-500'}>Pilih materi</label>
              <input id={'materi'} onClick={toggleModule} className='invisible flex rounded-lg flex-1'/>
              <span className='p-2'>Browse</span>
            </label>
            {editedMeet.modules.map((module, index) => (
              <div key={module.module_id} className='flex w-full justify-between items-center mt-5 pr-3'>
                <div>
                  <FontAwesomeIcon size='1x' color='#666666' icon={icon({name: 'file-alt'})}/>
                  <span className='font-light text-base text-slate-800 ml-2'>{module.module_name}</span>
                </div>
                <FontAwesomeIcon
                  icon={icon({name: 'trash-can'})}
                  className='cursor-pointer'
                  onClick={() => onDeleteModule(index)}
                />
              </div>
            ))}
            
          </div>
          <div className='flex-1 space-y-2'>
            <span>Latihan soal</span>
            <label
              htmlFor={'soal'}
              className={'items-center cursor-pointer flex flex-1 rounded-lg bg-white flex-row divide-x divide-[#D0D5DD] border border-[#D0D5DD]'}>
              <label htmlFor="soal" className={'ml-4 text-gray-500'}>Pilih latihan soal</label>
              <input id={'soal'} onClick={toggleQuestion} className='invisible flex rounded-lg flex-1'/>
              <span className='p-2'>Browse</span>
            </label>
            <div className='space-y-3'>
            {
              editedMeet.questions.map((question, index) => (
                <div key={question.question_package_id} className='flex flex-1 items-center'>
                  <FontAwesomeIcon size='1x' color='#666666' icon={icon({name: 'file-alt'})}/>
                  <span className='font-light text-base text-slate-800 ml-2 flex-1'>{question.question_package_name}</span>
                  <div className='space-x-2 items-center'>
                    <Badge color='blue-jeans'>{question.n_multiple_choice} PG</Badge>
                    <Badge color='green-light'>{question.n_essay} essay</Badge>
                    <Badge color='gray'>{QUESTION_DIFFICULTY[Number(question.difficulty)]}</Badge>
                    <FontAwesomeIcon 
                      icon={icon({ name: "trash-alt" })} 
                      className='cursor-pointer'
                      onClick={() => onDeleteQuestion(index)}  
                    />
                  </div>
                </div>
              ))
            }
            </div>
          </div>
        </div>
        <div className={'flex flex-row space-x-6'}>
          <DatePicker
            placeholder="Pilih jadwal"
            label="Tanggal"
            value={date}
            onChange={(val) => {
              setDate(String(val))
              }
            }
          />
          <TimePicker 
            label= "Jam Pertemuan"
            value= {time}
            onChange={(val) => {
              setTime(String(val))
              }
            }
          />
          <div>
            <p className="font-medium text-sm text-gray-700 mb-1.5">Durasi</p>
            <div className="flex flex-row">
              <label
                htmlFor={'jam'}
                className={'items-center cursor-pointer flex flex-1 rounded-lg bg-white flex-row divide-x divide-[#D0D5DD] border border-[#D0D5DD]'}>
                <span className='p-2.5'>Jam</span>
                <input 
                  placeholder={'jam'} id={'jam'} className='pl-4 w-20'
                  value={hours | 0}
                  onChange={(e) => {
                  setHours(parseInt(e.target.value));
                  }
                }/>
              </label>
              <label
                htmlFor={'menit'}
                className={'items-center cursor-pointer flex flex-1 rounded-lg bg-white flex-row divide-x divide-[#D0D5DD] border border-[#D0D5DD] ml-2'}>
                <span className='p-2.5'>Menit</span>
                <input placeholder={'menit'} id={'menit'} className='pl-4 w-20'
                value={minutes | 0}
                onChange={(e) => {
                  setMinutes(parseInt(e.target.value))
                  }
                }
                />
              </label>
            </div>
          </div>
        </div>
        <div className={'space-x-2 p-0 m-0'}>
          <Button.Cancel onButtonClick={onCancelEdit}>Batal</Button.Cancel>
          <Button
            color="primary"
            onButtonClick={() => {
              onSave()
            }}
          >
            Simpan
          </Button>
        </div>
        <BrowseDirectory
          show={showModule}
          onClose={toggleModule}
          onSubmit={(val) => {
            addModule(val)
          } }
          selectedQuestions={mappedModules}               
        />
        <BrowseQuestion
          show={showQuestion}
          onClose={(toggleQuestion)}
          onSubmit={(val) => {
            addQuestion(val)
          } }
          selectedQuestion={[]} 
          title={""} 
          directoryId={0} 
          directories={[]}                  
        />
      </div>
    )
  }

  return (
    <div className='flex flex-1 space-x-4 bg-gray-100 my-3 px-7 py-4 rounded-md'>
      <span
        className='self-center rounded-full border-gray-200 border text-gray-700 bg-gray-100 mr-4 w-10 h-10 text-center align-middle items-center leading-10'>{index + 1}</span>

      <div className='flex flex-1 justify-between'>

        <div className='flex flex-col space-y-2 flex-1'>
          <span className='font-medium text-xs text-gray-500'>Materi</span>

          {meet.modules.map((module) => (
            <div key={module.module_id}>
              <FontAwesomeIcon size='1x' color='#666666' icon={icon({name: 'file-alt'})}/>
              <span className='font-light text-base text-slate-800 ml-2'>{module.module_name}</span>
              
            </div>
          ))}
        </div>


        <div className='flex flex-col space-y-2 flex-1'>
          <span className='font-medium text-xs text-gray-500'>Soal</span>
          {
            meet.questions.map((question) => (
              <div key={question.question_package_id}>
                <FontAwesomeIcon size='1x' color='#666666' icon={icon({name: 'file-alt'})}/>
                <span className='font-light text-base text-slate-800 ml-2'>{question.question_package_name}</span>
              </div>
            ))
          }
        </div>


        <div className='flex flex-col flex-start mr-8'>
          <span className='font-medium text-xs text-gray-500'>Durasi</span>
          <span className='font-light text-base text-slate-800'>{hours} jam {remainingMinutes} menit</span>
        </div>


        <div className='flex flex-col mr-8'>
          <span className='font-medium text-xs text-gray-500'>Jadwal</span>
          <span className='font-light text-base text-slate-800'>{parsedScheduleDate}</span>
        </div>

        <FontAwesomeIcon onClick={onStartEdit} className='cursor-pointer' color='#B5A300' icon={icon({name: 'edit'})}/>

      </div>


    </div>
  )
}

export default memo(MeetingsTab)
