import { Badge, Spinner } from "components";
import { PROGRAM_TYPE } from "constant";
import { memo, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useStudent from "services/useStudent";
import Footer from "./Footer";
import { getFormatDate } from "utilities";

const defaultPagination: PaginationResponse = {
  current_page: 1,
  per_page: 10,
  last_page: 1,
  from: 1,
  to: 1,
  total: 1,
};

const PurchaseHistory = () => {
  const { getHistory } = useStudent();
  const { pathname } = useLocation();
  const id = pathname.split("/siswa/")[1];

  const [data, setData] = useState<ProgramItem[]>([]);
  const [pagination, setPagination] = useState(defaultPagination);
  const [loading, setLoading] = useState(false);

  const getData = useCallback(async (params?: ProgramParams) => {
    setLoading(true);
    const response = await getHistory(id, {
      page: params?.page,
      per_page: params?.per_page,
    });
    const { data, ...restData } = response;

    setPagination(restData);
    setData(response?.data || []);
    setLoading(false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (id) {
      getData();
    }
    // eslint-disable-next-line
  }, [id]);

  const getTypeColor = (type: ProgramItem["type"]) => {
    switch (type) {
      case "learn":
        return "purple-light";
      case "tryout":
        return "warning-light";
      default:
        return "blue-light";
    }
  };

  return (
    <div className="space-y-4">
      {loading ? (
        <Spinner />
      ) : data.length ? (
        data.map((val) => (
          <div
            key={val.id}
            className="bg-white rounded-lg px-5 py-3 grid grid-cols-5 gap-x-5"
          >
            <p className="font-semibold text-sm text-ellipsis line-clamp-2">
              {val.title}
            </p>
            <div className="space-y-2">
              <p className="text-xs text-gray-600">Jenis program</p>
              <Badge color={getTypeColor(val.type)}>
                {PROGRAM_TYPE[val.type].toUpperCase()}
              </Badge>
            </div>
            <div className="space-y-2">
              <p className="text-xs text-gray-600">Strata</p>
              <p className="text-sm font-semibold">{val.strata}</p>
            </div>
            <div className="space-y-2">
              <p className="text-xs text-gray-600">Tanggal pembelian</p>
              <p className="text-sm font-semibold">
                {getFormatDate(val.registration_period_start, "DD/MM/YYYY")}
              </p>
            </div>
            <div className="space-y-2">
              <p className="text-xs text-gray-600">Tanggal selesai</p>
              <p className="text-sm font-semibold">
                {getFormatDate(val.registration_period_end, "DD/MM/YYYY")}
              </p>
            </div>
          </div>
        ))
      ) : (
        <p className="text-center italic">Tidak ada data</p>
      )}
      <Footer pagination={pagination} onGetData={getData} />
    </div>
  );
};

export default memo(PurchaseHistory);
