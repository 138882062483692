import { useParams } from "react-router-dom";
import Level from "./fragment/level";
import { currencyConverter } from "utilities";
import Avatar from "./fragment/avatar";
import History from "./fragment/history";
import Rating from "./fragment/rating";
import useHomework from "services/useAskHomework";
import { useCallback, useEffect, useState } from "react";
import { askHomeworkChannel } from "libraries/pusher";

const defaultDetail: TeacherDetail = {
  avatar: "",
  biography: "",
  grades: [],
  homework_rating: 0,
  is_online_ask_homework: false,
  name: "",
  subjects: [],
  total_done_homeworks: 0,
  total_income: 0,
  average_per_month: 0,
};

const Detail = () => {
  const { id } = useParams();
  const { getTeacher, getIncome } = useHomework();

  const [detail, setDetail] = useState(defaultDetail);

  const getData = useCallback(async () => {
    const responseDetail = await getTeacher(String(id));
    const responseIncome = await getIncome(String(id));
    const result = {
      ...responseDetail,
      ...responseIncome,
    } as TeacherDetail;

    setDetail(result);
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    askHomeworkChannel.bind(
      "status_online_backoffice_event",
      (event: PusherEvent) => {
        if (event.message?.status_refresh) {
          getData();
        }
      }
    );

    return () => {
      askHomeworkChannel.unbind();
    };
  }, [getData]);

  return (
    <section className="grid grid-cols-[23rem,1fr] gap-x-8">
      <div className="space-y-8">
        <div className="bg-white rounded-xl p-5 space-y-4">
          <div className="flex items-center gap-1.5">
            <div className="relative w-fit">
              <Avatar image={detail.avatar} size={56} />
              <span
                className={`absolute w-3.5 h-3.5 rounded-full border-2 border-white right-0 bottom-0 ${
                  detail.is_online_ask_homework
                    ? "bg-green-500"
                    : "bg-gray-500"
                }`}
              />
            </div>
            <p className="text-sm">{detail.name}</p>
          </div>
          <Rating rate={detail.homework_rating} />
          <div className="space-y-1.5">
            <p className="text-sm text-gray-600">Biografi</p>
            <p className="text-sm">{detail.biography || "-"}</p>
          </div>
          <Level title="Strata mengajar" data={detail.grades} isColumn />
          <Level title="Mata pelajaran" data={detail.subjects} isColumn />
        </div>
        <div className="bg-white rounded-xl space-y-4">
          <p className="font-bold px-5 py-4 border-b">Pendapatan tanya PR</p>
          <div className="space-y-4 p-5">
            <div className="space-y-1.5">
              <p className="text-sm">Total pendapatan</p>
              <p className="font-bold text-3xl">
                {currencyConverter(detail.total_income)}
              </p>
              <p className="text-sm">
                <span className="font-bold text-green-500">{`${detail.total_done_homeworks} sesi`}</span>{" "}
                terselesaikan
              </p>
            </div>
            <div className="space-y-1.5">
              <p className="text-sm">Rata-rata pendapatan bulanan</p>
              <div className="flex flex-wrap items-center gap-x-1.5">
                <p className="font-bold text-xl">
                  {currencyConverter(detail.average_per_month)}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <History id={String(id)} />
    </section>
  );
};

export default Detail;
