import {useCallback} from 'react';
import {services} from 'services';
import {API_URL} from 'constant';

const useGrade = () => {
  const getList = useCallback(async (): Promise<GradeItem[]> => {
    try {
      const response = await services.get(`${API_URL.grades}`);
      
      return response?.data?.data;
    } catch (error) {
      return [];
    }
  }, []);

  return {
    getList
  }
};

export default useGrade;
