import {FC, memo, useEffect, useState} from "react";
import Modal from 'components/Modal';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import Table from "../../../../../../components/Table";
import {Button} from "../../../../../../components";
import useLiveClass from "../../../../../../services/useLiveClass";
import toast from "react-hot-toast";
import {useDebounce} from "../../../../../../utilities";

type Props = {
  programId?: number,
  show: boolean,
  onClose: () => void,
  onSuccess: () => void,
  classCode: string,
  isAlreadyAssigned: number,
}

const columns = [
  {
    title: 'Nama guru',
    isSort: true,
    key: 'name'
  },
  {
    title: 'Tarif per sesi',
    isSort: true,
    key: 'rate'
  },
];

const ChooseTeacherModal: FC<Props> = (props) => {
  const {programId, show, onClose, classCode, onSuccess, isAlreadyAssigned} = props;
  const {getTeacherByProgramId, assignTeacherToClass, updateTeacherToClass} = useLiveClass();

  const [search, setSearch] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<LiveClassTeacherByProgramId|null>(null);
  const [selectedTeacherId, setSelectedTeacherId] = useState<number|null>(isAlreadyAssigned)

  const debounceSearch = useDebounce(search);

  useEffect(()=>{
    setSelectedTeacherId(null)
    setSearch('')
  },[show,classCode])

  useEffect(()=>{
    if (programId && show){
      setLoading(true);
      getTeacherByProgramId(Number(programId),classCode,debounceSearch)
        .then((response) => {
          const {data} = response
          setData(data)
        })
        .catch((err) => toast.error(err?.message || 'Gagal memuat data.'))
        .finally(() => setLoading(false))
    }
  },[show, programId, getTeacherByProgramId, debounceSearch])

  const onSelectTeacher = () => {
    if (programId && classCode && selectedTeacherId){
      if (isAlreadyAssigned !== selectedTeacherId){
        updateTeacherToClass(programId, classCode, selectedTeacherId).then((resp) => {
          onSuccess()
        })
      }
      else{
        onSuccess()
      }
    }
  }

  return(
    <Modal
      show={show}
      title={`Pilih pengajar`}
      onClose={onClose}
      customFooter={(
        <div className="float-right flex gap-x-2">
          <button
            onClick={onClose}
            className="px-4 py-[0.625rem] rounded-xl border border-primary"
          >
            Batal
          </button>
          <Button color='primary' onButtonClick={onSelectTeacher} isDisabled={selectedTeacherId === null}>
            Pilih
          </Button>
        </div>
      )}
      >
      <div>
        <span>Kelas ID</span>
        <span> {classCode}</span>
      </div>
      <div className='flex flex-1 items-center mt-3'>
        <SearchBox setSearch={setSearch} search={search}/>
        <div className='flex flex-1 justify-end'>
          <span className='font-normal text-base text-gray-600'>Total pengajar: </span>
          <span className='font-medium text-bold text-gray-600 ml-1'>{data?.data?.length ?? 0}</span>
        </div>
      </div>
      {data ?
        <Table
          className={'px-0'}
          data={data?.data}
          columns={columns}
          currentLimit={5}
          currentPage={data?.current_page ?? 1}
          total={data?.total}
          handleLimit={()=>{}}
          handlePagination={()=>{}}
          handleSort={()=>{}}
        >
          {data?.data?.map((item,index)=>{
            return(
              <tr
                key={`${item.id}`}
                className={`border-b ${item.teacher_id === selectedTeacherId ? 'bg-yellow-100' : 'bg-transparent'}`}
                onClick={()=>setSelectedTeacherId(item.teacher_id)}
              >
                <td className='p-4'>{item.name}</td>
                <td className='p-4 font-normal text-gray-600'>{item.price_per_hour}</td>
              </tr>
            )
          })}
        </Table>
        :
        null
      }

    </Modal>
  )
}

const SearchBox = ({search, setSearch}:{search:string, setSearch: (val: string)=>void}) => {
  return(
    <div className='bg-white px-4 py-[0.625rem] rounded-xl w-72 flex gap-x-2 items-center border'>
      <FontAwesomeIcon icon={icon({name: 'search'})}/>
      <input
        className='w-full'
        placeholder='Cari pengajar'
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      {Boolean(search.length) && (
        <FontAwesomeIcon
          icon={icon({name: 'close'})}
          onClick={() => setSearch('')}
          className='cursor-pointer'
        />
      )}
    </div>
  )
}

export default memo(ChooseTeacherModal)
