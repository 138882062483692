import {memo, FC, useState, useEffect} from 'react';
import {useFormContext} from 'react-hook-form';
import Modal from 'components/Modal';
import {Button} from 'components';

import useBrowse from 'services/useBrowse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { useDebounce } from 'utilities';
import Questionary from '../Questionary';
import DetailQuestion from '../DetailQuestion';

type Props = {
  show: boolean
  directoryId?: string
  onClose: () => void
  onSubmit: (data: LocalAsset[]) => void
}

const Questions: FC<Props> = (props) => {
  const {show, directoryId, onClose} = props;
  const {getList} = useBrowse('question');

  const [data, setData] = useState<BrowseItem[]>([]);
  const [tab, setTab] = useState(1);
  const [search, setSearch] = useState('');
  const [question, setQuestion] = useState<BrowseItem | null>(null);

  const debounceSearch = useDebounce(search);

  const {setValue, getValues, watch} = useFormContext();
  const questionPackage = getValues('question_package');
  const watchQuestion = watch('question_package.id');

  useEffect(() => {
    if (show) {
      setTab(1);
      setQuestion(questionPackage);
    }
  }, [show]);

  useEffect(() => {
    if (!watchQuestion) {
      setQuestion(null);
    }
  }, [watchQuestion]);

  useEffect(() => {
    if (debounceSearch.length >= 3 || !debounceSearch.length) {
      getList({
        directory_id: Number(directoryId),
        keyword: debounceSearch,
        per_page: 50
      })
      .then((response) => setData(response?.data || []))
      .catch((err) => console.log('Err', err));
    }
  }, [debounceSearch, getList, directoryId]);

  const handleClose = () => {
    setQuestion(question);
    setValue('question_package', question);
    onClose();
  };

  const handleCancel = () => {
    if (tab === 2) {
      setTab(1);
    } else {
      handleClose();
    }
  };

  const handleSubmit = () => {
    setQuestion(questionPackage);
    onClose();
  };

  return (
    <Modal
      show={show}
      title={tab === 1 ? 'Pilih soal' : 'Tentukan jumlah soal'}
      onClose={handleClose}
      customFooter={(
        <div className="float-right flex gap-x-2">
          <Button.Cancel onButtonClick={handleCancel} />
          {tab === 2 && (
            <Button color='primary' onButtonClick={handleSubmit}>
              Pilih soal
            </Button>
          )}
        </div>
      )}
    >
      {tab === 1 ? (
        <>
          <div className='bg-white px-4 py-[0.625rem] rounded-xl flex gap-x-2 items-center border w-full mb-4'>
            <FontAwesomeIcon icon={icon({name: 'search'})} />
            <input
              className='w-full'
              placeholder='Search...'
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
            {Boolean(search.length) && (
              <FontAwesomeIcon
                icon={icon({name: 'close'})}
                onClick={() => setSearch('')}
                className='cursor-pointer'
              />
            )}
          </div>
          <p className="text-sm font-bold">Direktori</p>
          <Questionary
            directoryId={Number(directoryId)}
            indent={1}
            initialData={data}
            onSelectQuestion={() => setTab(2)}
          />
        </>
      ) : (
        <DetailQuestion />
      )}
    </Modal>
  )
}

export default memo(Questions);
