import moment from "moment";
import Table from "../../../components/Table";
import {Fragment, useEffect, useState} from "react";
import useWithdrawal from "../../../services/useWithdrawal";
import {currencyConverter} from "../../../utilities";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import {Listbox} from "@headlessui/react";

const columns = [
  {
    title: 'Nama pengajar',
    isSort: true,
    key: 'teacher_name',
  },
  {
    title: 'Nominal',
    isSort: true,
    key: 'amount',
  },
  {
    title: 'Tanggal',
    isSort: true,
    key: 'created_at'
  },
  {
    title: 'Status',
    isSort: true,
    key: 'status'
  },
  {
    title: 'Note',
    isSort: false,
    isLeft: true,
    key: 'remark'
  },
];

const GET_LIST_DEFAULT_PARAMS : WithdrawalListParams = {
  per_page: 10,
  page: 1,
  orderBy: 'asc'
}

const WithdrawalList = ({ onClick }: { onClick: (data:WithdrawalTransaction)=> void}) => {
  const {getList} = useWithdrawal();
  const [data, setData] = useState<WithdrawalListResponse>();
  const [sortFilterData, setSortFilterData] = useState<WithdrawalListParams>(GET_LIST_DEFAULT_PARAMS)

  useEffect(()=>{
    getList(sortFilterData).then((resp)=>{
      setData(resp)
    })
  },[sortFilterData])

  const handleLimit = async (limit: string) => {
    setSortFilterData((prev)=> ({...prev, per_page: Number(limit)}))
  }

  const handlePagination = async (ctrl: 'next' | 'prev') => {
    if (data){
      const currentPage = data.current_page
      const lastPage = data.last_page
      const isNext = ctrl === 'next';
      const page = isNext ? currentPage + 1 : currentPage - 1;

      if (page > 0 && page <= lastPage) {
        setSortFilterData((prev)=>({...prev, page: page}))
      }
    }
  }

  const handleSort =async (sortParams: string) => {
    const currentSortBy = sortFilterData.sortBy
    const currentOrderBy = sortFilterData.orderBy
    const reverseOrderBy = currentOrderBy === 'asc' ? 'desc' : 'asc'
    const selectedOrderBy = currentSortBy === sortParams ? reverseOrderBy : 'asc'
    setSortFilterData((prev)=> ({...prev, sortBy: sortParams, orderBy: selectedOrderBy}))
  }

  if (!data) return null
  return(
    <div className='space-y-4'>
      <div className={'flex space-x-4 items-center'}>
        <p className={'text-md font-bold text-gray-600 flex flex-1'}>{`Total ${data.total} data`}</p>
        <StatusFilter onChange={(val)=>setSortFilterData({...sortFilterData, status: val})}/>
        <Searchbar search={sortFilterData.keyword ?? ''} setSearch={(val)=>setSortFilterData({...sortFilterData,keyword:val})}/>
      </div>
      <Table
        data={data?.data ?? []}
        columns={columns}
        currentLimit={data.per_page}
        currentPage={data.current_page}
        total={data.total}
        handleLimit={handleLimit}
        handlePagination={handlePagination}
        handleSort={handleSort}
        handleFirstColumn={"max-w-fit"}
      >
        <>
          {data?.data.length ? data?.data?.map((item) => {
            const { id, remark, teacher_name, created_at, status, amount } = item;
            const formatAmount = Number(parseInt(amount).toFixed(0))
            return (
              <tr key={id} className='border-b cursor-pointer hover:bg-gray-100' onClick={()=>onClick(item)}>
                <td className='p-4'>{teacher_name}</td>
                <td className='p-4 text-end'>{currencyConverter(formatAmount)}</td>
                <td className='p-4 text-[#7A7B7E]'>{moment(created_at).format('DD/MM/YYYY')}</td>
                <td><StatusCell status={status}/></td>
                <td className='p-4 text-base text-gray-600 text-base'>{remark}</td>
              </tr>
            )
          }) : (
            <tr>
              <td
                colSpan={columns.length}
                className="italic opacity-40 text-center pt-3"
              >
                <span>Tidak ada data.</span>
              </td>
            </tr>
          )}
        </>
      </Table>
    </div>
  )
}

const StatusCell = ({status} : {status: string}) => {
  const baseClass = 'w-fit text-white px-1.5 rounded-full text-sm font-medium'
  switch (status){
    case "success":
      return <div className={`bg-[#17B26A] ${baseClass}`}>DIBAYARKAN</div>
    case "requested":
      return <p className={`bg-[#0BA5EC] ${baseClass}`}>BELUM DIPROSES</p>
    case "failed":
      return <div className={`bg-[#F04438] ${baseClass}`}>DITOLAK</div>
    default:
      return null
  }
}

const Searchbar = ({search, setSearch}: {search: string, setSearch: (val: string) => void}) => {
  return(
    <div className='bg-white px-4 py-[0.625rem] rounded-xl w-72 flex gap-x-2 items-center border'>
      <FontAwesomeIcon icon={icon({name: 'search'})} color={'#667085'}/>
      <input
        className='w-full'
        placeholder='Cari nama pengajar...'
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      {Boolean(search.length) && (
        <FontAwesomeIcon
          icon={icon({name: 'close'})}
          onClick={() => setSearch('')}
          className='cursor-pointer'
        />
      )}
    </div>
  )
}


const STATUS: {name: string; key?: WithdrawalStatus}[] = [
  {
    name: 'Semua status',
  },
  {
    name: 'Belum diproses',
    key: 'requested'
  },
  {
    name: 'Dibayarkan',
    key: 'success'
  },
  {
    name: 'Ditolak',
    key: 'failed'
  }
]
const StatusFilter = ({onChange}:{onChange: (statusType?: WithdrawalStatus) => void}) => {
  const [selectedStatus, setStatus] = useState(STATUS[0])
  return(
    <div className="relative flex-1 min-w-[150px] max-w-[200px]">
      <Listbox value={selectedStatus} onChange={(val)=>{
        onChange(val?.key)
        setStatus(val)
      }}>
        <Listbox.Button className="relative flex w-full cursor-default items-center justify-between gap-x-1 rounded-lg border bg-white px-3.5 py-2.5 text-left text-sm">
          <span className="block truncate">{selectedStatus.name}</span>
          <FontAwesomeIcon icon={icon({ name: "chevron-down" })} />
        </Listbox.Button>
        <Listbox.Options className="absolute z-10 mt-1 max-h-48 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
          {STATUS.map((val, idx) => (
            <Listbox.Option
              key={`${val.key}`}
              as={Fragment}
              value={val}
            >
              {({ active }) => (
                <li
                  className={`relative flex items-center justify-between cursor-default select-none px-3.5 py-2.5 ${
                    active ? "bg-gray-50" : "text-gray-900"
                  }`}
                >
                  <span className="block truncate">{val.name}</span>
                  {val.key === selectedStatus.key && (
                    <FontAwesomeIcon
                      icon={icon({ name: "check" })}
                      color="#7F56D9"
                    />
                  )}
                </li>
              )}
            </Listbox.Option>
          ))}
        </Listbox.Options>
      </Listbox>
    </div>
  )
}

export default WithdrawalList
