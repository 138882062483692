import dayjs from 'dayjs';
import React, {useCallback, useEffect, useRef, useState} from "react";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import useLiveClass from "../../../services/useLiveClass";
import moment from "moment";

const LiveClassCalendar = () => {
  const {getLiveClassSchedules} = useLiveClass();
  const [data, setData] = useState<LiveClassSchedulesResponse|null>(null);

  const getData = useCallback(async () => {
    const response = await getLiveClassSchedules();
    setData(response?.data);
  }, []);

  useEffect(()=>{
    void getData()
  },[])

  return (
    <div className={'flex flex-col h-full'}>
      <h2 className={'font-bold text-xl text-[#2C3131]'}>Kalender live class</h2>
      <CalendarView data={data}/>
    </div>
  )
}

export const generateDate = (
  month = dayjs().month(),
  year = dayjs().year()
) => {
  const firstDateOfMonth = dayjs().year(year).month(month).startOf("month");
  const lastDateOfMonth = dayjs().year(year).month(month).endOf("month");

  const arrayOfDate = [];

  // create prefix date
  for (let i = 0; i < firstDateOfMonth.day(); i++) {
    const date = firstDateOfMonth.day(i);

    arrayOfDate.push({
      currentMonth: false,
      date,
    });
  }

  // generate current date
  for (let i = firstDateOfMonth.date(); i <= lastDateOfMonth.date(); i++) {
    arrayOfDate.push({
      currentMonth: true,
      date: firstDateOfMonth.date(i),
      today:
        firstDateOfMonth.date(i).toDate().toDateString() ===
        dayjs().toDate().toDateString(),
    });
  }

  const remaining = 35 - arrayOfDate.length;

  for (
    let i = lastDateOfMonth.date() + 1;
    i <= lastDateOfMonth.date() + remaining;
    i++
  ) {
    arrayOfDate.push({
      currentMonth: false,
      date: lastDateOfMonth.date(i),
    });
  }
  return arrayOfDate;
};

export const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

function cn(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const AppointmentItem = ({ data, overlayPosition} : { data: LiveClassSchedulesItem, overlayPosition?:string[]}) => {
  return(
    <div className={'group'}>
      <div className={'bg-[#FFD9D9] w-full cursor-pointer hover:opacity-80'}>
        <p className={'text-[#000000] text-[10px] font-normal text-start px-1.5 rounded-md py-0.5 overflow-hidden truncate'}>{data.title}</p>
      </div>
      <AppointmentOverlay data={data} overlayPosition={overlayPosition}/>
    </div>
  )
}

const AppointmentOverlay = ({data, overlayPosition = []} : {data: LiveClassSchedulesItem, overlayPosition?: string[]}) => {
  return(
    <div className={`group-hover:flex hidden absolute p-4 ml-px w-max bg-white rounded-md shadow-md border border-[#F2F4F7] space-x-3
    ${overlayPosition.includes('top') ? 'top-0' : 'bottom-0'}
    ${overlayPosition.includes('left') ? 'right-full' : 'left-full'}
    `}>
      <div className={'bg-[#FFD9D9] w-4 h-4 rounded-md mt-1'}/>
      <div className={'text-start flex flex-1 flex-col pr-8'}>
        <p className={'text-xl'}>{data.title}</p>
        <div className={'flex space-x-3 text-gray-600 text-sm'}>
          <p>{moment(data.date_start).format('dddd, DD MMM YYYY')}</p>
          <p>{`${moment(data.date_start).format('HH:mm')} - ${moment(data.date_end).format('HH:mm')}`}</p>
        </div>
        <div className={'mt-3 grid grid-cols-2'}>
          <p className={'text-gray-600 text-sm'}>Strata</p>
          <p className={'text-gray-800 text-sm'}>{data.grade_name ?? '-'}</p>
          <p className={'text-gray-600 text-sm'}>Mata pelajaran</p>
          <p className={'text-gray-800 text-sm'}>{data.major_name ?? '-'}</p>
          <p className={'text-gray-600 text-sm'}>Pengajar</p>
          <p className={'text-gray-800 text-sm'}>{data.teacher_name ?? '-'}</p>
        </div>
      </div>
      <FontAwesomeIcon
        className={'mt-1 cursor-pointer'}
        icon={icon({name: 'times'})}
        color={'#98A2B3'}
      />
    </div>
  )
}

const ViewMoreCell = ({ remainingData }: { remainingData : LiveClassSchedulesItem[]}) => {
  return(
    <div className={'group/view-more'}>
      <span
        className={'flex text-[#015DE7] text-[10px] font-normal ml-1 cursor-pointer hover:opacity-80 z-20'}>view more</span>
        <div
          className={'hidden group-hover/view-more:flex flex-col absolute w-[175%] bg-white left-0 right-0 bottom-0 top-[70px] z-50 h-auto shadow-md border-2 border-[#F2F4F7] h-max rounded-md divide-y divide-gray-300'}>
          {remainingData.map((item)=>(
            <div className={'group'}>
              <div className={'flex items-center py-1 px-2 space-x-2'}>
                <div className={'w-1 h-4 rounded-full bg-[#FFD9D9]'}/>
                <span className={'flex flex-1 text-base font-medium truncate overflow-hidden'}>Judul live class disini</span>
                <div className={'flex flex-col'}>
                  <p className={'text-xs text-gray-600'}>09:00</p>
                  <p className={'text-xs text-gray-600'}>10:30</p>
                </div>
              </div>
              <AppointmentOverlay data={item} />
            </div>
          ))}
        </div>
    </div>
  )
}

const CalendarCell = ({date, index, schedules = [], height = 0}: { date: dayjs.Dayjs, index: number, schedules?: LiveClassSchedulesItem[], height: number }) => {
  if (!schedules.length) return null
  const hasRightSpace = date.get('d') < 4
  const remainingBelowSpace = 5 - Math.floor(index/7)
  const hasBelowSpace = remainingBelowSpace < 2
  const maxNumberSchedules = Math.floor(height/24)
  const isOverflow = schedules.length > maxNumberSchedules
  const overlayPosition = [
    `${hasRightSpace ? 'right': 'left'}`,
    `${hasBelowSpace ? 'bottom': 'top'}`,
  ]
  return (
    <div className={'flex relative space-y-0.5 flex-col w-full justify-start'}>
        <>
          {schedules.slice(0,maxNumberSchedules).map((item)=>(
            <AppointmentItem data={item} overlayPosition={overlayPosition}/>
          ))}
          { isOverflow && <ViewMoreCell remainingData={schedules.slice(schedules.length - maxNumberSchedules)}/> }
        </>
    </div>
  )
}

const CalendarView = ({ data }: {data: LiveClassSchedulesResponse| null}) => {
  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const currentDate = dayjs();
  const ref = useRef<HTMLDivElement>(null)
  const [tableHeight, setTableHeight] = useState(0);
  useEffect(() => {
    // when the component gets mounted
    setTableHeight(ref?.current?.offsetHeight ?? 0);
    // to handle page resize
    const getHeight = () => {
      setTableHeight(ref?.current?.offsetHeight ?? 0);
    };
    window.addEventListener("resize", getHeight);
    return () => window.removeEventListener("resize", getHeight);
  }, []);

  return (
    <div className="flex rounded-md flex-1 gap-10 h-full max-h-full overflow-y-hidden flex-col p-4 bg-white mt-4 z-20">
      <div className="h-full max-h-full">
        <div className="flex items-center">
          <h1 className="select-none text-[28px] text-[#252525] font-bold">
            {months[currentDate.month()]}
          </h1>
          <h1 className="select-none text-[28px] text-[#252525] font-light ml-2">
            {currentDate.year()}
          </h1>
        </div>
        <div className="flex grid grid-cols-7 mt-6">
          {days.map((day, index) => {
            return (
              <h1
                key={index}
                className="w-full opacity-50 text-sm text-center grid place-content-center text-[#252525] text-xs font-normal select-none bg-white"
              >
                {day}
              </h1>
            );
          })}
        </div>

        <div
          ref={ref}
          className=" grid grid-cols-7 border-l border-r border-l-[#EBEFF0] border-r-[#EBEFF0] divide-x divide-[#EBEFF0] border-b border-b-[#EBEFF0]">
          {generateDate(currentDate.month(), currentDate.year()).map(
            ({date, currentMonth, today}, index) => {
              return (
                <div
                  key={index}
                  className="flex text-center h-[calc(calc(100vh-280px)/5)] flex-col items-center text-sm border-t space-y-0.5"
                >
                  <h1
                    className={cn(
                      currentMonth ? "" : "text-gray-400",
                      today
                        ? "bg-[#252525] text-white"
                        : "",
                      "text-center text-[10px] w-6 h-6 rounded-full grid place-content-center transition-all select-none"
                    )}
                  >
                    {date.date()}
                  </h1>
                  <CalendarCell
                    height={(tableHeight/5)-24}
                    date={date}
                    index={index}
                    schedules={data?.[`${date.format('YYYY-MM-DD')}`]}
                  />
                </div>
              );
            }
          )}
        </div>
      </div>
    </div>
  );
}


export default LiveClassCalendar
