// React
import { Fragment} from "react"

// Components
import { Button, Icon } from "components"

interface SaveAsDraftProps {
  onSave: () => void;
}

export function SaveAsDraft({onSave}:SaveAsDraftProps) {
const handleSaveClick = () => {
    onSave(); // Call the onSave function from props
  };

  return (
    <Fragment>
      <Button.Create
        isDropdown
        items={[
          {
            title: (
              <div className="flex flex-wrap">
                <Icon.Folder className="w-4 mr-1 fill-primary" />
                <span>Simpan sebagai draft</span>
              </div>
            ),
            onItemClick: handleSaveClick
          },
        //   {
        //     linkTo: `${programDetailData.url}/tambah`,
        //     title: (
        //       <div className="flex flex-wrap">
        //         <Icon.Cash className="w-4 mr-1 fill-primary" />
        //         <span>Simpan sebagai draft</span>
        //       </div>
        //     )
        //   }
        ]}
      />
    </Fragment>
  )
}