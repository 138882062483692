import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

interface Module {
  type: string;
  id: number;
  name: string;
  updated_at: string;
  grade_id: string | null;
}

interface MeetingModuleListProps {
  modules: Module[][][];
  onDelete?: (index: number) => void;
}

const MeetingModuleList: React.FC<MeetingModuleListProps> = ({ modules, onDelete }) => {
  const flattenedModules = modules.flat(2);

  return (
    <>
      {flattenedModules.map((module, index) => (
        <div key={`module-${module.id}`} className='flex w-full justify-between items-center mt-5'>
          <div className='flex items-center gap-x-2'>
            <FontAwesomeIcon icon={icon({name: 'file-lines'})} />
            <p>{module.name}</p>
          </div>
          {onDelete &&
            <FontAwesomeIcon
              icon={icon({name: 'trash-can'})}
              className='cursor-pointer'
              onClick={() => onDelete(index)}
            />
          }
        </div>
      ))}
    </>
  );
};

export default MeetingModuleList;
