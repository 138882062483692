import React, { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import RemoveValidation from './Modal/removeValidation';
import useHomework from "services/useAskHomework";
import toast from 'react-hot-toast';
import { ErrorFetch } from 'types';

type CardProps = {
    data: PackageData;
    handleClick: () => void;
    onRefresh: () => void;
};

const SettingCard: React.FC<CardProps> = ({ data, handleClick, onRefresh }) => {
    const { deletePackage } = useHomework();
    const[removeValidation, setRemoveValidation] = useState(false)

    const handleDelete = useCallback(async () => {
        try {
            await toast.promise(
                deletePackage(data.id),
                {
                    loading: 'Deleting...',
                    success: () => {
                        setRemoveValidation(false);
                        onRefresh()
                        return '';
                    },
                    error: (err: ErrorFetch) => {
                        const msg = err?.response?.data?.message || 'Error deleting package.';
                        return msg;
                    }
                }
            );
        } catch (error) {
            console.error("Error deleting package:", error);
        }
    }, [data.id, deletePackage]);

    const formatCurrency = (number: number) => {
        const formatted = new Intl.NumberFormat('id-ID', {
            style: 'currency',
            currency: 'IDR',
            minimumFractionDigits: 0,
        }).format(number);
        
        return formatted;
    };

    return (
        <div className="bg-[#F9FAFB] shadow-md rounded-md p-4 mb-4 text-[14px]">
            <div className="flex justify-between items-center mb-4">
                <div className="flex items-center">
                <FontAwesomeIcon 
                    icon={data.session_type === "chat" ? icon({ name: "message"}) : icon({ name: "video"})} 
                    className="mr-2" 
                    color='#B5A300'
                />
                <span className='font-semibold text-gray-800'>{data.session_type === "chat" ? 'Chat' : 'Video'}</span>
                </div>
                <div className="flex items-center">
                <FontAwesomeIcon 
                    icon={icon({ name: "cog" })} 
                    className="mr-2 cursor-pointer" 
                    color='#667085'
                    size={"sm"}
                    onClick={handleClick}
                />
                <FontAwesomeIcon 
                    icon={icon({ name: "trash" })} 
                    color='#667085'
                    size={"sm"}
                    className='cursor-pointer'
                    onClick={() => {
                        setRemoveValidation(true)
                    }}
                />
                </div>
            </div>

            <div className="mb-2">
                <p className='text-gray-800 font-semibold'>{data.title}</p>
            </div>

            <div className="mb-2">
                <p className='text-gray-600'>{data.description}</p>
            </div>

            <div className="flex justify-between border-t-2 gray-2 pt-1">
                <div className="flex flex-col items-left">
                    <div>
                        <FontAwesomeIcon icon={icon({ name: "clock" })} className="text-gray-500 mr-2" />
                        <span className='text-gray-600'>Durasi</span>
                    </div>
                    <p className='font-semibold text-gray-800'>{data.duration_minute} menit</p>
                </div>
                <div className="flex flex-col items-left">
                    <div>
                        <FontAwesomeIcon icon={icon({ name: "wallet" })} className="text-gray-500 mr-2" />
                        <span className='text-gray-600'>Harga Paket</span>
                    </div>
                    <p className='font-semibold text-gray-800'>{formatCurrency(parseInt(data.price))}</p>
                </div>
            </div>
            <RemoveValidation
                show={removeValidation}
                onClose={() => {
                    setRemoveValidation(false);
                }}
                onDelete={() => {
                    handleDelete();
                }}
            />
        </div>
    );
};

export default SettingCard;
