import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { useForm, FormProvider } from "react-hook-form";
import useBundle from "services/useBundle";
import DetailProgram from "../fragment/DetailProgram";
import Programs from "../fragment/ProgramsAndPrice";
import Students from "./Students";

const TABS = [
  {
    id: 0,
    label: "Detail program",
    component: DetailProgram,
  },
  {
    id: 1,
    label: "Program & harga",
    component: Programs,
  },
  {
    id: 2,
    label: "Peserta",
    component: Students,
  },
];

export default function Detail() {
  const navigate = useNavigate();
  const location = useLocation();
  const id = location.pathname.split("/bundling/")[1];

  const { getDetail, updateBundle } = useBundle();

  const [detail, setDetail] = useState<BundleItem | null>(null);
  const [selectedTab, setSelectedTab] = useState(0);

  const methods = useForm<BundleItem>({
    values: detail ?? undefined,
  });
  const { getValues, reset } = methods;

  const getData = useCallback(async () => {
    const response = await getDetail(id);

    if (!response) {
      toast.error("Data tidak ditemukan");
      navigate("/bundling", {
        replace: true,
      });
    }

    setDetail(response);
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const handleSave = () => {
    const values = getValues();

    toast.promise(updateBundle(id, values), {
      loading: "...",
      error: () => {
        reset(detail ?? undefined);
        return "Gagal menyimpan";
      },
      success: () => {
        setDetail(values);
        return "";
      },
    });
  };

  const handleCancel = () => {
    reset(detail ?? undefined);
  };

  const ActiveTab = useMemo(() => {
    return (
      TABS.find((t) => t.id === selectedTab)?.component || TABS[0].component
    );
  }, [selectedTab]);

  return (
    <div className="space-y-5">
      <div className="flex items-center gap-5">
        <p className="text-xl font-bold">{detail?.title ?? "-"}</p>
        <span className="text-sm font-medium px-3 py-1 rounded-2xl border border-gray-200">
          BUNDLE
        </span>
      </div>
      <div className="flex gap-x-1">
        {TABS.map((val) => (
          <button
            key={`tab-${val.id}`}
            className={`px-3 py-2 rounded-lg font-semibold ${
              selectedTab === val.id
                ? "bg-[#FFFCE6] text-[#B5A300]"
                : "text-gray-500"
            }`}
            onClick={() => setSelectedTab(val.id)}
          >
            {val.label}
          </button>
        ))}
      </div>
      <FormProvider {...methods}>
        <ActiveTab
          canEdit
          onSave={handleSave}
          onCancel={handleCancel}
        />
      </FormProvider>
    </div>
  );
}
