import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'components';
import {FC, memo, useState} from 'react';
import BrowseModule from 'pages/Syllabus/fragment/Modal/BrowseModule';
import { useFieldArray, useFormContext } from 'react-hook-form';
import {FormSyllabus, Module} from 'views/Silabus/types';

type Props = {
  directoryId: number
  directoryIndex: number
  packageIndex: number
  isPreview: boolean
}

export type DirectoryModule = {
  directoryId: number
  directory: string[]
  modules: Module[]
}

const Modules: FC<Props> = (props) => {
  const {
    directoryId,
    directoryIndex,
    packageIndex,
    isPreview
  } = props;

  const {control, getValues} = useFormContext<FormSyllabus>();
  const {replace, remove, fields} = useFieldArray({
    control,
    name: `packages.${packageIndex}.directories.${directoryIndex}.modules`
  });
  const directories = getValues(`packages.${packageIndex}.directories.${directoryIndex}.crumbs`);

  const [show, setShow] = useState(false);

  const toggle = () => setShow(!show);

  const handleSubmitModule = (items: BrowseItem[]) => {
    const modules = items.map((item) => ({
      name: item.name,
      module_id: item.id
    }));
    replace(modules);
    toggle();
  };

  return (
    <>
      <section className='border rounded-xl overflow-hidden'>
        <div className='px-5 py-4 flex justify-between items-center border-b bg-slate-100'>
          <p className='font-bold text-xl'>Sub bab</p>
          {!isPreview && (
            <Button.Create
              text='Tambah modul'
              onButtonClick={toggle}
            />
          )}
        </div>
        {fields.length ? (
          <div className='px-5 py-4 grid gap-y-[0.625rem]'>
            {fields.map((module, index) => {
              return (
                <div key={`module-${module.module_id}`} className='flex w-full justify-between items-center'>
                  <div className='flex items-center gap-x-2'>
                    <FontAwesomeIcon icon={icon({name: 'file-lines'})} />
                    <p>{module.name}</p>
                  </div>
                  {!isPreview && (
                    <FontAwesomeIcon
                      icon={icon({name: 'trash-can'})}
                      className='cursor-pointer'
                      onClick={() => remove(index)}
                    />
                  )}
                </div>
              )
            })}
          </div>
        ) : (
          <div className='px-5 py-4'>
            <p className='my-6'>Anda belum menambahkan modul apapun.</p>
          </div>
        )}
      </section>
      {!isPreview && (
        <BrowseModule
          show={show}
          onClose={toggle}
          onSubmit={(items) => handleSubmitModule(items)}
          title='Pilih modul'
          directoryId={directoryId}
          checkedModules={fields}
          directories={['Soal', ...directories]}
        />
      )}
    </>
  );
};

export default memo(Modules);
