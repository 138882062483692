// React
import { Fragment, useState } from "react"

// Components
import { Button, Card, Content, Datatable, Icon, Label, Modal, Select } from "components"
import { CreateSection } from "./components"

// Third-Party Libraries
import { Link } from "react-router-dom"

// Utilities
import { baseData } from "./utilities"

function DetailSection(): JSX.Element {
  // Variables
  const items = [
    "Modul Bab 1 - Dasar Bahasa Indonesia",
    "Modul Bab 2 - Dasar Bahasa Indonesia",
    "Modul Bab 3 - Dasar Bahasa Indonesia",
    "Modul Bab 4 - Dasar Bahasa Indonesia"
  ]

  // Hooks
  const [isOpen, setOpen] = useState(false)

  return (
    <Fragment>
      <Button.Icon action="show" onButtonClick={() => setOpen(true)} />

      {isOpen && (
        <Modal size="xl" title="Detail Modul" isOpen={isOpen} closeModal={() => setOpen(false)}>
          <Modal.Body>
            <div className="flex flex-col gap-y-3">
              <section>
                <Label>Judul Modul</Label>
                <div>Modul Pembelajaran Bab I</div>
              </section>

              <section>
                <Label>Isi Modul:</Label>
                {items.map((item, key) => (
                  <div key={key} className="flex flex-wrap">
                    <Icon.Document className="w-4 mr-1 fill-primary" />
                    <span className="text-primary">{item}</span>
                  </div>
                ))}
              </section>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button type="button" color="primary">Kembali</Button>
          </Modal.Footer>
        </Modal>
      )}
    </Fragment>
  )
}

export default function ListDetail(): JSX.Element {
  return (
    <Content>
      <Content.Header
        title={(
          <div>
            <section className="flex">
              <Icon.Folder className="w-5 mr-1 fill-primary" />
              <span>Lulus UTBK 100</span>
            </section>

            <section>
              <div className="breadcrumbs text-sm">
                <ul>
                  <li>Silabus</li>
                  <li>
                    <div className="text-primary">
                      Program Lulus UTBK 100
                    </div>
                  </li>
                </ul>
              </div>
            </section>
          </div>
        )}
        rightElement={<CreateSection />}
      />

      <Content.Body>
        <Card>
          <Card.Header>
            <div className="flex flex-col md:flex-row justify-center md:justify-between items-center">
              <Card.Title>Daftar Kelas</Card.Title>

              <Select
                options={[
                  { label: "Terakhir dibuat" }
                ]}
              />
            </div>
          </Card.Header>

          <Card.Body>
            <Datatable>
              <Datatable.Table
                columns={[
                  {
                    title: "#",
                    field: (_, key) => <div className="text-center">{`${key + 1}.`}</div>
                  },
                  {
                    title: "Kelas",
                    field: (item, key) => {
                      if (item.is_file) {
                        return (
                          <div key={key} className="flex flex-wrap">
                            <Icon.Document className="w-4 mr-1 fill-primary" />
                            <span>{item.kelas}</span>
                          </div>
                        )
                      } else {
                        return (
                          <Link key={key} to={`${baseData.url}/list-kelas/${item.id}`} className="link link-secondary">
                            <Icon.Folder className="w-4 mr-1 fill-primary" />
                            <span>{item.kelas}</span>
                          </Link>
                        )
                      }
                    }
                  },
                  {
                    title: "Tanggal Diubah",
                    field: (item, key) => <div key={key} className="text-center">{item.tanggal}</div>
                  },
                  {
                    title: "Aksi",
                    field: (row, key) => (
                      <div key={key} className="flex flex-row justify-center gap-x-1">
                        {row.is_file && (
                          <Fragment>
                            <DetailSection />
                            <Button.Icon action="edit" />
                          </Fragment>
                        )}

                        <Button.Icon action="delete" />
                      </div>
                    )
                  }
                ]}
                data={[
                  {
                    id: 1,
                    is_file: false,
                    kelas: "12 (1)",
                    tanggal: new Date().toLocaleDateString('id-ID')
                  },
                  {
                    id: 2,
                    is_file: true,
                    kelas: "Modul Linier 2 Variabel",
                    tanggal: new Date().toLocaleDateString('id-ID')
                  }
                ]}
              />

              <Datatable.Pagination
                current_page={0}
                data_length={{ from: 0, to: 0 }}
                onNextClick={() => {}}
                onPreviousClick={() => {}}
                onLengthChange={() => {}}
                rows_per_page={10}
              />
            </Datatable>
          </Card.Body>
        </Card>
      </Content.Body>
    </Content>
  )
}