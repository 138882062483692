// React
import {
  DetailedHTMLProps,
  InputHTMLAttributes,
  useId,
  useMemo,
  useState,
} from "react";

// Components
import { Label } from "components/texts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import {
  validateEmail,
  validateLength,
  validateLowercase,
  validateNumber,
  validateSpecialChar,
  validateUppercase,
} from "utilities";

type TInput = DetailedHTMLProps<
  InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

type Type = TInput & {
  label: string;
  errors?: string | undefined;
  required?: boolean;
};

type PassProps = Type & {
  requirement?: string;
};

function Input(props: Type): JSX.Element {
  // Hooks
  const id = useId();

  return (
    <div>
      <Label id={id}>
        {props.label}
        {props.required && <span className="ml-1 text-red-500">*</span>}
      </Label>
      <input
        type={props.type ?? "text"}
        id={id}
        className="w-full placeholder:text-[#9DA4B3] px-3.5 py-2.5 border rounded-lg"
        {...props}
      />
      {props.errors && (
        <small className="text-error capitalize font-bold">
          {props.errors}
        </small>
      )}
    </div>
  );
}

function Password(props: PassProps) {
  const { disabled, label, required, requirement, value } = props;

  const [showPassword, setShowPassword] = useState(false);

  const passIcon = useMemo(() => {
    return showPassword ? icon({ name: "eye-slash" }) : icon({ name: "eye" });
  }, [showPassword]);

  const togglePassword = () => setShowPassword(!showPassword);

  const isValid = useMemo(() => {
    const isLowercase = validateLowercase(String(value));
    const isUppercase = validateUppercase(String(value));
    const isNumber = validateNumber(String(value));
    const isLength = validateLength(String(value), 8);
    const isSpecialChar = validateSpecialChar(String(value));

    return (
      !value ||
      (isLowercase && isUppercase && isNumber && isLength && isSpecialChar)
    );
  }, [value]);

  return (
    <div className="space-y-1.5">
      <Label>
        {label}
        {required && <span className="ml-1 text-red-500">*</span>}
      </Label>
      {requirement && (
        <span className="text-sm text-gray-500">{requirement}</span>
      )}
      <div className="w-full flex items-center border rounded-lg overflow-hidden">
        <input
          placeholder="Masukkan password"
          type={showPassword ? "text" : "password"}
          className="w-full px-3.5 py-2.5"
          {...props}
        />
        <button
          disabled={disabled}
          className="btn p-3.5 rounded-none bg-transparent"
          onClick={togglePassword}
        >
          <FontAwesomeIcon icon={passIcon} />
        </button>
      </div>
      {!isValid && (
        <small className="text-error capitalize font-bold">
          Masukkan password yang valid
        </small>
      )}
    </div>
  );
}

function Email(props: Type) {
  const { value } = props;

  const isValid = useMemo(() => {
    return validateEmail(String(value));
  }, [value]);

  return (
    <Input
      placeholder="Masukkan email"
      type="email"
      errors={isValid ? "" : "Masukkan email yang valid"}
      {...props}
    />
  );
}

function Search(props: TInput) {
  const { onChange, placeholder } = props;

  return (
    <div className="bg-white border rounded-lg px-3.5 py-2 flex items-center w-auto">
      <FontAwesomeIcon icon={icon({ name: "search" })} />
      <input
        type="search"
        placeholder={placeholder}
        className="ml-2 text-sm w-full"
        onChange={onChange}
      />
    </div>
  );
}

Input.Email = Email;
Input.Password = Password;
Input.Search = Search;

export { Input };
