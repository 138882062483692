// React
import { FC, Fragment, memo, useEffect } from "react"

// Components
import { Input, Select, TextArea } from "components"
import Material from 'pages/Syllabus/fragment/Material';

// Form
import { Controller, useFieldArray, useFormContext, useWatch } from "react-hook-form"

// Types
import { Form } from "views/Silabus/types"

// View Models
import { useGrade } from "views/Silabus/viewModels"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro"
import { Disclosure } from "@headlessui/react"

type Props = {
  onSetMeeting?: () => void
  onEditMeeting?: (idx: number) => void
}

const FormSection: FC<Props> = ({onSetMeeting, onEditMeeting}) => {
  // Form
  const { control } = useFormContext<Form>()
  const { fields, remove } = useFieldArray({
    control: control,
    name: "packages"
  });

  return (
    <Fragment>
      <section className="w-full bg-white rounded-xl mb-5">
        <div className='px-5 py-4 border-b border-gray-200'>
          <p className='font-bold text-xl'>Informasi silabus</p>
        </div>
        <div className="px-5 py-4">
          <div className="grid grid-flow-col gap-8 mb-8">
            <Controller
              name="name"
              control={control}
              render={({ field, formState }) => (
                <Input
                  {...field}
                  label="Judul"
                  required ref={null}
                  placeholder="Masukkan judul"
                  errors={formState.errors.name?.message}
                />
              )}
            />
            <Grade />
          </div>

          <Controller
            name="description"
            control={control}
            render={({ field, formState }) => (
              <TextArea {...field} isRichText label="Deskripsi" required ref={null} placeholder="Masukkan deskripsi silabus anda" errors={formState.errors.description?.message} />
            )}
          />
        </div>
      </section>

      <section className="w-full bg-white rounded-xl">
        <div className='px-5 py-4 border-b border-gray-200 flex justify-between items-center'>
          <p className='font-bold text-xl'>Pertemuan</p>
          <button
            className='bg-primary px-4 py-[0.625rem] rounded-xl btn-'
            onClick={onSetMeeting}
          >
            <FontAwesomeIcon icon={icon({name: 'plus'})} />
            <span className='ml-[0.625rem]'>Tambah pertemuan</span>
          </button>
        </div>
        <div className="px-5 py-4 grid gap-y-5">
          {fields.length ? (
            fields.map((meetingPackage, key) => {
              return (
                <section key={key} className="bg-gray-100 pl-3 pr-5 py-4 rounded-s border">
                  <Disclosure defaultOpen={key === 0}>
                    {({ open }) => {
                      const itemIcon = open ? icon({name: 'chevron-down'}) : icon({name: 'chevron-right'});

                      return (
                        <>
                          <div key={key} className="flex items-center justify-between">
                            <Disclosure.Button className={'flex w-full items-center gap-x-3'}>
                              <FontAwesomeIcon icon={itemIcon} />
                              <p className="text-xl font-bold">
                                {`Pertemuan ${key + 1}`}
                              </p>
                            </Disclosure.Button>
                            <div className="flex items-center gap-x-4">
                              <FontAwesomeIcon
                                icon={icon({name: 'pen-square'})}
                                className="cursor-pointer"
                                onClick={() => {
                                  if (onEditMeeting) {
                                    onEditMeeting(key);
                                  }
                                }}
                              />
                              <FontAwesomeIcon
                                icon={icon({name: 'trash-can'})}
                                className="cursor-pointer"
                                onClick={() => remove(key)}
                              />
                            </div>
                          </div>
                          <Disclosure.Panel className={'border-t mt-4 pt-4 grid gap-y-5'}>
                            <div className="grip gap-x-2">
                              <p className="font-medium text-sm">Judul pertemuan</p>
                              <p className="text-gray-500 text-sm">{meetingPackage.title}</p>
                            </div>
                            <div className="grip gap-x-2">
                              <p className="font-medium text-sm">Deskripsi pertemuan</p>
                              <p className="text-gray-500 text-sm" dangerouslySetInnerHTML={{__html: meetingPackage.description}} />
                            </div>
                            <p className="font-bold text-lg">Bab</p>
                            <Material packageIndex={key} isPreview />
                          </Disclosure.Panel>
                        </>
                      )
                    }}
                  </Disclosure>
                </section>
              )
            })
          ) : (
            <p>Anda belum menambahkan pertemuan apapun.</p>
          )}
        </div>
      </section>
    </Fragment>
  )
}

function Grade() {
  // Hooks
  const { control, setValue } = useFormContext<Form>();
  const { data, isLoading } = useGrade();
  const grade_id = useWatch({
    control,
    name: 'grade_id'
  });

  useEffect(() => {
    if (data.length > 0 && !grade_id) {
      setValue('grade_id', data[0].value);
    }

    // eslint-disable-next-line
  }, [data]);
  

  return (
    <Controller
      name="grade_id"
      control={control}
      render={method => {
        return (
          <Select
            {...method.field}
            label="Strata"
            required
            ref={null}
            layout="line"
            isloading={isLoading ? 1 : 0}
            options={data}
            value={method.field.value ?? undefined}
            onChange={e => method.field.onChange(parseInt(e.target.value))}
          />
        )
      }}
    />
  )
};

export default memo(FormSection);
