// React
import { Fragment, useState } from "react"

// API
import { services } from "services"

// Components
import { Alert, Button } from "components"

// Third-Party Libraries
import toast from "react-hot-toast"

// Types
import { ErrorFetch, SuccessFetch } from "types"

export function DeleteRow(props: {
  id: number
  onDeleted: () => void
  type: "directory" | "syllabus" | "module"
}): JSX.Element {
  // Hooks
  const [isOpen, setOpen] = useState(false)

  const toggle = () => setOpen(prev => !prev)

  const submitHandler = () => {
    toast.promise(
      services.delete(`/syllabus/${props.id}`),
      {
        loading: "Loading...",
        success: (res: SuccessFetch<{message: string}>) => {
          props.onDeleted()
          return res.data.message
        },
        error: (err: ErrorFetch) => err.response.data.message
      }
    ).catch(() => {})
  }

  return (
    <Fragment>
      <Button.Icon action="delete" onButtonClick={() => props.type === "syllabus" && toggle()} />

      {isOpen && (
        <Alert
          title="Hapus Silabus"
          description="Anda yakin untuk menghapus silabus?"
          onClose={toggle}
          confirm={{
            title: "Hapus",
            onClick: () => {
              toggle()
              submitHandler()
            }
          }}
          cancel={{
            title: "Batal",
            onClick: toggle
          }}
        />
      )}
    </Fragment>
  )
}