import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Disclosure } from '@headlessui/react';
import { Badge, Spinner } from 'components';
import {FC, memo, useEffect, useState} from 'react';
import {useFormContext} from 'react-hook-form';
import useBrowse from 'services/useBrowse';

type Props = {
  directoryId: number
  indent: number
  initialData?: BrowseItem[]
  onSelectQuestion: (item: BrowseItem) => void
  directories?: string[]
}

const Questionary: FC<Props> = (props) => {
  const {
    directoryId,
    indent,
    initialData,
    onSelectQuestion,
    directories = []
  } = props;
  const {getList} = useBrowse('question');

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<BrowseItem[]>([]);
  const {setValue, getValues} = useFormContext();
  const questionPackage = getValues('question_package');

  useEffect(() => {
    if (!initialData?.length && directoryId) {
      setLoading(true);
      getList({directory_id: Number(directoryId), per_page: 50})
      .then((response) => setData(response?.data || []))
      .catch((err) => console.log('Err', err))
      .finally(() => setLoading(false))
    }
  }, [directoryId]);

  useEffect(() => {
    setData(initialData || []);
  }, [initialData]);

  return (
    <>
      {loading ? (
        <Spinner />
      ) : data.length === 0 ? (
        <div className="text-center">No data available</div>
      ) : data?.map((item) => {
        let paddingLeft = 32 * (indent - 1);
        paddingLeft = paddingLeft ? paddingLeft : 4;

        if (item.type === "directory") {
          return (
            <Disclosure key={`directory-${item.id}`}>
              {({ open }) => {
                const itemIcon = open ? icon({name: 'chevron-down'}) : icon({name: 'chevron-right'});

                return (
                  <>
                    <Disclosure.Button
                      className={'flex w-full items-center gap-x-4 pr-4 py-2 text-left font-medium hover:bg-yellow-100 border-b'}
                      style={{paddingLeft}}
                    >
                      <FontAwesomeIcon icon={itemIcon} />
                      <section className="flex items-center gap-x-3">
                        <FontAwesomeIcon icon={icon({name: 'folder'})} />
                        <span>{item.name}</span>
                      </section>
                    </Disclosure.Button>

                    {open && (
                      <Disclosure.Panel>
                        <Questionary
                          directoryId={item.id}
                          indent={indent + 1}
                          onSelectQuestion={onSelectQuestion}
                          directories={[...directories, item.name]}
                        />
                      </Disclosure.Panel>
                    )}
                  </>
                )
              }}
            </Disclosure>
          )
        } else {
          // Variables
          const isChecked = false;
          // const grade_name = item.grade_name ?? ""
          const isSameItem = item.id === questionPackage?.id;
          const nEssay = isSameItem ? questionPackage?.n_essay : item.n_essay;
          const nMultiple = isSameItem ? questionPackage?.n_multiple_choice : item.n_multiple_choice;

          let customStyle: any = {
            paddingLeft
          };

          if (isChecked) {
            customStyle = {
              paddingLeft,
              backgroundColor: '#fef9c3'
            }
          };

          return (
            <label
              key={`question-${item.id}`}
              className={'flex items-center cursor-pointer gap-2 pr-4 py-2 text-left font-medium hover:bg-yellow-100 border-b'}
              style={customStyle}
            >
              <input
                readOnly
                type="radio"
                className="radio checked:bg-primary checked:border-none"
                id={`question-${item.id}`}
                name='question'
                checked={questionPackage?.id === item.id}
                onClick={() => {
                  onSelectQuestion(item);
                  setValue('directories', directories);
                  setValue('question_package', {
                    id: item.id,
                    n_essay: nEssay,
                    n_multiple_choice: nMultiple,
                    name: item.name,
                    test_count: isSameItem ? questionPackage.test_count : 'all',
                    total_n_essay: item.n_essay,
                    total_n_multiple_choice: item.n_multiple_choice
                  });
                }}
              />

              <span className="w-full">
                <div className="flex flex-wrap justify-between gap-1">
                  <section className="flex items-center gap-x-3">
                    <FontAwesomeIcon icon={icon({name: 'file-lines', style: 'regular'})} />
                    <span>{item.name}</span>
                  </section>

                  <section className="flex flex-wrap gap-1">
                    <Badge color="blue">{item.n_multiple_choice} PG</Badge>
                    <Badge color="success">{item.n_essay} essay</Badge>
                  </section>
                </div>
              </span>
            </label>
          )
        }
      })}
    </>
  )
};

export default memo(Questionary)
