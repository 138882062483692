import TimesIcon from "../../../../icons/MiscOperations/times.svg";
import DivIcon from "../../../../icons/MiscOperations/div.svg";
import CdotIcon from "../../../../icons/MiscOperations/cdot.svg";
import PmIcon from "../../../../icons/MiscOperations/pm.svg";
import MpIcon from "../../../../icons/MiscOperations/mp.svg";
import AstIcon from "../../../../icons/MiscOperations/ast.svg";
import StarIcon from "../../../../icons/MiscOperations/star.svg";
import CircIcon from "../../../../icons/MiscOperations/circ.svg";
import BulletIcon from "../../../../icons/MiscOperations/bullet.svg";
import OplusIcon from "../../../../icons/MiscOperations/oplus.svg";
import OminusIcon from "../../../../icons/MiscOperations/ominus.svg";
import OslashIcon from "../../../../icons/MiscOperations/oslash.svg";
import OtimesIcon from "../../../../icons/MiscOperations/otimes.svg";
import OdotIcon from "../../../../icons/MiscOperations/odot.svg";
import DaggerIcon from "../../../../icons/MiscOperations/dagger.svg";
import DdaggerIcon from "../../../../icons/MiscOperations/ddagger.svg";
import VeeIcon from "../../../../icons/MiscOperations/vee.svg";
import WedgeIcon from "../../../../icons/MiscOperations/wedge.svg";


const miscOperations = [
  {
    iconPath: TimesIcon,
    label: '\\times'
  },
  {
    iconPath: DivIcon,
    label: '\\div'
  },
  {
    iconPath: CdotIcon,
    label: '\\cdot'
  },
  {
    iconPath: PmIcon,
    label: '\\pm'
  },
  {
    iconPath: MpIcon,
    label: '\\mp'
  },
  {
    iconPath: AstIcon,
    label: '\\ast'
  },
  {
    iconPath: StarIcon,
    label: '\\star'
  },
  {
    iconPath: CircIcon,
    label: '\\circ'
  },
  {
    iconPath: BulletIcon,
    label: '\\bullet'
  },
  {
    iconPath: OplusIcon,
    label: '\\oplus'
  },
  {
    iconPath: OminusIcon,
    label: '\\ominuse'
  },
  {
    iconPath: OslashIcon,
    label: '\\oslash'
  },
  {
    iconPath: OtimesIcon,
    label: '\\otimes'
  },
  {
    iconPath: OdotIcon,
    label: '\\odot'
  },
  {
    iconPath: DaggerIcon,
    label: '\\dagger'
  },
  {
    iconPath: DdaggerIcon,
    label: '\\ddagger'
  },
  {
    iconPath: VeeIcon,
    label: '\\vee'
  },
  {
    iconPath: WedgeIcon,
    label: '\\wedge'
  },
]

export default miscOperations
