import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {FC, useRef, FormEvent, useEffect, useState, useLayoutEffect} from 'react';
import toast from 'react-hot-toast';
import useAuth from 'services/useAuth';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import { Spinner } from 'components';

const Login: FC = () => {
  const emailRef = useRef<HTMLInputElement>(null);
  const passRef = useRef<HTMLInputElement>(null);
  const rememberRef = useRef<HTMLInputElement>(null);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [hasSession, setHasSession] = useState<boolean | null>(null);

  const {login, checkSession} = useAuth();

  useLayoutEffect(() => {

    setHasSession(checkSession());
  }, []);

  useEffect(() => {
    if (hasSession !== null) {
      if (hasSession) {
        navigate('/', {
          replace: true
        });
      }

      setLoading(false);
    }
  }, [hasSession, navigate]);

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const isRemember = rememberRef.current?.checked;

    if (emailRef.current?.value && passRef.current?.value) {
      toast.promise(
        login({
          email: emailRef.current?.value,
          password: passRef.current?.value
        }),
        {
          loading: '...',
          success: (response) => {
            const token = response.access_token;
            if (isRemember) {
              Cookies.set('SESSION', token);
            } else {
              sessionStorage.setItem('SESSION', token);
            }

            navigate('/', {
              replace: true
            });
            return 'Login berhasil';
          },
          error: 'Login gagal!',
        }
      )
    }
  };

  return (
    <div className='flex items-center justify-center h-screen bg-gray-100'>
      {loading ? (
        <Spinner />
      ) : (
        <div className='grid gap-y-5 justify-items-center'>
          <img src="/assets/logo.png" alt="logo" />
          <form onSubmit={handleSubmit} className='py-3 bg-white rounded-lg grid gap-y-5 w-[25rem]'>
            <p className='border-b pb-3 px-5'>Masuk untuk memulai sesi anda</p>
            <div className='rounded border flex items-center justify-between mx-5'>
              <input
                type="email"
                placeholder='Email'
                name="email"
                id="email"
                ref={emailRef}
                className='px-2 py-2 w-full'
              />
              <FontAwesomeIcon icon={icon({name: 'envelope'})} className='pr-2' />
            </div>
            <div className='rounded border flex items-center justify-between mx-5'>
              <input
                type="password"
                placeholder='Kata sandi'
                name="password"
                id="password"
                ref={passRef}
                className='px-2 py-2 w-full'
              />
              <FontAwesomeIcon icon={icon({name: 'lock'})} className='pr-2' />
            </div>
            <div className='flex items-center justify-between mx-5'>
              <div className='flex items-center gap-x-2'>
                <input type="checkbox" id="remember" ref={rememberRef} />
                <label htmlFor="remember">Ingat Saya</label>
              </div>
              <button type='submit' className='flex items-center gap-x-1 rounded bg-primary px-5 py-2'>
                <FontAwesomeIcon icon={icon({name: 'right-to-bracket'})} />
                <span>Masuk</span>
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
};

export default Login;
