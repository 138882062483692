export interface ProgramProps{
  program_id?: number
}
export const baseData = {
  title: "Program",
  url: "/program"
}

export const programDetailData = {
  title: "Detail Program",
  url:`/program/program-detail/`,
};

export const SilabusProgramDetail = {
  title: "Silabus",
  url: "/program/silabus"
}

export const KelasProgramDetail = {
  title: "Kelas",
  url: "/program/kelas"
}

export const JadwalKelasProgramDetail = {
  title: "Jadwal Kelas",
  url: "/program/jadwal-kelas"
}

export const AssignSiswa = {
  title: "Tetapkan Siswa",
}