import {useRef} from 'react';
import {services} from 'services';
import {API_URL} from 'constant';

const defaultParams: MassUploadParams = {
  page: 1,
  per_page: 10,
  sort_by: 'created_at',
  sort_dir: 'desc',
}

type UploadParams = {
  upload_type: FileInfo['type']
  file: File
}

const useMassUpload = () => {
  const lastPayload = useRef(defaultParams);

  const getList = async (params?: MassUploadParams) => {
    try {
      let payload = {
        ...lastPayload.current,
        ...params,
      };

      lastPayload.current = payload;
      const response = await services.get(API_URL.massUpload, {
        params: payload
      });
      
      return response.data;
    } catch (error) {
      return [];
    }
  };

  const addFile = async (params: UploadParams) => {
    try {
      const data = new FormData();
      data.append('upload_type', params.upload_type);
      data.append('file', params.file);

      await services.post(API_URL.massUpload, data, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      return true;
    } catch (error) {
      return false
    }
  }

  return {
    getList,
    addFile
  }
};

export default useMassUpload;
