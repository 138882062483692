import { useCallback } from "react";
import { services } from "services";
import { API_URL } from "constant";
import { AxiosError } from "axios";

type RequestParams = {
  page: number;
  per_page: number;
  request_status: Partial<AskHomeworkStatusType>[];
  grade_id: number;
};

type HistoryParams = {
  id: string;
  page?: number;
  per_page?: number;
  name?: string;
  type?: "chat" | "video" | "";
};

type Response = PaginationResponse & {
  data: TeacherRequest[];
};

type ApprovalRequest = {
  id: string;
  status: ApprovalStatus;
};

type editPackageparams = {
  id: string;
  payload: addPackageParams;
}

type PackageParams = {
  page?: number;
  per_page?: number;
  search?: string;
};

export type orderParams = {
  page?: number;
  per_page?: number | null;
  keyword?: string;
  sort_by?:
    | "id"
    | "invoice_no"
    | "created_at"
    | "session_type"
    | "duration_minute"
    | "price";
  order_by?: "asc" | "desc";
  session_type?: "video" | "chat" | null;
};

const useAskHomework = () => {
  const getList = useCallback(
    async (
      params: AskHomeworkSessionParams
    ): Promise<AskHomeworkSessionResponse> => {
      try {
        const response = await services.get(`${API_URL.askHomework}`, {
          params,
        });

        return response?.data?.data;
      } catch (error) {
        const err = error as AxiosError;
        throw err.response?.data;
      }
    },
    []
  );

  const getSummary =
    useCallback(async (): Promise<AskHomeworkSummaryResponse> => {
      try {
        const response = await services.get(`${API_URL.askHomework}/summary`);

        return response?.data?.data;
      } catch (error) {
        const err = error as AxiosError;
        throw err.response?.data;
      }
    }, []);

  // get list of requested ask homework
  const getTeacherRequest = async (
    params?: Partial<RequestParams>
  ): Promise<Response> => {
    const {
      page = 1,
      per_page = 10,
      request_status = ["accepted", "rejected"],
      grade_id,
    } = params ?? {};
    try {
      const status = request_status.join();

      const payload = {
        page,
        per_page,
        request_status: status,
        grade_id: grade_id || null,
      };
      const response = await services.get(
        `${API_URL.askHomework}/teacher-request`,
        {
          params: payload,
        }
      );

      return response.data?.data;
    } catch (error) {
      return {
        current_page: 1,
        data: [],
        from: 1,
        last_page: 1,
        per_page: 1,
        to: 1,
        total: 1,
      };
    }
  };

  // get detail homework
  const getDetail = async (id: string): Promise<HomeworkDetail | null> => {
    try {
      const response = await services.get(
        `${API_URL.askHomework}/${id}/detail`
      );

      return response.data?.data;
    } catch (error) {
      return null;
    }
  };

  // get detail teacher
  const getTeacher = async (id: string): Promise<Partial<TeacherDetail>> => {
    try {
      const response = await services.get(
        `${API_URL.askHomework}/teachers/${id}/detail`
      );

      return response?.data?.data;
    } catch (error) {
      return {
        avatar: "",
        biography: "",
        grades: [],
        homework_rating: 0,
        is_online_ask_homework: false,
        name: "",
        subjects: [],
        total_done_homeworks: 0,
        total_income: 0,
        average_per_month: 0,
      };
    }
  };

  // get history teacher
  const getHistory = async (
    params: HistoryParams
  ): Promise<PaginationResponse & { data: HomeworkSession[] }> => {
    try {
      const { id, page = 1, per_page = 10, name, type } = params ?? {};
      let payload: Partial<HistoryParams> = {
        page,
        per_page,
        name,
      };

      if (type) {
        payload = {
          ...payload,
          type,
        };
      }

      const response = await services.get(
        `${API_URL.askHomework}/teachers/${id}/detail/history-ask-homework`,
        {
          params: payload,
        }
      );

      return response?.data?.data;
    } catch (error) {
      return {
        data: [],
        current_page: 1,
        from: 1,
        last_page: 1,
        per_page: 10,
        to: 1,
        total: 0,
      };
    }
  };

  // get income teacher
  const getIncome = async (id: string): Promise<Partial<TeacherDetail>> => {
    try {
      const response = await services.get(
        `${API_URL.askHomework}/teachers/${id}/detail/profit-income`
      );
      return response?.data?.data;
    } catch (error) {
      return {
        total_done_homeworks: 0,
        total_income: 0,
        average_per_month: 0,
      };
    }
  };

  const getDetailById = useCallback(async (id: string): Promise<AskHomeworkDetailByIdResponse> => {
    try {
      const response = await services.get(`${API_URL.askHomework}/${id}/detail`);

      return response?.data?.data;
    } catch (error) {
      const err = error as AxiosError;
      throw err.response?.data;
    }
  }, []);

  // approval request
  const approvalRequest = async (params: ApprovalRequest): Promise<boolean> => {
    try {
      const { id, status } = params;
      await services.put(
        `${API_URL.askHomework}/teacher-request/${id}/approval/${status}`
      );

      return true;
    } catch (error) {
      throw new Error('false');
    }
  };

  const getPackageData = async (
    params?: PackageParams
  ): Promise<{ data: PackageData[] }> => {
    try {
      const response = await services.get(
        `${API_URL.askHomework}/packets`,
        {
          params: params,
        }
      );

      return response?.data?.data;
    } catch (error) {
      return {
        data: [],
      };
    }
  };

  const deletePackage = async (id: number) => {
    try {
      await services.delete(`${API_URL.askHomework}/packets/${id}`);
    } catch (error) {
      throw error;
    }
  };

  const addPackage = async (params: addPackageParams) => {
    try {
      await services.post(`${API_URL.askHomework}/packets`, params);

      return true;
    } catch (error) {
      throw error;
    }
  };

  const editPackage = async (params: editPackageparams) => {
    try {
      const { id, payload } = params;
      await services.put(`${API_URL.askHomework}/packets/${id}`, payload);

      return true;
    } catch (error) {
      throw error;
    }
  };

  const getOrderSummary = async (): Promise<summaryOrderData> => {
    try {
      const response = await services.get(
        `${API_URL.askHomework}/order/summary`,
      );

      return response?.data?.data;
    } catch (error) {
      throw error
    }
  };

  const getOrderData = async (
    params?: orderParams
  ): Promise<PaginationResponse & { data: orderData[] }> => {
    try {
      const { page = 1, per_page = 10, keyword, sort_by, order_by, session_type } = params ?? {};
      let payload: Partial<orderParams> = {
        page,
        per_page,
        keyword,
        sort_by,
        order_by,
        session_type,
      };

      const response = await services.get(
        `${API_URL.askHomework}/orders`,
        {
          params: payload,
        }
      );

      return response?.data?.data;
    } catch (error) {
      return {
        data: [],
        current_page: 1,
        from: 1,
        last_page: 1,
        per_page: 10,
        to: 1,
        total: 0,
      };
    }
  };

  const getOrderDetail = async (id: string): Promise<orderDetail> => {
    try {
      const response = await services.get(
        `${API_URL.askHomework}/orders/${id}`,
      );
      return response?.data?.data;
    } catch (error) {
      throw error
    }
  };


  return {
    getList,
    getSummary,
    getTeacherRequest,
    getDetail,
    getTeacher,
    getHistory,
    getIncome,
    getDetailById,
    approvalRequest,
    getPackageData,
    deletePackage,
    addPackage,
    editPackage,
    getOrderSummary,
    getOrderData,
    getOrderDetail
  };
};

export default useAskHomework;
