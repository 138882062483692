import { Badge } from "components";
import { PROGRAM_TYPE } from "constant";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import usePromo from "services/usePromo";
import { getFormatDate, getProgramColor } from "utilities";
import UserList from "../fragment/UserList";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import ButtonDropdown from "components/dropdowns/ButtonDropdown";
import toast from 'react-hot-toast';

type Programs = {
  title: string;
  type: ProgramType;
};

export default function Detail() {
  const location = useLocation();
  const id = location.pathname.split("/promo/")[1];
  const { detail, updatePromo } = usePromo();

  const [data, setData] = useState<PromoDetail | null>(null);
  const [programList, setProgramList] = useState<Programs[]>([]);
  const [isAllProgram, setIsAllProgram] = useState(false);

  const getData = useCallback(async () => {
    const response = await detail(id);
    const { promo_objects, ...restResponse } = response;
    let programs: Programs[] = [];
    let isAll = false;

    if (promo_objects) {
      promo_objects.forEach((val) => {
        const content = val.items.map((item) => ({
          title: item.title,
          type: val.object,
        }));

        isAll = Boolean(val.is_all_items);
        programs = [...programs, ...content];
      });
    }

    setProgramList(programs);
    setIsAllProgram(isAll);
    setData({
      ...restResponse,
      promo_objects,
    });
  }, [id]);

  const isActive = useMemo(() => {
    return data?.status === "Aktif";
  }, [data?.status]);

  useEffect(() => {
    getData();
  }, []);

  const handleStatus = () => {
    toast.promise(updatePromo(Number(id), !isActive), {
      loading: `${isActive ? "Nonaktifkan" : "Aktifkan"} ${data?.title}...`,
      success: () => {
        getData();
        return `Berhasil ${isActive ? "Nonaktifkan" : "Aktifkan"} ${
          data?.title
        }`;
      },
      error: `Gagal ${isActive ? "Nonaktifkan" : "Aktifkan"} ${data?.title}`,
    });
  };

  return (
    <div className="space-y-5">
      <div className="flex items-center justify-between">
        <p className="font-bold space-x-5">
          <span className="text-xl">{data?.title || "-"}</span>
          <span className="text-sm text-[#7F56D9]">
            {data?.type === "promo_regular" ? "PROMO" : "VOUCHER"}
          </span>
        </p>
        <ButtonDropdown
          menuItems={[
            <button
              onClick={handleStatus}
              className="w-full flex items-center justify-start gap-x-2 rounded-lg px-4 py-2.5 hover:bg-gray-50"
            >
              <p className="text-sm">{isActive ? "Nonaktif" : "Aktif"}</p>
            </button>,
          ]}
          widthItems="w-40"
        >
          <div
            className={`rounded-lg px-4 py-2.5 text-white text-sm space-x-3 ${
              isActive ? "bg-[#17B26A]" : "bg-gray-400"
            }`}
          >
            <span className="font-semibold">{data?.status || "-"}</span>
            <FontAwesomeIcon
              icon={icon({ name: "chevron-down" })}
              className="cursor-pointer"
            />
          </div>
        </ButtonDropdown>
      </div>
      <div className="grid grid-cols-[20rem,1fr] gap-x-8">
        <div className="bg-white rounded-xl">
          <p className="font-bold px-5 py-4 border-b">Informasi voucher</p>
          <div className="p-6">
            {data?.type === "voucher_code" && (
              <>
                <p className="font-medium text-sm text-gray-500">
                  Kode voucher
                </p>
                <p className="font-medium text-sm mb-4">
                  {data?.voucher_code || "-"}
                </p>
              </>
            )}
            <p className="font-medium text-sm text-gray-500">Periode mulai</p>
            <p className="font-medium text-sm mb-4">
              {data?.start_period
                ? getFormatDate(data?.start_period, "DD/MM/YYYY")
                : "-"}
            </p>
            <p className="font-medium text-sm text-gray-500">
              Periode berakhir
            </p>
            <p className="font-medium text-sm mb-4">
              {data?.end_period
                ? getFormatDate(data?.end_period, "DD/MM/YYYY")
                : "-"}
            </p>
            <p className="font-medium text-sm text-gray-500">Diskon</p>
            <p className="font-medium text-sm mb-4">{data?.discount || "-"}</p>
            <p className="font-medium text-sm text-gray-500">Kuota</p>
            <p className="font-medium text-sm mb-4">{data?.quota || "-"}</p>
            <p className="font-bold mt-4 mb-3">Daftar program</p>
            {isAllProgram ? (
              <div className="bg-[#FFF38A] grid place-items-center p-10 rounded-lg text-center space-y-3">
                <FontAwesomeIcon
                  icon={icon({ name: "file-circle-check" })}
                  size="2x"
                />
                <p className="text-sm">
                  Voucher dapat digunakan untuk semua program
                </p>
              </div>
            ) : (
              programList.map((val, index) => (
                <div
                  key={index}
                  className="flex items-center justify-between py-3 border-b gap-x-2"
                >
                  <span className="text-sm">{val.title}</span>
                  <Badge color={getProgramColor(val.type)}>
                    {PROGRAM_TYPE[val.type].toUpperCase()}
                  </Badge>
                </div>
              ))
            )}
          </div>
        </div>
        <div className="bg-white rounded-xl">
          <p className="font-bold px-5 py-4 border-b">Daftar pengguna</p>
          <UserList id={id} />
        </div>
      </div>
    </div>
  );
}
