import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

interface CardProps {
    title: string;
    text: number;
    icon: IconDefinition;

}

const Card: React.FC<CardProps> = ({ title, text, icon }) => {
    const formattedCurrency = text.toLocaleString('id', { minimumFractionDigits: 0 });
    
    return (
        <div className="container p-5 flex flex-col items-start gap-1 rounded-lg bg-white shadow-md">
        <div className="flex items-center gap-2">
            <div className="w-[20px] h-[20px] flex justify-center items-center rounded-full bg-[#FFE500]">
                <FontAwesomeIcon icon={icon} width={12} />
            </div>
            <p className="text-gray-600 font-medium text-sm">{title}</p>
        </div>
        <p className="text-right text-gray-900 font-bold text-2xl">{title === "Total sesi"? text : `Rp ${formattedCurrency}`}</p>
        </div>
    );
};

export default Card;
