import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Disclosure } from "@headlessui/react";
import { Spinner } from "components/Spinner";
import { FC, memo, useEffect, useState } from "react";
import useBrowse from "services/useBrowse";

type Props = {
  directoryId?: number;
  initialData?: BrowseItem[];
  indent?: number;
  onSelect: (item: BrowseItem[]) => void;
  selected?: BrowseItem[];
};

const List: FC<Props> = (props) => {
  const { directoryId, initialData = [] } = props;
  const { getListAdmin } = useBrowse("question");
  const {getList} = useBrowse('module');

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<BrowseItem[]>(initialData);

  useEffect(() => {
    if (initialData.length) {
      setData(initialData);
    }
  }, [initialData]);

  useEffect(() => {
    if (directoryId) {
      setLoading(true);
      getList({
        directory_id: directoryId,
        per_page: 50,
      })
        .then((response) => setData(response?.data || []))
        .catch((err) => console.log("Err", err))
        .finally(() => setLoading(false));
    }
    // eslint-disable-next-line
  }, [directoryId]);

  return <>{loading ? <Spinner /> : <RenderItem data={data} {...props} />}</>;
};

const RenderItem: FC<Props & { data: BrowseItem[] }> = memo((props) => {
  const { data, indent = 1, onSelect, selected = [] } = props;

  const handleCheck = (item: BrowseItem) => {
    const isExist = selected?.find((val) => item.id === val.id);

    if (isExist) {
      const filterSelected = selected.filter((val) => val.id !== item.id);

      onSelect(filterSelected);
    } else {
      onSelect([...selected, item]);
    }
  };

  return (
    <div>
      {data.map((item) => {
        const isDirectory = item.type === "directory";
        const isSelected =
          Boolean(selected.find((val) => val.id === item.id)) && !isDirectory;
        let paddingLeft = 32 * (indent - 1);
        paddingLeft = paddingLeft ? paddingLeft : 4;

        return isDirectory ? (
          <Disclosure key={`directory-${item.id}`}>
            {({ open }) => {
              const itemIcon = open
                ? icon({ name: "chevron-down" })
                : icon({ name: "chevron-right" });

              return (
                <>
                  <Disclosure.Button
                    as="div"
                    className={`
                          flex 
                          w-full 
                          items-center 
                          gap-x-4 
                          pr-4 
                          py-2 
                          text-left 
                          font-medium
                          border-b
                          cursor-pointer
                          hover:bg-yellow-100
                        `}
                    style={{
                      paddingLeft,
                    }}
                  >
                    <FontAwesomeIcon icon={itemIcon} width={16} />
                    <div className="flex items-center gap-x-3 w-full">
                      <FontAwesomeIcon icon={icon({ name: "folder" })} />
                      <span>{item.name}</span>
                    </div>
                  </Disclosure.Button>

                  {open && (
                    <Disclosure.Panel>
                      <List
                        directoryId={item.id}
                        indent={indent + 1}
                        onSelect={onSelect}
                        selected={selected}
                      />
                    </Disclosure.Panel>
                  )}
                </>
              );
            }}
          </Disclosure>
        ) : (
          <label
            key={`question-${item.id}`}
            className={`flex w-full gap-x-4 py-2 cursor-pointer hover:bg-yellow-100 border-b ${
              isSelected ? "bg-yellow-100" : "bg-white"
            }`}
            style={{
              paddingLeft,
            }}
          >
            <input
              type="checkbox"
              id={`question-${item.id}`}
              className="checkbox checked:bg-yellow-400 checked:border-primary text-gray-500"
              onChange={() => handleCheck(item)}
              checked={isSelected}
            />
            <div className="flex w-full items-center gap-x-2">
              <FontAwesomeIcon icon={icon({ name: "file-lines" })} />
              <p>{item.name}</p>
            </div>
          </label>
        );
      })}
    </div>
  );
});

export default memo(List);
