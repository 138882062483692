// React
import { Fragment, useState } from "react"

// Components
import { Button, Icon, Modal } from "components"

// Form
import { Controller, useForm } from "react-hook-form"

// Third-Party Libraries
import { useNavigate } from "react-router"

export function CreateSection() {
  // Hooks
  const [isOpen, setOpen] = useState(false)

  const toggle = () => setOpen(prev => !prev)

  return (
    <Fragment>
      <Button color="primary" className="flex items-center" onButtonClick={toggle}>
        <Icon.Plus className="w-3 mr-1" />
        <span>Tambah Program</span>
      </Button>

      {isOpen && <ModalSection toggle={toggle} />}
    </Fragment>
  )
}

function ModalSection(props: {
  toggle: () => void
}) {
  // Variables
  const options = [
    {
      description: "Program yang dapat diakses oleh semua orang yang ada di Guruku tanpa harus membayar",
      title: "E-Learning",
      value: "elearning"
    },
    {
      description: "Program yang diperuntukan kepada siswa Guruku yang akan dilakukan secara luring/offline",
      title: "Kelas Offline",
      value: "offline"
    },
    {
      description: "Program yang diperuntukan kepada siswa Guruku yang akan dilakukan secara daring/online",
      title: "Kelas Online",
      value: "online"
    }
  ]

  // Hooks
  const navigate = useNavigate()

  // Form
  type Form = {
    selectedOption: string
  }
  const defaultValues = { selectedOption: "elearning" }
  const onSubmit = (values: Form) => {
    // Variables
    let state = {
      type: "learn",
      is_online: true
    }

    if (values.selectedOption === "online") {
      state = {
        type: "class",
        is_online: true
      }
    } else if (values.selectedOption === "offline") {
      state = {
        type: "class",
        is_online: false
      }
    }

    navigate("tambah", { state })
  }
  const { control, handleSubmit } = useForm<Form>({ defaultValues })

  return (
    <Modal isOpen title="Pilih Kategori Program" closeModal={props.toggle}>
      <Modal.Body>
        <Controller
          control={control}
          name="selectedOption"
          render={method => (
            <div className="flex flex-col gap-[16px]">
              {options.map((item, key) => (
                <div key={key} className={`${key > 0 && "bg-gray-300"} flex flex-row gap-4 w-full border-solid border-[1px] items-center border-gray-300 rounded-lg`}>
                  <label className="p-[16px] flex items-center cursor-pointer gap-2">
                    <input
                      type="radio"
                      className="radio"
                      disabled={key > 0}
                      checked={Boolean(method.field.value === item.value)}
                      value={item.value}
                      onChange={method.field.onChange}
                    />

                    <div className="flex flex-col w-full gap-1">
                      <div className="text-lg font-bold">{item.title}</div>
                      <p>{item.description}</p>
                    </div>
                  </label>
                </div>
              ))}
            </div>
          )}
        />
      </Modal.Body>

      <Modal.Footer className="flex justify-end gap-x-2">
        <Button type="button" color="primary" className="w-full" onButtonClick={handleSubmit(onSubmit)}>Lanjutkan</Button>
      </Modal.Footer>
    </Modal>
  )
}