import { FC, memo, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LiveClassDetail from "./LiveClass";
import EditProgram from "../Edit";
import TryOut from "./TryOut";
import useProgram from "../../../services/useProgram";

const ProgramType = {
  class: <LiveClassDetail />,
  tryout: <TryOut />,
  learn: <EditProgram />,
};

const ProgramDetail: FC = () => {
  const { id } = useParams();
  const [data, setData] = useState<ProgramItem | null>(null);
  const { getDetail } = useProgram();

  useEffect(() => {
    getDetail(Number(id))
      .then((res) => {
        const data = res.data;
        setData(data);
      })
      .catch((error) => console.error(error));
  }, [id, getDetail]);

  if (!data?.type) return <div>Loading...</div>;

  const { type } = data;

  return (ProgramType as any)[type];
};

export default memo(ProgramDetail);
