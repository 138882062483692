import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import {IconDefinition} from "@fortawesome/fontawesome-common-types";
import useAskHomework from "../../../../services/useAskHomework";
import {useEffect, useState} from "react";

type SummaryDataKeyType = 'session_total'|'session_video' |'session_chat' |'total_duration_in_minutes'
const DATA_MAPPING: {title: string, key: SummaryDataKeyType, icon: IconDefinition, suffix?: string, divider?: number}[] = [
  {
    title: 'Total sesi',
    key: 'session_total',
    icon: icon({ name: 'layer-group' }),
  },
  {
    title: 'Total sesi video',
    key: 'session_video',
    icon: icon({ name: 'video' }),
  },
  {
    title: 'Total sesi chat',
    key: 'session_chat',
    icon: icon({ name: 'comments' }),
  },
  {
    title: 'Total durasi',
    key: 'total_duration_in_minutes',
    suffix: 'jam',
    icon: icon({ name: 'stopwatch' }),
    divider: 60
  },
]
const SalesSummaryWidget = () => {
  const {getSummary} = useAskHomework();
  const [data, setData] = useState<AskHomeworkSummaryResponse>();

  useEffect(()=>{
    getSummary().then((resp)=>{
      setData(resp)
    })
  },[])

  if (!data) return null
  return(
    <div className='flex flex-row space-x-4'>
      {DATA_MAPPING.map(({title, key, icon, suffix, divider = 1})=>
        <SummaryItem
          title={title}
          value={(data[key]/divider).toFixed(1).replace(/[.,]0$/, "").toString()}
          icon={icon}
          suffix={suffix}
        />
      )}
    </div>
  );
};

const SummaryItem = ({ title, value, icon, suffix} : {title: string; value: string, icon: IconDefinition, suffix?: string}) => {
  return(
    <div className='flex flex-col bg-white py-5 px-8 rounded-lg space-y-2 flex-1 shadow'>
      <div className='flex flex-row space-x-2 items-center'>
        <div className="flex bg-[#FFE500] w-6 h-6 rounded-full justify-center items-center">
          <FontAwesomeIcon icon={icon} className="h-3 w-3 text-black" />
        </div>
        <span className='text-gray-600 text-sm'>{title}</span>
      </div>
      <div className='flex flex-row space-x-1'>
        <span className={'text-3xl font-bold'}>{value}</span>
        <span className='self-end text-gray-500 mb-px text-xl'>{suffix}</span>
      </div>
    </div>
  )
}

export default SalesSummaryWidget;
