// React
import { Fragment, useState } from "react"

// Components
import { Button, Card, Content, Datatable, DateString, Icon, Label, Modal, Skeleton } from "components"
import { CreateSection, DeleteRow } from "../components"

// Utilities
import { baseData } from "../utilities"

// Third-Party Libraries
import { Link, useLocation } from "react-router-dom"

// Types
import { List as ListType } from "../types"

// View Models
import { SortField, useList, useSingle } from "./viewModels"

export default function List() {
  // Hooks
  const location: {
    state?: {
      useCache?: boolean
    }
  } = useLocation()
  const { 
    data,
    dataResult,
    directoryList,
    isLoading,
    latestDirectory,
    params,
    onAddDirectory,
    onDirectoryClick,
    refetch,
    setLength,
    setPage,
    setSort
  } = useList({ useCache: Boolean(location.state?.useCache) })

  return (
    <Content>
      <Content.Header
        title={directoryList.length === 0 ? baseData.title : (
          <div>
            <section className="flex">
              <Icon.Folder className="w-5 mr-1 fill-primary" />
              <span>{directoryList[directoryList.length - 1].path}</span>
            </section>

            <section>
              <div className="breadcrumbs text-sm">
                <ul>
                  <li onClick={() => onDirectoryClick()}>Silabus</li>

                  {directoryList.map((item, key) => {
                    // Variables
                    const isLastItem = key + 1 === directoryList.length

                    return (
                      <li
                        key={key}
                        onClick={() => onDirectoryClick(key)}
                      >
                        <div className={isLastItem ? "text-primary" : ""}>
                          {item.path}
                        </div>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </section>
          </div>
        )}
        rightElement={(
          <CreateSection
            directory_id={latestDirectory?.id}
            onAddFolder={refetch}
          />
        )}
      />

      <Content.Body>
        <Card>
          <Card.Header>
            <Card.Title>Daftar {baseData.title}</Card.Title>
          </Card.Header>

          <Card.Body>
            <Datatable>
              <Datatable.Table
                isLoading={isLoading}
                sort={{
                  field: params.sort_by!,
                  dir: params.sort_dir!
                }}
                columns={[
                  {
                    title: "#",
                    tdProps: { className: "!text-center" },
                    field: (_, key) => `${key + dataResult.from}.`
                  },
                  {
                    title: "Kelas",
                    sortField: "name",
                    field: (item: ListType, key) => {
                      if (item.type === "syllabus") {
                        return (
                          <div key={key} className="flex">
                            <Icon.Document className="w-4 mr-1 fill-primary" />
                            <span>{item.name}</span>
                          </div>
                        )
                      } else {
                        return (
                          <div
                            key={key}
                            className="flex text-blue-400 cursor-pointer"
                            onClick={() => {
                              onAddDirectory(item.name, item.id)
                            }}
                          >
                            <Icon.Folder className="w-4 mr-1 fill-primary" />
                            <span>{item.name}</span>
                          </div>
                        )
                      }
                    }
                  },
                  {
                    title: "Tingkatan",
                    tdProps: {
                      className: "!text-center"
                    },
                    field: (item: ListType) => item.grade_name ?? ""
                  },
                  {
                    title: "Tanggal Diubah",
                    tdProps: {
                      className: "!text-center"
                    },
                    sortField: "updated_at",
                    field: (item: ListType) => {
                      if (item.updated_at) {
                        return <DateString date={item.updated_at} format="DD MMMM YYYY HH:mm" />
                      } else {
                        return ""
                      }
                    }
                  },
                  {
                    title: "Aksi",
                    tdProps: {
                      className: "flex justify-center gap-x-1"
                    },
                    field: (item: ListType, key) => (
                      <Fragment key={key}>
                        {item.type === "syllabus" && (
                          <Fragment>
                            <DetailRow item={item} />

                            <Link to={`/silabus/ubah/${item.id}`} state={{ directory_id: latestDirectory?.id }}>
                              <Button.Icon action="edit" />
                            </Link>
                          </Fragment>
                        )}

                        <DeleteRow type={item.type} id={item.id} onDeleted={refetch} />
                      </Fragment>
                    )
                  }
                ]}
                data={data}
                onSortChange={(props) => {
                  setSort({
                    sort_by: props.field as SortField,
                    sort_dir: props.dir
                  })
                }}
              />

              <Datatable.Pagination
                isLoading={isLoading}
                current_page={params.page}
                rows_per_page={params.per_page}
                data_length={{
                  from: dataResult.from,
                  to: dataResult.to
                }}
                onLengthChange={setLength}
                onNextClick={value => dataResult.next && setPage(value)}
                onPreviousClick={value => dataResult.previous && setPage(value)}
              />
            </Datatable>
          </Card.Body>
        </Card>
      </Content.Body>
    </Content>
  )
}

function DetailRow(props: {
  item: ListType
}) {
  // Hooks
  const [isOpen, setOpen] = useState(false)

  const toggle = () => setOpen(prev => !prev)

  const ModalSection = () => {
    // Hooks
    const { data, isLoading } = useSingle(props.item.id)

    return (
      <Modal isOpen closeModal={toggle} title="Detail Silabus">
        <Modal.Body className="flex flex-col gap-y-3">
          <Content>
            <Label>Judul Silabus</Label>

            <Content.Body>
              {props.item.name}
            </Content.Body>
          </Content>

          {isLoading ? (
            <Fragment>
              <section>
                <Skeleton.Label />
                <Skeleton.List />
              </section>

              <section>
                <Skeleton.Label />
                <Skeleton.List />
                <Skeleton.List />
                <Skeleton.List />
              </section>
            </Fragment>
          ) : data && (
            <Fragment>
              <Content>
                <Label>Deskripsi:</Label>

                <Content.Body>
                  {data.description}
                </Content.Body>
              </Content>

              <Content>
                <Label>Isi Silabus:</Label>

                <Content.Body>
                  {data.packages.map((item, key) => (
                    <div key={key} className="flex">
                      <Icon.Document className="w-4 fill-primary" />
                      <span>{item.title}</span>
                    </div>
                  ))}
                </Content.Body>
              </Content>
            </Fragment>
          )}
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <Fragment>
      <Button.Icon action="show" onButtonClick={toggle} />

      {isOpen && <ModalSection />}
    </Fragment>
  )
}