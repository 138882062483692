import { useState, useRef, useEffect } from 'react';

const useDebounce = <T>(value: T, delay = 500): T => {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);
  const timeRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    timeRef.current = setTimeout(() => setDebouncedValue(value), delay);

    return () => {
      clearTimeout(timeRef.current);
    };
  }, [value, delay]);

  return debouncedValue;
};

export default useDebounce;
