import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { memo, FC, useMemo } from "react";

type Props = {
  questions: TryoutPayload["question_package"];
  variety?: Variety;
  isEdit?: boolean;
};

const QuestionComposition: FC<Props> = ({
  questions,
  variety,
  isEdit = false,
}) => {
  const questionComposition = useMemo(() => {
    const listEasy = questions.filter((val) => val.difficulty === 1);
    const listMedium = questions.filter((val) => val.difficulty === 2);
    const listHard = questions.filter((val) => val.difficulty === 3);
    const easy = listEasy.reduce((prev, current) => {
      return prev + current.n_question_value;
    }, 0);
    const medium = listMedium.reduce((prev, current) => {
      return prev + current.n_question_value;
    }, 0);
    const hard = listHard.reduce((prev, current) => {
      return prev + current.n_question_value;
    }, 0);
    const totalSelected = questions.reduce(
      (prev, current) => prev + current.n_question_value,
      0
    );
    const total =
      questions.reduce((prev, current) => {
        return prev + (current.n_multiple_choice + current.n_essay);
      }, 0) || 0;
    let easyPercentage, mediumPercentage, hardPercentage;
    easyPercentage = mediumPercentage = hardPercentage = 0;

    if (total) {
      easyPercentage = ((easy / total) * 100).toFixed(1);
      mediumPercentage = ((medium / total) * 100).toFixed(1);
      hardPercentage = ((hard / total) * 100).toFixed(1);
    }

    return {
      easy,
      easyPercentage,
      medium,
      mediumPercentage,
      hard,
      hardPercentage,
      total,
      totalSelected,
    };
  }, [questions]);

  const notValid =
    questionComposition.totalSelected < (variety?.total_variety ?? 0);

  return (
    <div className="grid gap-y-4">
      <div className="flex gap-x-5">
        <div className="px-3 py-1 rounded bg-[#FFFCE6]">
          <span>Total soal tersedia</span>
          <span className="font-bold ml-2">{`${questionComposition.total} soal`}</span>
        </div>
        <div className="px-3 py-1 rounded bg-[#FFFCE6]">
          <span>Total soal dipilih</span>
          <span className="font-bold ml-2">{`${questionComposition.totalSelected} soal`}</span>
        </div>
        {isEdit && notValid && (
          <div className="flex items-center gap-x-2">
            <FontAwesomeIcon
              icon={icon({ name: "info-circle" })}
              color="#D92D20"
            />
            <p className="font-medium text-sm text-red-500">
              Total soal dipilih belum memenuhi kriteria
            </p>
          </div>
        )}
      </div>
      <div className="flex items-center gap-x-4">
        <p>Komposisi soal</p>
        <div className="flex gap-x-2 px-3 py-1 rounded bg-[#ECFDF3]">
          <span>Mudah</span>
          <span className="font-bold">{`${questionComposition.easy} soal`}</span>
          <span className="text-[#079455] font-semibold">{`${questionComposition.easyPercentage}%`}</span>
        </div>
        <div className="flex gap-x-2 px-3 py-1 rounded bg-[#FFFAEB]">
          <span>Sedang</span>
          <span className="font-bold">{`${questionComposition.medium} soal`}</span>
          <span className="text-[#DC6803] font-semibold">{`${questionComposition.mediumPercentage}%`}</span>
        </div>
        <div className="flex gap-x-2 px-3 py-1 rounded bg-[#FEF3F2]">
          <span>Sulit</span>
          <span className="font-bold">{`${questionComposition.hard} soal`}</span>
          <span className="text-[#D92D20] font-semibold">{`${questionComposition.hardPercentage}%`}</span>
        </div>
      </div>
    </div>
  );
};

export default memo(QuestionComposition);
