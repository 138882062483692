import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

type Menus = {
  [menu: string]: {
    label: string;
    icon: IconDefinition;
    path: string;
    subpath?: {
      label: string;
      path: string;
    }[];
  }[];
};

export const MENUS: Menus = {
  main: [
    {
      label: "Dashboard",
      icon: icon({ name: "house" }),
      path: "/",
    },
    {
      label: "Modul",
      icon: icon({ name: "book" }),
      path: "/modul",
    },
    {
      label: "Soal",
      icon: icon({ name: "circle-question" }),
      path: "/bank-soal",
    },
    {
      label: "Silabus",
      icon: icon({ name: "clipboard-list" }),
      path: "/silabus",
    },
    {
      label: "Program",
      icon: icon({ name: "book-open-reader" }),
      path: "/program",
    },
    {
      label: "Tanya PR",
      icon: icon({ name: "comment-dots" }),
      path: "tanya-pr",
      subpath: [
        {
          label: "List tanya PR",
          path: "/tanya-pr/list",
        },
        {
          label: "Pengajar",
          path: "/tanya-pr/pengajar",
        },
        {
          label: "Penjualan",
          path: "/tanya-pr/penjualan",
        },
        {
          label: "Pengaturan",
          path: "/tanya-pr/pengaturan",
        },
      ],
    },
    {
      label: "Poin",
      icon: icon({ name: "coins" }),
      path: "/poin",
    },
    {
      label: "Live class",
      icon: icon({ name: "users" }),
      path: "live-class",
      subpath: [
        {
          label: "Kelender",
          path: "/live-class/calendar",
        },
        {
          label: "Monitoring",
          path: "/live-class/monitoring",
        }
        ]
    },
    {
      label: "Penarikan gaji",
      icon: icon({ name: "wallet" }),
      path: "/withdrawal",
    },
    {
      label: "Promo & Voucher",
      icon: icon({ name: "tags" }),
      path: "promo",
    },
    {
      label: "Bundling",
      icon: icon({ name: "boxes-packing" }),
      path: "bundling",
    },
    {
      label: "Cuti",
      icon: icon({ name: "calendar" }),
      path: "teacher-leave",
    },
  ],
  config: [
    {
      label: "Upload masal",
      icon: icon({ name: "cloud-arrow-up" }),
      path: "/mass-upload",
    },
  ],
  users: [
    {
      label: "Siswa",
      icon: icon({ name: "circle-user", style: "regular" }),
      path: "/siswa",
    },
    {
      label: "Guru",
      icon: icon({ name: "chalkboard-user" }),
      path: "/guru",
    },
  ],
};

export const PAGE_LIMIT = [
  {
    value: 5,
    label: "5",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 20,
    label: "20",
  },
  {
    value: 30,
    label: "30",
  },
  {
    value: 40,
    label: "40",
  },
  {
    value: 50,
    label: "50",
  },
];

type Difficulty = {
  [key: number]: string;
};

export const QUESTION_DIFFICULTY: Difficulty = {
  1: "MUDAH",
  2: "SEDANG",
  3: "SULIT",
};

type STATUS = {
  [status: string]: {
    label: string;
    color: BadgeColor;
  };
};

export const FILE_STATUS: STATUS = {
  failed: {
    label: "GAGAL",
    color: "error",
  },
  in_process: {
    label: "DIPROSES",
    color: "primary",
  },
  unprocessed: {
    label: "BELUM DIPROSES",
    color: "citrine",
  },
  success: {
    label: "SUKSES",
    color: "success",
  },
};

export const FILE_TYPE = [
  {
    label: "User",
    value: "siswa",
    description: "Tambahkan user siswa secara masal",
    sampleUrl: process.env.REACT_APP_SAMPLE_UPLOAD_STUDENT || "",
  },
  {
    label: "Layanan program",
    value: "langganan",
    description: "Tambahkan langganan program bagi siswa secara masal",
    sampleUrl: process.env.REACT_APP_SAMPLE_UPLOAD_SUBSCRIPTION || "",
  },
  {
    label: "Soal",
    value: "soal",
    description: "Tambahkan soal secara masal",
    sampleUrl: process.env.REACT_APP_SAMPLE_UPLOAD_QUESTION || "",
  },
  {
    label: "User sekolah",
    value: "school_users",
    description: "Tambahkan daftar guru secara massal",
    sampleUrl: process.env.REACT_APP_SAMPLE_UPLOAD_SCHOOL_USERS || "",
  },
];

export const PROGRAM_TYPE = {
  learn: "E-learning",
  class: "Live class",
  tryout: "Try out",
  ask_homework: "Tanya PR",
};

export const PROGRAM_CATEGORY = [
  {
    label: "E-learning",
    value: "learn",
    description:
      "Program yang dapat diakses oleh semua orang yang ada di Guruku tanpa harus membayar",
    disabled: false,
  },
  {
    label: "Live Class",
    value: "class",
    description:
      "Program yang dapat diakses oleh semua orang yang ada di Guruku tanpa harus membayar",
    disabled: false,
  },
  {
    label: "Try Out",
    value: "tryout",
    description:
      "Program yang dapat diakses oleh semua orang yang ada di Guruku tanpa harus membayar",
    disabled: false,
  },
];

export const PARENTAL_STATUS = [
  {
    id: "father",
    name: "Ayah",
  },
  {
    id: "mother",
    name: "Ibu",
  },
  {
    id: "guardian",
    name: "Wali",
  },
];

export const PROMO_CATEGORY = [
  {
    label: "Promo",
    value: "promo_regular",
    description:
      "Promo akan otomatis memotong harga pada saat pengguna melakukan checkout.",
  },
  {
    label: "Voucher",
    value: "voucher_code",
    description:
      "Voucher akan memotong harga program jika pengguna memasukkan kode voucher pada saat checkout.",
  },
];

export const DEFAULT_PAGINATION: PaginationResponse = {
  current_page: 1,
  per_page: 10,
  last_page: 1,
  from: 1,
  to: 1,
  total: 0,
};

export * from "./api";
