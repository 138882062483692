import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { Fragment, useEffect, useState } from "react";

// import index from "@/scenes/courses";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

type Item = {
  name: string;
  value: string;
};

type Props = {
  id: number;
  label: string;
  menuItems: Item[];
  onChange: (item: Item) => void;
};

const Dropdown: React.FC<Props> = (props) => {
  const { label } = props;
  const [selected, setSelected] = useState(label);

  useEffect(() => {
    setSelected(label);
  }, [label]);

  return (
    <Menu as="div" className="relative inline-block text-left w-full">
      <div>
        <Menu.Button className="inline-flex w-full outline outline-1 outline-stone-200 justify-between gap-x-1.5 bg-white px-3.5 py-2.5 text-sm font-semibold text-gray-900 rounded-lg outline-hover">
          <span className="text-neutral-700 text-[14px] font-medium leading-tight sm:flex hidden">
            {selected}
          </span>
          <ChevronDownIcon
            className="-mr-1 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            {props.menuItems.map((item, index) => (
              <div key={index}>
                <Menu.Item>
                  {({ active }: any) => (
                    <button
                      className={classNames(
                        active ? "bg-gray-100 text-gray-900" : "text-gray-700",
                        "block px-4 py-2 text-sm w-full text-left"
                      )}
                      onClick={() => {
                        setSelected(item.name);
                        props.onChange(item);
                      }}
                    >
                      {item?.name || ""}
                    </button>
                  )}
                </Menu.Item>
              </div>
            ))}
            {/* <form method="POST" action="#">
              <Menu.Item>
                {({ active }: any) => (
                  <button
                    type="submit"
                    className={classNames(
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block w-full px-4 py-2 text-left text-sm',
                    )}
                  >
                    Sign out
                  </button>
                )}
              </Menu.Item>
            </form> */}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default Dropdown;
