import { FC, memo } from "react";
import DetailProgram from "../fragment/DetailProgram";
import ProgramsAndPrice from "../fragment/ProgramsAndPrice";
import Summary from './Summary';

const STEP_STATE = {
  0: <DetailProgram isAdd />,
  1: <ProgramsAndPrice isAdd />,
  2: <Summary />,
};

type Props = {
  step: number;
};

type RenderState = keyof typeof STEP_STATE;

const Steps: FC<Props> = ({ step }) => {
  return STEP_STATE[step as RenderState];
};

export default memo(Steps);
