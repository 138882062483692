/* eslint-disable @typescript-eslint/no-unused-vars */
// React
import { useEffect, useState } from "react"

// API
import { services } from "services"

// Components
import {Card, Content} from "components"

// Utilities
import { KelasProgramDetail} from "../utilities"
import { SaveAsDraft } from "../components/SaveAsDraft"

interface TeachersData {
  id: number;
  name: string;
}
export default function Kelas() {
  const [programData, setProgramData] = useState<any[] | null[]>([])
  const [className, setClassName]=useState("")
  const [classAmount, setClassAmount]=useState<number | null>(0)
  const [teacherChoices, setTeacherChoices]=useState("")
  const [teacherID, setTeacherID] = useState<number[] | null>(null)
  const [teacherChoicesOptions, setTeacherChoicesOptions]=useState<string[]>([]);
  const [selectedTeacherID, setSelectedTeacherID] = useState<number | null>(null);
  // ALERT
  const [classNameAlert, setClassNameAlert]=useState(false)
  const [classAmountAlert, setClassAmountAlert]=useState(false)
  const [teacherChoicesAlert, setTeacherChoicesAlert]=useState(false)
  const [formValid, setFormValid] = useState(true);
  const currentPath = window.location.pathname;
  // Extract the program_id from the current URL
  const programIdMatch = currentPath.match(/\/jadwal-kelas\/(\d+)/);
  const programIdFromURL = programIdMatch ? programIdMatch[1] : null;
  async function GetTeachersOptions() {
    services.get("/dropdown/teacher").then((resp) => {
      const response = resp.data.data
      if (Array.isArray(response)) {
        // Map the response to an array of strings representing the options
        const teacher_name = response.map((teachersName: TeachersData) => teachersName.name);
        const teacher_id = response.map((teachersName: TeachersData) => teachersName.id);
        setTeacherChoicesOptions(teacher_name);
        setTeacherID(teacher_id)
      }
    }).catch(() => {})
  }
console.log('teacher', teacherChoicesOptions)
  useEffect(()=>{
    GetTeachersOptions()
  },[])

  const handleClassNameInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setClassName(e.target.value);
    setClassNameAlert(false)
    setFormValid(true);
  };

  function handleClassAmount(event:any) {
    const value = event.target.value;
    if (/^\d*\.?\d*$/.test(value)) {
      setClassAmount(value === '' ? 0 : parseFloat(value));
    }
  }

  const handleTeacherOptions = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedTeacher = event.target.value;
    setTeacherChoices(selectedTeacher);
    
    if (!selectedTeacher || selectedTeacher === "Pilih Guru") {
      setTeacherChoicesAlert(true);
      setTimeout(() => {
        setTeacherChoicesAlert(false);
      }, 3000);
    }
    
    if (teacherID) {
      const selectedTeacherIndex = teacherChoicesOptions.indexOf(selectedTeacher);
      if (selectedTeacherIndex !== -1) {
        setSelectedTeacherID(teacherID[selectedTeacherIndex]);
      } else {
        setSelectedTeacherID(null);
      }
    }
  };

  // const handleTeacherOptions = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   const selectedTeacher = event.target.value
  //   setTeacherChoices(selectedTeacher);
  //   if(!selectedTeacher || selectedTeacher === "Pilih Guru"){
  //     setTeacherChoicesAlert(true)
  //     setTimeout(() => {
  //       setTeacherChoicesAlert(false);
  //     }, 3000);
  //   }
  // };
 
  function handleBack(){
    window.location.href = '/program/silabus'
  }
  const handleNext = async (e: React.MouseEvent<HTMLButtonElement>) => { 
    e.preventDefault();

    let valid = true;

    if(className.trim() === ''){
      setClassNameAlert(true);
      setTimeout(() => {
        setClassNameAlert(false);
      }, 3000);
      valid = false;
    }
    if(classAmount === 0){
      setClassAmountAlert(true);
      setTimeout(() => {
        setClassAmountAlert(false);
      }, 3000);
      valid = false;
    }
    if(!teacherChoices || teacherChoices === "Pilih Guru"){
      setTeacherChoicesAlert(true)
      setTimeout(() => {
        setTeacherChoicesAlert(false);
      }, 3000);
      valid = false;
    }
    if (!valid) {
      setFormValid(false);
      setTimeout(() => {
        setFormValid(true);
      }, 3000);
      return;
    } else {
      try {
      const existingData = localStorage.getItem('list-program-data');
      let dataArray: any[] = [];
  
      if (existingData) {
        dataArray = JSON.parse(existingData);
      }
      
      // Get the latest data (last item in the array)
      const latestData = dataArray[dataArray.length - 1];
      if (latestData) {
        const data = {
        program_id:latestData.program_id,
        grade_id: latestData.grade_id ,
        major_id: latestData.major_id,
        syllabus_id: latestData.syllabus_id,
        subject_id:latestData.subject_id,
        teacher_id: selectedTeacherID,
        title:latestData.title,
        thumbnail_path: latestData.thumbnail_path,
        price: latestData.price,
        discount: latestData.discount,
        is_promo: latestData.is_promo,
        is_active: latestData.is_active,
        is_flat_disc: latestData.is_flat_disc,
        type: latestData.type,
        is_draft: latestData.is_draft,
        type_detail: {
          register_until:latestData.type_detail.register_until,
          started_at:latestData.type_detail.started_at,
          max_students: latestData.type_detail.max_students,
          meet_time: latestData.type_detail.meet_time
        }
      };
      // if (dataArray.length > 0) {
      //   dataArray[0] = data; // Replace the data at index 0 with the new 'data'
      // }
      // if (dataArray.length > 0) {
      //   dataArray[dataArray.length - 1] = data; // Replace the data at the last index with the new 'data'
      // }
      if (data.program_id === null) {
        dataArray.push(data); // Push the new 'data' to the end of the array
      } else {
        const programIndex = dataArray.findIndex((item) => item.program_id === data.program_id);
      
        if (programIndex !== -1) {
          // Replace the data at the selected index
          dataArray[programIndex] = data;
        } else {
          // Add the new 'data' to the end of the array
          dataArray.push(data);
        }
      }
      localStorage.setItem('list-program-data', JSON.stringify(dataArray));
      const resp = await services.post('/program', data);
      if(resp.data.success !== false){
      const silabusUrl = programIdFromURL ? `/program/jadwal-kelas/${programIdFromURL}` : '/program/jadwal-kelas';
      
      // Update the window location to navigate to the silabus page
      window.location.href = silabusUrl;
    }else{
      alert('something went wrong')
    }
      // window.location.href = '/program/jadwal-kelas'
    }
      // try {
      //   const data = {
      //     grade_id: 1,
      //     major_id: null,
      //     program_id: null,
      //     branch_id: null,
      //     teacher_id: selectedTeacherID,
      //     name: className,
      //     is_draft: true,
      //     student_ids: [1,2,3],
      //     max_student: null,
      //     schedules: [
      //       {
      //         program_detail_id: null,
      //         teacher_id: selectedTeacherID,
      //         started_at: '2023-09-01 09:00',
      //         finished_at: '2023-09-01 10:00',
      //       },
      //     ],
      //   };
  
      //   // const resp = await services.post('/class', data);
      //   // if(resp.data.success !== false){
      //   //   // window.location.href = '/program/jadwal-kelas'
      //   // }
      //   // else{
      //   //   console.log('wrong')
      //   // }
      } catch (error) {
        console.error('Failed to create program', error);
      }
    // window.location.href = '/program/jadwal-kelas'

    }
  }
  
  const saveToLocalStorage = () => {
    const initialClassData = {
      grade_id: 1,
      major_id: null,
      program_id: null,
      branch_id: null,
      teacher_id: selectedTeacherID,
      name: className,
      is_draft: true,
      student_ids: [1, 2, 3],
      max_student: null,
      schedules: [
        {
          program_detail_id: null,
          teacher_id: teacherID,
          started_at: '2023-09-01 09:00',
          finished_at: '2023-09-01 10:00',
        },
      ],
    };
  
    localStorage.setItem('savedData', JSON.stringify(initialClassData));
  };
  
  return (
    <Content>
      <Content.Header
        title={`${KelasProgramDetail.title} (3/4)`}
        rightElement={<SaveAsDraft onSave={saveToLocalStorage}/>}
      />

      <Content.Body>
        <Card>
          {/* <Card.Header>
            <div className="flex flex-col md:flex-row justify-center md:justify-between items-center text-black">
              <Card.Title>Daftar {programDetailData.title} (1/4)</Card.Title>

              <Select
                options={[
                  { label: "Terakhir dibuat" }
                ]}
              />
            </div>
          </Card.Header> */}

          <Card.Body>
            <div className="flex flex-col w-full text-black gap-8">
                <div className="flex flex-col min-w-full">
                    <label>Nama Kelas*</label>
                    <input value={className} onChange={handleClassNameInput} type="text" placeholder="Masukan nama kelas" className="bg-white h-[36px] border-b-[1px] border-b-gray-500 focus:border-b-[1px] focus:border-b-[#FFF200]"/>
                    {classNameAlert && <><label className="text-red-600">Mohon isi nama kelas terlebih dahulu!</label></>}
                </div>
                <div className="flex flex-row gap-8 w-full">
                <div className="flex flex-col w-full">
                    <label>Jumlah Kelas*</label>
                    <input value={classAmount === null ? '' : classAmount} onChange={handleClassAmount} type="text" placeholder="Masukan jumlah kelas" className="bg-white h-[36px] border-b-[1px] border-b-gray-500 focus:border-b-[1px] focus:border-b-[#FFF200]"/>
                    {classAmountAlert && <><label className="text-red-600">Mohon isi jumlah kelas terlebih dahulu!</label></>}
                </div>

                        <div className="flex flex-col w-full">
                            <label>Pilih Guru*</label>
                            <select value={teacherChoices} onChange={handleTeacherOptions} className="bg-white h-[36px] border-b-[1px] border-b-gray-500 focus:border-b-[1px] focus:border-b-[#FFF200]"><option value="" disabled selected>
                            Pilih Guru
                                </option>
                                {/* <option value="lokasi1">Guru 1</option>
                                <option value="lokasi2">Guru 2</option>
                                <option value="lokasi3">Guru 3</option>  */}
                                {teacherChoicesOptions.map((option) => (
                                    <option key={option} value={option}>
                                      {option}
                                    </option>
                                  ))}
                                </select>
                                {teacherChoicesAlert && <><label className="text-red-600">Mohon pilih guru terlebih dahulu!</label></>}
                        </div>
                </div>
                <div className="flex flex-row w-full justify-end items-center gap-4">
                    <button onClick={handleBack} className="px-[20px] py-[10px] text-[#8C8500] bg-[#FFFBB0] rounded-[4px]">Kembali</button>
                    <button onClick={handleNext} className="px-[20px] py-[10px] text-black bg-[#FFF200] rounded-[4px]">Lanjutkan</button>
                </div>
            </div>
          </Card.Body>
        </Card>
      </Content.Body>
    </Content>
  )
}