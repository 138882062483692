import Breadcrumbs from "components/Breadcrumbs";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useMemo, useState } from "react";
import useStudent from "services/useStudent";
import toast from "react-hot-toast";
import Info from "./fragment/Detail/Information";
import GeneralInfo from "./fragment/Detail/GeneralInfo";
import PurchaseHistory from "./fragment/Detail/PurchaseHistory";
import Performance from "./fragment/Detail/Performance";

const TABS = [
  { id: "general", label: "Informasi umum", component: GeneralInfo },
  {
    id: "purchases",
    label: "Riwayat pembelian",
    component: PurchaseHistory,
  },
  { id: "performance", label: "Prestasi", component: Performance },
] as const;

type TabId = (typeof TABS)[number]["id"];

const Detail = () => {
  const { getDetail } = useStudent();
  const navigate = useNavigate();
  const location = useLocation();

  const pathId = location.pathname.split("/siswa/")[1];

  const [tab, setTab] = useState<TabId>("general");
  const [detail, setDetail] = useState<StudentData | null>(null);

  useEffect(() => {
    getDetail(pathId)
      .then((resp) => setDetail(resp))
      .catch(() => {
        toast.error("Data tidak ditemukan");
        navigate("/siswa", {
          relative: "path",
        });
      });
    // eslint-disable-next-line
  }, [pathId]);

  const handleCrumb = (item: Crumb) => {
    navigate("/siswa", {
      replace: true,
    });
  };

  const handleTab = (id: TabId) => {
    setTab(id);
  };

  const ActiveTab = useMemo(() => {
    return TABS.find((t) => t.id === tab)?.component || TABS[0].component;
  }, [tab]);

  return (
    <div className="space-y-5">
      <Breadcrumbs crumbs={["Siswa", "Detail siswa"]} onClick={handleCrumb} />
      <div className="flex gap-x-8">
        <Info data={detail} />
        <div className="w-full">
          <div className="flex gap-x-2 mb-8">
            {TABS.map((val) => (
              <button
                key={val.id}
                onClick={() => handleTab(val.id)}
                className={`font-semibold px-3 py-2 rounded-md hover:bg-yellow-100 ${
                  val.id === tab
                    ? "text-[#B5A300] bg-yellow-100"
                    : "text-gray-500"
                }`}
              >
                {val.label}
              </button>
            ))}
          </div>
          <ActiveTab summary={detail} />
        </div>
      </div>
    </div>
  );
};

export default Detail;
