import {memo, FC, useState, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro';
import {Link} from 'react-router-dom';
import Modal from 'components/Modal';
import {getFormatDate} from 'utilities';
import { Badge } from 'components';

type Props = {
  show: boolean
  data: ModuleDetail | null
  onClose: () => void
}

const Module: FC<Props> = ({show, data, onClose}) => {
  const [crumbs, setCrumbs] = useState<string[]>(['Modul']);

  useEffect(() => {
    if (data?.directory) {
      const dirMap = data.directory.split('/');

      setCrumbs([...crumbs, ...dirMap]);
    }
  }, [data]);

  useEffect(() => {
    if (!show) {
      setCrumbs(['Modul'])
    }
  }, [show]);

  return (
    <Modal
      show={show}
      onClose={onClose}
      title='Info modul'
    >
      <div className='mb-8'>
        <p className='font-bold text-sm'>Direktori</p>
        <div className="breadcrumbs text-sm pl-0 mt-1">
          <ul>
            <li>
              <FontAwesomeIcon icon={icon({name: 'house'})} />
            </li>
            {crumbs.map((item, index) => {
              const isLast = index === crumbs.length - 1;

              return (
                <li key={item}>
                  <span className={`cursor-default ${isLast ? 'font-semibold text-gray-800' : 'font-normal'}`}>{item}</span>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      <div className='mb-8'>
        <p className='font-bold text-sm'>Judul modul</p>
        <p className='text-base text-gray-500'>{data?.name}</p>
      </div>
      <div className='mb-8'>
        <p className='font-bold text-sm'>Deskripsi modul</p>
        <p className='text-base text-gray-500' dangerouslySetInnerHTML={{__html: data?.description || ''}} />
      </div>
      <div className='grid grid-flow-col mb-8'>
        <div>
          <p className='font-bold text-sm'>Tanggal dibuat</p>
          <p className='text-base text-gray-500'>
            {getFormatDate(String(data?.created_at), 'DD MMM YYYY')}
          </p>
        </div>
        <div>
          <p className='font-bold text-sm'>Tanggal diperbarui</p>
          <p className='text-base text-gray-500'>
            {getFormatDate(String(data?.updated_at), 'DD MMM YYYY')}
          </p>
        </div>
      </div>
      <p className='font-bold text-sm mb-1'>Materi</p>
      {data?.assets.map((item) => (
        <div key={item.id} className='px-4 py-2 bg-gray-200 flex gap-x-2 items-center'>
          <FontAwesomeIcon icon={icon({name: 'file-invoice'})} />
          <Link
            to={item.full_asset}
            target='_blank'
            rel='noreferrer'
            download={item.name}
            className='text-base text-blue-400'
          >
            {item.name}
          </Link>
        </div>
      ))}
      {data?.question_package?.id && (
        <>
        <p className='font-bold text-sm mb-1 mt-5'>Soal</p>
        <div className='py-4 pl-3 pr-5 flex justify-between'>
          <div className='flex gap-x-2 items-center'>
            <FontAwesomeIcon icon={icon({name: 'file-invoice'})} />
            <p className='text-gray-800'>{data?.question_package?.name}</p>
          </div>
          <div className='flex items-center gap-x-2'>
            <Badge color='blue'>
              {`${data?.question_package?.n_multiple_choice} PG`}
            </Badge>
            <Badge color='success'>
              {`${data?.question_package?.n_essay} essay`}
            </Badge>
          </div>
        </div>
        </>
      )}
    </Modal>
  )
}

export default memo(Module);
