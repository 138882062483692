import {memo, FC, useState, ChangeEvent, useEffect} from 'react';
import Modal from 'components/Modal';
import {Button} from 'components';

import useAsset from 'services/useAsset';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';


type Props = {
  show: boolean
  directoryId?: string
  onClose: () => void
  onSubmit: (data: LocalAsset[]) => void
}

const AddModule: FC<Props> = (props) => {
  const {show, directoryId, onClose, onSubmit} = props;
  const {upload} = useAsset();

  const [file, setFile] = useState<File | null>(null);
  const [progress, setProgress] = useState(0);
  const [uploaded, setUploaded] = useState('');
  const [disabled, setDisabled] = useState(true);
  const [data, setData] = useState('');

  useEffect(() => {
    let localAsset = localStorage.getItem('assets');
    if (!localAsset) {
      localAsset = JSON.stringify({
        directoryId
      });
    };

    if (show) {
      setFile(null);
      setDisabled(true);
      setData(localAsset);
    } else {
      setData('');
    }
  }, [directoryId, show]);

  const handleSubmit = async () => {
    if (data) {
      localStorage.setItem('assets', data);
      let listAsset = JSON.parse(data);

      onSubmit(listAsset?.data || []);
      onClose();
    }
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const currentFile = e.target.files?.[0];

    if (currentFile && directoryId) {
      setFile(currentFile);
      const response = await upload({
        directory_id : parseInt(`${directoryId}`),
        file: currentFile,
        onUploadProgress: ({loaded, total}) => {
          const uploadSize = loaded / (total || currentFile.size);
          let currentProgress = uploadSize * 100;
          if (currentProgress > 100) {
            currentProgress = 100;
          };
          const sizeKB = loaded / 1000;
          let size = `${sizeKB.toFixed()}KB`;

          if (sizeKB > 1000) {
            const sizeMB = sizeKB / 1000;
            size = `${sizeMB.toFixed()}MB`;
          }

          setProgress(Number(currentProgress.toFixed(2)));
          setUploaded(size);
        }
      });

      if (response) {
        setDisabled(false);
        let currentData = JSON.parse(data || '{}');

        currentData = {
          ...currentData,
          data: [
            ...currentData?.data || [],
            {
              id: response.id,
              name: currentFile.name,
              url: response.full_asset,
              size: currentFile.size
            }
          ]
        };
        
        currentData = JSON.stringify(currentData);
        setData(currentData);
      } else {
        setFile(null);
      }
    }
  };

  return (
    <Modal
      show={show}
      title='Tambah modul'
      onClose={onClose}
      customFooter={(
        <div className="float-right flex gap-x-2">
          <Button.Cancel onButtonClick={onClose} />
          <Button color='primary' onButtonClick={handleSubmit} isDisabled={disabled}>
            Tambah
          </Button>
        </div>
      )}
    >
      {Boolean(file) ? (
        <div className='px-5 py-3 rounded-[0.250rem] bg-gray-100'>
          <div className='flex gap-x-2 items-center mb-2'>
            <FontAwesomeIcon icon={icon({name: 'file-invoice'})} size='3x' />
            <div className='w-full '>
              <p className='text-gray-800 text-sm mb-1'>{file?.name}</p>
              <div className='flex justify-between'>
                <p className='text-gray-400 text-xs'>{`${uploaded}`}</p>
                <p className='text-gray-400 text-xs'>{`${progress}%`}</p>
              </div>
            </div>
          </div>
          <div className='h-1 w-full bg-white rounded-[1.125rem] overflow-hidden'>
            <div
              className='h-1 bg-blue-400 rounded-[1.125rem]'
              style={{width: `${progress}%`}}
            />
          </div>
        </div>
      ) : (
        <div className='border-dashed border py-8 text-center mb-5'>
          <FontAwesomeIcon icon={icon({name: 'cloud-arrow-up'})} size='2x' />
          <p className='mt-4'>
            <input
              type='file'
              id='addModule'
              className='hidden'
              accept='image/*, .pdf, video/*'
              onChange={handleFileChange}
            />
            <label
              htmlFor='addModule'
              className='text-blue-500 cursor-pointer'
            >
              Pilih file
            </label>
            {' yang akan di unggah.'}
          </p>
          <p className='italic text-sm text-gray-400'>Format file: jpg, png, pdf, mp4</p>
        </div>
      )}
    </Modal>
  )
}

export default memo(AddModule);
