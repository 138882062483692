import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input, Label, TextArea, UploadImage } from "components";
import { FC, memo, useEffect, useState } from "react";

type Props = {
  data: Partial<TryoutPayload>;
  canEdit?: boolean;
  onSave?: (data: Partial<TryoutPayload>) => void;
};

const Info: FC<Props> = (props) => {
  const { canEdit = false, data, onSave } = props;
  const [isEdit, setIsEdit] = useState(false);
  const [title, setTitle] = useState(data.title);
  const [description, setDescription] = useState(data?.description || "");
  const [thumbnail, setThumbnail] = useState(data?.thumbnail_path || "");

  useEffect(() => {
    setTitle(data.title);
    setDescription(data?.description || "");
    setThumbnail(data?.thumbnail_path || "");
  }, [data]);

  const toggleEdit = () => setIsEdit(!isEdit);

  const onCancel = () => {
    setTitle(data.title);
    setDescription(data?.description || "");
    setThumbnail(data?.thumbnail_path || "");
    toggleEdit();
  };

  const handleSave = () => {
    if (onSave && canEdit) {
      onSave({
        description,
        thumbnail_path: thumbnail,
        title,
      });
    }
    toggleEdit();
  };

  return (
    <div className="flex-[55%] bg-white rounded-xl">
      <div className="flex items-center justify-between px-5 py-4 border-b">
        <p className="font-bold">Informasi program</p>
        {canEdit &&
          (isEdit ? (
            <div className="flex items-center gap-x-2">
              <FontAwesomeIcon
                color="#F04438"
                icon={icon({ name: "close" })}
                className="cursor-pointer"
                onClick={onCancel}
              />
              <FontAwesomeIcon
                color="#17B26A"
                icon={icon({ name: "floppy-disk", style: "regular" })}
                className="cursor-pointer"
                onClick={handleSave}
              />
            </div>
          ) : (
            <FontAwesomeIcon
              color="#B5A300"
              icon={icon({ name: "edit" })}
              className="cursor-pointer"
              onClick={toggleEdit}
            />
          ))}
      </div>
      <div className="p-5 grid gap-y-4">
        <div>
          <p className="text-gray-500 font-medium">Judul program</p>
          {isEdit ? (
            <Input
              label=""
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          ) : (
            <p>{title}</p>
          )}
        </div>
        <div>
          <p className="text-gray-500 font-medium">Deskripsi</p>
          {isEdit ? (
            <TextArea
              label=""
              isRichText
              value={description}
              onChange={(val) => setDescription(val)}
            />
          ) : (
            <p dangerouslySetInnerHTML={{ __html: description }} />
          )}
        </div>
        <div>
          <Label>Thumbnail</Label>
          <UploadImage
            imgSrc={thumbnail}
            onUpload={(data) => setThumbnail(data?.full_asset || "")}
            onDelete={isEdit ? () => setThumbnail("") : null}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(Info);
