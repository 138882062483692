import { FC, memo, useCallback, useEffect, useState } from "react";
import { InView } from "react-intersection-observer";
import Modal from "components/Modal";
import useProgram from "services/useProgram";
import { Badge, Button, Input, Spinner } from "components";
import { PROGRAM_TYPE, DEFAULT_PAGINATION } from "constant";
import { getProgramColor, useDebounce } from "utilities";
import GradeOption from "../GradeOption";
import TypeOption from "../TypeOption";

type Props = {
  show: boolean;
  onClose: () => void;
  onAdd: (items: ProgramItem[]) => void;
  listSelected: ProgramItem[];
};

const Programs: FC<Props> = ({ show, onClose, onAdd, listSelected }) => {
  const { getList } = useProgram();

  const [list, setList] = useState<ProgramItem[]>([]);
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [keyword, setKeyword] = useState("");
  const [selected, setSelected] = useState<ProgramItem[]>([]);

  const debounceSearch = useDebounce(keyword);

  const getData = useCallback(
    async (params?: ProgramParams, loadMore?: boolean) => {
      const response = await getList(params);
      const { data, ...restResponse } = response;

      if (loadMore) {
        setList([...list, ...data]);
      } else {
        setList(data);
      }

      setPagination(restResponse);
    },
    [list]
  );

  useEffect(() => {
    if (debounceSearch.length >= 3) {
      getData({
        keyword,
        page: 1,
      });
    }

    if (!debounceSearch.length) {
      getData({
        keyword: "",
        page: 1,
      });
    }
  }, [debounceSearch]);

  useEffect(() => {
    setSelected(listSelected);
  }, [listSelected]);

  const loadMore = () => {
    getData(
      {
        page: pagination?.current_page + 1,
      },
      true
    );
  };

  const handleGrade = (value: GradeItem) => {
    getData({
      page: 1,
      grade_id: value.id || null,
    });
  };

  const handleType = (value: ProgramType) => {
    getData({
      page: 1,
      type: value,
    });
  };

  const handleClose = () => {
    getData({
      keyword: "",
      page: 1,
      grade_id: null,
      type: null,
    });
    onClose();
  };

  const handleCheck = (item: ProgramItem) => {
    let tempSelected = [...selected];
    const isExisted = selected.find((val) => val.id === item.id);

    if (isExisted) {
      tempSelected = selected.filter((val) => val.id !== item.id);
    } else {
      tempSelected = [...selected, item];
    }

    setSelected(tempSelected);
  };

  const handleAddProgram = () => {
    onAdd(selected);
    handleClose();
  };

  return (
    <Modal
      title={
        <>
          <p className="font-bold text-xl">Pilih Program</p>
          <div className="flex gap-x-2 mt-4 font-normal">
            <GradeOption onChange={handleGrade} />
            <TypeOption onChange={handleType} />
            <Input.Search
              placeholder="Cari program"
              onChange={(e) => setKeyword(e.target.value)}
              value={keyword}
            />
          </div>
        </>
      }
      show={show}
      onClose={handleClose}
      customFooter={
        <div className="flex justify-end gap-x-2">
          <Button.Cancel onButtonClick={handleClose} />
          <Button color="primary" onButtonClick={handleAddProgram}>
            Tambah
          </Button>
        </div>
      }
    >
      <>
        {list.map((item) => (
          <label
            key={item.id}
            className="flex items-center justify-between py-4 border-b hover:bg-gray-50"
          >
            <div className="space-x-4">
              <input
                type="checkbox"
                id={`program-${item.id}`}
                className="checkbox checked:bg-yellow-400 checked:border-primary text-gray-500"
                onChange={() => handleCheck(item)}
                checked={
                  Boolean(selected.find((val) => val.id === item.id)) || false
                }
              />
              <span>{item.title}</span>
            </div>
            <Badge color={getProgramColor(item.type)}>
              {PROGRAM_TYPE[item.type].toUpperCase()}
            </Badge>
          </label>
        ))}
        {pagination.current_page < pagination.last_page && (
          <InView
            as="div"
            onChange={(inView) => {
              if (inView) {
                loadMore();
              }
            }}
          >
            <Spinner />
          </InView>
        )}
      </>
    </Modal>
  );
};

export default memo(Programs);
