// React
import { Fragment, useState } from "react";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";

// Components
import { Button, Icon, Modal } from "components";

// Utilities
import { baseData } from "../utilities";
import { services } from "services";
import { ErrorFetch } from "types";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Title is required"),
});

export function CreateSection(props: { directory_id?: null | number }) {
  // Hooks
  const [isOpen, setOpen] = useState(false);

  const toggle = () => setOpen((prev) => !prev);

  return (
    <Fragment>
      <Button.Create
        isDropdown
        items={[
          {
            title: (
              <div className="flex flex-wrap">
                <Icon.Folder className="w-4 mr-1 fill-primary" />
                <span>Tambah Folder</span>
              </div>
            ),
            onItemClick: toggle,
          },
          {
            linkTo: `${baseData.url}/tambah`,
            state: { directory_id: props.directory_id },
            title: (
              <div className="flex flex-wrap">
                <Icon.Cash className="w-4 mr-1 fill-primary" />
                <span>Tambah Soal</span>
              </div>
            ),
          },
        ]}
      />

      {isOpen && (
        <>
          <Formik
            initialValues={{ name: "" }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
              const data = {
                name: values.name,
                parent_id: props.directory_id,
              };
              services
                .post("/directory", data)
                .then((resp) => {
                  alert("Success");
                })
                .catch((error: ErrorFetch) => {
                  alert(error.response.data.message);
                });
              // Handle form submission here
            }}
          >
            {({ values, handleChange, submitForm, errors }) => (
              <Modal isOpen title="Tambah Folder" closeModal={toggle}>
                <Form>
                  <Modal.Body className="flex gap-4 w-full ">
                    <form className="flex flex-col gap-4 w-full">
                      <Field
                        label="Nama Folder*"
                        name="name"
                        placeholder="Masukkan nama folder"
                        className={
                          errors.name &&
                          "outline-none border-b  border-rose-400 placeholder:text-rose-400  mt-3 pb-2 w-full"
                        }
                        value={values.name}
                        onChange={handleChange}
                      />
                    </form>
                  </Modal.Body>
                  <Modal.Footer className="flex justify-end gap-x-2">
                    <button
                      type="submit"
                      className="bg-[#FFF200] text-sm font-medium text-[#38393A] rounded-xl w-[126px] h-[40px] flex items-center justify-center shrink-0"
                    >
                      Buat folder
                    </button>
                    <Button
                      type="button"
                      color="outline-error"
                      onButtonClick={toggle}
                    >
                      Batal
                    </Button>
                  </Modal.Footer>
                </Form>
              </Modal>
            )}
          </Formik>
        </>
      )}
    </Fragment>
  );
}
