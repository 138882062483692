import {FC, memo,} from 'react';
import {Controller, useFormContext, useWatch} from 'react-hook-form';
import Breadcrumbs from 'components/Breadcrumbs';
import { Input, Radio } from 'components';

const DetailQuestion: FC = () => {
  const {getValues, control} = useFormContext();
  const testCount = useWatch({control, name: 'question_package.test_count'});
  const questionPackage = getValues('question_package');
  let directories: string[] = getValues('directories');
  directories = ['Soal', ...directories];

  const crumbs: Crumb[] = directories.map((val, index) => ({
    id: index,
    label: val
  }));


  return (
    <section>
      <p className="font-bold text-sm mb-2">Direktori</p>
      <Breadcrumbs crumbs={crumbs} />
      <p className="font-bold text-sm mb-2">Detail soal</p>
      <div className="grid grid-flow-col auto-cols-max gap-x-2 mb-8">
        <div className="grid gap-y-2 items-center">
          <p className="text-sm flex justify-between gap-x-2">
            Nama soal
            <span>:</span>
          </p>
          <p className="text-sm flex justify-between gap-x-2">
            Pilihan ganda
            <span>:</span>
          </p>
          <p className="text-sm flex justify-between gap-x-2">
            Essay
            <span>:</span>
          </p>
        </div>
        <div className="grid gap-y-2 items-center">
          <p className="font-bold text-sm">
            {questionPackage?.name}
          </p>
          <p className="font-bold text-sm">
            {`${questionPackage?.total_n_multiple_choice} soal`}
          </p>
          <p className="font-bold text-sm">
            {`${questionPackage?.total_n_essay} soal`}
          </p>
        </div>
      </div>
      <p className='mb-3'>Masukkan jumlah soal yang ingin anda berikan kepada siswa.</p>
      <Controller
        name="question_package.test_count"
        control={control}
        render={method => (
          <div className="flex flex-col gap-3">
            <Radio
              readOnly
              label="Pilih semua soal"
              checked={Boolean(method.field.value === "all")}
              onClick={() => method.field.onChange("all")}
            />

            <Radio
              readOnly
              label="Atur jumlah soal"
              checked={Boolean(method.field.value === "part")}
              onClick={() => method.field.onChange("part")}
            />
          </div>
        )}
      />
      {testCount === 'part' && (
        <div className="grid grid-flow-col grid-cols-2 gap-x-8 mt-3 ml-6">
          <Controller
            control={control}
            name="question_package.n_multiple_choice"
            render={method => {
              return (
                <div>
                  <Input
                    {...method.field}
                    ref={null}
                    type="number"
                    label="Pilihan ganda"
                    min={0}
                    max={questionPackage?.total_n_multiple_choice}
                    disabled={questionPackage?.total_n_multiple_choice === 0}
                  />
                </div>
              )
            }}
          />

          <Controller
            control={control}
            name="question_package.n_essay"
            render={method => {
              return (
                <div>
                  <Input
                    {...method.field}
                    value={questionPackage.n_essay}
                    ref={null}
                    type="number"
                    label="Essay"
                    min={0}
                    max={questionPackage?.total_n_essay}
                    disabled={questionPackage?.total_n_essay === 0}
                  />
                </div>
              )
            }}
          />
        </div>
      )}
    </section>
  );
};

export default memo(DetailQuestion);
