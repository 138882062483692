import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "components";
import Table from "components/Table";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useTeacher from "services/useTeacher";
import { useDebounce } from "utilities";
import DeletePrompt from "./fragment/Modal/DeletePrompt";
import toast from "react-hot-toast";
import Breadcrumbs from "components/Breadcrumbs";

const columns = [
  {
    title: "Nama",
    isSort: true,
    key: "name",
  },
  {
    title: "Email",
    isSort: true,
    key: "email",
  },
  {
    title: "Nomor HP",
    isSort: true,
    key: "phone_number",
  },
  {
    title: "",
  },
];

const defaultPagination: PaginationResponse = {
  current_page: 1,
  per_page: 10,
  last_page: 1,
  from: 1,
  to: 1,
  total: 1,
};

type RequestParams = {
  per_page: number;
  search: string;
  order_by: "name" | "phone_number" | "email";
  sort: "asc" | "desc";
  page: number;
};

const Teacher = () => {
  const navigate = useNavigate();
  const { getList, deleteTeacher } = useTeacher();

  const [search, setSearch] = useState("");
  const [data, setData] = useState<User[]>([]);
  const [pagination, setPagination] = useState(defaultPagination);
  const [orderBy, setOrderBy] = useState<RequestParams["order_by"]>("name");
  const [sort, setSort] = useState<RequestParams["sort"]>("asc");
  const [selectedItem, setSelectedItem] = useState<User | null>(null);
  const [showDelete, setShowDelete] = useState(false);
  const [loading, setLoading] = useState(false);

  const debounceSearch = useDebounce(search);

  const toggleDelete = () => setShowDelete(!showDelete);

  const getData = useCallback(async (params?: RequestParams) => {
    setLoading(true);
    const response = await getList(params);
    const { data: respData, ...restResponse } = response;

    setData(respData);
    setPagination(restResponse);
    setLoading(false);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (debounceSearch.length >= 3) {
      getData({
        search: debounceSearch,
        per_page: pagination.per_page,
        order_by: orderBy,
        sort,
        page: 1,
      });
    } else if (!debounceSearch.length) {
      getData();
    }
    // eslint-disable-next-line
  }, [debounceSearch]);

  const handleLimit = (limit: string) => {
    getData({
      per_page: Number(limit),
      page: 1,
      search,
      sort,
      order_by: orderBy,
    });
  };

  const handlePagination = (control: string) => {
    const isNext = control === "next";
    const page = isNext
      ? pagination.current_page + 1
      : pagination.current_page - 1;

    if (page > 0 && page <= pagination.last_page) {
      getData({
        per_page: pagination.per_page,
        page,
        search,
        sort,
        order_by: orderBy,
      });
    }
  };

  const handleSort = (param: string) => {
    let direction = sort === "asc" ? "desc" : "asc";

    if (param !== orderBy) {
      direction = "asc";
    }

    setSort(direction as RequestParams["sort"]);
    setOrderBy(param as RequestParams["order_by"]);
    getData({
      page: 1,
      order_by: param as RequestParams["order_by"],
      sort: direction as RequestParams["sort"],
      search,
      per_page: pagination.per_page,
    });
  };

  const handleDelete = () => {
    if (selectedItem) {
      toast.promise(deleteTeacher(String(selectedItem?.id)), {
        loading: `Menghapus ${selectedItem.name}`,
        success: () => {
          toggleDelete();
          setSearch("");
          getData();
          return `${selectedItem.name} berhasil dihapus`;
        },
        error: "Gagal menghapus",
      });
    }
  };

  return (
    <>
      <Breadcrumbs crumbs={["Guru"]} />
      <div className="my-5 flex items-center justify-between">
        <div className="bg-white border rounded-lg px-3.5 py-2 flex items-center w-80">
          <FontAwesomeIcon icon={icon({ name: "search" })} />
          <input
            type="search"
            placeholder="Cari guru..."
            className="ml-2 text-sm w-full"
            onChange={(e) => setSearch(e.target.value)}
          />
        </div>
        <Button.Create
          onButtonClick={() =>
            navigate("tambah", {
              relative: "path",
            })
          }
        />
      </div>
      <Table
        data={data}
        columns={columns}
        currentLimit={pagination.per_page}
        currentPage={pagination.current_page}
        total={pagination.total}
        handleLimit={handleLimit}
        handlePagination={handlePagination}
        handleSort={handleSort}
        loading={loading}
      >
        {data.map((val) => (
          <tr key={val.id} className="border-b">
            <td className="p-4">{val.name}</td>
            <td className="p-4 text-gray-500">{val.email}</td>
            <td className="p-4 text-gray-500">{val.phone_number}</td>
            <td className="p-4">
              <div className="flex items-center gap-x-4">
                <FontAwesomeIcon
                  icon={icon({ name: "pen" })}
                  className="cursor-pointer"
                  onClick={() =>
                    navigate(String(val.id), {
                      relative: "path",
                    })
                  }
                />
                <FontAwesomeIcon
                  icon={icon({ name: "trash-alt" })}
                  className="cursor-pointer"
                  onClick={() => {
                    setSelectedItem(val);
                    toggleDelete();
                  }}
                />
              </div>
            </td>
          </tr>
        ))}
      </Table>
      <DeletePrompt
        show={showDelete}
        onClose={toggleDelete}
        onDelete={handleDelete}
      />
    </>
  );
};

export default Teacher;
