import {FC, memo} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro';

type Props = {
  crumbs: Crumb[] | string[]
  onClick?: (item: Crumb) => void
}

const Breadcrumbs: FC<Props> = ({crumbs, onClick}) => {
  const handleClick = (item: Crumb) => {
    if (onClick) {
      onClick(item);
    };
  };

  return (
    <div className={`breadcrumbs text-sm pl-0 ${!onClick ? 'my-1' : 'mb-4'}`}>
      <ul className='flex-wrap'>
        <li>
          <FontAwesomeIcon icon={icon({name: 'house'})} />
        </li>
        {crumbs.map((item, index) => {
          const isLast = index === crumbs.length - 1;
          const isString = typeof item === 'string';

          return (
            <li key={isString ? index : item.id} className={`${onClick ? 'cursor-pointer' : '!cursor-default'} !important`}>
              {isLast || !onClick ? (
                <span className='font-semibold'>{isString ? item : item.label}</span>
              ) : (
                <button
                  onClick={() => handleClick(item as Crumb)}>
                    {isString ? item : item.label}
                </button>
              )}
            </li>
          )
        })}
      </ul>
    </div>
  );
};

export default memo(Breadcrumbs);
