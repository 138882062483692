import {memo, FC} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro';
import Modal from 'components/Modal';
import {getFormatDate} from 'utilities';
import { Badge } from 'components';
import { FILE_STATUS } from 'constant';

type Props = {
  show: boolean
  data: FileInfo | null
  onClose: () => void
}

const Detail: FC<Props> = ({show, data, onClose}) => {
  const hasStatus = data?.status;
  const badgeColor = FILE_STATUS[hasStatus || 'success'].color;

  return (
    <Modal
      show={show}
      onClose={onClose}
      title='Info file'
    >
      <section className='grid gap-y-8'>
        <div>
          <p className='font-bold text-sm'>Nama file</p>
          <p className='text-base text-gray-500'>{data?.file_name}</p>
        </div>
        <div className='grid grid-cols-3 gap-x-5'>
          <div>
            <p className='font-bold text-sm mb-1'>Total data</p>
            <p className='text-base text-gray-500'>
              {data?.total_rows}
            </p>
          </div>
          <div>
            <p className='font-bold text-sm mb-1'>Data diproses</p>
            <p className='text-base text-gray-500'>
              {data?.processed_rows}
            </p>
          </div>
          <div>
            <p className='font-bold text-sm mb-1'>Status</p>
            {hasStatus && (
              <Badge color={badgeColor}>
                {FILE_STATUS[data.status].label}
              </Badge>
            )}
          </div>
        </div>
        <div className='grid grid-cols-3 gap-x-5'>
          <div>
            <p className='font-bold text-sm mb-1'>Tanggal diunggah</p>
            <p className='text-base text-gray-500'>
              {getFormatDate(String(data?.created_at), 'DD MMM YYYY')}
            </p>
          </div>
          <div>
            <p className='font-bold text-sm mb-1'>Pengunggah</p>
            <p className='text-base text-gray-500'>
              {data?.created_by}
            </p>
          </div>
          <div>
            <p className='font-bold text-sm mb-1'>Download hasil</p>
            <button className='flex gap-x-2 items-center' onClick={() => {
              if (data?.processed_file_location) {
                window.open(data?.processed_file_location, '_blank')
              }
            }}>
              <FontAwesomeIcon icon={icon({name: 'cloud-download'})} />
              <span>Download</span>
            </button>
          </div>
        </div>
      </section>
    </Modal>
  )
}

export default memo(Detail);
