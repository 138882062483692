import { JitsiMeeting } from '@jitsi/react-sdk';
import {useNavigate, useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import useAuth from "../../../services/useAuth";
import Modules from "./fragment/Modules";
import Questions from "./fragment/Questions";
import Students from "./fragment/Students";
import useLiveClass from "../../../services/useLiveClass";

const LiveClassMeet = () => {
  const { program_id, meet_id } = useParams();
  const navigate = useNavigate();
  const { checkSession } = useAuth();
  const {generateMeetToken, getLiveMeetDetail} = useLiveClass();
  const [meetDetail, setMeetDetail] = useState<LiveClassDetailResponse|null>(null)
  const [meetRoomData, setMeetRoomData] = useState<LiveClassGenerateTokenResponse|null>(null)

  useEffect(() => {
    if (!checkSession()) {
      navigate("/login", {
        replace: true,
      });
    }else{
      prepareData()
    }
  }, []);


  const generateToken = () => {
    if (program_id && meet_id){
      generateMeetToken(program_id, meet_id).then((res)=>setMeetRoomData(res))
    }
  }

  useEffect(()=>{
    if (!meetDetail?.meets?.teacher_jitsi_token && !meetRoomData?.jwt){
      generateToken()
    }
  },[meetDetail])


  const prepareData = () => {
    if (program_id && meet_id){
      getLiveMeetDetail(program_id, meet_id).then((res)=>{
        setMeetDetail(res?.data)
      })
    }
  }

  const onClickBack = () => {
    navigate('/live-class/monitoring')
  }
  return(
    <div className='flex flex-1 flex-col bg-gray-100 h-screen'>
      <div className='flex flex-1 justify-between bg-white px-8 py-4'>
        <div className='flex flex-1 items-center space-x-3'>
          <button className='bg-[#FFE500] px-3 py-1.5 rounded-lg' onClick={onClickBack}>Kembali</button>
          <p className='text-xs text-blue-500 px-2 py-1 rounded-full bg-blue-100 border-blue-500 border'>LIVE CLASS</p>
          <div className='h-6 w-0.5 bg-[#D9D9D9]'/>
          <p className='font-bold text-gray-900'>{meetDetail?.meets?.class_code ?? ''}</p>
        </div>
        <div className='flex flex-1 justify-end items-center space-x-3'>
          {meetDetail?.meets?.date_end && (<p className='text-xs text-gray-700 bg-gray-50 border-gray-200 border rounded-full py-1 px-2'>00:42:20</p>)}
          <button disabled className='border border-gray-200 bg-gray-100 text-gray-400 font-semibold text-sm px-2 py-1 rounded-md'>Akhiri pertemuan</button>
        </div>
      </div>

      <div className='flex w-full h-full max-h-fit overflow-y-hidden'>
        <div className='w-2/3 bg-black max-h-full'>
          {meetRoomData && (
            <div
              id="custom-jitsi-wrapper"
              className={'w-full h-full items-stretch justify-items-stretch'}
            >
            <JitsiMeeting
              domain={'8x8.vc'}
              interfaceConfigOverwrite={{
                VIDEO_LAYOUT_FIT: 'nocrop',
                MOBILE_APP_PROMO: false,
                TILE_VIEW_MAX_COLUMNS: 4,
                MAXIMUM_ZOOMING_COEFFICIENT: 4,
                SHOW_JITSI_WATERMARK: false,
                SHOW_CHROME_EXTENSION_BANNER: false,
                HIDE_DEEP_LINKING_LOGO: true,
                SHOW_BRAND_WATERMARK: false,
                SHOW_WATERMARK_FOR_GUESTS: false,
              }}
              roomName={meetRoomData.roomName}
              jwt={meetRoomData.jwt}
              configOverwrite={{
                prejoinPageEnabled: false,
              }}
            />
            </div>
          )
          }
        </div>
        <div className='max-w-1/3 p-3 flex flex-1 flex-col space-y-4'>
          <Modules data={meetDetail?.modules}/>
          <Questions data={meetDetail?.questions}/>
          <Students/>
        </div>
      </div>
    </div>
  )
}

export default LiveClassMeet
