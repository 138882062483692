import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, Controller, FormProvider } from "react-hook-form";
import { Badge, Button, Input, InputNumber, Label } from "components";
import { DatePicker } from "components/datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { getProgramColor } from "utilities";
import { PROGRAM_TYPE } from "constant";
import toast from "react-hot-toast";
import usePromo from "services/usePromo";
import Programs from "../fragment/Modal/Program";
import SuccessAdd from "../fragment/Modal/SuccessAdd";

export default function Add() {
  const navigate = useNavigate();
  const { state } = useLocation();
  const { add } = usePromo();

  const [showModal, setShowModal] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [isDraft, setIsDraft] = useState(false);
  const [programList, setProgramList] = useState<ProgramItem[]>([]);
  const [programType, setProgramType] = useState<ProgramType | null>(null);

  const methods = useForm<PromoPayload>({
    defaultValues: {
      banner_url: "https://shorturl.at/glGWY",
      description: "",
      discount_type: "flat",
      discount_value: undefined,
      end_period: "",
      is_active: true,
      objects: [],
      quota: 0,
      start_period: "",
      title: "",
      type: state?.type,
      voucher_code: "",
    },
  });
  const { control, watch, handleSubmit } = methods;
  const values = watch();

  const isVoucher = useMemo(() => {
    return state?.type === "voucher_code";
  }, [state]);

  const canContinue = useMemo(() => {
    const {
      voucher_code,
      type,
      title,
      start_period,
      end_period,
      discount_value,
    } = values;

    const hasVoucher = type === "voucher_code" ? Boolean(voucher_code) : true;
    const hasDetail = Boolean(
      title && start_period && end_period && discount_value
    );

    if (!programType) {
      return hasDetail && hasVoucher && Boolean(programList.length);
    } else {
      return hasDetail && hasVoucher;
    }
  }, [values, programList, programType]);

  const goBack = useCallback(() => {
    navigate("/promo", {
      replace: true,
    });
  }, []);

  useEffect(() => {
    if (!state) {
      goBack();
    }
  }, [state, goBack]);

  const toggleModal = () => setShowModal(!showModal);

  const handleCloseSuccess = () => {
    setShowSuccess(false);
    goBack();
  };

  const handleAddProgram = (items: ProgramItem[]) => {
    setProgramList(items);
  };

  const removeProgram = (id: number) => {
    setProgramList((prevValue) =>
      prevValue.filter((val) => Number(val.id) !== id)
    );
  };

  const onSubmit = (data: PromoPayload, saveAsDraft?: boolean) => {
    setIsDraft(Boolean(saveAsDraft));
    let newObjects: PromoObject[] = [];

    if (programType) {
      newObjects = [
        {
          is_all_items: true,
          object: programType,
          object_id: [],
        },
      ];
    } else {
      const listClass: PromoObject = {
        object: "class",
        is_all_items: false,
        object_id: programList
          .filter((val) => val.type === "class")
          .map((val) => Number(val.id)),
      };
      const listLearn: PromoObject = {
        object: "learn",
        is_all_items: false,
        object_id: programList
          .filter((val) => val.type === "learn")
          .map((val) => Number(val.id)),
      };
      const listTryout: PromoObject = {
        object: "tryout",
        is_all_items: false,
        object_id: programList
          .filter((val) => val.type === "tryout")
          .map((val) => Number(val.id)),
      };
      const tempList = [listClass, listLearn, listTryout];
      newObjects = tempList.filter((val) => val.object_id.length);
    }

    const payload = {
      ...data,
      is_active: !saveAsDraft,
      objects: newObjects,
    };
    toast.promise(add(payload), {
      loading: "...",
      error: `Gagal menambahkan ${isVoucher ? "Voucher" : "Promo"}`,
      success: () => {
        setShowSuccess(true);
        return "";
      },
    });
  };

  return (
    <>
      <div className="absolute left-0 top-0 right-0 px-8 py-6 h-[calc(100%-5rem)] overflow-y-auto">
        <h3 className="font-bold text-xl">Tambah voucher baru</h3>
        <FormProvider {...methods}>
          <div className="mt-8 grid grid-cols-[1fr,27rem] gap-x-8">
            <div className="bg-white rounded-xl">
              <h3 className="px-5 py-4 font-bold text-xl border-b">
                Informasi
              </h3>
              <div className="p-5 space-y-5">
                {isVoucher && (
                  <Controller
                    control={control}
                    name="voucher_code"
                    render={({ field }) => (
                      <Input
                        label="Kode voucher"
                        placeholder="Masukkan kode voucher"
                        value={field.value}
                        onChange={(e) => {
                          const value = e.target.value.trim().toUpperCase();
                          field.onChange(value);
                        }}
                      />
                    )}
                  />
                )}
                <Controller
                  control={control}
                  name="title"
                  render={({ field }) => (
                    <Input
                      label={`Nama ${isVoucher ? "voucher" : "promo"}`}
                      placeholder={`Masukkan nama ${
                        isVoucher ? "voucher" : "promo"
                      }`}
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
                <div className="grid grid-cols-2 gap-x-5">
                  <Controller
                    control={control}
                    name="start_period"
                    render={({ field }) => (
                      <DatePicker
                        label="Periode mulai"
                        displayFormat="DD/MM/YYYY"
                        value={field.value}
                        onChange={field.onChange}
                      ></DatePicker>
                    )}
                  />
                  <Controller
                    control={control}
                    name="end_period"
                    render={({ field }) => (
                      <DatePicker
                        label="Periode berakhir"
                        displayFormat="DD/MM/YYYY"
                        value={field.value}
                        onChange={field.onChange}
                      ></DatePicker>
                    )}
                  />
                </div>
                <div id="discount">
                  <Label>Diskon</Label>
                  <div className="px-3.5 py-2.5 border rounded-lg mt-1.5 flex">
                    <Controller
                      control={control}
                      name="discount_type"
                      render={({ field }) => (
                        <select
                          name="discountType"
                          id="discountType"
                          value={field.value}
                          onChange={field.onChange}
                        >
                          <option value={"flat"}>Rp</option>
                          <option value={"percentage"}>%</option>
                        </select>
                      )}
                    />
                    <Controller
                      control={control}
                      name="discount_value"
                      render={({ field }) => (
                        <InputNumber
                          label=""
                          className="w-full px-3"
                          placeholder="Masukkan diskon"
                          options={{
                            numeral: true,
                            numeralDecimalMark: ",",
                            delimiter: ".",
                          }}
                          value={field.value}
                          onChange={(e) =>
                            field.onChange(Number(e.target.rawValue))
                          }
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="space-y-1">
                  <Controller
                    control={control}
                    name="quota"
                    render={({ field }) => (
                      <Input
                        label="Kuota"
                        placeholder="Masukkan kuota"
                        value={field.value}
                        onChange={field.onChange}
                      />
                    )}
                  />
                  <span className="text-sm text-gray-600">
                    Kosongi jika tidak terbatas
                  </span>
                </div>
              </div>
            </div>
            {/* program */}
            <div className="bg-white rounded-xl">
              <h3 className="px-5 py-4 font-bold text-xl border-b">Program</h3>
              <div className="p-5 h-[40rem] space-y-2">
                <p className="text-sm font-medium">Variasi soal</p>
                <div className="flex flex-wrap items-center gap-3">
                  <span
                    onClick={() => setProgramType(null)}
                    className={`px-3.5 py-2.5 rounded-lg border cursor-pointer text-sm ${
                      !programType ? "bg-primary" : ""
                    }`}
                  >
                    Program pilihan
                  </span>
                  {Object.entries(PROGRAM_TYPE).map((val) => (
                    <span
                      key={val[0]}
                      onClick={() => setProgramType(val[0] as ProgramType)}
                      className={`px-3.5 py-2.5 rounded-lg border cursor-pointer text-sm ${
                        programType === val[0] ? "bg-primary" : ""
                      }`}
                    >
                      {val[1]}
                    </span>
                  ))}
                </div>
                <div className="h-[26.5rem] overflow-y-scroll">
                  {programList.length && !programType ? (
                    programList.map((val) => (
                      <div
                        key={val.id}
                        className="flex items-center justify-between py-3 border-b"
                      >
                        <span className="text-sm">{val.title}</span>
                        <div className="flex items-center gap-x-2">
                          <Badge color={getProgramColor(val.type)}>
                            {PROGRAM_TYPE[val.type].toUpperCase()}
                          </Badge>
                          <FontAwesomeIcon
                            icon={icon({ name: "trash-alt" })}
                            className="cursor-pointer"
                            onClick={() => removeProgram(Number(val.id))}
                          />
                        </div>
                      </div>
                    ))
                  ) : (
                    <p className="text-gray-400">
                      Anda belum menambahkan program apapun.
                    </p>
                  )}
                </div>
                <div className="flex items-center justify-between">
                  <p>
                    {programType ? (
                      <FontAwesomeIcon icon={icon({ name: "infinity" })} />
                    ) : (
                      <span>{programList.length}</span>
                    )}{" "}
                    program
                  </p>
                  <Button
                    isDisabled={Boolean(programType)}
                    color="primary"
                    onButtonClick={toggleModal}
                  >
                    <FontAwesomeIcon
                      icon={icon({ name: "file-circle-plus" })}
                    />
                    <span>Tambah program</span>
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <Programs
            show={showModal}
            onClose={toggleModal}
            onAdd={handleAddProgram}
            listSelected={programList}
          />
        </FormProvider>
      </div>
      <div className="flex items-center justify-between absolute bottom-0 right-0 left-0 px-8 py-5 bg-white">
        <Button
          color="outline-gray"
          onButtonClick={handleSubmit((data) => onSubmit(data, true))}
        >
          <FontAwesomeIcon icon={icon({ name: "pen" })} />
          <span className="ml-3">Simpan sebagai draft</span>
        </Button>
        <div className="space-x-3">
          <Button.Cancel onButtonClick={goBack} />
          <Button
            onButtonClick={handleSubmit((data) => onSubmit(data))}
            isDisabled={!canContinue}
            color="primary"
          >
            Simpan dan aktif
          </Button>
        </div>
      </div>
      <SuccessAdd
        show={showSuccess}
        isVoucher={isVoucher}
        isDraft={isDraft}
        onClose={handleCloseSuccess}
      />
    </>
  );
}
