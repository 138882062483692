import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "components/Modal";
import { FC, memo } from "react";

type Props = {
  show: boolean;
  onClose: () => void;
  onDelete: () => void;
};

const RemoveValidation: FC<Props> = ({ show, onClose, onDelete }) => {
  const statusIcon = icon({ name: "close" })

  return (
    <Modal
      title=""
      show={show}
      onClose={onClose}
      customFooter={
        <div className="mt-10 items-center justify-center flex flex-row">
          <button
            className="py-[0.55rem] px-5 mr-3 rounded-xl border border-gray-400"
            onClick={onClose}
          >
            Batal
          </button>
          <button
            className="py-[0.625rem] px-5 rounded-xl bg-primary"
            onClick={onDelete}
          >
            Hapus
          </button>
        </div>
      }
    >
      <div className="grid place-items-center gap-y-10 text-center">
        <FontAwesomeIcon
          icon={statusIcon}
          className={`w-10 h-10 text-white rounded-full p-1.5 bg-red-500`}
        />
        <div className="space-y-4 w-96">
          <p className="font-bold text-2xl">
            Yakin akan menghapus paket?
          </p>
          <p className="text-gray-600">
            Paket yang telah dihapus tidak bisa diakses.
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default memo(RemoveValidation);
