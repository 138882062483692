import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PAGE_LIMIT } from "constant";
import { FC, memo } from "react";

type Props = {
  pagination: PaginationResponse;
  onGetData: (params: ProgramParams) => Promise<void>;
};

const Footer: FC<Props> = ({ pagination, onGetData }) => {
  const handleLimit = async (limit: string) => {
    await onGetData({
      page: 1,
      per_page: Number(limit),
    });
  };

  const handlePagination = async (ctrl: "next" | "prev") => {
    const isNext = ctrl === "next";
    const page = isNext
      ? pagination.current_page + 1
      : pagination.current_page - 1;

    if (page > 0 && page <= pagination.last_page) {
      await onGetData({
        page,
      });
    }
  };

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center">
        <label htmlFor="limit">ROWS PER PAGE:</label>
        <div className="relative">
          <select
            id="limit"
            className="select bg-transparent border-none w-fit bg-none relative pr-6 z-[1]"
            onChange={(e) => handleLimit(e.target.value)}
            value={pagination.per_page}
          >
            {PAGE_LIMIT.map(({ value, label }) => (
              <option key={value} value={value}>
                {label}
              </option>
            ))}
          </select>
          <FontAwesomeIcon
            className="absolute right-0 top-1/4"
            icon={icon({ name: "caret-down" })}
          />
        </div>
      </div>
      <div className="flex gap-3 justify-end items-center">
        <p>{`${pagination?.from ?? 0} - ${pagination?.to ?? 0} of ${
          pagination.total
        }`}</p>
        <FontAwesomeIcon
          icon={icon({ name: "chevron-left" })}
          onClick={() => handlePagination("prev")}
          className="cursor-pointer"
        />
        <FontAwesomeIcon
          icon={icon({ name: "chevron-right" })}
          onClick={() => handlePagination("next")}
          className="cursor-pointer"
        />
      </div>
    </div>
  );
};

export default memo(Footer);
