// React
import { PropsWithChildren } from "react"

function Content (props: PropsWithChildren<{
  className?: string
}>): JSX.Element {
  return (
    <div className={props.className}>
      {props.children}
    </div>
  )
}

function Body (props: PropsWithChildren<{
  className?: string
}>): JSX.Element {
  return (
    <div className={props.className}>
      {props.children}
    </div>
  )
}

function Header (props: {
  level?: 1 | 2 | 3
  rightElement?: JSX.Element
  subtitle?: string
  title: string | JSX.Element
}): JSX.Element {
  // Variables
  const level = {
    1: "text-xl",
    2: "text-lg",
    3: "text-md",
  }

  return (
    <div className="flex flex-col md:flex-row justify-center md:justify-between mb-4">
      <section>
        <div className={`font-bold ${level[props.level ?? 1]}`}>{props.title}</div>
        <div>{props.subtitle}</div>
      </section>

      <section className="self-center">
        {props.rightElement}
      </section>
    </div>
  )
}

Content.Header = Header
Content.Body = Body

export { Content }