// React
import { PropsWithChildren } from "react";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro';

// Components
import { Icon as CIcon } from "components/Icon"

// Third-Party Libraries
import { Link } from "react-router-dom"

// Utilities
import { useText } from "utilities"

type ButtonColor =
  | "error"
  | "primary"
  | "success"
  | "transparent"
  | "warning"
  | "secondary"
  | "outline-error"
  | "outline-primary"
  | "outline-success"
  | "outline-warning"
  | "outline-secondary"
  | "outline-gray";

type BaseButton = {
  // isLoading?: Boolean // this was causing build error since its a duplicate -mylo
  isDropdown?: Boolean
  isDisabled?: Boolean
  isLoading?: Boolean
  items?: {
    linkTo?: string
    onItemClick?: () => void
    state?: {
      [key: string]: any
    }
    title: string | JSX.Element
  }[]
  onButtonClick?: () => void
  rounded?: boolean
  type?: "submit" | "button"
}

interface ButtonType extends BaseButton {
  className?: string
  color: ButtonColor
}

interface ButtonCreateType extends BaseButton {
  text?: string
}

interface IconType extends BaseButton {
  action?: "delete" | "edit" | "show"
  className?: string
  color?: ButtonColor
  noTooltip?: Boolean
  tooltipText?: string
}

function Button (props: PropsWithChildren<ButtonType>) {
  // Variables
  const color = {
    error: "btn-error",
    primary: "bg-primary",
    success: "btn-success",
    transparent: "btn-ghost",
    warning: "btn-warning",
    secondary: "btn-secondary",
    "outline-error": "btn-outline-error",
    "outline-primary": "btn-outline-primary",
    "outline-success": "btn-outline-success",
    "outline-warning": "btn-outline-warning",
    "outline-secondary": "btn-outline-secondary",
    "outline-gray": "btn-outline-gray"
  }

  if (props.isDropdown) {
    return (
      <div className="dropdown">
        <label className={`btn ${color[props.color]} ${props.rounded && "btn-circle"} ${props.className}`} tabIndex={0}>
          {props.children}
        </label>

        <div className="dropdown-menu">
          {props.items!.map((item, key) => (
            <Link
              key={key}
              to={item.linkTo ?? "#"}
              state={item.state}
              tabIndex={key === 0 ? 0 : -1}
              className="dropdown-item text-sm"
              onClick={item.onItemClick}
            >
              {item.title}
            </Link>
          ))}
        </div>
      </div>
    )
  }

  return (
    <button
      type="button"
      disabled={Boolean(props.isDisabled)}
      className={`btn ${props.isLoading ? "btn-loading" : ''} ${color[props.color]} ${props.className}`}
      onClick={props?.onButtonClick}
    >
      {props.children}
    </button>
  );
}

function Cancel (props: PropsWithChildren<BaseButton>) {
  return (
    <button
      onClick={props?.onButtonClick}
      type={props.type ?? "button"}
      className={`btn ${props.isLoading && "btn-loading"} bg-white border border-primary hover:bg-primary text-sm font-medium rounded-xl px-5 py-3`}
    >
      {props.children ?? "Batal"}
    </button>
  )
}

function Create (props: ButtonCreateType) {
  return (
    <Button {...props} type={props.type ?? "button"} className={`text-black bg-[#FFF200] ${props.rounded && "btn-circle"}`} color="primary">
      <FontAwesomeIcon icon={icon({name: 'plus'})} />
      <span className="text-black ml-[0.625rem]">{props.text ?? "Tambah"}</span>
    </Button>
  )
}

function Close (props: BaseButton) {
  return (
    <span className="tooltip tooltip-bottom" data-tooltip="Close">
      <Button {...props} type="button" color="secondary" className={`w-6 h-6 p-0 text-black btn-xs ${props.rounded && "btn-circle"}`}>
        <CIcon.Close className="w-3" />
      </Button>
    </span>
  )
}

function Icon(props: PropsWithChildren<IconType>) {
  // Variables
  const type: {
    [key: string]: {
      color: ButtonColor
      element: any
    }
  } = {
    delete: {
      color: "error",
      element: CIcon.Delete
    },
    edit: {
      color: "success",
      element: CIcon.Edit
    },
    show: {
      color: "warning",
      element: CIcon.Info
    }
  }

  // Hooks
  const text = useText()

  return (
    <span className={`${props.noTooltip ? "" : "tooltip tooltip-top"} ${props.className}`} data-tooltip={!props.noTooltip ? text.capitalize(props.tooltipText ?? props.action!) : ""}>
      <Button {...props} type={props.type ?? "button"} color={props.color ?? type[props.action!].color} className="w-8 h-8 p-0">
        {props.children ?? type[props.action!].element({ className: "w-4 text-white" })}
      </Button>
    </span>
  )
}

Button.Cancel = Cancel
Button.Close = Close
Button.Create = Create
Button.Icon = Icon

export { Button }