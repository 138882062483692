import Modal from "../../../../components/Modal";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import useAskHomework from "../../../../services/useAskHomework";
import {useEffect, useState} from "react";
import moment from "moment";
import StatusBadge from "./StatusBadge";

type Props = {
  id: string | null
  show: boolean
  onClose: () => void
  onSuccess?: () => Promise<void>
  data?: BrowseItem | null
}

const ModalSessionDetail = (props: Props) => {
  const {id, show, onClose} = props
  const {getDetailById} = useAskHomework();

  const [data, setData] = useState<AskHomeworkDetailByIdResponse>()

  useEffect(()=>{
    if (id){
      getDetailById(id).then((resp)=>{
        setData(resp)
      })
    }
  },[id])

  if (!id || !data) return null
  return (
    <Modal
      title={'TPR/29102023/MPM/209982'}
      customTitle={'font-bold text-sm text-[#8C7E00]'}
      show={show}
      onClose={onClose}
      customFooter={(<></>)}
    >
      <div className="py-2 flex flex-col space-y-10">
        <DetailOrder orderData={data}/>
        <TeacherDetail teacherData={data.teacher}/>
        <SalesDetail data={data.order_detail}/>
        <button className="px-4 py-[0.625rem] rounded-xl bg-primary self-end" onClick={onClose}>Tutup</button>
      </div>
    </Modal>
  )
}

const DetailOrder = ({orderData}: {orderData: AskHomeworkDetailByIdResponse}) => {
  let duration = '-'
  if (orderData.start_time && orderData.end_time) {
    const startMoment = moment(orderData.start_time, 'YYYY-MM-DD HH:mm:ss');
    const endMoment = moment(orderData.end_time, 'YYYY-MM-DD HH:mm:ss');

    const durationInMillis = endMoment.diff(startMoment);
    duration = moment.utc(durationInMillis).format('HH:mm:ss');
  }
  return (
    <div className='flex flex-1 flex-col'>
      <span className={'text-gray-900 font-bold'}>Detail Order</span>
      <div className="divider my-0" />
      <div className='flex flex-1 flex-row space-x-2 mt-2'>
        {/*Left Section*/}
        <div className='flex flex-1 flex-col space-y-4'>
          <div className='flex'>
            <span className='flex text-gray-600 text-sm w-32'>Nama Siswa</span>
            <span className={'text-gray-600 font-bold text-sm flex-1'}>{orderData.student_name}</span>
          </div>
          <div className='flex'>
            <span className='flex text-gray-600 text-sm w-32'>Strata</span>
            <span className={'text-gray-600 font-bold text-sm flex-1'}>{orderData.grade_name}</span>
          </div>
          <div className='flex'>
            <span className='flex text-gray-600 text-sm w-32'>Mata pelajaran</span>
            <span className={'text-gray-600 font-bold text-sm flex-1'}>{orderData.subject_name}</span>
          </div>
          <div className='flex'>
            <span className='flex text-gray-600 text-sm w-32'>Tanggal</span>
            <span className={'text-gray-600 font-bold text-sm flex-1'}>{moment(orderData.start_time).format('DD/MM/YYYY HH:mm')}</span>
          </div>
        </div>

        {/*Right Section*/}
        <div className='flex flex-1 flex-col space-y-4'>
          <div className='flex'>
            <span className='flex text-gray-600 text-sm w-32'>Tipe</span>
            <span className={'text-gray-600 font-bold text-sm flex-1 capitalize'}>{orderData.type}</span>
          </div>
          <div className='flex'>
            <span className='flex text-gray-600 text-sm w-32'>Durasi sesi</span>
            <span className={'text-gray-600 font-bold text-sm flex-1'}>{duration}</span>
          </div>
          <div className='flex items-center'>
            <span className='flex text-gray-600 text-sm w-32'>Status</span>
            <StatusBadge status={orderData.status}/>
          </div>
        </div>
      </div>
    </div>
  )
}

const TeacherDetail = ({teacherData}: {teacherData: AskHomeworkDetailByIdResponse["teacher"]}) => {
  return(
    <div className='flex flex-1 flex-col'>
      <span className={'text-gray-900 font-bold'}>Pengajar</span>
      <div className="divider my-0" />
      <div className='flex flex-1 flex-col space-y-4'>
        <div className='flex flex-row'>
          <span className='flex text-gray-600 text-sm w-32'>Nama pengajar</span>
          <span className={'text-gray-600 font-bold text-sm flex-1'}>{teacherData.teacher_name}</span>
        </div>
        <div className='flex flex-row items-center'>
          <span className='flex text-gray-600 text-sm w-32'>Strata mengajar</span>
          <div className='flex flex-row flex-wrap self-start flex-1'>
          {teacherData.grades.map((item)=><span className='mr-2 mb-2 flex text-gray-600 text-xs border flex-nowrap border-gray-700 bg-gray-50 rounded-full px-2 py-0.5'>{item.name}</span>)}
          </div>
        </div>

        <div className='flex flex-row items-center'>
          <span className='flex text-gray-600 text-sm w-32'>Mata pelajaran</span>
          <div className='flex flex-row flex-wrap self-start flex-1'>
            {teacherData.subjects.map((item)=><span className='flex mr-2 mb-2 text-gray-600 text-xs border border-gray-700 bg-gray-50 rounded-full px-2 py-0.5'>{item.name}</span>)}
          </div>
        </div>
      </div>
    </div>
  )
}

const SalesDetail = ({data} : {data: AskHomeworkDetailByIdResponse['order_detail']}) => {
  return(
    <div className={'py-4 px-6 bg-gray-100 rounded-md space-y-3'}>
      <span className={'text-md text-gray-900 font-bold'}>Rincian Penjualan</span>
      <div className='flex flex-1 flex-col space-y-3'>
        <div className='flex flex-row'>
          <span className='flex text-gray-600 text-sm flex-1'>Nama paket</span>
          <span className={'text-gray-600 font-bold text-sm flex-1 text-end'}>{data.packet_name}</span>
        </div>
        <div className='flex flex-row'>
          <span className='flex text-gray-600 text-sm flex-1'>Harga paket</span>
          <span className={'text-gray-600 font-bold text-sm flex-1 text-end'}>Rp {data.price.toLocaleString('id-ID')}</span>
        </div>
        <div className='flex flex-row'>
          <span className='flex text-gray-600 text-sm flex-1'>Prosentase komisi pengajar</span>
          <span className={'text-gray-600 font-bold text-sm flex-1 text-end'}>{data.commission_percentage}%</span>
        </div>
        <div className='border-dashed border-t-2 h-1 w-full'/>
        <div className='flex flex-row'>
          <span className='flex text-gray-600 text-sm font-bold flex-1'>Pendapatan bersih</span>
          <span className={'text-gray-600 font-bold text-md flex-1 text-end'}>Rp {data.net_price.toLocaleString('id-ID')}</span>
        </div>
      </div>
    </div>
  )
}

export default ModalSessionDetail
