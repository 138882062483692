import React, { FC, useCallback, useEffect, useState } from 'react';
import Modal from 'components/Modal';
import { Button } from 'components';
import useAskHomework from 'services/useAskHomework';

type Props = {
  show: boolean;
  onClose: () => void;
  id: string;
};

const DetailOrderModal: FC<Props> = ({ show, id, onClose }) => {
  const {getOrderDetail} = useAskHomework();
  const [data, setData] = useState<orderDetail>()
  
  const getData = useCallback(async () => {
    const response: orderDetail = await getOrderDetail(id);
    setData(response)
  }, [data])

  useEffect(() => {
    getData()
  },[])

  const formatCurrency = (number: number) => {
    const formatted = new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0,
    }).format(number);

    return formatted;
  };

  return (
    <Modal
      show={show}
      title={data?.length? data[0].invoice_no : ""}
      onClose={onClose}
      customTitle={"text-[#8C7E00] font-bold text-sm md:text-base"}
    >
      {data?.length ?
      <>
      <p className="font-bold py-4 border-b">Detail order</p>
      <div className="flex">
        <div className="w-1/2 py-4">
          <table className="w-fit">
            <tbody>
              <tr>
                <td className="py-1 text-gray-500  pr-5 text-[14px]">Nama siswa</td>
                <td className="py-1 text-[14px]">{data[0].student_name}</td>
              </tr>
              <tr>
                <td className="py-1 text-gray-500  pr-5 text-[14px]">Strata</td>
                <td className="py-1 text-[14px]">{data[0].grade_name}</td>
              </tr>
              <tr>
                <td className="py-1 text-gray-500  pr-5 text-[14px]">Tanggal</td>
                <td className="py-1 text-[14px]">{data[0].order_date}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="w-1/2 py-4">
          <table className="w-fit">
            <tbody>
              <tr>
                <td className="py-1 text-gray-500  pr-5 text-[14px]">Tipe</td>
                <td className="py-1 text-[14px]">{data[0].session_type}</td>
              </tr>
              <tr>
                <td className="py-1 text-gray-500  pr-5 text-[14px]">Durasi sesi</td>
                <td className="py-1 text-[14px]">{data[0].duration_minute} menit</td>
              </tr>
              <tr>
                <td className="py-1 text-gray-500  pr-5 text-[14px]">Status</td>
                <td className="py-1 text-[14px]">
                  <span className='border border-green-700 text-green-700 px-2 py-[2px] rounded-full'>SELESAI</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div className='w-full bg-[#F2F4F7] rounded-lg p-5'>
        <p className="font-bold ">Rincian Penjualan</p>
        <div className='w-full border-b border-dashed py-3'>
          <div className='flex justify-between text-[14px] py-1'>
            <p className='text-gray-500'>Nama paket</p>
            <p>{data[0].packet_title}</p>
          </div>
          <div className='flex justify-between text-[14px] py-1'>
            <p className='text-gray-500'>Harga paket</p>
            <p>{formatCurrency(parseInt(data[0].packet_price))}</p>
          </div>
          <div className='flex justify-between text-[14px] py-1'>
            <p className='text-gray-500'>Prosentase komisi pengajar </p>
            <p>{data[0].teacher_comission_percentage} %</p>
          </div>
        </div>
        <div className='flex justify-between py-2'>
          <p className='font-bold  text-[14px]'>Pendapatan bersih</p>
          <p className='font-bold  text-[16px]'>{formatCurrency(parseInt(data[0].packet_price)*(100 - data[0].teacher_comission_percentage)/100)}</p>
        </div>
      </div>
      </>
      : <div></div>
      }
    </Modal>
  );
};

export default DetailOrderModal;
