import {memo, FC, useEffect, useState, useCallback} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro';
import {useFormContext} from 'react-hook-form';
import Modal from 'components/Modal';
import { useDebounce } from 'utilities';
import { Spinner, Button } from 'components';
import useSyllabus from 'services/useSyllabus';
import toast from 'react-hot-toast';

type Props = {
  show: boolean
  onClose: () => void
  onSubmit: (item: SyllabusDetail) => void
}

const SelectSyllabus: FC<Props> = ({show, onClose, onSubmit}) => {
  const {getList, getDetail} = useSyllabus();

  const {setValue, getValues} = useFormContext();
  const syllabusId = getValues("syllabus_id");

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<SyllabusItem[]>([]);
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState<number>(syllabusId || 0);

  const debounceSearch = useDebounce(search);

  const getSyllabusData = useCallback(async (params?: SyllabusParams) => {
    setLoading(true);
    const response = await getList({
      ...params,
      per_page: 50
    });

    setData(response?.data || []);
    setLoading(false);
  }, [getList]);

  useEffect(() => {
    if (debounceSearch.length >= 3) {
      getSyllabusData({page: 1, keyword: debounceSearch});
    }

    if (!debounceSearch.length) {
      getSyllabusData({page: 1, keyword: ''});
    }
  }, [debounceSearch]);

  const handleSelect = () => {
    if (selected) {
      toast.promise(
        getDetail(selected),
        {
          loading: '',
          success: (res) => {
            if (res) {
              setValue('syllabus_id', selected);
              onSubmit(res);
            }
            return '';
          },
          error: 'Gagal memilih Silabus.'
        }
      )
    }
  };

  return (
    <Modal
      show={show}
      onClose={onClose}
      title='Pilih silabus'
      customFooter={
        <div className='flex justify-end gap-x-2'>
          <Button.Cancel onButtonClick={onClose} />
          <Button color='primary' onButtonClick={handleSelect}>
            Pilih silabus
          </Button>
        </div>
      }
    >
      <>
        <div className='bg-white px-4 py-[0.625rem] rounded-xl w-full flex gap-x-2 items-center border mb-4'>
          <FontAwesomeIcon icon={icon({name: 'search'})} />
          <input
            className='w-full'
            placeholder='Search...'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          {Boolean(search.length) && (
            <FontAwesomeIcon
              icon={icon({name: 'close'})}
              onClick={() => setSearch('')}
              className='cursor-pointer'
            />
          )}
        </div>
      </>
      {loading ? (
        <Spinner />
      ) : (
        <section className='grid max-h-[70vh] overflow-scroll'>
          {data.map((item) => (
            <button
              key={item.id}
              className={`border-b hover:bg-yellow-100 px-4 py-2 cursor-pointer text-start ${selected === item.id ? 'bg-yellow-100' : 'bg-white'}`}
              onClick={() => setSelected(item.id)}
            >
              <FontAwesomeIcon icon={icon({name: 'file-lines', style: 'regular'})} />
              <span className='ml-2'>{item.syllabus_name}</span>
            </button>
          ))}
        </section>
      )}
    </Modal>
  )
}

export default memo(SelectSyllabus);
