import { FC, ReactNode, memo } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { PAGE_LIMIT } from "constant";
import { Spinner } from "./Spinner";

type Control = "next" | "prev";

type Props = {
  columns: {
    title: string;
    isSort?: boolean;
    key?: string;
    isLeft?: boolean;
  }[];
  children: ReactNode;
  data: any[];
  currentPage?: number;
  currentLimit?: number;
  total?: number;
  handlePagination?: (ctrl: Control) => void;
  handleLimit?: (limit: string) => void;
  handleSort?: (sort: string) => void;
  hasId?: boolean;
  className?: string;
  showFooter?: boolean;
  handleFirstColumn?: string;
  loading?: boolean;
};

const Table: FC<Props> = (props) => {
  const {
    children,
    columns,
    currentLimit = 1,
    currentPage = 1,
    data,
    handleLimit,
    handlePagination,
    handleSort,
    handleFirstColumn,
    hasId,
    total,
    showFooter = true,
    loading = false,
  } = props;

  const getRange = () => {
    const from = (currentPage - 1) * currentLimit + 1;
    const to = (currentPage - 1) * currentLimit + data?.length;
    const totalData = total ? `of ${total}` : "";

    return `${from} - ${to} ${totalData}`;
  };

  const onSort = async (sortBy: string) => {
    if (handleSort) {
      await handleSort(sortBy);
    }
  };

  return (
    <div className={`w-full px-5 py-4 rounded-xl bg-white ${props?.className}`}>
      <table className="table-auto w-full">
        <thead>
          <tr className="bg-gray-100">
            {columns.map(({ title, isSort, key, isLeft }, index) => {
              const firstColumn = index === 0;
              const secondColumn = index === 1;
              const lastColumn = index === columns.length - 1;
              let columnWidth = columns.length <= 4 ? "w-36" : "w-max";

              if (hasId) {
                if (firstColumn) {
                  columnWidth = handleFirstColumn
                    ? handleFirstColumn
                    : "w-[9rem]";
                } else if (secondColumn) {
                  if (columns.length <= 5) {
                    columnWidth = "w-3/5";
                  } else {
                    columnWidth = "w-[24rem]";
                  }
                } else if (lastColumn) {
                  columnWidth = "w-28";
                }
              } else {
                if (firstColumn) {
                  if (columns.length <= 5) {
                    columnWidth = handleFirstColumn
                      ? handleFirstColumn
                      : "w-3/5";
                  } else {
                    columnWidth = handleFirstColumn
                      ? handleFirstColumn
                      : "w-[24rem]";
                  }
                } else if (lastColumn) {
                  columnWidth = "w-28";
                }
              }

              return (
                <th key={index} className={`p-4 ${columnWidth}`}>
                  <div
                    className={`flex items-center ${
                      isSort || isLeft ? "justify-between" : "justify-end"
                    }`}
                  >
                    {title}
                    {isSort && (
                      <FontAwesomeIcon
                        icon={icon({ name: "sort" })}
                        className="cursor-pointer"
                        onClick={() => onSort(key || "")}
                      />
                    )}
                  </div>
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr className="w-full text-center">
              <td colSpan={columns.length}>
                <Spinner />
              </td>
            </tr>
          ) : data.length ? (
            children
          ) : (
            <tr className="w-full text-center">
              <td colSpan={columns.length} className="italic">
                Tidak ada data
              </td>
            </tr>
          )}
        </tbody>
        {showFooter && (
          <tfoot>
            <tr>
              <td className="p-4" colSpan={columns.length}>
                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <label htmlFor="limit">ROWS PER PAGE:</label>
                    <div className="relative">
                      <select
                        id="limit"
                        className="select bg-transparent border-none w-fit bg-none relative pr-6 z-[1]"
                        onChange={(e) => handleLimit?.(e.target.value)}
                        value={currentLimit}
                      >
                        {PAGE_LIMIT.map(({ value, label }) => (
                          <option key={value} value={value}>
                            {label}
                          </option>
                        ))}
                      </select>
                      <FontAwesomeIcon
                        className="absolute right-0 top-1/4"
                        icon={icon({ name: "caret-down" })}
                      />
                    </div>
                  </div>
                  <div className="flex gap-3 justify-end items-center">
                    <p>{`${getRange()}`}</p>
                    <FontAwesomeIcon
                      icon={icon({ name: "chevron-left" })}
                      onClick={() => handlePagination?.("prev")}
                      className="cursor-pointer"
                    />
                    <FontAwesomeIcon
                      icon={icon({ name: "chevron-right" })}
                      onClick={() => handlePagination?.("next")}
                      className="cursor-pointer"
                    />
                  </div>
                </div>
              </td>
            </tr>
          </tfoot>
        )}
      </table>
    </div>
  );
};

export default memo(Table);
