import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Badge, Button, Label } from "components";
import { memo, useState, useEffect, useCallback } from "react";
import BrowseQuestion from "./fragment/Modal/BrowseQuestion";
import { Controller, useFormContext } from "react-hook-form";
import ListQuestion from "./fragment/ListMeetings";
import QuestionComposition from "./fragment/MeetingsComposition";
import useProgram from "services/useProgram";
import { DatePicker } from "components/datepicker";
import MeetingsComposition from "./fragment/MeetingsComposition";
import BrowseDirectory from "./fragment/Modal/Directory";
import { TimePicker } from "components/timepicker";
import toast from "react-hot-toast";
import MeetingModuleList from "./fragment/ListModule";
import { calculateTotalMinutes, addMinutesToTime } from "utilities";
import MeetingQuestionList from "./fragment/ListQuestion";
import question from "../Tryout/question";

const Meetings = ({ triggerAddMeeting }: any) => {
  const { addLiveClassMeet, updateLiveclass } = useProgram();
  const [showQuestion, setShowQuestion] = useState(false);
  const [showModule, setShowModule] = useState(false);
  const [listVariety, setListVariety] = useState<Variety[]>([]);
  const [variety, setVariety] = useState<Variety | null>(null);
  const [Meetings, setMeetings] = useState<any[]>([]);

  const { setValue, watch, getValues, control } = useFormContext<LiveclassPayload>();
  const students = getValues("discount");
  const questionPerStudent = getValues("question_each_student");
  const questions = watch("question_package");
  const meeting = watch("meetings")
  const program_id = watch("program_id");
  const program_liveclass_id = watch("program_liveclass_id")

  useEffect(() => {
    const formData = getValues();
    const updatedMeetings = formData.meetings;
    setMeetings(updatedMeetings);
  }, []);

  useEffect(() => {
    if (triggerAddMeeting) {
      if (Meetings.length > 0) {
        if (program_id !== null && program_liveclass_id !== null) {
          toast.promise(addLiveClassMeet(parseInt(program_id, 10), parseInt(program_liveclass_id, 10), Meetings[Meetings.length-1]), {
            loading: "Loading...",
            success: (response: any) => {
              return "";
            },
            error: "Terjadi kesalahan",
          });
        }
      }  
  }}, [triggerAddMeeting]);

  const [index, setIndex] = useState(0)
  const toggleQuestion = () => setShowQuestion(!showQuestion);
  const toggleModule = () => setShowModule(!showModule);

  const addNewMeeting = (data: any) => {
    const updatedMeetings = Meetings || []
    setMeetings([
      ...updatedMeetings,
      {
        materi: [],
        clock:"",
        question: [],
        module_ids :[],
        date: "",
        date_start: "2023-05-01 00:00:00",
        date_end:"2023-05-01 00:00:00",
        durasi: {
          jam: "",
          menit: "",
          total: ""
        },
        duration: 0,
        question_package: []
      },
    ]);
    if (data.length > 0) {
      if (program_id !== null && program_liveclass_id !== null) {
        toast.promise(addLiveClassMeet(parseInt(program_id, 10), parseInt(program_liveclass_id, 10), data[data.length-1]), {
          loading: "Loading...",
          success: (response: any) => {
            return "";
          },
          error: "Terjadi kesalahan",
        });
      }
    } 
  };

  const handleMeetingsChange = (index: number, fieldName: any, value: any) => {
    let updatedMeetings = [...Meetings]; 

  if (fieldName === 'materi') {
    updatedMeetings[index] = {
      ...updatedMeetings[index],
      [fieldName]: [value]
    };
    const moduleIdsArray = value.map((id: any) => id.id);

    updatedMeetings[index] = {
      ...updatedMeetings[index],
      module_ids: moduleIdsArray,
    };
    setMeetings(updatedMeetings);
    setValue('meetings', updatedMeetings);
  } else if(fieldName === 'question'){
    const moduleIdsArray = {"question_package_id": value.id, "n_essay": String(value.n_essay), "n_multiple_choice": String(value.n_multiple_choice)}

    const updatedQuestionField = [...updatedMeetings[index].question];
    updatedQuestionField.push(value);

    updatedMeetings[index] = {
      ...updatedMeetings[index],
      question_package: [...updatedMeetings[index].question_package ,moduleIdsArray],
      question: [...updatedMeetings[index].question, value]
    };
    
    setMeetings(updatedMeetings);
    setValue('meetings', updatedMeetings);
  } else if (fieldName === 'jam' || fieldName === 'menit' || fieldName === 'total') {
    let updatedDurasi = { ...updatedMeetings[index].durasi };
    let hours = updatedDurasi.jam;
    let minutes = updatedDurasi.menit;

    if (fieldName === 'jam') {
      hours = value;
    } else if (fieldName === 'menit') {
      minutes = value;
    }

    const totalMinutes = calculateTotalMinutes(hours, minutes);

    updatedMeetings[index] = {
      ...updatedMeetings[index],
      durasi: {
        ...updatedMeetings[index].durasi,
        [fieldName]: value,
        total: totalMinutes.toString(), 
      },
      duration: totalMinutes,
    };
    setMeetings(updatedMeetings);
    setValue('meetings', updatedMeetings);
  } else if (fieldName === 'module_ids') {
    const moduleIdsArray = value.map((id: any) => id.id);

    updatedMeetings[index] = {
      ...updatedMeetings[index],
      [fieldName]: moduleIdsArray,
    };
    setMeetings(updatedMeetings);
    setValue('meetings', updatedMeetings);
  } else if (fieldName === 'question_package') {
    const moduleIdsArray = {"question_package_id": value.id, "n_essay": String(value.n_essay), "n_multiple_choice": String(value.n_multiple_choice)}

    updatedMeetings[index] = {
      ...updatedMeetings[index],
      [fieldName]: [moduleIdsArray],
    };
    setMeetings(updatedMeetings);
    setValue('meetings', updatedMeetings);
  } else if (fieldName === 'clock' || fieldName === 'date') {
    updatedMeetings[index] = {
      ...updatedMeetings[index],
      [fieldName]: value,
    };
    const { date, clock } = updatedMeetings[index];
    if (date && clock) {
      updatedMeetings[index] = {
        ...updatedMeetings[index],
        date_start: `${date} ${clock}`,
        date_end: `${date} ${clock}`
      };
    }
    setMeetings(updatedMeetings);
    setValue('meetings', updatedMeetings);
  } else {
    updatedMeetings = updatedMeetings.map((meeting, idx) => {
      if (index === idx) {
        return {
          ...meeting,
          [fieldName]: value,
        };
      }
      return meeting;
    });
    setMeetings(updatedMeetings);
    setValue('meetings', updatedMeetings);
  }
  };
  
  const handleRemove = (indexToRemove: number) => {
    setMeetings((prevMeetings) => {
      return prevMeetings.filter((_, index) => index !== indexToRemove);
    });
  };

  const handleRemoveModule = (meetingIndex: number, moduleIndex:number) => {
    setMeetings(prevMeetings => {
      const updatedMeetings = [...prevMeetings];
      const updatedMateri = [...updatedMeetings[meetingIndex].materi[0]];
  
      updatedMateri.splice(moduleIndex, 1); 
      updatedMeetings[meetingIndex].materi[0] = updatedMateri;
  
      return updatedMeetings; 
    });
  };

  const handleRemoveQuestion = (meetingIndex: number, questionIndex:number) => {
    setMeetings(prevMeetings => {
      const updatedMeetings = [...prevMeetings];
      const updatedQuestion = [...updatedMeetings[meetingIndex].question];
      const updatedQuestionPackage = [...updatedMeetings[meetingIndex].question_package]
  
      updatedQuestion.splice(questionIndex, 1);
      updatedQuestionPackage.splice(questionIndex, 1);
  
      updatedMeetings[meetingIndex] = {
        ...updatedMeetings[meetingIndex],
        question: updatedQuestion,
        question_package: updatedQuestionPackage,
      };
  
      return updatedMeetings; 
    });
  };

  return (
    <>
      <section className="grid gap-y-5">
          {Meetings.length ? (
            Meetings.map((Meetings, index) => {
              return(
                <div className="bg-white rounded-xl h-fit ">
                  <div className="flex px-5 py-3 items-center relative justify-between flex-wrap border-b">
                    <p className="font-bold">Pertemuan {index+1}</p>
                    <button
                      type="button"
                      title="Delete"
                      onClick={() => handleRemove(index)}
                      className="w-[40px] aspect-square bg-[#F04438] rounded-xl flex justify-center items-center ml-3"
                    >
                      <Button.Icon action="delete" />
                    </button>
                  </div>
                  <div className="grid grid-flow-col gap-x-5 px-5 py-4">
                    <div>
                      <p>Materi</p>
                      <label
                        htmlFor={'materi'}
                        className={'items-center cursor-pointer flex flex-1 rounded-lg bg-white flex-row divide-x divide-[#D0D5DD] border border-[#D0D5DD]'}>
                        <label htmlFor="materi" className={'ml-4 text-gray-500'}>Pilih materi</label>
                        <input id={'materi'} className='invisible flex rounded-lg flex-1' onClick={toggleModule}/>
                        <span className='p-2'>Browse</span>
                      </label>
                      <MeetingModuleList
                        modules={Meetings.materi}
                        onDelete={(moduleIndex) => handleRemoveModule(index, moduleIndex)}
                      />
                    </div>
                    <div>
                      <p>Latihan soal</p>
                      <label
                        htmlFor={'Latihan question'}
                        className={'items-center cursor-pointer flex flex-1 rounded-lg bg-white flex-row divide-x divide-[#D0D5DD] border border-[#D0D5DD]'}>
                        <label htmlFor="Latihan question" className={'ml-4 text-gray-500'}>Pilih Latihan soal</label>
                        <input id={'Latihan question'} className='invisible flex rounded-lg flex-1' onClick={toggleQuestion}/>
                        <span className='p-2'>Browse</span>
                      </label>
                      <MeetingQuestionList 
                        modules={Meetings.question}
                        onDelete={(questionIndex) => handleRemoveQuestion(index, questionIndex)}
                      />
                    </div>
                  </div>
                  <div className="px-5 py-4">
                    <div className="flex gap-x-5">
                          <DatePicker
                            placeholder="Pilih jadwal"
                            label="date"
                            required
                            value={Meetings.date[index]}
                            onChange={(val) => {
                              handleMeetingsChange(index, 'date', val);
                              }
                            }
                          />
                          <TimePicker 
                            label= "Jam Pertemuan"
                            value= ""
                            onChange={(val) => {
                              handleMeetingsChange(index, 'clock', val);
                              }
                            }
                          />
                      <div>
                      <p className={`font-medium text-sm text-gray-700 mb-1.5 after:text-red-500`}>Durasi</p>
                      <div className="flex flex-row">
                        <label
                            htmlFor={'jam'}
                            className={'items-center cursor-pointer flex flex-1 rounded-lg bg-white flex-row divide-x divide-[#D0D5DD] border border-[#D0D5DD] mt-0.5'}>
                            <span className='p-2.5'>Jam</span>
                            <input placeholder="1" id={'jam'} className='pl-4 w-20'
                              onChange={(e) => {
                              handleMeetingsChange(index, 'jam', e.target.value);
                              }
                            }/>
                          </label>
                          <label
                            htmlFor={'menit'}
                            className={'items-center cursor-pointer flex flex-1 rounded-lg bg-white flex-row divide-x divide-[#D0D5DD] border border-[#D0D5DD] mt-0.5 ml-2'}>
                            <span className='p-2.5'>Menit</span>
                            <input placeholder="30" id={'menit'} className='pl-4 w-20'
                            onChange={(e) => {
                              handleMeetingsChange(index, 'menit', e.target.value);
                              }
                            }
                            />
                          </label>
                      </div>
                      </div>
                    </div>
                  </div>
                  <BrowseDirectory
                    show={showModule}
                    onClose={toggleModule}
                    onSubmit={(val) => {
                      handleMeetingsChange(index, 'materi', val);
                    } }
                    selectedQuestions={Meetings.materi[index]}                  
                  />
                  <BrowseQuestion
                    show={showQuestion}
                    onClose={(toggleQuestion)}
                    onSubmit={(val) => {
                      handleMeetingsChange(index, 'question', val);
                    } }
                    selectedQuestion={[]} 
                    title={""} 
                    directories={[]}                  
                  />
                </div>
              )
            })
          ) : (
            <p className="my-6 px-5 text-gray-400 text-left">
              Anda belum menambahkan pertemuan apapun.
            </p>
          )}
        <div
            className={`flex p-5 justify-between gap-x-12`}
          >
              <MeetingsComposition meetings={Meetings} />
            <Button
              color="outline-gray"
              className="bg-yellow-100 hover:bg-yellow-200 hover:text-gray-800"
              onButtonClick={() => {
                addNewMeeting(Meetings);
              }}
            >
              <FontAwesomeIcon icon={icon({ name: "user-plus" })} />
              <span className="ml-1.5">Tambah Pertemuan</span>
            </Button>
          </div>
      </section>
    </>
  );
};

export default memo(Meetings);
