import { memo } from "react";
import { useFormContext } from "react-hook-form";
import ScoreSection from "../../fragment/TryOut/Settings/Score";
import Duration from "../../fragment/TryOut/Settings/Duration";

type ScoreDifficulty = keyof TryoutPayload["score"] | "general";
type DurationDifficulty = keyof TryoutPayload["duration"] | "general";

const Score = () => {
  const { getValues, setValue } = useFormContext<TryoutPayload>();
  const currentValue = getValues();

  const handleScore = (difficulty: ScoreDifficulty, value: number) => {
    if (difficulty === "general") {
      setValue("score.easy", value);
      setValue("score.medium", value);
      setValue("score.hard", value);
    } else {
      setValue(`score.${difficulty}`, value);
    }
  };

  const handleDuration = (difficulty: DurationDifficulty, value: number) => {
    if (difficulty === "general") {
      setValue("duration.easy", value);
      setValue("duration.medium", value);
      setValue("duration.hard", value);
    } else {
      setValue(`duration.${difficulty}`, value);
    }
  };

  const handleSwitchScore = (value: boolean) => {
    setValue("is_flat_score", value);
    setValue("score.easy", 0);
    setValue("score.medium", 0);
    setValue("score.hard", 0);
  };

  const handleSwitchDuration = (value: boolean) => {
    setValue("is_flat_duration", value);
    setValue("duration.easy", 0);
    setValue("duration.medium", 0);
    setValue("duration.hard", 0);
  };

  return (
    <section className="flex gap-x-5">
      <ScoreSection
        data={currentValue}
        isEdit
        onSwitch={handleSwitchScore}
        onChange={handleScore}
      />
      <Duration
        data={currentValue}
        isEdit
        onSwitch={handleSwitchDuration}
        onChange={handleDuration}
      />
    </section>
  );
};

export default memo(Score);
