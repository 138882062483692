export function Spinner() {
  return (
    <div className="flex justify-center">
      <div className="spinner-wave">
        <div className="spinner-wave-dot"></div>
        <div className="spinner-wave-dot"></div>
        <div className="spinner-wave-dot"></div>
        <div className="spinner-wave-dot"></div>
      </div>
    </div>
  )
}