/* eslint-disable @typescript-eslint/no-unused-vars */
// React
import { useEffect, useState } from "react"

// API
import { services } from "services"

// Components
import { Card, Content, Modal } from "components"

// Utilities
import {SilabusProgramDetail } from "../utilities"
import { SaveAsDraft } from "../components/SaveAsDraft"

interface Grade {
  id: number;
  name: string;
}

interface Subject {
  id: number;
  name: string;
}
interface Directory {
  id: number;
  name: string;
}
interface Major {
  id: number;
  name: string;
}

interface MainDropdownItem {
  id: number;
  grade: Grade;
  subject: Subject;
  major: null;
  name: string;
  description: boolean | null;
  directory: Directory
  updated_at: string;
}

interface MainDropdownItem {
  id: number;
  type: 'directory' | 'syllabus';
  name: string;
}

interface SubDropdownItem {
  id: number;
  type: 'directory' | 'syllabus';
  name: string;
}
export default function Silabus() {
   const [majorID, setMajorID] = useState<number | null | undefined>(null);
   const [gradeID, setGradeID] = useState<number | null | undefined>(null)
   const [subjectID, setSubjectID] = useState<number | null | undefined>(null)
   const [directoryID, setDirectoryID] = useState<number | null | undefined>(null)
   const [silabusID, setSilabusID] = useState<number | null>()
    const [isChooseSilabusModal, setIsChooseSilabusModal] = useState(false); 
  //   const [mainDropdownData, setMainDropdownData] = useState<MainDropdownItem[]>([]);
  // const [openDropdown, setOpenDropdown] = useState<number | null>(null);
  // const [subDropdownData, setSubDropdownData] = useState<SubDropdownItem[]>([]);
  const [selectedFolders, setSelectedFolders] = useState<number[]>([]);
  // const [selectedFolderValue, setSelectedFolderValue] = useState('')
  const [programData, setProgramData] = useState<string | null>(null)
  
  const [savedProgramData, setSavedProgramData] = useState<string | null>(null)

  const [mainDropdownData, setMainDropdownData] = useState<MainDropdownItem[]>([]);
  const [subDropdownData, setSubDropdownData] = useState<SubDropdownItem[]>([]);
  const [openDropdown, setOpenDropdown] = useState<number | null>(null);
  const [selectedSyllabus, setSelectedSyllabus] = useState<SubDropdownItem | { id: number; name: string } | null>(null);

  const [selectedFolderValue, setSelectedFolderValue] = useState('');

const handlePilihSilabusClick = () => {
  if (selectedSyllabus instanceof Array) {
    const selectedSyllabusNames = selectedSyllabus.map(syllabus => syllabus.name).join(', ');
    setSelectedFolderValue("Silabus terpilih: " + selectedSyllabusNames);
  } else if (selectedSyllabus) {
    setSelectedFolderValue("Silabus terpilih: " + selectedSyllabus.name);
  }
  setIsChooseSilabusModal(false)
};

  useEffect(() => {
    async function fetchMainDropdownData() {
      try {
        const response = await services.get('/browse?includes=syllabus'); // Replace with your API endpoint
        const data = response.data.data;
        setMainDropdownData(data);
      } catch (error) {
        console.error('Error fetching main dropdown data:', error);
      }
    }

    fetchMainDropdownData();
  }, []);

  const toggleDropdown = async (dropdownId: number) => {
    setOpenDropdown(prevOpenDropdown => (prevOpenDropdown === dropdownId ? null : dropdownId));
    setSelectedSyllabus(null)

    // Fetch subdropdown data based on the selected directory ID
    if (dropdownId !== null) {
      try {
        const response = await services.get(`/browse?directory_id=${dropdownId}`); // Replace with your API endpoint
        const data = response.data.data;
        setSubDropdownData(data);
      } catch (error) {
        console.error('Error fetching sub dropdown data:', error);
      }
    } else {
      setSubDropdownData([]);
    }
  };
  const handleSyllabusCheckboxChange = (syllabusId: number, syllabusName: string) => {
    setSelectedSyllabus({ id: syllabusId, name: syllabusName });
    setOpenDropdown(null)
  };


  const currentPath = window.location.pathname;
  // Extract the program_id from the current URL
  const programIdMatch = currentPath.match(/\/silabus\/(\d+)/);
  const programIdFromURL = programIdMatch ? programIdMatch[1] : null;

  // const toggleDropdown = (dropdownId: number) => {
  //   if (openDropdown === dropdownId) {
  //     setOpenDropdown(null);
  //     setSubDropdownData([]); // Clear subDropdownData when closing dropdown
  //   } else {
  //     setOpenDropdown(dropdownId);
  //     if (dropdownId !== 0) {
  //       const selectedMainDropdown = mainDropdownData.find(item => item.id === dropdownId);
  //       if (selectedMainDropdown && selectedMainDropdown.grade.id !== null && selectedMainDropdown.subject.id !== null) {
  //         const subItems: SubDropdownItem[] = [
  //           selectedMainDropdown.grade,
  //           selectedMainDropdown.subject,
  //           // selectedMainDropdown.major,
  //           selectedMainDropdown.directory, 
  //         ];
  //         if (selectedMainDropdown) { 
  //           subItems.push(selectedMainDropdown.grade);
  //           subItems.push(selectedMainDropdown.subject);
  //           // subItems.push(selectedMainDropdown.major);
  //           subItems.push(selectedMainDropdown.directory);
  //         }
  //         setSubDropdownData(subItems);
  //         // setGradeID(selectedMainDropdown.grade.id)
  //         // setSubjectID(selectedMainDropdown.subject.id)
  //         // setDirectoryID(selectedMainDropdown.directory.id)
  //       } else {
  //         setSubDropdownData([]);
  //       }
  //     }
  //   }
  // };

  // useEffect(() => {
  //   async function fetchDropdownData() {
  //     try {
  //       const response = await services.get('/syllabus');
  //       const data = response.data.data.data;
  //       setMainDropdownData(data);
  //     } catch (error) {
  //       console.error('Error fetching data:', error);
  //     }
  //   }

  //   fetchDropdownData();
  // }, []);

  useEffect(() => {
    // Get item from local storage
    // const storedData = localStorage.getItem('program-data');
    // if (storedData) {
    //   setProgramData(storedData);
    // }
    const existingData = localStorage.getItem('list-program-data');
    let dataArray: any[] = [];

    if (existingData) {
      dataArray = JSON.parse(existingData);
    }
    
    // Get the latest data (last item in the array)
    const latestData = dataArray[dataArray.length - 1];
    
    if (latestData) {
      setProgramData(latestData)
      const programId = latestData.id;
      console.log('Latest Program ID:', programId);
    } else {
      console.log('No data available.');
    }
  }, []);

  function handleBack(){
    window.location.href = '/program/program-detail'
  }
  async function handleNext(){
    try {
      const existingData = localStorage.getItem('list-program-data');
      let dataArray: any[] = [];
  
      if (existingData) {
        dataArray = JSON.parse(existingData);
      }
      
      // Get the latest data (last item in the array)
      const latestData = dataArray[dataArray.length - 1];
      if (latestData) {
        const data = {
        program_id:programIdFromURL ? parseInt(programIdFromURL): latestData.program_id,
        grade_id: programIdFromURL ? gradeID : gradeID,
        major_id: programIdFromURL ? majorID : majorID,
        syllabus_id: programIdFromURL ? silabusID : silabusID,
        subject_id:programIdFromURL ? subjectID : subjectID,
        teacher_id: latestData.teacher_id,
        title:latestData.title,
        thumbnail_path: latestData.thumbnail_path,
        price: latestData.price,
        discount: latestData.discount,
        is_promo: latestData.is_promo,
        is_active: latestData.is_active,
        is_flat_disc: latestData.is_flat_disc,
        type: latestData.type,
        is_draft: latestData.is_draft,
        type_detail: {
          register_until:latestData.type_detail.register_until,
          started_at:latestData.type_detail.started_at,
          max_students: latestData.type_detail.max_students,
          meet_time: latestData.type_detail.meet_time
        }
      };
      // if(latestData.program_id === null){
      //   if (dataArray.length > 0) {
      //     dataArray[dataArray.length - 1] = data; // Replace the data at the last index with the new 'data'
      //   }
      // } else{
      //   const programIndex = dataArray.findIndex((item) => item.program_id === latestData.program_id);

      //   if (programIndex !== -1) {
      //     dataArray[programIndex] = data; // Replace the data at the selected index
      //   }
      // }
      if (data.program_id === null) {
        dataArray.push(data); // Push the new 'data' to the end of the array
      } else {
        const programIndex = dataArray.findIndex((item) => item.program_id === data.program_id);
      
        if (programIndex !== -1) {
          // Replace the data at the selected index
          dataArray[programIndex] = data;
        } else {
          // Add the new 'data' to the end of the array
          dataArray.push(data);
        }
      }
      localStorage.setItem('list-program-data', JSON.stringify(dataArray));
      const silabusUrl = programIdFromURL ? `/program/kelas/${programIdFromURL}` : '/program/kelas';
    
      // Update the window location to navigate to the silabus page
      window.location.href = silabusUrl;
      // window.location.href = '/program/kelas'
    }
    } catch (error) {
      console.error('Failed to create program', error);
    }
    // window.location.href = '/program/Kelas'
  }

  function handleChooseSilabusModalToggle() {
    setIsChooseSilabusModal((prev) => !prev); // Toggle the state to show/hide the modal
  }
  // const handlePilihSilabusClick = () => {
    // setSelectedFolderValue(selectedSyllabus?.name)
    // const selectedFolderNames = selectedFolders.map(folderId =>
    //   mainDropdownData.find(item => item.id === folderId)?.name || ""
    // );
    // setSelectedFolderValue("Silabus terpilih: " + selectedFolderNames.join(", "))
    // const selectedFoldersData = selectedFolders.map(folderId =>
    //   mainDropdownData.find(item => item.id === folderId)
    // );
  
    // // Display the selected folders data
    // selectedFoldersData.forEach(folderData => {
    //   console.log("Folder ID:", folderData?.id);
    //   console.log("Folder Name:", folderData?.name);
    //   // console.log("Description:", folderData?.description);
    //   // console.log("Major:", folderData?.major?.name);
    //   // console.log("Directory:", folderData?.directory.id);
    //   console.log("Updated At:", folderData?.updated_at);
    //   setSelectedFolderValue("Silabus terpilih: " +  folderData?.name)
    //   setGradeID(folderData?.grade.id)
    //   // setMajorID(folderData?.major?.id)
    //   setSubjectID(folderData?.subject?.id)
    //   // setDirectoryID(folderData?.directory.id)
    //   setSilabusID(folderData?.id)
    // });
  //   setIsChooseSilabusModal(false);
  // };
  const handleFolderCheckboxChange = (folderId: number) => {
    if (selectedFolders.includes(folderId)) {
      setSelectedFolders(selectedFolders.filter(id => id !== folderId));
      // setSilabusID(folderId)
    } else {
      setSelectedFolders([...selectedFolders, folderId]);
    }
  };

  const saveToLocalStorage = () => {
    const existingData = localStorage.getItem('list-program-data');
      let dataArray: any[] = [];
  
      if (existingData) {
        dataArray = JSON.parse(existingData);
      }
      // Get the latest data (last item in the array)
      const latestData = dataArray[dataArray.length - 1];
      if (latestData) {
        const dataToSave = {
        program_id:latestData.program_id,
        grade_id: programIdFromURL ? gradeID : gradeID,
        major_id: programIdFromURL ? majorID : majorID,
        syllabus_id: programIdFromURL ? silabusID : silabusID,
        subject_id:programIdFromURL ? subjectID : subjectID,
        teacher_id: latestData.teacher_id,
        title:latestData.title,
        thumbnail_path: latestData.thumbnail_path,
        price: latestData.price,
        discount: latestData.discount,
        is_promo: latestData.is_promo,
        is_active: latestData.is_active,
        is_flat_disc: latestData.is_flat_disc,
        type: latestData.type,
        is_draft: latestData.is_draft,
        type_detail: {
          register_until:latestData.type_detail.register_until,
          started_at:latestData.type_detail.started_at,
          max_students: latestData.type_detail.max_students,
          meet_time: latestData.type_detail.meet_time
        }
      };
      if(latestData.program_id === null){
        if (dataArray.length > 0) {
          dataArray[dataArray.length - 1] = dataToSave; // Replace the data at the last index with the new 'data'
        }
      } else{
        const programIndex = dataArray.findIndex((item) => item.program_id === latestData.program_id);

        if (programIndex !== -1) {
          dataArray[programIndex] = dataToSave; // Replace the data at the selected index
        }
      }
      localStorage.setItem('saved-draft-data', JSON.stringify(dataArray));
  }
}

  return (
    <div className="h-[100vh] bg-[#f6f6f6]">
    <Content>
      <Content.Header
        title={`${SilabusProgramDetail.title} (2/4)`}
        rightElement={<SaveAsDraft onSave={saveToLocalStorage}/>}
      />

      <Content.Body>
        <Card>
          <Card.Body>
            <div className="flex flex-col w-full text-black gap-8">
            <div className="flex flex-col w-full gap-2">
                        <label className="font-semibold">Pilih Silabus</label>
                        <div className="flex flex-row items-center">
                        <button
                            onClick={handleChooseSilabusModalToggle}
                            className="flex flex-row gap-2 items-center bg-[#FFFEE6] text-[#E8DC00] py-2 px-4 cursor-pointer border-2 border-none rounded-md hover:bg-[#FFFEE6] hover:text-[#E8DC00] me-2"
                        >
                            <img src="/assets/Upload.png" alt="/" />
                            Pilih Silabus
                        </button>
                        {selectedFolderValue}
                        {/* <button
                            type="file"
                            id="file-input"
                            className="hidden"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                        ></button> */}
                        {/* <div
                            className="left-0 top-0 right-0 py-2 px-4 bg-white rounded-md"
                            style={{ visibility: fileName !== 'Choose File' ? 'visible' : 'hidden' }}
                        >
                            {fileName}
                        </div> */}
                        </div>
                       
                        <label className="text-[#B4BAC6]">Silakan pilih satu silabus yang anda ingin terapkan pada program ini</label>
                        </div>
                <div className="flex flex-row w-full justify-end items-center gap-4">
                    <button onClick={handleBack} className="px-[20px] py-[10px] text-[#8C8500] bg-[#FFFBB0] rounded-[8px]">Kembali</button>
                    <button onClick={handleNext} className="px-[20px] py-[10px] text-black bg-[#FFF200] rounded-[8px]">Lanjutkan</button>
                </div>
            </div>
          </Card.Body>
        </Card>
      </Content.Body>
      
    {isChooseSilabusModal && (
        <Modal size="full" isOpen title="" closeModal={()=> setIsChooseSilabusModal(false)}>
          <Modal.Body>
          <Content>
      <Content.Body>
        <Card>
          <Card.Header>
            <div className="flex flex-col md:flex-row justify-center md:justify-between items-center text-black">
              <Card.Title>{SilabusProgramDetail.title}</Card.Title>

              {/* <Select
                options={[
                  { label: "Pendaftar Terbaru" }
                ]}
              /> */}
            </div>
          </Card.Header>

          <Card.Body>
          <div className="relative">
      {mainDropdownData.map((item) => (
        <div key={item.id} className="flex flex-col w-full items-end">
          <div className="flex flex-row w-[95%] justify-between items-center">
            <div className="flex flex-row items-center w-full gap-4">
              {item.type === 'directory' ? (
                <>
                  <img src="/assets/folder_icon.png" alt="/" width={20} height={20} className="min-w-[20px] min-h-[20px]" />
                  <button
                    className={`bg-white text-black w-full px-4 py-2 rounded focus:outline-none flex flex-row justify-between ${
                      openDropdown === item.id ? 'bg-gray-200' : ''
                    }`}
                    onClick={() => toggleDropdown(item.id)}
                  >
                    {item.name}
                  </button>
                </>
              ) : (
                <label className="bg-white text-black px-2 py-1 rounded flex items-center">
                  <input
                    type="checkbox"
                    checked={selectedSyllabus?.id === item.id}
                    onChange={() => handleSyllabusCheckboxChange(item.id, item.name)}
                  />
                  {item.name}
                </label>
              )}
            </div>
            {item.type === 'directory' ? (
              openDropdown === item.id ? (
                <img src="/assets/dropdown_arrow.png" alt="/" className="rotate-180 transition-all duration-300" />
              ) : (
                <img src="/assets/dropdown_arrow.png" alt="/" className="rotate-0 transition-all duration-300" />
              )
            ) : null}
          </div>

          {openDropdown === item.id && item.type === 'directory' && (
            <div className="w-[90%] flex flex-col gap-2 ml-4">
              {subDropdownData.map((subItem) => (
                <div key={subItem.id} className="flex flex-row items-center justify-between gap-2">
                  {subItem.type === 'directory' ? (
                    <>
                      <img src="/assets/folder_icon.png" alt="/" width={20} height={20} className="min-w-[20px] min-h-[20px]" />
                      <button
                        className={`bg-white text-black w-full px-4 py-2 rounded focus:outline-none flex flex-row justify-between ${
                          openDropdown === subItem.id ? 'bg-gray-200' : ''
                        }`}
                        onClick={() => toggleDropdown(subItem.id)}
                      >
                        {subItem.name}
                      </button>
                    </>
                  ) : (
                    <label className="bg-white text-black px-2 py-1 rounded flex items-center">
                      <input
                        type="checkbox"
                        checked={selectedSyllabus?.id === subItem.id}
                        onChange={() => handleSyllabusCheckboxChange(subItem.id, subItem.name)}
                      />
                      {subItem.name}
                    </label>
                  )}
                  {subItem.type === 'directory' ? (
                    openDropdown === subItem.id ? (
                      <img src="/assets/dropdown_arrow.png" alt="/" className="rotate-180 transition-all duration-300" />
                    ) : (
                      <img src="/assets/dropdown_arrow.png" alt="/" className="rotate-0 transition-all duration-300" />
                    )
                  ) : null}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
          </Card.Body>
        </Card>
      </Content.Body>
    </Content>
          </Modal.Body>

          <Modal.Footer className="flex justify-end gap-x-2">
      <div className="flex flex-row w-full justify-end items-center gap-4">
                    <button onClick={()=>setIsChooseSilabusModal(false)} className="px-[20px] py-[10px] text-[#8C8500] bg-[#FFFBB0] rounded-[8px]">Batal</button>
                    <button onClick={()=>handlePilihSilabusClick()} className="px-[20px] py-[10px] text-black bg-[#FFF200] rounded-[8px]">Pilih Silabus</button>
                </div>
          </Modal.Footer>
        </Modal>
      )}
    </Content>
    </div>
  )
}