import { FC, memo } from "react";
import { Input } from "components";
import LatestOption from "./LatestOption";
import PromoStatusOption from "./PromoStatusOption";
import PromoTypeOption from "./PromoTypeOption";

type Props = {
  onChange: (value: string) => void;
  onChangeLatest: (value: PromoParams["latest"] | null) => void;
  onChangeStatus: (value: PromoParams["status"] | null) => void;
  onChangeType: (value: PromoType | null) => void;
};

const Filter: FC<Props> = ({
  onChange,
  onChangeLatest,
  onChangeStatus,
  onChangeType,
}) => {
  return (
    <div className="flex items-center gap-5 flex-wrap">
      <div className="w-[30rem]">
        <Input.Search
          onChange={(e) => onChange(e.target.value)}
          placeholder="Cari promo atau voucher..."
        />
      </div>
      <PromoStatusOption onChange={onChangeStatus} />
      <PromoTypeOption onChange={onChangeType} />
      <LatestOption onChange={onChangeLatest} />
    </div>
  );
};

export default memo(Filter);
