import { memo, useCallback, useMemo, useState } from "react";
import { useNavigate, useBeforeUnload, useBlocker } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { Button } from "components";

import DetailProgram from "./detail";
import Question from "./question";
import Score from "./score";
import Summary from "./summary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import toast from "react-hot-toast";
import useProgram from "services/useProgram";
import ProgressBullet from "components/ProgressBullet";
import DraftPrompt from "../../fragment/Modal/DraftPrompt";

const STEPS = [
  {
    label: "Detail program",
    component: <DetailProgram />,
  },
  {
    label: "Soal",
    component: <Question />,
  },
  {
    label: "Pengaturan",
    component: <Score />,
  },
  {
    label: "Ringkasan",
    component: <Summary />,
  },
];

const Tryout = () => {
  const { addTryout } = useProgram();
  const navigate = useNavigate();

  const [step, setStep] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const methods = useForm<TryoutPayload>({
    defaultValues: {
      description: "",
      discount: 0,
      grade_id: null,
      is_active: true,
      is_draft: true,
      is_explanation_showed: true,
      is_flat_disc: true,
      is_flat_duration: false,
      is_flat_score: false,
      is_limited_participant: true,
      major_id: null,
      number_of_participants: 0,
      price: 0,
      program_id: null,
      question_package: [],
      subject_id: "",
      syllabus_id: "",
      teacher_id: null,
      thumbnail_path: "",
      title: "",
      type: "tryout",
      variety_id: "",
    },
  });
  const { handleSubmit, watch, setValue } = methods;
  const title = watch("title");
  const description = watch("description");
  const thumbnail = watch("thumbnail_path");
  const isLimited = watch("is_limited_participant");
  const participants = watch("number_of_participants");
  const registrationStart = watch("registration_period_start");
  const registrationEnd = watch("registration_period_end");
  const startDate = watch("start_date");
  const endDate = watch("end_date");
  const price = watch("price");
  const questionPerStudent = watch("question_each_student");
  const varietyId = watch("variety_id");
  const variety = watch("variety");
  const questions = watch("question_package");
  const isFlatScore = watch("is_flat_score");
  const easyScore = watch("score.easy");
  const mediumScore = watch("score.medium");
  const hardScore = watch("score.hard");
  const isFlatDuration = watch("is_flat_duration");
  const easyDuration = watch("duration.easy");
  const mediumDuration = watch("duration.medium");
  const hardDuration = watch("duration.hard");
  const programId = watch("program_id");

  const validateDetail = useMemo(() => {
    const hasParticipant = isLimited ? Boolean(participants) : true;

    return Boolean(
      title &&
        description &&
        thumbnail &&
        hasParticipant &&
        registrationStart &&
        registrationEnd &&
        startDate &&
        endDate &&
        price &&
        questionPerStudent
    );
  }, [
    title,
    description,
    thumbnail,
    isLimited,
    participants,
    registrationStart,
    registrationEnd,
    startDate,
    endDate,
    price,
    questionPerStudent,
  ]);

  const validateQuestion = useMemo(() => {
    const totalSelected = questions.reduce(
      (prev, current) => prev + current.n_question_value,
      0
    );
    const isValid = totalSelected >= (variety?.total_variety ?? 0);
    return Boolean(varietyId && questions.length && isValid);
  }, [varietyId, questions, variety]);

  const validateConfig = useMemo(() => {
    let validScore = false,
      validDuration = false;

    const easyQuestion = questions.filter((val) => val.difficulty === 1).length;
    const mediumQuestion = questions.filter(
      (val) => val.difficulty === 2
    ).length;
    const hardQuestion = questions.filter((val) => val.difficulty === 3).length;

    if (isFlatScore) {
      validScore = Boolean(easyScore);
    } else {
      const isEasy = easyQuestion ? Boolean(easyScore) : true;
      const isMedium = mediumQuestion ? Boolean(mediumScore) : true;
      const isHard = hardQuestion ? Boolean(hardScore) : true;
      validScore = Boolean(isEasy && isMedium && isHard);
    }

    if (isFlatDuration) {
      validDuration = Boolean(easyDuration);
    } else {
      const isEasy = easyQuestion ? Boolean(easyDuration) : true;
      const isMedium = mediumQuestion ? Boolean(mediumDuration) : true;
      const isHard = hardQuestion ? Boolean(hardDuration) : true;
      validDuration = Boolean(isEasy && isMedium && isHard);
    }

    return validScore && validDuration;
  }, [
    isFlatScore,
    easyScore,
    mediumScore,
    hardScore,
    isFlatDuration,
    easyDuration,
    mediumDuration,
    hardDuration,
    questions,
  ]);

  const canContinue = useMemo(() => {
    switch (step) {
      case 0:
        return validateDetail;
      case 1:
        return validateQuestion;
      case 2:
        return validateConfig;
      default:
        return false;
    }
  }, [step, validateDetail, validateQuestion, validateConfig]);

  // block document reload or close
  useBeforeUnload(
    useCallback(
      (event) => {
        if (programId) {
          event.preventDefault();
          return null;
        }
      },
      [programId]
    ),
    {
      capture: true,
    }
  );

  // block client navigate
  const blocker = useBlocker(Boolean(programId) && !isSubmitting);

  const onSubmit = (data: TryoutPayload, isDraft?: boolean) => {
    setIsSubmitting(true);
    const payload: TryoutPayload = {
      ...data,
      is_draft: Boolean(isDraft),
    };
    toast.promise(addTryout(payload), {
      loading: "Loading...",
      success: () => {
        navigate("/program", {
          replace: true,
        });
        return "";
      },
      error: () => {
        setIsSubmitting(false);
        return "Terjadi kesalahan";
      },
    });
  };

  const handleNext = (data: TryoutPayload) => {
    if (step < STEPS.length && canContinue) {
      toast.promise(addTryout(data), {
        loading: "Loading...",
        success: (response: string) => {
          setValue("program_id", response);
          setStep(step + 1);
          return "";
        },
        error: "Terjadi kesalahan",
      });
    }
  };

  const handleCancel = () => {
    if (step > 0) {
      setStep(step - 1);
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <section className="h-full overflow-y-scroll hide-scrollbar mt-[-1.5rem] pb-20">
        <div className="flex items-center gap-x-10 my-5">
          <p className="font-bold text-xl basis-40">Try out</p>
          <ProgressBullet
            data={STEPS.map((val) => val.label)}
            currentStep={step}
            onBack={() => setStep(step - 1)}
            onNext={handleSubmit((data) => handleNext(data))}
            canContinue={canContinue}
          />
        </div>
        <FormProvider {...methods}>{STEPS[step].component}</FormProvider>
      </section>
      <div className="flex justify-between items-center absolute left-0 right-0 bottom-0 bg-white px-8 py-5 border-t">
        <Button
          color="outline-gray"
          onButtonClick={handleSubmit((data) => onSubmit(data, true))}
        >
          <FontAwesomeIcon icon={icon({ name: "pen" })} />
          <span className="ml-3">Simpan sebagai draft</span>
        </Button>
        <div className="">
          <Button color="outline-gray" onButtonClick={handleCancel}>
            Batal
          </Button>
          {step === STEPS.length - 1 ? (
            <Button
              color="primary"
              className="ml-3"
              onButtonClick={handleSubmit((data) => onSubmit(data, false))}
            >
              Submit
            </Button>
          ) : (
            <Button
              isDisabled={!canContinue}
              color="primary"
              className="ml-3"
              onButtonClick={handleSubmit((data) => handleNext(data))}
            >
              Lanjutkan
            </Button>
          )}
        </div>
      </div>
      <DraftPrompt
        show={blocker.state === "blocked"}
        onClose={() => blocker.reset?.()}
        onProceed={() => blocker.proceed?.()}
      />
    </>
  );
};

export default memo(Tryout);
