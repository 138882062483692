import { memo } from "react";
import { useFormContext } from "react-hook-form";
import ListQuestion from "../../fragment/TryOut/Question/ListQuestion";
import QuestionComposition from "../../fragment/TryOut/Question/QuestionComposition";
import Info from "../../fragment/TryOut/Detail/Info";
import Price from "../../fragment/TryOut/Detail/Price";
import Config from "../../fragment/TryOut/Detail/Config";
import Score from '../../fragment/TryOut/Settings/Score';
import Duration from "../../fragment/TryOut/Settings/Duration";

const Summary = () => {
  const { getValues } = useFormContext<TryoutPayload>();
  const currentValue = getValues();

  return (
    <section className="grid gap-y-5">
      <div className="flex gap-x-8">
        <Info data={{ ...currentValue }} />
        <div className="flex flex-col gap-y-6">
          <Price data={{ ...currentValue }} />
          <Config data={{ ...currentValue }} />
        </div>
      </div>
      <div className="bg-white rounded-xl">
        <p className="font-bold px-5 py-4 border-b">Variasi soal</p>
        <div className="p-5 flex gap-x-8">
          <div className="flex flex-col gap-y-1.5">
            <p className="text-sm font-medium text-gray-700">Variasi soal</p>
            <p className="px-3.5 py-2.5 bg-primary rounded-lg min-w-[10rem] text-center font-semibold text-sm">
              {currentValue.variety?.name || "-"}
            </p>
          </div>
          <QuestionComposition questions={currentValue.question_package} />
        </div>
      </div>
      <div className="bg-white rounded-xl">
        <p className="font-bold px-5 py-4 border-b">Daftar soal</p>
        <ListQuestion data={currentValue.question_package} />
      </div>
      <div className="flex gap-x-5">
        <Score data={currentValue} />
        <Duration data={currentValue} />
      </div>
    </section>
  );
};

export default memo(Summary);
