import {FC, memo} from 'react';
import Modal from 'components/Modal';
import {Button} from 'components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';

type Props = {
  show: boolean
  onClose: () => void
  onDelete: () => Promise<void> | void
}

const DeletePrompt: FC<Props> = ({show, onClose, onDelete}) => {
  return (
    <Modal
      title=''
      show={show}
      onClose={onClose}
      customFooter={(
        <div className="grid grid-flow-col justify-center gap-x-3">
          <Button.Cancel onButtonClick={onClose} />
          <Button color="primary" onButtonClick={onDelete}>
            Hapus
          </Button>
        </div>
      )}
    >
      <div className="grid justify-center justify-items-center gap-y-10">
        <FontAwesomeIcon icon={icon({name: 'circle-exclamation'})} size="3x" color="red" />
        <p className='font-bold text-2xl'>Anda yakin?</p>
        <p className='text-gray-400'>Program yang telah dihapus tidak dapat dipulihkan kembali.</p>
      </div>
    </Modal>
  );
};

export default memo(DeletePrompt);
