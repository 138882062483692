import { FC, memo, useState } from "react";

type Props = {
  label: string;
  isChecked?: boolean;
  onChange: (checked: boolean) => void;
};

const ToggleSwitch: FC<Props> = (props) => {
  const { label, isChecked = false, onChange } = props;

  const [checked, setChecked] = useState(isChecked);

  const handleToggle = (value: boolean) => {
    onChange(value);
    setChecked(value);
  }

  return (
    <label className="relative inline-flex items-center cursor-pointer">
      <span className="mr-5 text-sm font-medium text-gray-700">{label}</span>
      <input
        type="checkbox"
        checked={checked}
        className="sr-only peer"
        onChange={(val) => handleToggle(val.target.checked)}
      />
      <div className="w-11 h-6 bg-gray-200 relative rounded-full peer peer-checked:bg-yellow-300 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all" />
    </label>
  );
};

export default memo(ToggleSwitch);
