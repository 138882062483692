import { FC, memo, useEffect, useState } from "react";
import DatePicker, { DateValueType } from "react-tailwindcss-datepicker";

type Props = {
  dateRange: DateValueType;
  onChange: (range: DateValueType) => void;
};

const Filter: FC<Props> = ({ dateRange, onChange }) => {
  const [range, setRange] = useState<DateValueType>(dateRange);

  useEffect(() => {
    setRange(dateRange);
    //eslint-disable-next-line
  }, [dateRange]);

  const handleRange = async (value: DateValueType) => {
    setRange(value);
    onChange(value);
  };

  return (
    <div className="flex items-center gap-x-2">
      <div className="w-64">
        <DatePicker
          value={range}
          startFrom={new Date(range?.startDate || "")}
          onChange={handleRange}
          showFooter
          showShortcuts
          primaryColor="blue"
          i18n={"id"}
          placeholder="Pilih durasi waktu"
          inputId="period"
          inputName="period"
        />
      </div>
    </div>
  );
};

export default memo(Filter);
