import {FC, memo, useEffect, useState} from "react";
import Modal from 'components/Modal';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import Table from "../../../../../../components/Table";
import {Button} from "../../../../../../components";

type Props = {
  show: boolean
  classId: number,
  onClose: () => void,
  onSuccess: () => void,
}

const STUDENT_DATA = ['','','','','','','','']

const columns = [
  {
    title: 'Nama Siswa',
    isSort: true,
    key: 'name'
  },
  {
    title: 'Sekolah',
    isSort: true,
    key: 'school_name'
  },
];

const ClassInfoModal: FC<Props> = (props) => {
  const {show, classId, onClose, onSuccess} = props;
  const [search, setSearch] = useState<string>('');
  const [selectedTeacherId, setSelectedTeacherId] = useState<number|null>(null)
  useEffect(()=>{
    setSelectedTeacherId(null)
    setSearch('')
  },[show,classId])
  return(
    <Modal
      show={show}
      title={`Info kelas`}
      onClose={onClose}
      customFooter={(
        <div className="float-right flex gap-x-2">
          <Button color='primary' onButtonClick={onClose}>
            Tutup
          </Button>
        </div>
      )}
    >
      <div className='flex flex-col space-y-3'>
        <div className='mt-4'>
          <span>Kelas ID</span>
          <span> #0928</span>
        </div>
        <ClassDetailSection/>
        <span className='font-bold'>Daftar peserta</span>
        <div className='flex flex-1 items-center'>
          <div className='flex flex-1'>
            <span className='font-normal text-base text-gray-600'>Total pengajar: </span>
            <span className='font-medium text-bold text-gray-600 ml-1'>76</span>
          </div>
          <SearchBox setSearch={setSearch} search={search}/>
        </div>
        <Table
          className={'px-0'}
          data={STUDENT_DATA}
          columns={columns}
          currentLimit={10}
          currentPage={1}
          total={10}
          handleLimit={()=>{}}
          handlePagination={()=>{}}
          handleSort={()=>{}}
        >
          {STUDENT_DATA.map((item,index)=>{
            return(
              <tr
                key={`${index}`}
                className={`border-b ${index === selectedTeacherId ? 'bg-yellow-100' : 'bg-transparent'}`}
                onClick={()=>setSelectedTeacherId(index)}
              >
                <td className='p-4'>Herbert Auer</td>
                <td className='p-4 font-normal text-gray-600'>SMA Bumi Langit 1 Jakarta</td>
              </tr>
            )
          })}
        </Table>

      </div>
    </Modal>
  )
}

const SearchBox = ({search, setSearch}:{search:string, setSearch: (val: string)=>void}) => {
  return(
    <div className='bg-white px-4 py-[0.625rem] rounded-xl w-72 flex gap-x-2 items-center border  justify-end'>
      <FontAwesomeIcon icon={icon({name: 'search'})}/>
      <input
        className='w-full'
        placeholder='Cari pengajar'
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      {Boolean(search.length) && (
        <FontAwesomeIcon
          icon={icon({name: 'close'})}
          onClick={() => setSearch('')}
          className='cursor-pointer'
        />
      )}
    </div>
  )
}

const ClassDetailSection = () => {
  return(
    <div className='bg-[#FFFCE6] p-6 rounded-md space-y-6'>
      <div className='flex flex-row justify-between'>
        <div className='flex flex-1 flex-col'>
          <span className='text-xs text-gray-500'>Pengajar</span>
          <span className='text-gray-900 font-medium text-base'>Bagus Deo S.Pd</span>
        </div>
        <div className='flex flex-1 flex-col'>
          <span className='text-xs text-gray-500'>Durasi</span>
          <span className='text-gray-900 font-medium text-base'>1 jam 30 menit</span>
        </div>
        <div className='flex flex-1 flex-col'>
          <span className='text-xs text-gray-500'>Jadwal</span>
          <span className='text-gray-900 font-medium text-base'>31/10/2023 14:00</span>
        </div>
      </div>


      <div className='flex flex-row justify-between'>
        <div className='flex flex-1 flex-col'>
          <span className='text-xs text-gray-500'>Materi</span>
          <span className='text-gray-900 font-medium text-base'>Perkalian bilangan genap</span>
        </div>
        <div className='flex flex-1 flex-col'>
          <span className='text-xs text-gray-500'>Soal</span>
          <span className='text-gray-900 font-medium text-base'>Perkalian bilangan genap</span>
        </div>
      </div>
    </div>
  )
}

export default memo(ClassInfoModal)
