// React
import { useEffect, useState } from "react";

// API
import { services } from "services";

// Types
import { ErrorFetch, SuccessFetch } from "types";
import { List } from "views/Silabus/types";

type SubjectType = {
  id: number;
  name: string;
};
interface Type extends List {
  name: string;
  subject: SubjectType;
}

export function useSingle(id: number) {
  // Hooks
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState<Type | null>(null);

  useEffect(() => {
    setLoading(true);

    services
      .get(`question-package/${id}`)
      .then(
        (
          res: SuccessFetch<{
            data: Type;
          }>
        ) => {
          setData(res.data.data);
        }
      )
      .catch((err: ErrorFetch) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });

    return () => {
      setData(null);
      setLoading(false);
    };

    // eslint-disable-next-line
  }, []);

  return { data, isLoading };
}
