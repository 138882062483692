import Modal from "components/Modal";
import { FC, memo } from "react";
import { currencyConverter, getDuration, getFormatDate } from "utilities";
import Rating from "../rating";

type Props = {
  show: boolean;
  onClose: () => void;
  data: HomeworkDetail;
};

const Order: FC<Props> = ({ show, onClose, data }) => {
  return (
    <Modal
      show={show}
      onClose={onClose}
      title={""}
      customTitle="text-sm text-[#8C7E00]"
    >
      <p className="font-bold">Detail order</p>
      <hr className="my-4" />
      <div className="grid grid-cols-2 gap-x-4">
        <div className="grid grid-cols-[7rem,1fr] gap-x-1.5">
          <div className="space-y-4">
            <p className="text-sm">Nama siswa</p>
            <p className="text-sm">Strata</p>
            <p className="text-sm">Mata pelajaran</p>
            <p className="text-sm">Tanggal</p>
          </div>
          <div className="space-y-4">
            <p className="font-bold text-sm">{data?.student_name}</p>
            <p className="font-bold text-sm">{data?.grade_name}</p>
            <p className="font-bold text-sm">{data?.subject_name}</p>
            {/* <p className="font-bold text-sm">
              {getFormatDate(data.createdAt, "DD/MM/YYYY HH:mm")}
            </p> */}
          </div>
        </div>
        <div className="grid grid-cols-[7rem,1fr] gap-x-1.5">
          <div className="space-y-4">
            <p className="text-sm">Tipe</p>
            <p className="text-sm">Durasi sesi</p>
            <p className="text-sm">Status</p>
          </div>
          <div className="space-y-4">
            <p className="font-bold text-sm">
              {data?.type === "chat" ? "Chat" : "Video"}
            </p>
            <p className="font-bold text-sm">{`${getDuration(data?.start_time, data?.end_time)} menit`}</p>
            <p className="w-fit text-sm text-green-600 border border-green-600 rounded-2xl px-2.5">
              {data.status.toUpperCase()}
            </p>
          </div>
        </div>
      </div>
      <p className="font-bold mt-8">Feedback</p>
      <hr className="my-4" />
      <div className="space-y-4">
        <div className="flex items-center gap-x-1.5">
          <p className="text-sm text-gray-600">Rata-rata rating</p>
          <Rating rate={0} />
        </div>
        <div className="flex items-center gap-x-5">
          <p className="text-sm space-x-1">
            <span className="text-gray-600">Ketepatan waktu</span>
            <span className="font-bold">{"-"}</span>
          </p>
          <span className="border h-4" />
          <p className="text-sm space-x-1">
            <span className="text-gray-600">Penampilan</span>
            <span className="font-bold">{"-"}</span>
          </p>
          <span className="border h-4" />
          <p className="text-sm space-x-1">
            <span className="text-gray-600">Kondusifitas</span>
            <span className="font-bold">{"-"}</span>
          </p>
          <span className="border h-4" />
          <p className="text-sm space-x-1">
            <span className="text-gray-600">Interaktif</span>
            <span className="font-bold">{"-"}</span>
          </p>
          <span className="border h-4" />
          <p className="text-sm space-x-1">
            <span className="text-gray-600">Favorit</span>
            <span className="font-bold">{"-"}</span>
          </p>
        </div>
        <div>
          <p className="text-sm text-gray-500">Komentar</p>
          <p className="text-sm text-gray-800">{"-"}</p>
        </div>
        <div className="rounded-lg bg-gray-100 px-6 py-5 space-y-4">
          <p className="font-bold">Rincian pendapatan</p>
          <p className="text-sm text-gray-600 flex items-center justify-between">
            <span>Nama paket</span>
            <span className="font-bold">{data.order_detail.packet_name}</span>
          </p>
          <p className="text-sm text-gray-600 flex items-center justify-between">
            <span>Harga paket</span>
            <span className="font-bold">
              {currencyConverter(data.order_detail.net_price)}
            </span>
          </p>
          <p className="text-sm text-gray-600 flex items-center justify-between">
            <span>Prosentase komisi pengajar</span>
            <span className="font-bold">{data.order_detail.commission_percentage}</span>
          </p>
          <hr />
          <p className="font-bold text-sm text-gray-800 flex items-center justify-between">
            <span>Pendapatan pengajar</span>
            <span>{currencyConverter(data.order_detail.price)}</span>
          </p>
        </div>
      </div>
    </Modal>
  );
};

export default memo(Order);
