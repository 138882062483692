import { memo, useEffect, useState } from "react";
import Datepicker, {
  DateType,
  DateValueType,
} from "react-tailwindcss-datepicker";

type Props = {
  label: string;
  value: string;
  onChange: (val: DateType) => void;
  required?: boolean;
  placeholder?: string;
  displayFormat?: string;
  minDate?: Date;
  disabled?: boolean;
};

function DatePicker(props: Props) {
  const {
    label,
    onChange,
    required,
    value,
    placeholder,
    displayFormat = "DD/MM/YYYY",
    minDate,
    disabled,
  } = props;

  const [currentDate, setCurrentDate] = useState<DateValueType>({
    startDate: value,
    endDate: value,
  });

  useEffect(() => {
    setCurrentDate({
      startDate: value,
      endDate: value,
    });
  }, [value]);

  const handleChange = (date: DateValueType) => {
    setCurrentDate(date);
    onChange(date?.endDate || "");
  };

  return (
    <div className="flex flex-col">
      <p
        className={`font-medium text-sm text-gray-700 mb-1.5 after:text-red-500 ${
          required ? 'after:content-["*"]' : ""
        }`}
      >
        {label}
      </p>
      <Datepicker
        value={currentDate}
        asSingle
        i18n="id"
        useRange={false}
        onChange={handleChange}
        displayFormat={displayFormat}
        placeholder={placeholder ? placeholder : undefined}
        minDate={minDate}
        disabled={disabled}
        inputClassName="w-full px-3.5 py-2.5 rounded-lg border border-gray-300"
      />
    </div>
  );
}

export default memo(DatePicker);
