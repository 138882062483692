import {FC, memo, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import {Button} from "components";
import ChooseTeacherModal from "./Modal/ChooseTeacherModal";
import ClassInfoModal from "./Modal/ClassInfoModal";
import useLiveClass from "../../../../../services/useLiveClass";
import {useParams} from "react-router-dom";
import toast from "react-hot-toast";
import moment from "moment";

const Classes: FC = () => {
  const {id} = useParams();
  const {getClassByProgramId} = useLiveClass();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<LiveClassClassroom>([])
  const [generationCount, setGenerationCount] = useState(0);
  const updateGenerationCount = () => {
    setGenerationCount((prevCount) => prevCount + 1);
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      getClassByProgramId(Number(id))
        .then((response) => {
          const {data} = response
          setData(data.data)
        })
        .catch((err) => toast.error(err?.message || 'Gagal memuat data.'))
        .finally(() => setLoading(false))
    }
  }, [id, getClassByProgramId, generationCount]);

  return (
    <div className='flex flex-1 space-x-4'>
      <CreateClassSection hasGenerated={data?.length > 0} updateGenerationCount={updateGenerationCount}/>
      <ClassList initialClassCode={data?.[0]?.class_code}/>
    </div>
  )
}

const CreateClassSection = ({hasGenerated, updateGenerationCount,} : {hasGenerated: boolean, updateGenerationCount: () => void;}) => {
  const {id} = useParams();
  const {generateClass, getStudentsByProgram} = useLiveClass();
  const [loading, setLoading] = useState(false);
  const [totalStudents, setTotalStudents] = useState(0);
  const [inputValue, setInputValue] = useState(0)
  const [isauto, setIsauto] = useState(true)
  

  useEffect(() => {
    if (id) {
      setLoading(true);
      getStudentsByProgram(Number(id))
        .then((response) => setTotalStudents(response?.data?.total))
        .catch((err) => toast.error(err?.message || 'Gagal memuat data.'))
        .finally(() => setLoading(false))
    }
  }, [id, getStudentsByProgram]);

  const onPressGenerate = () => {
    if (id){
      generateClass(id,{
        is_auto: isauto,
        count_class: inputValue,
        student_per_class: inputValue
      }).then((response) => {
        updateGenerationCount()
      })
    }
  }
  return (
    <div className='bg-white flex flex-col rounded-md self-start'>
      <div className='px-4 pt-4 pb-2 content-between flex items-center'>
        <span className='flex flex-1 font-semibold text-md text-black-100'>Buat kelas</span>
      </div>
      <div className="divider my-0"/>
      <div className='px-4 flex flex-col space-y-4 py-3'>
        <div className='flex flex-1'>
          <span className='font-normal text-base text-gray-600'>Total peserta: </span>
          <span className='font-medium text-bold text-gray-600 ml-6'>{totalStudents} siswa</span>
        </div>
        <span className='font-medium text-base text-gray-700'>Generated by</span>
        <div className='space-x-6 flex flex-row'>
          <label className={`flex cursor-pointer gap-2 items-center`}>
            <input name='class-type' checked={isauto} type="radio" className="radio checked:bg-primary checked:border-none" disabled={hasGenerated} onChange={(event) => setIsauto(event.target.checked)}/>
            <span className="font-medium text-base text-gray-700">Kelas</span>
          </label>
          <label className={`flex cursor-pointer gap-2 items-center`}>
            <input name='class-type' checked={!isauto} type="radio" className="radio checked:bg-primary checked:border-none" disabled={hasGenerated} onChange={(event) => setIsauto(!event.target.checked)}/>
            <span className="font-medium text-base text-gray-700">Siswa</span>
          </label>
        </div>
        <input type={'number'} disabled={hasGenerated} placeholder={'75'} className='border border-gray-300 py-2 px-3 rounded-md' onChange={(event) => setInputValue(Number(event.target.value))}/>
        <Button className="flex self-start" type="submit" color="primary" isDisabled={hasGenerated} onButtonClick={onPressGenerate}>Generate kelas</Button>
      </div>
    </div>
  )
}

const ClassList = ({initialClassCode = ''}: {initialClassCode: string}) => {

  const {id} = useParams();
  const {getClassDetailByClassCode, getClassItemsByClassCode, getClassByProgramId} = useLiveClass();

  const [loading, setLoading] = useState(false);
  const [activeClassId, setActiveClassId] = useState<string>(initialClassCode)
  const [activeClassDetail, setActiveClassDetail] = useState<ClassroomDetail>()
  const [classroomClasses, setClassroomClasses] = useState<ClassroomClass[]>([])
  const [data, setData] = useState<LiveClassClassroom>([])
  const [showSelectTeacherModal, setSelectTeacherModal] = useState(false);
  const [showClassInfoModal, setShowClassInfoModal] = useState(false);
  const [generationCount, setGenerationCount] = useState(0);
  const updateGenerationCount = () => {
    setGenerationCount((prevCount) => prevCount + 1);
    setSelectTeacherModal(false)
  };

  useEffect(()=>{
    setActiveClassId(initialClassCode)
  },[initialClassCode])

  useEffect(() => {
    if (activeClassId) {
      getClassDetailByClassCode(Number(id), activeClassId).then((response) => {
        const {data} = response
        setActiveClassDetail(data)
        getClassItemsByClassCode(Number(id), activeClassId, data.count_class).then((response) => {
          const {data} = response
          setClassroomClasses(data.data)
        })
      })
        .catch((err) => toast.error(err?.message || 'Gagal memuat data.'))
        .finally(() => setLoading(false))
    }
  }, [activeClassId, generationCount])

  useEffect(() => {
      getClassByProgramId(Number(id))
        .then((response) => {
          const {data} = response
          setData(data.data)
        })
        .catch((err) => toast.error(err?.message || 'Gagal memuat data.'))
        .finally(() => setLoading(false))
  }, [id, getClassByProgramId, activeClassId, generationCount]);


  return (
    <div className='bg-white flex flex-col flex-1 rounded-md'>
      <div className='px-4 pt-4 pb-2 content-between flex items-center'>
        <span className='flex flex-1 font-semibold text-md text-black-100'>Daftar kelas</span>
      </div>
      <div className="divider my-0"/>
      <div className='px-4 flex flex-col space-y-4 py-3'>
        <div className='space-x-2'>
          {data.map((item) => <button
            className={`btn btn-ghost btn-sm ${activeClassId === item.class_code ? 'btn-solid-warning btn' : 'btn-ghost'}`}
            onClick={() => setActiveClassId(item.class_code)}>Kelas {item.class_code}</button>)}
        </div>

        { initialClassCode ? 
        (<div>
          <div className='py-2 bg-gray-200 rounded-t-lg px-6 content-between flex items-center'>
            <div className='flex-1'>
              <span>Kelas ID</span>
              <span className='font-bold ml-2'>{`#${activeClassId}`}</span>
            </div>
            <div className='cursor-pointer'>
              <FontAwesomeIcon color='#B5A300' icon={icon({name: 'file-invoice'})}/>
              <span className='ml-1 text-[#B5A300]'>Detail</span>
            </div>
          </div>
          <div className='py-2 bg-gray-100 px-6 space-y-4 rounded-b-lg'>
            <div className='flex flex-1 space-x-10'>
              <div className='flex flex-col flex-1'>
                {activeClassDetail?.teachers?.length ?
                  <>
                    <div>
                      <span className='text-gray-900 text-sm font-medium'>Pengajar utama</span>
                      <FontAwesomeIcon className='cursor-pointer ml-1' onClick={() => setSelectTeacherModal(true)} size={'xs'} color='#B5A300' icon={icon({name: 'user-edit'})}/>
                    </div>
                    <span className='text-base font-bold'>{activeClassDetail?.teachers[0].name}</span>
                  </>
                  :
                  <>
                    <span className='text-gray-900 text-sm font-medium'>Pengajar utama</span>
                    <div className='cursor-pointer' onClick={() => setSelectTeacherModal(true)}>
                      <FontAwesomeIcon size={'xs'} color='#B5A300' icon={icon({name: 'user'})}/>
                      <span className=' ml-1 text-[#B5A300] text-sm font-medium'>Pilih pengajar</span>
                    </div>
                  </>
                }
              </div>
              <div className='flex flex-col'>
                <span className='text-gray-500 text-sm font-medium'>Jumlah siswa</span>
                <span className='text-gray-900 text-sm font-medium'>{activeClassDetail?.count_student} siswa</span>
              </div>
              <div className='flex flex-col'>
                <span className='text-gray-500 text-sm font-medium'>Total pertemuan</span>
                <span className='text-gray-900 text-sm font-medium'>{activeClassDetail?.count_class} pertemuan</span>
              </div>
              <div className='flex flex-col'>
                <span className='text-gray-500 text-sm font-medium'>Total durasi</span>
                <span className='text-gray-900 text-sm font-medium'>{activeClassDetail?.total_duration}</span>
              </div>
            </div>

            <ClassAllocation data={classroomClasses} onClickDetail={() => setShowClassInfoModal(true)}/>
          </div>
        </div>) : (<div>belum ada kelas</div>)
        }
      </div>
      <ChooseTeacherModal
        programId={Number(id)}
        classCode={activeClassId}
        onClose={() => setSelectTeacherModal(false)}
        onSuccess={() => {
          updateGenerationCount()
        }}
        show={showSelectTeacherModal}
        isAlreadyAssigned={Number(activeClassDetail?.teachers[0]?.id)}
      />
      <ClassInfoModal onClose={() => setShowClassInfoModal(false)} onSuccess={() => {
      }} show={showClassInfoModal} classId={1}/>
    </div>
  )
}

const ClassAllocation = ({data, onClickDetail}: { data?: ClassroomClass[], onClickDetail: () => void }) => {
  return (
    <div>
      {data?.map((item, index) => {
        const isLastItem = index === (data?.length - 1)
        return (
          <div className='flex flex-row items-center flex-1 space-x-6'>
            <div className='flex flex-col self-stretch justify-center'>
              <div
                className={`w-1 mb-1 h-[calc((100%-40px-4px)/2)] ${index === 0 ? 'bg-transparent' : 'bg-[#FFE500]'} rounded-b-full self-center`}/>
              <div
                className='self-center font-bold w-10 h-10 bg-[#FFE500] rounded-full items-center justify-center text-center leading-10'>{index + 1}</div>
              <div
                className={`w-1 mt-1 h-[calc((100%-40px-4px)/2)] ${isLastItem ? 'bg-transparent' : 'bg-[#FFE500]'} rounded-t-full self-center`}/>
            </div>
            <div className='my-2 flex-1'>
              <div className='flex flex-1 bg-white rounded-md p-4 flex-row space-x-10'>
                <div className='flex flex-1 flex-col'>
                  <span className='text-gray-500 text-sm font-medium'>Pengajar</span>
                  <span className='text-gray-900 text-sm font-normal'>{item.teacher_name}</span>
                </div>
                <div className='flex flex-col'>
                  <span className='text-gray-500 text-sm font-medium'>Jadwal</span>
                  <span
                    className='text-gray-900 text-sm font-normal'>{moment(item.date_start).format('DD/MM/YYYY HH:mm')}</span>
                </div>
                <div className='flex flex-col'>
                  <span className='text-gray-500 text-sm font-medium'>Durasi</span>
                  <span className='text-gray-900 text-sm font-normal'>{item.duration}</span>
                </div>
                <div className='flex items-center cursor-pointer' onClick={onClickDetail}>
                  <FontAwesomeIcon color='#B5A300' icon={icon({name: 'file-invoice'})}/>
                  <span className='text-[#B5A300] text-sm font-semibold ml-1'>Lihat materi</span>
                </div>
              </div>
            </div>
          </div>
        )
      })}

    </div>
  )
}

export default memo(Classes)
