import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, memo } from "react";

type Props = {
  data: BalanceHomework[];
};

const BalanceAskHomework: FC<Props> = ({ data }) => {
  return (
    <div className="bg-white rounded-xl">
      <h4 className="px-5 py-4 border-b font-bold">Saldo Tanya PR</h4>
      <div className="p-6 grid grid-cols-3 gap-4">
        {data.length ? (
          data.map((val) => {
            const isChat = val.session_type === "chat";
            const iconType = isChat
              ? icon({ name: "comments" })
              : icon({ name: "video" });
            return (
              <div
                key={val.id}
                className="bg-gray-50 rounded-lg px-6 py-4 space-y-4"
              >
                <div className="flex items-center gap-x-2">
                  <FontAwesomeIcon icon={iconType} />
                  <label>{isChat ? "Chat" : "Video"}</label>
                </div>
                <p className="font-semibold text-sm">{val.title}</p>
                <div>
                  <p className="text-xs text-gray-600">Durasi</p>
                  <p className="font-bold">{`${val.duration_minute} menit`}</p>
                </div>
              </div>
            );
          })
        ) : (
          <h3 className="italic">Tidak ada data</h3>
        )}
      </div>
    </div>
  );
};

export default memo(BalanceAskHomework);
