import {memo, FC} from 'react';
import Modal from 'components/Modal';
import { Badge } from 'components';

type Props = {
  show: boolean
  data: SyllabusDetail | null
  onClose: () => void
}

const Info: FC<Props> = ({show, data, onClose}) => {
  const packages = data?.packages.filter((val) => val.content_type === 'mixed');

  return (
    <Modal
      show={show}
      onClose={onClose}
      title='Info silabus'
    >
      <div className='mb-8'>
        <p className='font-bold text-sm'>Judul silabus</p>
        <p className='text-base text-gray-500'>{data?.name}</p>
      </div>
      <div className='mb-8'>
        <p className='font-bold text-sm'>Deskripsi silabus</p>
        <p className='text-base text-gray-500' dangerouslySetInnerHTML={{__html: data?.description || ''}} />
      </div>
      <div className='grid grid-flow-col mb-8'>
        <div>
          <p className='font-bold text-sm'>Strata</p>
          <p className='text-base text-gray-500'>
            {data?.grade_name}
          </p>
        </div>
        <div>
          <p className='font-bold text-sm'>Jumlah pertemuan</p>
          <p className='text-base text-gray-500'>
            {data?.packages?.length}
          </p>
        </div>
      </div>
      <p className='font-bold text-sm mb-1'>Detail pertemuan</p>
      {packages?.map((item) => (
        <div key={item.id} className='flex justify-between px-4 py-2 bg-gray-100 gap-x-2 items-center rounded-s-sm mb-1'>
          <p>{item.title}</p>
          <div className='flex gap-x-2'>
            <Badge color='gray'>
              <span>{`${item?.directories?.length} BAB`}</span>
            </Badge>
          </div>
        </div>
      ))}
    </Modal>
  )
}

export default memo(Info);
