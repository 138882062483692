import { FC, memo, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import useStudent from "services/useStudent";
import Summary from "./Summary";
import Balance from "./Balance";
import { Badge } from "components";
import { PROGRAM_TYPE } from "constant";
import { getFormatDate } from "utilities";

type Props = {
  summary: StudentData | null;
};

const GeneralInfo: FC<Props> = ({ summary }) => {
  const { getBalance, getProgram, getPerformance } = useStudent();
  const { pathname } = useLocation();
  const id = pathname.split("/siswa/")[1];

  const [balance, setBalance] = useState<BalanceHomework[]>([]);
  const [program, setProgram] = useState<ProgramItem[]>([]);

  const getBalanceData = useCallback(async () => {
    const response = await getBalance(id);
    setBalance(response.data);
    // eslint-disable-next-line
  }, []);

  const getProgramData = useCallback(async () => {
    const response = await getProgram(id);
    setProgram(response);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (id) {
      getBalanceData();
      getProgramData();
    }
    // eslint-disable-next-line
  }, [id]);

  const getTypeColor = (type: ProgramItem["type"]) => {
    switch (type) {
      case "learn":
        return "purple-light";
      case "tryout":
        return "warning-light";
      default:
        return "blue-light";
    }
  };

  return (
    <div className="space-y-8">
      <Summary data={summary} />
      <Balance data={balance} />
      <div className="bg-white rounded-xl">
        <p className="px-5 py-4 font-bold border-b">Program berjalan</p>
        <div className="p-6 space-y-4">
          {program.length ? (
            program.map((val) => (
              <div
                key={val.id}
                className="bg-gray-50 rounded-lg grid grid-cols-3 gap-x-5 px-5 py-3"
              >
                <div className="space-y-4">
                  <p className="font-bold">{val.title}</p>
                  <Badge color={getTypeColor(val.type)}>
                    {PROGRAM_TYPE[val.type].toUpperCase()}
                  </Badge>
                </div>
                <div className="space-y-3">
                  <div>
                    <p className="text-xs text-gray-600">Strata</p>
                    <p className="text-sm font-medium">{val.strata ?? "-"}</p>
                  </div>
                  <div>
                    <p className="text-xs text-gray-600">Tanggal pembelian</p>
                    <p className="text-sm font-medium">
                      {getFormatDate(val.date, "DD/MM/YYYY")}
                    </p>
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-x-5 text-center items-center">
                  <div className="bg-gray-200 rounded-lg py-2.5">
                    <p className="text-xs">Materi dilihat</p>
                    <p className="font-bold text-2xl">{val.materi}</p>
                  </div>
                  <div className="bg-gray-200 rounded-lg py-2.5">
                    <p className="text-xs">Latihan soal</p>
                    <p className="font-bold text-2xl">{val.question}</p>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <h3 className="italic">Tidak ada program</h3>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(GeneralInfo);
