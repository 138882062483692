import ToggleSwitch from "../../../components/ToggleSwitch";
import {Button, Icon} from "../../../components";
import {Field, FormikErrors, FormikTouched, getIn} from "formik";
import AddImageAnswer from "./AddImageAnswer";
import {useEffect, useState} from "react";
import { addStyles, EditableMathField } from 'react-mathquill'
import MathOperations from "../../../icons/MathOperations.svg";
import GreekLetters from "../../../icons/GreekLetters.svg";
import MiscOperations from "../../../icons/MiscOperations.svg";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import MathOperationsTool from "./MathOperations";
import {mathOperations, greekLetters, miscOperations} from "../utilities/math/constants";

addStyles();

type Props = {
  optionIndex: number;
  index: number;
  setFieldValue: (field: string, value: any, shouldValidate?: (boolean | undefined)) => Promise<void | FormikErrors<{name: string, description: string, subject: string, difficulty: number, grade: string, true_score: string, false_score: string, questions: {}[], explanation_asset: {}}>>;
  errors: FormikErrors<{name: string, description: string, subject: string, difficulty: number, grade: string, true_score: string, false_score: string, questions: {description: null, is_essay: number, asset: {id: string, name: string, url: string}, asset_id: null}[], explanation_asset: {}}>;
  touched: FormikTouched<{name: string, description: string, subject: string, difficulty: number, grade: string, true_score: string, false_score: string, questions: {description: null, is_essay: number, asset: {id: string, name: string, url: string}, asset_id: null}[], explanation_asset: {}}>;
  question: {
    options: {
      optionImageUrl: string
    }[]
  };
  option: {
    option: string;
    isFormulaPreview?: boolean;
  }
  values: {
    name: string,
    description: string,
    subject: string,
    difficulty: number,
    grade: string,
    true_score: string,
    false_score: string,
    questions: {
      description: string|null;
      is_essay: number;
      asset: {
        id: string;
        name: string;
        url: string;
      },
      question: string;
      options: {
        option: string;
        optionImageFile: string|null;
        optionImageUrl: string;
        asset_id: string|null;
        is_answer: boolean;
      }[];
      answer: string;
      explanation: string;
      explanation_asset_id: string;
      explanationAssetFile: string;
      explanationAssetUrl: string;
      true_score: string;
      false_score: string;
      questionAssetFile: string;
      questionAssetUrl: string;
      asset_id: string|null;
    }[],
    explanation_asset: {
      name: string;
      full_asset: string;
      id: string
    }
  }
}
const MultipleChoicesItem = (props: Props) => {
  const { optionIndex, index, values, setFieldValue, errors, touched, question, option } = props
  const [isFormulaPreview, setIsFormulaPreview] = useState(option.isFormulaPreview ?? false)
  const [key, setKey] = useState(0)

  // required to force reload the EquationEditor by updating their key
  const updateKey = () => {
    setKey((prevKey)=>prevKey+1)
  }

  useEffect(()=>{
    setFieldValue(
      `questions.${index}.options.${optionIndex}.isFormulaPreview`,
      isFormulaPreview
    )
  },[isFormulaPreview])

  const setValue = (val: string) => {
    setFieldValue(
      `questions.${index}.options.${optionIndex}.option`,
      val
    )
  }

  return(
    <div
      key={optionIndex}
      className="p-4 border rounded-xl"
    >
      <div className="flex flex-col md:flex-row md:items-center">
        <label className="font-medium text-sm after:content-['*'] after:font-medium after:text-rose-400 after:ml-1">
          Jawaban {optionIndex + 1}
        </label>
        <div className="flex mt-2 md:mt-0 md:ml-auto">
          <ToggleSwitch
            label="Aktifkan preview rumus"
            isChecked={isFormulaPreview}
            onChange={(val) => setIsFormulaPreview(val)}
          />
          <button
            type="button"
            title="Delete"
            disabled={optionIndex <= 1}
            onClick={() => {
              const updatedQuestions = [...values.questions];
              updatedQuestions[index].options.splice(optionIndex, 1);
              setFieldValue(`questions[${index}].options`, updatedQuestions[index].options);
            }}
            className="w-[40px] aspect-square bg-[#F04438] rounded-xl flex justify-center items-center ml-3"
          >
            <Button.Icon action="delete" />
          </button>
        </div>
      </div>
      {isFormulaPreview ?
        <div className={'py-2 border-b border-gray-400'}>
          <div>
            <span className={'flex'} onMouseDown={(e) => e.preventDefault()}>
              <div onClick={(event)=>{
                event.preventDefault()
                const windowSelection = window?.getSelection?.()
                if (!windowSelection) return
                const selectedText = windowSelection?.toString() ?? ''
                if (selectedText){
                  const prevVal = option.option
                  const replacedString = prevVal.replace(selectedText, `\\text{${selectedText}}`);
                  setValue(replacedString)
                  updateKey()
                }
              }} className={'flex items-end py-px px-1 hover:bg-gray-100 cursor-pointer rounded-md tooltip tooltip-bottom'} data-tooltip="Input text">
                <FontAwesomeIcon icon={icon({ name: 't' })} className="h-3 w-3 mb-1 ml-px text-black" />
              </div>
              <div className={'group flex items-end py-px px-1 hover:bg-gray-100 cursor-pointer rounded-md relative'}>
                <img src={GreekLetters} className="h-6 w-6 text-primary" alt="" />
                <FontAwesomeIcon icon={icon({ name: 'caret-down' })} className="h-3 w-3 mb-1 ml-px text-black" />
                <MathOperationsTool
                  data={greekLetters}
                  onClick={(label)=>{
                    const prevVal = option.option
                    setValue(`${prevVal}${label}`)
                    updateKey()
                  }
                  }/>
              </div>
              <div className={'group flex items-end py-px px-1 hover:bg-gray-100 cursor-pointer rounded-md relative'}>
                <img src={MiscOperations} className="h-6 w-6 text-primary" alt="" />
                <FontAwesomeIcon icon={icon({ name: 'caret-down' })} className="h-3 w-3 mb-1 ml-px text-black" />
                <MathOperationsTool
                  data={miscOperations}
                  onClick={(label)=>{
                    const prevVal = option.option
                    setValue(`${prevVal}${label}`)
                    updateKey()
                  }
                  }/>
              </div>
              <div className={'group flex items-end py-px px-1 hover:bg-gray-100 cursor-pointer rounded-md relative'}>
                <img src={MathOperations} className="h-6 w-6 text-primary" alt="" />
                <FontAwesomeIcon icon={icon({ name: 'caret-down' })} className="h-3 w-3 mb-1 ml-px text-black" />
                <MathOperationsTool
                  data={mathOperations}
                  onClick={(label)=>{
                    const prevVal = option.option
                    setValue(`${prevVal}${label}`)
                  updateKey()
                }
                }/>
              </div>
            </span>
          </div>
        <EditableMathField
          // key={`${optionIndex}-${key}`}
          latex={option.option}
          onChange={(val)=>{
            setValue(val.latex())
            }
          }
        />
        </div>
        :
        <Field
          name={`questions.${index}.options.${optionIndex}.option`}
          className={
            getIn(
              errors,
              `questions.${index}.options.${optionIndex}.option`
            ) &&
            getIn(
              touched,
              `questions.${index}.options.${optionIndex}.option`
            )
              ? "outline-none border-b border-rose-400 placeholder:text-rose-400 mt-3 pb-2 w-full"
              : "outline-none border-b border-[#9DA4B3] placeholder:text-[#9DA4B3] mt-3 pb-2 w-full mb-4"
          }
          placeholder={`Masukan Jawaban ${
            optionIndex + 1
          }`}
        />
      }
      {getIn(
        errors,
        `questions.${index}.options.${optionIndex}.option`
      ) && (
        <p className="text-[#F04438] mt-0.5">
          {getIn(
            errors,
            `questions.${index}.options.${optionIndex}.option`
          )}
        </p>
      )}
      <AddImageAnswer
        onChange={(
          file: any,
          url: any,
          asset_id: number | null
        ) => {
          setFieldValue(
            `questions.${index}.options.${optionIndex}.optionImageUrl`,
            url
          );
          setFieldValue(
            `questions.${index}.options.${optionIndex}.optionImageFile`,
            file
          );
          setFieldValue(
            `questions.${index}.options.${optionIndex}.asset_id`,
            asset_id
          );
        }}
        subject_id={values.subject}
        grade_id={values.grade}
        id={`questions.${index}.options.${optionIndex}.asset_id`}
        // id={`questions.${index}.options.${optionIndex}.optionImage`}
      />
      {question.options[optionIndex]
        .optionImageUrl?.length > 0 && (
        <div className="relative h-[160px] w-full max-w-[237px] mt-5">
          <button
            type="button"
            title="close"
            onClick={() => {
              setFieldValue(
                `questions.${index}.options.${optionIndex}.optionImageFile`,
                null
              );
              setFieldValue(
                `questions.${index}.options.${optionIndex}.optionImageUrl`,
                ""
              );
            }}
            className="right-0 translate-x-1/2 -translate-y-1/2 w-6 aspect-square absolute bg-[#C7C8CA] rounded-full flex peer z-10"
          >
            <Icon.Close className="h-3 aspect-square m-auto" />
          </button>
          <div className="bg-gray-50 border border-gray-100 flex justify-start h-full relative peer-hover:opacity-70 duration-300 transition-all">
            <img
              alt=""
              src={
                question.options[
                  optionIndex
                  ].optionImageUrl
              }
              // layout="fill"
              // objectFit="contain"
            />
          </div>
        </div>
      )}
    </div>
  )
}

export default MultipleChoicesItem
