import {memo, FC} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro';
import Modal from 'components/Modal';
import {getFormatDate} from 'utilities';

type Crumb = {
  label: string
  id: number
}

type Props = {
  show: boolean
  data: BrowseItem | null
  onClose: () => void
  crumbs: Crumb[]
}

const Folder: FC<Props> = ({show, data, onClose, crumbs}) => {
  return (
    <Modal
      show={show}
      onClose={onClose}
      title='Info folder'
    >
      <p className='font-bold text-sm'>Nama folder</p>
      <p className='text-base text-gray-500'>{data?.name}</p>
      <p className='mt-8 font-bold text-sm'>Direktori</p>
      <div className="breadcrumbs text-sm pl-0 mt-1">
        <ul>
          <li>
            <FontAwesomeIcon icon={icon({name: 'house'})} />
          </li>
          {crumbs.map((item, index) => {
            const isLast = index === crumbs.length - 1;

            return (
              <li key={item.id}>
                <span className={`cursor-default ${isLast ? 'font-semibold text-gray-800' : 'font-normal'}`}>{item.label}</span>
              </li>
            )
          })}
        </ul>
      </div>
      <div className='flex gap-[3.75rem] mt-8'>
        <div>
          <p className='font-bold text-sm'>Tanggal dibuat</p>
          <p className='text-base text-gray-500'>
            {getFormatDate(String(data?.created_at), 'DD MMM YYYY')}
          </p>
        </div>
        <div>
          <p className='font-bold text-sm'>Tanggal diperbarui</p>
          <p className='text-base text-gray-500'>
            {getFormatDate(String(data?.updated_at), 'DD MMM YYYY')}
          </p>
        </div>
      </div>
    </Modal>
  )
}

export default memo(Folder);
