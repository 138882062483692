import * as Yup from "yup";

const validationSchema = Yup.object().shape({
  name: Yup.string().required("Title is required"),
  description: Yup.string().required("Description is required"),
  subject: Yup.number().required("Subject is required"),
  difficulty: Yup.string().required("Difficulty is required"),
  grade: Yup.number().required("Grade is required"),
  true_score: Yup.number(),
  false_score: Yup.number(),
  questions: Yup.array().of(
    Yup.object().shape({
      question: Yup.string().required("Question is required"),
      // type: Yup.string().required("Type is required"),
      // true_score: Yup.number().required("True Score is required"),
      // false_score: Yup.number().required("False Score is required"),
    })
  ),
});

export default validationSchema;
