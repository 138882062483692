import {useCallback, useEffect, useState} from 'react';
import Table from 'components/Table';
import useMassUpload from 'services/useMassUpload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import {Badge, Spinner} from 'components';
import FileDetail from './fragment/Modal/Detail';
import UploadFile from './fragment/Modal/Upload';
import { getFormatDate, useDebounce } from 'utilities';
import {FILE_STATUS} from 'constant';
import Breadcrumbs from 'components/Breadcrumbs';

const columns = [
  {
    title: 'Nama file',
    isSort: true,
    key: 'file_name'
  },
  {
    title: 'Tipe',
    isSort: true,
    key: 'type'
  },
  {
    title: 'Status',
    isSort: true,
    key: 'status'
  },
  {
    title: 'Data',
    isSort: true,
    key: 'total_rows'
  },
  {
    title: 'Data diproses',
    isSort: true,
    key: 'processed_rows'
  },
  {
    title: 'Waktu',
    isSort: true,
    key: 'created_at'
  },
  {
    title: ''
  }
];

const crumbs = [
  {
    label: 'Upload masal',
    id: 0
  }
];

const defaultParams: MassUploadParams = {
  page: 1,
  sort_by: 'created_at',
  sort_dir: 'desc',
  keyword: ''
};

const MassUpload = () => {
  const {getList} = useMassUpload();

  const [show, setShow] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [detail, setDetail] = useState<FileInfo | null>(null);
  const [data, setData] = useState<FileInfo[]>([]);
  const [search, setSearch] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLimit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState<MassUploadParams['sort_by']>('id');
  const [sortDir, setSortDir] = useState<MassUploadParams['sort_dir']>('asc');
  const [total, setTotal] = useState(0);
  const [lastPage, setLastPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const debounceSearch = useDebounce(search);

  const getData = useCallback(async (params?: MassUploadParams) => {
    setLoading(true);
    const response = await getList(params);

    setCurrentPage(params?.page || currentPage);
    setLimit(params?.per_page || currentLimit);
    setTotal(response?.data?.total || 0);
    setLastPage(response?.data?.last_page || lastPage);
    setData(response?.data?.data || []);
    setLoading(false);
  }, []);

  useEffect(() => {
    if (debounceSearch.length >= 3) {
      getData({
        page: 1,
        sort_by: 'id',
        sort_dir: 'asc',
        keyword: debounceSearch
      });
    }

    if (!debounceSearch.length) {
      getData(defaultParams);
    }
  }, [debounceSearch]);

  const handleModal = (item: FileInfo) => {
    setDetail(item);
    setShow(true);
  };

  const clearSearch =async () => {
    setSearch('');
    await getData(defaultParams);
  };

  const handleSort =async (params: string) => {
    let direction: MassUploadParams['sort_dir'] = sortDir === 'asc' ? 'desc' : 'asc';

    if (params !== sortBy) {
      direction = 'asc';
    }
    
    setSortBy(params as MassUploadParams['sort_by']);
    setSortDir(direction);
    await getData({
      page: 1,
      sort_by: params as MassUploadParams['sort_by'],
      sort_dir: direction
    });
  };

  const handlePagination = async (ctrl: 'next' | 'prev') => {
    const isNext = ctrl === 'next';
    const page = isNext ? currentPage + 1 : currentPage - 1;
    
    if (page > 0 && page <= lastPage) {
      await getData({
        page,
        sort_by: sortBy,
        sort_dir: sortDir
      });
    }
  };

  const handleLimit = async (limit: string) => {
    await getData({
      page: 1,
      per_page: Number(limit),
      sort_by: 'id',
      sort_dir: 'asc'
    });
  };

  const handleDownload = (item: FileInfo) => {
    if (item.processed_file_location) {
      window.open(item.processed_file_location, '_blank');
    }
  };

  const handleClose = () => {
    setShowUpload(false);
    getData(defaultParams);
  };

  return (
    <>
      <Breadcrumbs crumbs={crumbs} onClick={() => {}} />
      <div className='flex justify-between items-center mb-5'>
        <div className='bg-white px-4 py-[0.625rem] rounded-xl w-72 flex gap-x-2 items-center'>
          <FontAwesomeIcon icon={icon({name: 'search'})} />
          <input
            className='w-full'
            placeholder='Search...'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          {Boolean(search.length) && (
            <FontAwesomeIcon
              icon={icon({name: 'close'})}
              onClick={clearSearch}
              className='cursor-pointer'
            />
          )}
        </div>
        <button
          onClick={() => setShowUpload(true)}
          className='bg-primary rounded-xl py-[0.625rem] pl-4 pr-5 flex items-center'
        >
          <FontAwesomeIcon icon={icon({name: 'plus'})} size='lg' />
          <span className='ml-[0.625rem] text-sm'>Tambah</span>
        </button>
      </div>
      <Table
        data={data}
        columns={columns}
        currentLimit={currentLimit}
        currentPage={currentPage}
        total={total}
        handlePagination={handlePagination}
        handleLimit={handleLimit}
        handleSort={handleSort}
      >
        <>
          {data.length ? (
            data.map((item, index) => {
              const badgeColor = FILE_STATUS[item.status].color;
  
              return (
                <tr key={item.id} className='border-b'>
                  <td className='p-4'>
                    <div className='flex gap-x-2 items-baseline'>
                      <FontAwesomeIcon icon={icon({name: 'file-lines', style: 'regular'})} />
                      <span className='cursor-default text-start'>{item.file_name}</span>
                    </div>
                  </td>
                  <td className='p-4'>
                    <span>{item.type}</span>
                  </td>
                  <td className='p-4'>
                    <Badge className='rounded-[6.25rem]' color={badgeColor}>
                      {FILE_STATUS[item.status].label}
                    </Badge>
                  </td>
                  <td className='p-4'>
                    <span>{item.total_rows}</span>
                  </td>
                  <td className='p-4'>
                    <span>{item.processed_rows}</span>
                  </td>
                  <td className='p-4'>
                    <span>{getFormatDate(item.created_at, 'DD MMM YYYY HH:mm')}</span>
                  </td>
                  <td className='p-4 text-end'>
                    <FontAwesomeIcon 
                      icon={icon({name: 'info'})} 
                      onClick={() => handleModal(item)} 
                      className='cursor-pointer'
                      title='info'
                    />
                    <FontAwesomeIcon 
                      icon={icon({name: 'cloud-download'})}
                      onClick={() => handleDownload(item)}
                      className='cursor-pointer ml-3'
                      title='download'
                    />
                  </td>
                </tr>  
              )
            })
          ) : (
            <tr>
              <td
                colSpan={columns.length}
                className="italic opacity-40 text-center pt-3"
              >
                {loading ? (
                  <Spinner />
                ) : (
                  <span>Tidak ada data.</span>
                )}
              </td>
            </tr>
          )}
        </>  
      </Table>
      <FileDetail
        show={show}
        data={detail}
        onClose={() => setShow(false)}
      />
      <UploadFile
        show={showUpload}
        onClose={handleClose}
      />
    </>
  );
};

export default MassUpload;
