import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  FC,
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { getFormatDate, getProgramColor } from "utilities";
import { useFormContext } from "react-hook-form";
import { Listbox } from "@headlessui/react";
import useGrade from "services/useGrade";
import { DatePicker } from "components/datepicker";
import { Badge } from "components";
import { PROGRAM_TYPE } from "constant";
import { DateType } from "react-tailwindcss-datepicker";

type Props = {
  isAdd?: boolean;
  canEdit?: boolean;
  onSave?: () => void;
  onCancel?: () => void;
};

const Config: FC<Props> = ({ isAdd, canEdit, onSave, onCancel }) => {
  const { getList } = useGrade();

  const { getValues, setValue, watch } = useFormContext<BundleItem>();
  const { detail, grade_id, grade_name } =
    getValues();
  const { start_date, expired_date } = watch();

  const [grades, setGrades] = useState<GradeItem[]>([]);
  const [selectedGrade, setSelectedGrade] = useState<GradeItem | null>(null);
  const [isEdit, setIsEdit] = useState(false);

  const getGrade = useCallback(async () => {
    const response = await getList();
    setGrades(response);
  }, [getList]);

  const programTypes = useMemo(() => {
    let result: ProgramType[] = [];
    const hasHomework = Boolean(detail?.ask_homework_packets?.length);

    if (hasHomework) {
      result.push("ask_homework");
    }

    if (detail?.programs?.length) {
      const programsRegular = detail?.programs?.map((val) => val.type);
      const uniquePrograms = programsRegular.filter(
        (val, index) => programsRegular.indexOf(val) === index
      );

      result.push(...uniquePrograms);
    }

    return result;
  }, [detail]);

  const canSave = useMemo(() => {
    return Boolean(start_date && expired_date);
  }, [start_date, expired_date]);

  useEffect(() => {
    getGrade();
  }, [getGrade]);

  useEffect(() => {
    if (Number(grade_id)) {
      setSelectedGrade({
        id: Number(grade_id),
        name: grade_name,
      });
    }
  }, [grade_id, grade_name]);

  const handleGrade = (value: GradeItem) => {
    if (value.id !== selectedGrade?.id) {
      setValue("grade_id", String(value?.id));
      setValue("grade_name", value?.name);
    }
  };

  const handleDate = (value: DateType, type: "start_date" | "expired_date") => {
    setValue(type, value?.toString() ?? "");
  };

  const handleCancel = () => {
    setIsEdit(false);
    onCancel?.();
  };

  const handleSave = () => {
    if (canSave) {
      setIsEdit(false);
      onSave?.();
    }
  };

  return (
    <div className="bg-white rounded-xl">
      <div className="px-5 py-4 border-b flex items-center justify-between">
        <p className="font-bold">Konfigurasi</p>
        {canEdit &&
          (isEdit ? (
            <div className="space-x-3">
              <FontAwesomeIcon
                icon={icon({ name: "xmark" })}
                color="#F04438"
                onClick={handleCancel}
                className="cursor-pointer"
              />
              <FontAwesomeIcon
                icon={icon({ name: "floppy-disk", style: "regular" })}
                color={canSave ? "#17B26A" : "grey"}
                onClick={handleSave}
                className={`${
                  canSave ? "cursor-pointer" : "cursor-not-allowed"
                }`}
              />
            </div>
          ) : (
            <FontAwesomeIcon
              icon={icon({ name: "pen-to-square", style: "regular" })}
              color="#B5A300"
              onClick={() => setIsEdit(true)}
            />
          ))}
      </div>
      <div className="p-5">
        <div
          className={`relative grid ${
            isAdd ? "" : "grid-cols-[9rem,1fr] items-center"
          }`}
        >
          <p>Strata</p>
          {isAdd || isEdit ? (
            <Listbox value={selectedGrade} onChange={handleGrade}>
              <Listbox.Button className="relative flex w-full cursor-default items-center justify-between gap-x-1 rounded-lg border bg-white px-3.5 py-2.5 text-left text-sm">
                <span className="block truncate">
                  {selectedGrade?.name || "Pilih strata pendidikan"}
                </span>
                <FontAwesomeIcon icon={icon({ name: "chevron-down" })} />
              </Listbox.Button>
              <Listbox.Options className="absolute z-10 mt-1 max-h-48 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                {grades.map((val, classIdx) => (
                  <Listbox.Option
                    key={`${classIdx}-${val.id}`}
                    as={Fragment}
                    value={val}
                  >
                    {({ active }) => (
                      <li
                        className={`relative flex items-center justify-between cursor-default select-none px-3.5 py-2.5 ${
                          active ? "bg-gray-50" : "text-gray-900"
                        }`}
                      >
                        <span className="block truncate">{val.name}</span>
                        {val.id === selectedGrade?.id && (
                          <FontAwesomeIcon
                            icon={icon({ name: "check" })}
                            color="#7F56D9"
                          />
                        )}
                      </li>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Listbox>
          ) : (
            <p>{selectedGrade?.name}</p>
          )}
        </div>
        <div className={`flex mt-4 ${isAdd ? "flex-col" : "flex-row"}`}>
          {!isAdd && <p className="w-36">Periode program</p>}
          {isAdd || isEdit ? (
            <div className={`grid gap-5 ${isEdit ? "" : "grid-cols-2"}`}>
              <DatePicker
                required
                label="Periode mulai program"
                value={start_date}
                onChange={(val) => handleDate(val, "start_date")}
              />
              <DatePicker
                required
                label="Periode akhir program"
                value={expired_date}
                onChange={(val) => handleDate(val, "expired_date")}
              />
            </div>
          ) : (
            <p>
              {start_date && expired_date
                ? `${getFormatDate(start_date, "DD/MM/YYYY")} - ${getFormatDate(
                    expired_date,
                    "DD/MM/YYYY"
                  )}`
                : "-"}
            </p>
          )}
        </div>
        {!isAdd && !canEdit && (
          <>
            <p className="mt-4">Jenis program</p>
            <div className="flex gap-2 flex-wrap">
              {programTypes.map((val, index) => (
                <Badge
                  key={`type-${val}-${index}`}
                  color={getProgramColor(val)}
                >
                  {PROGRAM_TYPE[val].toUpperCase()}
                </Badge>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default memo(Config);
