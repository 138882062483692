import {memo, FC, useState, ChangeEvent, useEffect} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro';
import Modal from 'components/Modal';

import {FILE_TYPE} from 'constant';
import {Button} from 'components';
import useMassUpload from 'services/useMassUpload';
import toast from 'react-hot-toast';

type Props = {
  show: boolean
  onClose: () => void
}

const Upload: FC<Props> = ({show, onClose}) => {
  const {addFile} = useMassUpload();

  const [tab, setTab] = useState(1);
  const [uploadType, setType] = useState<FileInfo['type']>('siswa');
  const [file, setFile] = useState<File | null>(null);

  useEffect(() => {
    if (!show) {
      setTab(1);
      setType('siswa');
      setFile(null);
    }
  }, [show])

  const handleCancel = () => {
    if (tab === 2) {
      setTab(1);
      setFile(null);
    } else {
      onClose();
    }
  };

  const handleSubmit = async () => {
    if (tab === 1) {
      setTab(2);
    } else {
      if (file) {
        const response = await addFile({
          upload_type: uploadType,
          file
        });

        if (response) {
          toast.success('File berhasil ditambahkan');
          onClose();
        } else {
          toast.error('Gagal upload');
          setFile(null);
        }
      }
    }
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const currentFile = e.target.files?.[0];

    if (currentFile) {
      setFile(currentFile);
    }
  };

  const handleDownloadSample = () => {
    const fileType = FILE_TYPE.find((val) => uploadType === val.value);
    const url = fileType?.sampleUrl || '';

    window.open(url, '_blank');
  };

  return (
    <Modal
      show={show}
      onClose={onClose}
      title='Upload file'
      customFooter={(
        <div className='flex justify-end gap-x-2'>
          <Button.Cancel onButtonClick={handleCancel} />
          <Button color='primary' onButtonClick={handleSubmit}>
            {tab === 1 ? 'Lanjutkan' : 'Tambah'}
          </Button>
        </div>
      )}
    >
      {tab === 1 ? (
        <div className='grid gap-y-5'>
          {FILE_TYPE.map((type) => (
            <label
              key={type.value}
              className='px-5 py-4 rounded-lg border flex gap-x-3 items-start cursor-pointer'
              htmlFor={type.value}
            >
              <input
                type="radio"
                id={type.value}
                value={type.value}
                name='type'
                className='radio checked:bg-primary checked:border-none'
                onChange={(e) => setType(e.target.value as FileInfo['type'])}
                checked={type.value === uploadType}
              />
              <div>
                <p className='font-bold text-sm'>{type.label}</p>
                <p className='font-bold text-sm'>{type.description}</p>
              </div>
            </label>
          ))}
        </div>
      ) : (
        <>
          <div className='border-dashed border py-8 text-center mb-5'>
            <FontAwesomeIcon icon={icon({name: 'cloud-arrow-up'})} size='2x' />
            <p className='mt-4'>
              {'Geret file atau '}
              <input
                type='file'
                id='addModule'
                className='hidden'
                accept='.csv, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                onChange={handleFileChange}
              />
              <label
                htmlFor='addModule'
                className='text-blue-500 cursor-pointer'
              >
                pilih file
              </label>
              {' yang akan di unggah.'}
            </p>
            <p className='italic text-sm text-gray-400'>Format file: xlsx, csv</p>
            <Button color='outline-primary' className='mt-2' onButtonClick={handleDownloadSample}>
              Unduh sample data
            </Button>
          </div>
          {Boolean(file) && (
            <div className='px-5 py-3 rounded-[0.250rem] bg-gray-100'>
              <div className='flex gap-x-2 items-center mb-2'>
                <FontAwesomeIcon icon={icon({name: 'file-invoice'})} size='3x' />
                <div className='w-full '>
                  <p className='text-gray-800 text-sm mb-1'>{file?.name}</p>
                  <div className='flex justify-between'>
                    <p className='text-gray-400 text-xs'>{`${(file?.size || 0) / 1000}KB`}</p>
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </Modal>
  )
}

export default memo(Upload);
