const StatusBadge = ({status}: {status: AskHomeworkStatusType}) => {
  switch (status) {
    case "in_progress": return <span className={'flex self-start text-blue-700 border border-blue-700 py-px px-2 rounded-full text-sm'}>BERJALAN</span>
    case "done": return <span className={'flex self-start text-success border border-success py-px px-2 rounded-full text-sm'}>SELESAI</span>
    case "expired": return <span className={'flex self-start text-red-500 border border-red-500 py-px px-2 rounded-full text-sm'}>EXPIRED</span>
    default: return null
  }
}

export default StatusBadge
