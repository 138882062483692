import { API_URL, DEFAULT_PAGINATION } from "constant";
import { useCallback, useRef } from "react";
import { services } from "./services";

type Response = PaginationResponse & {
  data: LeaveItem[];
};
const defaultParams: LeaveListParam = {
  search: "",
  page: 1,
  grade_id: "",
  order: "desc",
  sort_by: "created_at",
  per_page: 10,
};

const useLeave = () => {
  const lastPayload = useRef(defaultParams);
  const getList = useCallback(
    async (params?: LeaveListParam): Promise<Response> => {
      try {
        let payload = {
          ...lastPayload.current,
          ...params,
        };

        lastPayload.current = payload;
        const response = await services.get(API_URL.leave + "/list", {
          params: payload,
        });

        return response.data?.data;
      } catch (error) {
        return {
          ...DEFAULT_PAGINATION,
          data: [],
        };
      }
    },
    []
  );
  const updateStatus = useCallback(
    async (
      id: number,
      status: string,
      rejected_reason: string = ""
    ): Promise<boolean> => {
      try {
        if (status === "rejected") {
            rejected_reason = "Di tolak oleh administrator";
        }
        await services.patch(`${API_URL.leave}/${id}`, {
          status,
          rejected_reason,
        });
        return true;
      } catch (error) {
        return false;
      }
    },
    []
  );
  return {
    getList,
    updateStatus,
  };
};

export default useLeave;
