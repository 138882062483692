import { Input, InputNumber, TextArea, UploadImage } from "components";
import { memo, useMemo } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { DatePicker } from "components/datepicker";
import ToggleSwitch from "components/ToggleSwitch";
import { currencyConverter, priceByDiscount } from "utilities";
import { services } from "services";
import { useEffect, useState } from "react";
import { Field } from "formik";


const Detail = () => {
  const { control, watch, setValue } = useFormContext<LiveclassPayload>();
  const isLimited = watch("is_limited_participant");
  const isFlatDisc = watch("is_flat_disc");
  const price = watch("price");
  const discount = watch("discount");

  const [grades, setGrades] = useState<any[]>([]);
  const [subjects, setSubjects] = useState<any[]>([]);

  const finalPrice = useMemo(() => {
    if (isFlatDisc) {
      return currencyConverter(price - discount);
    } else {
      return priceByDiscount(price, discount);
    }
  }, [isFlatDisc, price, discount]);

  async function GetGrade() {
    services
      .get("dropdown/grade")
      .then((resp) => {
        const data = resp?.data?.data;

        setGrades(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function GetSubject() {
    services
      .get("dropdown/subject")
      .then((resp) => {
        const data = resp?.data?.data;
        setSubjects(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    GetGrade();
    GetSubject();
  }, []);



  return (
    <section className="grid gap-y-5">
      <div className="bg-white rounded-xl">
        <p className="font-bold text-xl px-5 py-4 border-b">Informasi</p>
        <div className="p-5 grid grid-cols-[2fr,1fr] gap-x-5">
          <div className="grid gap-y-5">
            <Controller
              control={control}
              name="title"
              render={({ field }) => (
                <Input
                  label="Judul"
                  required
                  placeholder="Masukkan judul program"
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
            <Controller
              control={control}
              name="description"
              render={({ field }) => (
                <TextArea
                  label="Deskripsi"
                  required
                  isRichText
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </div>
          <Controller
            control={control}
            name="thumbnail_path"
            render={({ field }) => (
              <UploadImage
                imgSrc={field.value}
                onDelete={() => field.onChange("")}
                onUpload={(data) => field.onChange(data?.full_asset)}
              />
            )}
          />
        </div>
      </div>
      <div className="flex gap-x-5">
        <div className="bg-white rounded-xl flex-1">
          <p className="font-bold text-xl px-5 py-4 border-b">Konfigurasi</p>
          <div className="p-5 grid gap-y-5">
            <div>
              <p className="text-sm after:content-['*'] after:text-rose-400 mb-1.5">
                Jenis Live Class
              </p>
              <div className="flex items-center gap-x-5">
                <Controller
                  control={control}
                  name="categories"
                  render={({ field }) => (
                    <div className="flex items-center gap-x-3">
                      <input
                        type="radio"
                        name="categories"
                        id="group"
                        className="radio checked:bg-primary checked:border-none flex"
                        checked={!field.value}
                        onChange={() => {
                          field.onChange(false);
                          setValue("number_of_participants", null);
                        }}
                      />
                      <label htmlFor="unlimited">Grup</label>
                    </div>
                  )}
                />
                <Controller
                  control={control}
                  name="categories"
                  render={({ field }) => (
                    <div className="flex items-center gap-x-3">
                      <input
                        type="radio"
                        name="categories"
                        id="private"
                        className="radio checked:bg-primary checked:border-none flex"
                        checked={field.value}
                        onChange={() => field.onChange(true)}
                      />
                      <label htmlFor="limited">Privat</label>
                    </div>
                  )}
                />
              </div>
            </div>
            <div className="grid grid-flow-col gap-x-5">
              <Controller
                control={control}
                name="registration_period_start"
                render={({ field }) => (
                  <DatePicker
                    label="Periode mulai pendaftaran"
                    required
                    value={field.value || ""}
                    onChange={(val) => field.onChange(val)}
                  />
                )}
              />
              <Controller
                control={control}
                name="registration_period_end"
                render={({ field }) => (
                  <DatePicker
                    label="Periode akhir pendaftaran"
                    required
                    value={field.value || ""}
                    onChange={(val) => field.onChange(val)}
                  />
                )}
              />
            </div>
            <div>
              <h1 className="text-sm after:content-['*'] after:text-rose-400 mb-1.5">
                Strata
              </h1>
              <div className="px-3.5 py-2.5 border rounded-lg mt-1.5 flex">
                <div className="flex flex-col w-full relative justify-between flex-wrap">
                  <Controller
                    control={control}
                    name="grade_id"
                    render={({ field }) => (
                      <select
                        name="grade_id"
                        id="grade_id"
                        value={field.value ?? ''}
                        onChange={(e) => {
                          const value = (e.target.value);
                          field.onChange(value);
                          console.log(e)
                        }}
                      >
                        {grades ? (
                          grades.map((grade) => (
                            <option key={grade.id} value={grade.id}>
                              {grade.name}
                            </option>
                          ))
                        ) : (
                          <option value="-">No Options</option>
                        )}
                      </select>
                    )}
                  />
                </div>
              </div>
            </div>
            <div>
              <h1 className="text-sm after:content-['*'] after:text-rose-400 mb-1.5">
                Mata Pelajaran
              </h1>
              <div className="px-3.5 py-2.5 border rounded-lg mt-1.5 flex">
                <div className="flex flex-col w-full relative justify-between flex-wrap">
                  <Controller
                    control={control}
                    name="subject_id"
                    render={({ field }) => (
                      <select
                        name="subject_id"
                        id="subject_id"
                        value={field?.value ?? ''}
                        onChange={(e) => {
                          const value = (e.target.value);
                          field.onChange((value));
                        }}
                      >
                        {subjects ? (
                          subjects.map((grade) => (
                            <option key={grade.id} value={grade.id}>
                              {grade.name}
                            </option>
                          ))
                        ) : (
                          <option value="-">No Options</option>
                        )}
                      </select>
                    )}
                  />
                </div>
              </div>
            </div>{" "}
          </div>
        </div>
        <div className="bg-white rounded-xl flex-1 flex flex-col justify-between">
          <div>
            <p className="font-bold text-xl px-5 py-4 border-b">Harga</p>
            <div className="p-5 grid gap-y-5">
              <Controller
                control={control}
                name="price"
                render={({ field }) => (
                  <InputNumber
                    label="Harga"
                    required
                    className="w-full px-3.5 py-2.5 border rounded-lg"
                    placeholder="Masukkan harga"
                    options={{
                      numeral: true,
                      numeralDecimalMark: ",",
                      delimiter: ".",
                    }}
                    value={field.value}
                    onChange={(e) => field.onChange(Number(e.target.rawValue))}
                  />
                )}
              />
              <div>
                <p className="font-medium text-sm">Diskon</p>
                <div className="px-3.5 py-2.5 border rounded-lg mt-1.5 flex">
                  <Controller
                    control={control}
                    name="discount"
                    render={({ field }) => (
                      <InputNumber
                        label=""
                        className="w-full pr-3.5"
                        placeholder="Masukkan diskon"
                        options={{
                          numeral: true,
                          numeralDecimalMark: ",",
                          delimiter: ".",
                        }}
                        value={field.value}
                        onChange={(e) =>
                          field.onChange(Number(e.target.rawValue))
                        }
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="is_flat_disc"
                    render={({ field }) => (
                      <select
                        name="isFlatDiscount"
                        id="isFlatDiscount"
                        value={Number(field?.value)}
                        onChange={(e) => {
                          const value = Number(e.target.value);
                          field.onChange(Boolean(value));
                        }}
                      >
                        <option value={1}>Rp</option>
                        <option value={0}>%</option>
                      </select>
                    )}
                  />
                </div>
              </div>
              <div>
                <p className="font-medium text-sm">Harga final</p>
                <p className="font-bold text-lg">{finalPrice}</p>
              </div>
            </div>
          </div>
          <div className="px-5 py-5">
            <p className="text-xs text-gray-500">
              * Harga final akan ditampilkan pada mobile dan web app
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default memo(Detail);
