import {FC, memo, useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import Table from 'components/Table';
import useLiveClass from "../../../../../services/useLiveClass";
import {useParams} from "react-router-dom";
import toast from "react-hot-toast";
import moment from "moment";

const StudentsTab: FC = () => {

  const {id} = useParams();
  const {getStudentsByProgram} = useLiveClass();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<ProgramStudentsResponse>();
  const [search, setSearch] = useState<string>('')

  useEffect(() => {
    if (id) {
      setLoading(true);
      getStudentsByProgram(Number(id))
        .then((response) => setData(response?.data))
        .catch((err) => toast.error(err?.message || 'Gagal memuat data.'))
        .finally(() => setLoading(false))
    }
  }, [id, getStudentsByProgram]);


  return (
    <div className='flex flex-1 space-x-4'>
      <div className='bg-white flex flex-col flex-1 rounded-md'>
        <div className='px-4 pt-4 pb-2 content-between flex items-center'>
          <span className='flex flex-1 font-semibold text-md text-black-100'>Daftar Peserta</span>
          {/* <div className='cursor-pointer'>
            <FontAwesomeIcon color='#B5A300' icon={icon({name: 'cloud-arrow-up'})}/>
            <span className='ml-1 text-[#B5A300]'>Impor siswa</span>
          </div> */}
        </div>

        <div className="divider my-0"/>

        <div className='px-4 pt-2'>
          <div className='flex flex-1 flex-row content-between items-center'>
            <div className='flex flex-1'>
              <span className='font-normal text-base text-gray-600'>Total peserta: </span>
              <span className='font-medium text-bold text-gray-600 ml-1'>{data?.total}</span>
            </div>
            <div className='bg-white px-4 py-[0.625rem] rounded-xl w-72 flex gap-x-2 items-center border'>
              <FontAwesomeIcon icon={icon({name: 'search'})}/>
              <input
                className='w-full'
                placeholder='Cari siswa'
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              {Boolean(search.length) && (
                <FontAwesomeIcon
                  icon={icon({name: 'close'})}
                  onClick={() => setSearch('')}
                  className='cursor-pointer'
                />
              )}
            </div>
          </div>
        </div>
        {
          data?.data?.length && (
            <StudentTable data={data}/>
          )
        }
      </div>
    </div>
  )
}

const columns = [
  {
    title: 'Nama siswa',
    isSort: true,
    key: 'name'
  },
  {
    title: 'Sekolah',
    isSort: true,
    key: 'grade'
  },
  {
    title: 'Tanggal daftar',
    isSort: true,
    key: 'created_at'
  }
];
const StudentTable = ({data}: {data: ProgramStudentsResponse}) => {
  return(
    <Table
      data={data.data ?? []}
      columns={columns}
      currentLimit={10}
      currentPage={1}
      total={data.total ?? 0}
      handleLimit={()=>{}}
      handlePagination={()=>{}}
      handleSort={()=>{}}
    >
      {data.data.map((item,index)=>{
        return(
          <tr key={`${index}`} className='border-b'>
            <td className='p-4'>{item.student_name}</td>
            <td className='p-4 text-gray-600'>{item.school_name}</td>
            <td className='p-4 text-gray-600'>{moment(item.created_at).format('DD/MM/YYYY')}</td>
          </tr>
        )
      })}
    </Table>
  )
}

export default memo(StudentsTab)
