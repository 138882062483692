import {useRef} from 'react';
import {services} from 'services';
import {API_URL} from 'constant';

const defaultParams: SyllabusParams = {
  page: 1,
  per_page: 10,
  sort_by: 'name',
  order_by: 'asc'
};


const useSyllabus = () => {
  const lastPayload = useRef(defaultParams);

  const getList = async (params?: SyllabusParams) => {
    try {
      let payload = {
        ...lastPayload.current,
        ...params,
      };

      lastPayload.current = payload;
      const response = await services.get(API_URL.syllabus, {
        params: payload
      });
      
      return response.data;
    } catch (error) {
      return [];
    }
  };

  const getDetail = async (id: number): Promise<SyllabusDetail | null> => {
    try {
      const response = await services.get(`${API_URL.syllabus}/${id}`);

      return response.data?.data;
    } catch {
      return null;
    }
  };

  return {
    getList,
    getDetail
  }
};

export default useSyllabus;
