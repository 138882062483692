import { icon } from '@fortawesome/fontawesome-svg-core/import.macro';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {FC, Fragment, memo} from 'react';


type Props = {
  data: string[]
  currentStep: number
  onBack: () => void
  onNext: () => void
  hideChevron?: boolean
  canContinue?: boolean
}

const ProgressBullet: FC<Props> = (props) => {
  const {
    data,
    currentStep,
    onBack,
    onNext,
    hideChevron = false,
    canContinue = false
  } = props;
  const handleStep = (step: 'back' | 'next') => {
    const isBack = step === 'back';
    const isNext = step === 'next';

    if (isBack && currentStep > 0) {
      onBack();
    } else if (isNext && currentStep < data.length - 1 && canContinue) {
      onNext();
    }
  };

  return (
    <div className="flex items-center justify-between w-full">
      <div className="flex items-center">
        {data?.map((val, index) => {
          const isLast = data.length - 1 === index;

          return (
            <Fragment key={index}>
              <div className="flex items-center gap-x-2">
                {index < currentStep ? (
                  <FontAwesomeIcon
                    icon={icon({ name: "circle-check" })}
                    color="green"
                    size="2x"
                  />
                ) : (
                  <p className="h-8 w-8 rounded-[100%] bg-green-600 flex items-center justify-center font-bold text-sm text-white">
                    {index + 1}
                  </p>
                )}
                <p className="text-sm">{val}</p>
              </div>
              {!isLast && (
                <div className="w-14 h-[0.125rem] bg-gray-400 mx-2" />
              )}
            </Fragment>
          );
        })}
      </div>
      {!hideChevron && (
        <div className='flex items-center gap-x-3'>
          <FontAwesomeIcon
            className='cursor-pointer'
            icon={icon({name: 'chevron-left'})}
            onClick={() => handleStep('back')}
          />
          <FontAwesomeIcon
            className='cursor-pointer'
            icon={icon({name: 'chevron-right'})}
            onClick={() => handleStep('next')}
          />
        </div>
      )}
    </div>
  );
};

export default memo(ProgressBullet);
