// Views
// ** Dashboard
import Dashboard from "pages/Dashboard";
import NotFound from "pages/404";
// ** Program
import ListDetail from "views/Program/ListDetail";
import ListKelas from "views/Program/ListKelas";
import JadwalKelas from "views/Program/ProgramDetail/Jadwalkelas";
import Kelas from "views/Program/ProgramDetail/Kelas";
import ProgramDetail from "views/Program/ProgramDetail/ProgramDetail";
import Silabus from "views/Program/ProgramDetail/Silabus";
import Program from "pages/Program";
import AddProgram from "pages/Program/Add";
import EditProgram from "pages/Program/Edit";
// bundling
import Bundling from "pages/Bundling";
import BundleDetails from "pages/Bundling/Detail";
import AddBundle from "pages/Bundling/Add";

// ** Menu Modul
import Module from "pages/Module";
import AddModule from "pages/Module/Add";
import EditModule from "pages/Module/Edit";

// ** Akademik
// *** Silabus
import { SilabusList, SilabusTambah, SilabusUbah } from "views/Silabus";
import Syllabus from "pages/Syllabus";

import {
  BankSoalDetailList,
  BankSoalKelasList,
  BankSoalList,
  BankSoalTambah,
} from "views/BankSoal";

import MassUpload from "pages/MassUpload";
import NewProgramDetail from "../pages/Program/Detail";

import Teacher from "pages/HomeWork/Teacher";
import TeacherDetails from "pages/HomeWork/Teacher/detail";
import Order from "pages/HomeWork/Order";
import SessionList from "../pages/HomeWork/SessionList";
import Setting from "pages/HomeWork/Setting";

// users management
import TeacherManagement from "pages/UserManagement/Teacher";
import AddTeacher from "pages/UserManagement/Teacher/add";
import Student from "pages/UserManagement/Student";
import AddStudent from "pages/UserManagement/Student/add";
import DetailStudent from "pages/UserManagement/Student/detail";

// withdrawal
import WithdrawalPage from "pages/Withdrawal";
import LiveClassCalendar from "../pages/LiveClass/Calendar";
import LiveClassMonitoring from "../pages/LiveClass/Monitoring";
// promo
import Promo from "pages/Promo";
import AddPromo from "pages/Promo/Add";
import DetailPromo from "pages/Promo/Detail";
import PoinPage from "../pages/Poin";
import Leave from "pages/Leave";

export const route = [
  {
    index: true,
    element: <Dashboard />,
  },
  {
    path: "modul",
    children: [
      {
        index: true,
        element: <Module />,
      },
      {
        path: "tambah/:directoryId",
        element: <AddModule />,
      },
      {
        path: "edit/:id",
        element: <EditModule />,
      },
    ],
  },
  {
    path: "silabus",
    children: [
      {
        index: true,
        element: <Syllabus />,
      },
      {
        path: "old",
        element: <SilabusList />,
      },
      {
        path: "tambah",
        element: <SilabusTambah />,
      },
      {
        path: "ubah/:id",
        element: <SilabusUbah />,
      },
    ],
  },
  {
    path: "withdrawal",
    children: [
      {
        index: true,
        element: <WithdrawalPage />,
      },
    ],
  },
  {
    path: "bank-soal",
    children: [
      {
        path: "",
        element: <BankSoalList />,
      },
      {
        path: "list-detail",
        children: [
          {
            path: ":id",
            element: <BankSoalDetailList />,
          },
        ],
      },
      {
        path: "list-kelas",
        children: [
          {
            path: ":id",
            element: <BankSoalKelasList />,
          },
        ],
      },
      {
        path: "tambah",
        element: <BankSoalTambah />,
      },
      {
        path: "edit/:id",
        element: <BankSoalTambah />,
      },
      {
        path: "tambah/:id",
        element: <BankSoalTambah />,
      },
    ],
  },
  {
    path: "program",
    children: [
      {
        index: true,
        element: <Program />,
      },
      {
        path: "edit/:id",
        element: <EditProgram />,
      },
      {
        path: "detail/:id",
        element: <NewProgramDetail />,
      },
      {
        path: "list-detail",
        children: [
          {
            path: ":id",
            element: <ListDetail />,
          },
        ],
      },
      {
        path: "list-kelas",
        children: [
          {
            path: ":id",
            element: <ListKelas />,
          },
        ],
      },
      {
        path: "tambah",
        element: <AddProgram />,
      },
      {
        path: "program-detail/:id",
        element: <ProgramDetail />,
      },
      {
        path: "program-detail",
        element: <ProgramDetail />,
      },
      {
        path: "silabus/:id",
        element: <Silabus />,
      },
      {
        path: "silabus",
        element: <Silabus />,
      },
      {
        path: "kelas/:id",
        element: <Kelas />,
      },
      {
        path: "kelas",
        element: <Kelas />,
      },
      {
        path: "jadwal-kelas/:id",
        element: <JadwalKelas />,
      },
      {
        path: "jadwal-kelas",
        element: <JadwalKelas />,
      },
    ],
  },
  {
    path: "bundling",
    children: [
      {
        index: true,
        element: <Bundling />,
      },
      {
        path: "tambah",
        element: <AddBundle />,
      },
      {
        path: ":id",
        element: <BundleDetails />,
      },
    ],
  },
  {
    path: "teacher-leave",
    children: [
      {
        index: true,
        element: <Leave />,
      },
    ],
  },
  {
    path: "mass-upload",
    children: [
      {
        index: true,
        element: <MassUpload />,
      },
    ],
  },
  {
    path: "tanya-pr",
    children: [
      {
        path: "pengajar",
        children: [
          {
            index: true,
            element: <Teacher />,
          },
          {
            path: ":id",
            element: <TeacherDetails />,
          },
        ],
      },
      {
        path: "penjualan",
        element: <Order />,
      },
      {
        path: "list",
        element: <SessionList />,
      },
      {
        path: "pengaturan",
        element: <Setting />,
      },
    ],
  },
  {
    path: "live-class",
    children: [
      {
        path: "calendar",
        element: <LiveClassCalendar />,
      },
      {
        path: "monitoring",
        element: <LiveClassMonitoring />,
      },
    ],
  },
  {
    path: "promo",
    children: [
      {
        index: true,
        element: <Promo />,
      },
      {
        path: "tambah",
        element: <AddPromo />,
      },
      {
        path: ":id",
        element: <DetailPromo />,
      },
    ],
  },
  {
    path: "guru",
    children: [
      {
        index: true,
        element: <TeacherManagement />,
      },
      {
        path: "tambah",
        element: <AddTeacher />,
      },
      {
        path: ":id",
        element: <AddTeacher />,
      },
    ],
  },
  {
    path: "siswa",
    children: [
      {
        index: true,
        element: <Student />,
      },
      {
        path: "tambah",
        element: <AddStudent />,
      },
      {
        path: ":id",
        element: <DetailStudent />,
      },
    ],
  },
  {
    path: "poin",
    element: <PoinPage />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
];
