import { useEffect, useRef } from 'react';

interface TimePickerProps {
  label: string;
  value: string;
  onChange: (time: string) => void;
}

const ManualTimePicker: React.FC<TimePickerProps> = ({ label, value, onChange }) => {
  const hourRef = useRef<HTMLSelectElement>(null);
  const minuteRef = useRef<HTMLSelectElement>(null);

  useEffect(() => {
    const handleInputChange = () => {
      const selectedHour = hourRef.current?.value || '00';
      const selectedMinute = minuteRef.current?.value || '00';

      const formattedTime = `${selectedHour}:${selectedMinute}:00`;
      onChange(formattedTime);
    };

    if (hourRef.current && minuteRef.current) {
      hourRef.current.addEventListener('change', handleInputChange);
      minuteRef.current.addEventListener('change', handleInputChange);

      return () => {
        hourRef.current?.removeEventListener('change', handleInputChange);
        minuteRef.current?.removeEventListener('change', handleInputChange);
      };
    }
  }, [onChange]);

  useEffect(() => {
    const [hours, minutes] = value.split(':');

    const selectedHour = hours || '00';
    const selectedMinute = minutes || '00';

    if (hourRef.current && minuteRef.current) {
      hourRef.current.value = selectedHour;
      minuteRef.current.value = selectedMinute;
    }
  }, [value]);

  return (
    <div className="flex flex-col" >
      <p className="font-medium text-sm text-gray-700 mb-1.5">{label}</p>
      <div className="inline-flex text-lg border rounded-lg p-2 bg-white border-gray-300">
        <select ref={hourRef} className="px-2 outline-none appearance-none bg-transparent">
          {[...Array(24)].map((_, index) => (
            <option key={`hour-${index}`} value={`${index}`.padStart(2, '0')}>
              {`${index}`.padStart(2, '0')}
            </option>
          ))}
        </select>
        <span className="px-2">:</span>
        <select ref={minuteRef} className="px-2 outline-none appearance-none bg-transparent">
          {[...Array(60)].map((_, index) => (
            <option key={`minute-${index}`} value={`${index}`.padStart(2, '0')}>
              {`${index}`.padStart(2, '0')}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
};

export default ManualTimePicker;
