import { FC, memo, useCallback, useEffect, useState } from "react";
import DetailTab from "./fragment/DetailTab";
import QuestionTab from "./fragment/QuestionTab";
import ParticipantTab from "./fragment/ParticipantTab";
import SettingTab from "./fragment/SettingTab";
import { Badge } from "components";
import useProgram from "services/useProgram";
import { useParams } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import toast from "react-hot-toast";
import ButtonDropdown from "components/dropdowns/ButtonDropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";

const TABS = ["Detail program", "Soal", "Peserta", "Pengaturan"];

const LiveClassDetail: FC = () => {
  const { detailTryout, addTryout, updateProgram } = useProgram();
  const { id } = useParams();

  const [activeTab, setActiveTab] = useState(0);
  const [data, setData] = useState<TryoutPayload>();

  const methods = useForm<TryoutPayload>({
    values: data,
  });
  const { getValues, setValue, watch } = methods;
  const isDraft = watch("is_draft");
  const title = getValues("title");

  const getDetail = useCallback(async (id: number) => {
    try {
      const response = await detailTryout(id);
      setData(response);
    } catch (error) {
      console.error(error);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getDetail(Number(id));
  }, [id, getDetail]);

  const handleSave = (data: TryoutPayload) => {
    toast.promise(addTryout(data), {
      loading: "Loading...",
      success: () => {
        setData(data);
        return "Success!";
      },
      error: "Terjadi kesalahan",
    });
  };

  const handlePublish = () => {
    toast.promise(updateProgram(String(id), !isDraft), {
      loading: `${isDraft ? "Publish" : "Unpublish"} ${title}...`,
      success: () => {
        setValue("is_draft", !isDraft);
        return `Berhasil ${isDraft ? "Publish" : "Unpublish"} ${title}`;
      },
      error: `Gagal ${isDraft ? "Publish" : "Unpublish"} ${title}`,
    });
  };

  return (
    <section className="flex flex-1 flex-col w-full space-y-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-x-5">
          <span className="font-bold text-lg">{data?.title || "-"}</span>
          <Badge color="warning-light">TRY OUT</Badge>
        </div>
        <ButtonDropdown
          menuItems={[
            <button
              className="w-full flex items-center justify-start gap-x-2 px-3.5 py-2.5 hover:bg-gray-50"
              onClick={() => handlePublish()}
            >
              {isDraft ? (
                <>
                  <FontAwesomeIcon
                    icon={icon({
                      name: "paper-plane",
                      style: "regular",
                    })}
                  />
                  <p className="text-sm">Publish</p>
                </>
              ) : (
                <>
                  <FontAwesomeIcon icon={icon({ name: "rotate-left" })} />
                  <p className="text-sm">Unpublish</p>
                </>
              )}
            </button>,
          ]}
          widthItems="w-40"
        >
          <div
            className={`flex items-center gap-x-1.5 rounded-lg px-4 py-2.5 text-white ${
              isDraft ? "bg-orange-400" : "bg-green-500"
            }`}
          >
            <span>{isDraft ? "Draft" : "Published"}</span>
            <FontAwesomeIcon icon={icon({ name: "chevron-down" })} />
          </div>
        </ButtonDropdown>
      </div>

      <div className="space-x-2">
        {TABS.map((item, index) => (
          <button
            key={index}
            className={`btn btn-ghost btn-sm ${
              activeTab === index ? "btn-solid-warning btn" : "btn-ghost"
            }`}
            onClick={() => setActiveTab(index)}
          >
            {item}
          </button>
        ))}
      </div>

      <FormProvider {...methods}>
        <Page page={activeTab} onSave={(data) => handleSave(data)} />
      </FormProvider>
    </section>
  );
};

type Props = {
  page: number;
  onSave: (data: TryoutPayload) => void;
};

const Page = ({ page, onSave }: Props) => {
  switch (page) {
    case 0:
      return <DetailTab onSave={onSave} />;
    case 1:
      return <QuestionTab onSave={onSave} />;
    case 2:
      return <ParticipantTab />;
    case 3:
      return <SettingTab onSave={onSave} />;
    default:
      return null;
  }
};

export default memo(LiveClassDetail);
