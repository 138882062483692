import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "components/Modal";
import { FC, MouseEvent, memo } from "react";
import Avatar from "../avatar";
import { getFormatDate } from "utilities";
import Level from "../level";

type Props = {
  show: boolean;
  onClose: () => void;
  data: TeacherRequest;
  onValidate: (event: MouseEvent<HTMLButtonElement>) => void;
};

const Detail: FC<Props> = ({ data, onClose, show, onValidate }) => {
  const { avatar, name, grades, subjects, created_at, biography } = data;

  const handleValidation = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    onClose();
    onValidate(event);
  };

  return (
    <>
      <Modal
        show={show}
        title="Detail pengajar"
        onClose={onClose}
        customFooter={
          <div className="flex items-center justify-end gap-x-3">
            <button
              value={"rejected"}
              onClick={handleValidation}
              className="text-sm text-red-700 border border-red-700 px-3 py-2 rounded-lg space-x-1 hover:bg-red-700 hover:text-white"
            >
              <FontAwesomeIcon icon={icon({ name: "close" })} />
              <span className="font-semibold">Tolak</span>
            </button>
            <button
              value={"approved"}
              onClick={handleValidation}
              className="text-sm text-white bg-green-500 px-3 py-2 rounded-lg space-x-1"
            >
              <FontAwesomeIcon icon={icon({ name: "check" })} />
              <span className="font-semibold">Terima</span>
            </button>
          </div>
        }
      >
        <div className="space-y-8">
          <div className="space-y-4">
            <div className="flex items-center gap-x-3">
              <Avatar image={avatar} />
              <p className="text-sm">{name}</p>
            </div>
            <p className="text-sm">{biography ?? '-'}</p>
            <p className="text-sm">
              Tanggal bergabung{" "}
              <span className="font-bold">{getFormatDate(created_at)}</span>
            </p>
          </div>
          <div className="space-y-4">
            <p className="font-bold">Kompetensi</p>
            <hr />
            <Level title="Strata mengajar" data={grades} />
            <Level title="Mata pelajaran" data={subjects} />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default memo(Detail);
