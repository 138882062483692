import { useState } from 'react';
import WithdrawalSummary from "./fragment/WithdrawalSummary";
import WithdrawalList from "./fragment/WithdrawalList";
import ModalWithdrawalDetail from "./fragment/ModalWithdrawalDetail";

const WithdrawalPage = () => {
  const [modalData, setModalData] = useState<WithdrawalTransaction| undefined>(undefined)
  return(
    <div className={'space-y-4'}>
      <WithdrawalSummary/>
      <WithdrawalList onClick={setModalData}/>
      <ModalWithdrawalDetail data={modalData} onClose={()=>setModalData(undefined)}/>
    </div>
  )
}

export default WithdrawalPage
