// React
import { useEffect, useState } from "react";

// API
import { services } from "services";

// Types
import { ErrorFetch, SuccessFetch } from "types";
import { List } from "views/Silabus/types";

export type SortField = "name" | "updated_at" | "difficulty" | "grade_id";
export type SortDir = "desc" | "asc";

type Sort = {
  sort_by?: SortField;
  sort_dir?: SortDir;
};

export interface Params extends Sort {
  directory_id?: number;
  includes: "question";
  page: number;
  per_page: number;
}

export function useList() {
  // Hooks
  const [data, setData] = useState<List[]>([]);
  const [isDirty, setDirty] = useState<boolean>(false);
  const [isLoading, setLoading] = useState(false);
  const [params, setParams] = useState<Params>({
    includes: "question",
    page: 1,
    per_page: 10,
  });
  const [dataResult, setResult] = useState({
    next: false,
    previous: false,
    from: 0,
    to: 0,
  });

  useEffect(() => {
    // setLoading(true);
    refetch();

    return () => {
      setData([]);
      setLoading(false);
    };
  }, [params]);

  const refetch = () => {
    setLoading(true);
    services
      .get("/browse", { params })
      .then(
        (
          res: SuccessFetch<{
            data: {
              data: List[];
              prev_page_url: string | null;
              next_page_url: string | null;
              from: number;
              to: number;
            };
          }>
        ) => {
          setData(res.data.data.data);
          setResult({
            next: Boolean(res.data.data.next_page_url),
            previous: Boolean(res.data.data.prev_page_url),
            from: res.data.data.from,
            to: res.data.data.to,
          });
        }
      )
      .catch((err: ErrorFetch) => {
        console.log(err.response.data.message);
      })
      .finally(() => {
        setLoading(false);
        setDirty(true);
      });
  };

  const setDirectory = (value?: number) => {
    const data = { ...params };

    if (value) {
      data.directory_id = value;
    } else {
      delete data.directory_id;
    }

    setParams(data);
  };

  const setLength = (value: number) => {
    setParams((prev) => {
      return {
        ...prev,
        page: 1,
        per_page: value,
      };
    });
  };

  const setPage = (value: number) => {
    setParams((prev) => {
      return {
        ...prev,
        page: value,
      };
    });
  };

  const setSort = (props: Sort) => {
    setParams((prev) => {
      return {
        ...prev,
        ...props,
      };
    });
  };
  const setDirectoryParams = (value?: number) => {
    const data = { ...params };

    if (value) {
      data.directory_id = value;
    } else {
      delete data.directory_id;
    }

    setParams(data);
  };

  return {
    data,
    dataResult,
    isDirty,
    isLoading,
    params,
    setDirectory,
    setLength,
    setPage,
    setSort,
    refetch,
    setDirectoryParams,
  };
}
