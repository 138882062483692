import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Input, TextArea, UploadImage } from "components";
import { FC, memo, useMemo, useState } from "react";
import { useFormContext, Controller } from "react-hook-form";
import { currencyConverter } from "utilities";
import Configuration from "./Config";

type Props = {
  isAdd?: boolean;
  canEdit?: boolean;
  onSave?: () => void;
  onCancel?: () => void;
};

const DetailProgram: FC<Props> = ({ isAdd, canEdit, onSave, onCancel }) => {
  const { watch, control } = useFormContext<BundleItem>();
  const { title, description, detail, total_price, bundle_price, image } =
    watch();

  const [isEdit, setIsEdit] = useState(false);

  const numberOfProgram = useMemo(() => {
    return (
      (detail?.ask_homework_packets?.length || 0) +
      (detail?.programs?.length || 0)
    );
  }, [detail]);

  const canSave = useMemo(() => {
    return Boolean(title && description && image);
  }, [title, description, image]);

  const handleCancel = () => {
    setIsEdit(false);
    onCancel?.();
  };

  const handleSave = () => {
    if (canSave) {
      setIsEdit(false);
      onSave?.();
    }
  };

  return (
    <div className={`flex flex-wrap gap-8 ${isAdd ? "flex-col" : "flex-row"}`}>
      <div className="flex-1 min-w-[40rem]">
        <div className="bg-white rounded-xl">
          <div className="px-5 py-4 border-b flex items-center justify-between">
            <p className="font-bold">Informasi</p>
            {canEdit &&
              (isEdit ? (
                <div className="space-x-3">
                  <FontAwesomeIcon
                    icon={icon({ name: "xmark" })}
                    color="#F04438"
                    onClick={handleCancel}
                    className="cursor-pointer"
                  />
                  <FontAwesomeIcon
                    icon={icon({ name: "floppy-disk", style: "regular" })}
                    color={canSave ? "#17B26A" : "grey"}
                    onClick={handleSave}
                    className={`${
                      canSave ? "cursor-pointer" : "cursor-not-allowed"
                    }`}
                  />
                </div>
              ) : (
                <FontAwesomeIcon
                  icon={icon({ name: "pen-to-square", style: "regular" })}
                  color="#B5A300"
                  onClick={() => setIsEdit(true)}
                />
              ))}
          </div>
          <div className={`p-5 ${isAdd ? "flex gap-5" : ""}`}>
            <div className="flex-1 max-w-[60rem]">
              <p className="font-medium text-gray-500">Judul program</p>
              {isAdd || isEdit ? (
                <Controller
                  control={control}
                  name="title"
                  render={({ field }) => (
                    <Input
                      label=""
                      placeholder="Masukkan judul program"
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              ) : (
                <p>{title}</p>
              )}
              <p className="font-medium text-gray-500 mt-5">Deskripsi</p>
              {isAdd || isEdit ? (
                <Controller
                  control={control}
                  name="description"
                  render={({ field }) => (
                    <TextArea
                      isRichText
                      value={field.value}
                      onChange={field.onChange}
                    />
                  )}
                />
              ) : (
                <p dangerouslySetInnerHTML={{ __html: description }} />
              )}
              {!isAdd && (
                <p className="font-medium text-gray-500 mt-4">Thumbnail</p>
              )}
            </div>
            <Controller
              control={control}
              name="image"
              render={({ field }) => (
                <UploadImage
                  imgSrc={field.value}
                  onUpload={(data) => field.onChange(data?.full_asset)}
                  onDelete={isEdit || isAdd ? () => field.onChange("") : null}
                />
              )}
            />
          </div>
        </div>
      </div>
      <div className={`${isAdd ? "w-[40rem]" : "w-[25rem]"}`}>
        <Configuration
          isAdd={isAdd}
          canEdit={canEdit}
          onSave={onSave}
          onCancel={onCancel}
        />
        {!canEdit && !isAdd && (
          <div className="bg-white rounded-xl mt-5">
            <p className="px-5 py-4 border-b flex items-center justify-between">
              Harga
            </p>
            <div className="p-5 grid grid-cols-2">
              <div className="space-y-2">
                <p className="font-medium text-gray-500">Jumlah program</p>
                <p className="font-medium text-gray-500">
                  Harga seluruh program
                </p>
                <p className="font-medium text-gray-500">Harga bundling</p>
              </div>
              <div className="space-y-2">
                <p>{`${numberOfProgram} program`}</p>
                <p>{currencyConverter(Number(total_price))}</p>
                <p className="font-bold">
                  {currencyConverter(Number(bundle_price))}
                </p>
              </div>
            </div>
            <p className="text-xs text-gray-500 px-5 pb-5">
              * Harga final akan ditampilkan pada mobile dan web app
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(DetailProgram);
