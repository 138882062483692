import {Button} from "../../../components";
import Modal from 'components/Modal';
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import useWithdrawal from "../../../services/useWithdrawal";
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import toast from "react-hot-toast";

const ModalWithdrawalDetail = ({ data, onClose }: { data?: WithdrawalTransaction, onClose: () => void}) => {
  const {patchApproval, getDetailById} = useWithdrawal();
  const [notes, setNotes] = useState<string>('')
  const [isApproved, setIsApproved] = useState(true)
  const [detail, setDetail] = useState<WithdrawalDetailResponse|null>(null)

  useEffect(()=>{
    if (!data){
      setNotes('')
      setIsApproved(true)
    } else {
      getDetailById(data?.id).then((res)=>{
        setDetail(res)
        if (res.status === 'failed') setIsApproved(false)
        if (res.notes) setNotes(res.notes)
      })
    }
  },[data])

  const onSubmit = () => {
    if (data?.id){
      patchApproval(data.id, {
        notes: notes,
        status: isApproved
      }).then(()=> {
        onClose()
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }).catch((e)=> {
        onClose()
        toast.error(e.message);
      })
    }
  }

  return(
    <Modal
      show={!!data}
      title={'Rincian penarikan gaji'}
      onClose={onClose}
      customFooter={(
        <div className="float-right">
          {detail?.status === 'success' || detail?.status === 'failed'?
            <Button color='primary' onButtonClick={onClose}>
              Tutup
            </Button>
            :
            <div className="flex gap-x-2">
            <Button.Cancel onButtonClick={onClose} />
            <Button isDisabled={!isApproved && !notes} color='primary' onButtonClick={onSubmit}>
            Simpan
            </Button>
            </div>
          }
        </div>
      )}
    >
      <div className={'divide-y divide-gray-200'}>
        <WithdrawalDetail detail={detail}/>
        <BankInfo detail={detail}/>
        <ActionButtons
          isApproved={isApproved}
          onChange={setIsApproved}
          notes={notes}
          onSetNotes={setNotes}
          disabled={detail?.status !== 'requested'}
        />
      </div>
    </Modal>
  )
}

const WithdrawalDetail = ({detail}: {detail: WithdrawalDetailResponse|null}) => {
  return(
    <div className={'py-3'}>
      <p className={'text-base font-bold text-gray-600 mb-4'}>Detail penarikan</p>
      <div className={'flex space-x-12'}>
        <div>
          <p className={'text-sm font-normal text-gray-600'}>Nama pengajar</p>
          <p className={'text-sm font-bold text-gray-600'}>{detail?.teacher_name ?? '-'}</p>
        </div>
        <div>
          <p className={'text-sm font-normal text-gray-600'}>No. Handphone</p>
          <p className={'text-sm font-bold text-gray-600'}>{detail?.phone_number ?? '-'}</p>
        </div>
        <div className={'flex flex-1 flex-col text-end'}>
          <p className={'text-sm font-normal text-gray-600'}>Nominal</p>
          <p className={'text-2xl font-bold text-[#079455]'}>Rp {detail?.amount ?? '-'}</p>
        </div>
      </div>
    </div>
  )
}

const BankInfo = ({detail}: {detail:WithdrawalDetailResponse|null}) => {
  return(
    <div className={'py-3'}>
      <p className={'text-base font-bold text-gray-600 mb-4'}>Info rekening</p>
      <div className={'flex space-x-12'}>
        <div>
          <p className={'text-sm font-normal text-gray-600'}>Nama bank</p>
          <p className={'text-sm font-bold text-gray-600'}>{detail?.bank_name ?? '-'}</p>
        </div>
        <div>
          <p className={'text-sm font-normal text-gray-600'}>Nomor rekening</p>
          <p className={'text-sm font-bold text-gray-600'}>{detail?.account_number ?? '-'}</p>
        </div>
        <div>
          <p className={'text-sm font-normal text-gray-600'}>Nama pemilik rekening</p>
          <p className={'text-sm font-bold text-gray-600'}>{detail?.account_name ?? '-'}</p>
        </div>
      </div>
    </div>
  )
}

const ActionButtons = (
  { isApproved, onChange, notes, onSetNotes, disabled} :
    { isApproved: boolean, onChange: (val: boolean)=>void, notes: string, onSetNotes: Dispatch<SetStateAction<string>>, disabled: boolean}
)=> {
  return(
    <div className={'py-3 flex flex-col space-y-3'}>
      <p className={'text-base font-bold text-gray-600'}>Aksi</p>
      <div className={'flex space-x-3'}>
        <button disabled={disabled} className={`py-2 px-6 border-gray-300 ${isApproved? 'bg-[#FFE500]': 'bg-white border'} rounded-md`} onClick={()=>onChange(true)}>
          <FontAwesomeIcon
            icon={icon({name: 'check'})}
            color={'#344054'}
          />
          <span className={'text-gray-600 text-sm font-semibold ml-2'}>Setuju</span>
        </button>
        <button disabled={disabled} className={`py-2 px-6 border-gray-300 ${isApproved? 'bg-white border': 'bg-[#FFE500]'} rounded-md`} onClick={()=>onChange(false)}>
          <FontAwesomeIcon
            icon={icon({name: 'times'})}
            color={'#344054'}
          />
          <span className={'text-gray-600 text-sm font-semibold ml-2'}>Tolak</span>
        </button>
      </div>
      {!isApproved && (
        <div className={'flex flex-col'}>
          <p className={'text-sm font-normal text-gray-600'}>Alasan ditolak</p>
          <textarea value={notes} onChange={(e)=>onSetNotes(e.target.value)} rows={2} placeholder={'Masukan alasan penolakan'} className={'resize-none text-md text-gray-500 py-2 px-3 border border-gray-300 rounded-md flex flex-1 h-60'}/>
        </div>
      )}
    </div>
  )
}

export default ModalWithdrawalDetail
