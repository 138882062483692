const MathOperationsTool = ({data = [], onClick}: {data: {iconPath: string; label: string}[] ;onClick: (label:string)=>void}) => {
  return(
    <div className={'z-20 hidden min-w-[150px] p-2 bg-gray-100 rounded-md absolute top-[26px] left-0 right-0 group-hover:grid grid-cols-6'}>
      {data.map((item)=>(
        <div
          key={item.label.toString()}
          onClick={(e)=> {
            e.preventDefault()
            onClick(item.label)
          }}
        >
        <img src={item.iconPath} className="p-1 w-6 h-6 text-primary" alt="" />
        </div>
      ))}
    </div>
  )
}

export default MathOperationsTool
