import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FC, memo } from "react";

type Props = {
  rate: number;
};

const Rating: FC<Props> = ({ rate }) => {
  return (
    <div className="flex items-center gap-x-1.5">
      <FontAwesomeIcon icon={icon({ name: "star" })} color="#FFE500" />
      <p className="text-sm">
        <span className="font-semibold">{rate}</span>
        <span className="text-gray-400"> / 5</span>
      </p>
    </div>
  );
};

export default memo(Rating);
