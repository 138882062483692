import { memo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { FormProvider, useForm } from "react-hook-form";
import { Button } from "components";

import DetailProgram from "../../fragment/AddtionalSteps/DetailProgram";
import Syllabus from "../../fragment/AddtionalSteps/Syllabus";
import Summary from "../../fragment/AddtionalSteps/Summary";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import toast from "react-hot-toast";
import useProgram from "services/useProgram";
import { ErrorFetch } from "types";
import Breadcrumbs from "components/Breadcrumbs";
import ProgressBullet from "components/ProgressBullet";

const crumbs = ["Program", "Tambah program baru"];
const progress = ["Detail program", "Silabus", "Ringkasan"];

const Elearning = () => {
  const { addProgram } = useProgram();
  const navigate = useNavigate();
  const location = useLocation();
  const { state } = location;

  const [step, setStep] = useState(0);

  const methods = useForm({
    defaultValues: {
      description: "",
      syllabus_id: 0,
      title: "",
      thumbnail_path: "",
      price: 0,
      discount: 0,
      is_active: true,
      is_flat_disc: true,
      type: state?.type,
    },
  });
  const { handleSubmit } = methods;

  const onSubmit = (data: ProgramPayload, isDraft?: boolean) => {
    const payload: ProgramPayload = {
      ...data,
      type: state?.type,
      is_draft: Boolean(isDraft),
    };
    toast.promise(addProgram(payload), {
      loading: "Loading...",
      success: () => {
        navigate("/program", {
          replace: true,
        });
        return "";
      },
      error: (err: ErrorFetch) => {
        const msg = err?.response?.data?.message || "Gagal menambahkan.";
        return msg;
      },
    });
  };

  const handleNext = () => {
    if (step < 3) {
      setStep(step + 1);
    }
  };

  const handleCancel = () => {
    if (step > 0) {
      setStep(step - 1);
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <div className="flex items-center gap-x-10 my-5">
        <p className="font-bold text-xl basis-40">E-Learning</p>
        <ProgressBullet
          data={progress}
          currentStep={step}
          onBack={() => setStep(step - 1)}
          onNext={() => setStep(step + 1)}
        />
      </div>
      <FormProvider {...methods}>
        {step === 0 && <DetailProgram />}
        {step === 1 && <Syllabus />}
        {step === 2 && <Summary />}
      </FormProvider>
      <div className="mt-5 flex justify-between items-center">
        <Button
          color="primary"
          onButtonClick={handleSubmit((data) => onSubmit(data, true))}
        >
          <FontAwesomeIcon icon={icon({ name: "floppy-disk" })} />
          <span className="ml-3">Simpan sebagai draft</span>
        </Button>
        <div>
          <Button.Cancel onButtonClick={handleCancel} />
          {step === 2 ? (
            <Button
              color="primary"
              className="ml-3"
              onButtonClick={handleSubmit((data) => onSubmit(data, false))}
            >
              Submit
            </Button>
          ) : (
            <Button color="primary" className="ml-3" onButtonClick={handleNext}>
              Lanjutkan
            </Button>
          )}
        </div>
      </div>
    </>
  );
};

export default memo(Elearning);
