// React
import { Fragment, useEffect, useState } from "react";

// Components
import {
  Button,
  Card,
  Content,
  Datatable,
  Icon,
  Label,
  Modal,
  Select,
} from "components";
import { CreateSection } from "./components";

// Third-Party Libraries
import { Link, useParams } from "react-router-dom";

// Utilities
import { baseData } from "./utilities";
import { services } from "services";
import { TableType } from "./List";

function DetailSection(): JSX.Element {
  // Variables
  const items = [
    "Soal Bab 1 - Dasar Bahasa Indonesia",
    "Soal Bab 2 - Dasar Bahasa Indonesia",
    "Soal Bab 3 - Dasar Bahasa Indonesia",
    "Soal Bab 4 - Dasar Bahasa Indonesia",
  ];

  // Hooks
  const [isOpen, setOpen] = useState(false);

  return (
    <Fragment>
      <Button.Icon action="show" onButtonClick={() => setOpen(true)} />

      {isOpen && (
        <Modal
          size="xl"
          title="Detail Modul"
          isOpen={isOpen}
          closeModal={() => setOpen(false)}
        >
          <Modal.Body>
            <div className="flex flex-col gap-y-3">
              <section>
                <Label>Judul Modul</Label>
                <div>Modul Pembelajaran Bab I</div>
              </section>

              <section>
                <Label>Isi Modul:</Label>
                {items.map((item, key) => (
                  <div key={key} className="flex flex-wrap">
                    <Icon.Document className="w-4 mr-1 fill-primary" />
                    <span className="text-primary">{item}</span>
                  </div>
                ))}
              </section>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button type="button" color="primary">
              Kembali
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </Fragment>
  );
}

export default function ListDetail(): JSX.Element {
  const [name, SetName] = useState("");
  const [childFolder, setChildFolder] = useState<TableType[] | null>();
  const params = useParams();
  const id = params ? params?.id : null;
  async function GetDetailFolder() {
    services
      .get(`/directory/${id}`)
      .then((resp) => {
        const fullPath = resp?.data?.data.full_path;
        const folderArray = fullPath
          .split("/")
          .slice(1)
          .map((folder: any) => folder.trim());
        // setChildFolder(folderArray);
        SetName(resp.data.data.name);
        // setTableData(resp.data.data);
      })
      .catch((error) => console.error(error));
  }

  useEffect(() => {
    GetDetailFolder();
    GetChildlFolder();
  }, []);

  async function GetChildlFolder() {
    services
      .get(`/directory/?parent_id=${id}`)
      .then((resp) => {
        // console.log("child folder", resp.data.data);
        setChildFolder(resp?.data?.data);
        // setTableData(resp.data.data);
      })
      .catch((error) => console.error(error));
  }

  return (
    <Content>
      <Content.Header
        title={
          <div>
            <section className="flex">
              <Icon.Folder className="w-5 mr-1 fill-primary" />
              <span>{name}</span>
            </section>

            <section>
              <div className="breadcrumbs text-sm">
                <ul>
                  <li>Bank Soal</li>
                  <li>
                    <div className="text-primary">{name}</div>
                  </li>
                </ul>
              </div>
            </section>
          </div>
        }
        rightElement={<CreateSection />}
      />

      <Content.Body>
        <Card>
          <Card.Header>
            <div className="flex flex-col md:flex-row justify-center md:justify-between items-center">
              <Card.Title>Daftar Kelas</Card.Title>

              <Select options={[{ label: "Terakhir dibuat" }]} />
            </div>
          </Card.Header>

          <Card.Body>
            <Datatable>
              <Datatable.Table
                columns={[
                  {
                    title: "#",
                    field: (_, key) => (
                      <div className="text-center">{`${key + 1}.`}</div>
                    ),
                  },
                  {
                    title: "Kelas",
                    field: (item, key) => {
                      if (item.is_file) {
                        return (
                          <div key={key} className="flex flex-wrap">
                            <Icon.Document className="w-4 mr-1 fill-primary" />
                            <span>{item.kelas}</span>
                          </div>
                        );
                      } else {
                        return (
                          <Link
                            key={key}
                            to={`${baseData.url}/list-kelas/${item.id}`}
                            className="link link-secondary"
                          >
                            <Icon.Folder className="w-4 mr-1 fill-primary" />
                            <span>{item.kelas}</span>
                          </Link>
                        );
                      }
                    },
                  },
                  {
                    title: "Tanggal Diubah",
                    field: (item, key) => (
                      <div key={key} className="text-center">
                        {item.tanggal}
                      </div>
                    ),
                  },
                  {
                    title: "Kesulitan",
                    field: (item, key) => (
                      <div
                        key={key}
                        className="flex gap-2 text-center justify-start items-center"
                      >
                        {item?.kesulitan === "Sedang" && (
                          <div className="bg-yellow-300 rounded-full h-4 w-4"></div>
                        )}
                        {item?.kesulitan === "Sulit" && (
                          <div className=" bg-red-500 rounded-full h-4 w-4"></div>
                        )}
                        {item?.kesulitan === "Mudah" && (
                          <div className="  bg-green-500 rounded-full h-4 w-4"></div>
                        )}
                        <p>{item.kesulitan}</p>
                      </div>
                    ),
                  },
                  {
                    title: "Tingkatan",
                    field: (item, key) => (
                      <div key={key} className="text-center">
                        {item.tingkatan}
                      </div>
                    ),
                  },
                  {
                    title: "Aksi",
                    field: (row, key) => (
                      <div
                        key={key}
                        className="flex flex-row justify-center gap-x-1"
                      >
                        {row.is_file && (
                          <Fragment>
                            <DetailSection />
                            <Button.Icon action="edit" />
                          </Fragment>
                        )}

                        <Button.Icon action="delete" />
                      </div>
                    ),
                  },
                ]}
                data={
                  childFolder
                    ? childFolder.map((data) => {
                        return {
                          id: data.id,
                          kelas: data.name,
                          kesulitan: "-",
                          is_file: false,
                          tingkatan: "-",
                          tanggal: "-",
                        };
                      })
                    : [{}]
                }
              />

              {/* <Datatable.Pagination
                first_of_page={1}
                rows_per_page={10}
                total_data={1}
                total_of_page={1}
              /> */}
            </Datatable>
          </Card.Body>
        </Card>
      </Content.Body>
    </Content>
  );
}
