import {useRef} from 'react';
import {toast} from 'react-hot-toast';
import {services} from 'services';
import {API_URL} from 'constant';
import { AxiosError } from 'axios';

const defaultParams: BrowseParams = {
  page: 1,
  per_page: 10,
  sort_by: 'name',
  sort_dir: 'asc',
};

const useModule = () => {
  const lastPayload = useRef(defaultParams);

  const getList = async (params?: BrowseParams) => {
    try {
      let payload: BrowseParams = {
        ...lastPayload.current,
        ...params,
        includes: 'module'
      };

      if (params?.directory_id) {
        payload = {
          ...payload,
          directory_id: params.directory_id
        }
      }

      lastPayload.current = payload;
      const response = await services.get(API_URL.browse, {
        params: payload
      });

      return response.data?.data;
    } catch (error) {
      return [];
    }
  };

  const getDetail = async (id: number): Promise<ModuleDetail | null> => {
    try {
      const response = await services.get(`${API_URL.module}/${id}`);

      return response.data?.data;
    } catch {
      return null;
    }
  };

  const addFolder = async (name: string, parent?: number) => {
    try {
      await services.post(API_URL.directory, {
        name,
        parent_id: parent
      });

      toast.success(`${name} berhasil di tambah`);
      return true
    } catch (error) {
      const err = error as AxiosError;
      const response = err.response?.data as any;
      const errMessage = response?.message || 'Terjadi kesalahan!';

      toast.error(errMessage);
      return false;
    }
  };

  const editFolder = async (param: {
    name: string, parent: number, id: number
  }) => {
    try {
      await services.put(`${API_URL.directory}/${param.id}`, {
        name: param.name
      });

      toast.success(`${param.name} berhasil di ubah`);
      return true
    } catch (error) {
      const err = error as AxiosError;
      const response = err.response?.data as any;
      const errMessage = response?.message || 'Terjadi kesalahan!';

      toast.error(errMessage);
      return false;
    }
  };

  const addModule =async (params: AddModuleParams) => {
    try {
      await services.post(API_URL.module, params);
      return 'success';
    } catch (error) {
      const err = error as AxiosError;
      const errMessage = err.response?.data as any;

      throw errMessage;
    }
  };

  const editModule =async (id: number, params: AddModuleParams) => {
    try {
      await services.put(`${API_URL.module}/${id}`, params);
      return 'success';
    } catch (error) {
      const err = error as AxiosError;
      const errMessage = err.response?.data as any;

      throw errMessage;
    }
  }

  const deleteModule =async (id: number) => {
    try {
      await services.delete(`${API_URL.module}/${id}`);
      return 'success';
    } catch (error) {
      const err = error as AxiosError;
      return err.response?.data as any;
    }
  }

  return {
    getList,
    getDetail,
    addFolder,
    addModule,
    editModule,
    editFolder,
    deleteModule
  }
};

export default useModule;
