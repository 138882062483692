// Components
import { Button } from "components/buttons"
import { Modal } from "./Modal"

export function Alert(props: {
  description?: string
  cancel?: {
    title: string
    onClick: () => void
  }
  confirm: {
    title: string
    onClick: () => void
  }
  title: string
  onClose?: () => void
}) {
  return (
    <Modal
      isOpen
      title={props.title}
      closeModal={() => {
        if (props.onClose) {
          props.onClose()
        }
      }}
    >
      <Modal.Body>
        <div>{props.description ?? "Apakah anda yakin?"}</div>
      </Modal.Body>

      <Modal.Footer className="flex flex-wrap justify-end gap-3">
        <Button color="outline-primary" onButtonClick={props.confirm.onClick}>{props.confirm.title}</Button>
        {props.cancel && <Button color="outline-error" onButtonClick={props.cancel.onClick}>{props.cancel.title}</Button>}
      </Modal.Footer>
    </Modal>
  )
}