// React
import { Fragment, useEffect, useState } from "react";

// Components
import {
  Button,
  Card,
  Content,
  Datatable,
  DateString,
  Icon,
  Label,
  Modal,
  Skeleton,
} from "components";
import { CreateSection, DeleteRow } from "../components";

// Utilities
import { baseData } from "../utilities";

// Third-Party Libraries
import { Link, useNavigate } from "react-router-dom";

// Types

// View Models
import { SortField, useList, useSingle } from "./viewModels";
import { CreatePopup } from "../components/CreatePopup";
import { QuestionType } from "./types";
import DifficultyBadge from "./viewModels/useDifficulty";

export default function List() {
  // Hooks
  const [directory, setDirectory] = useState<
    {
      id: number;
      path: string;
    }[]
  >([]);
  const {
    data,
    dataResult,
    isDirty,
    isLoading,
    params,
    refetch,
    setDirectoryParams,
    setLength,
    setPage,
    setSort,
  } = useList();

  // Variables
  const directoryProp = directory[directory.length - 1]?.id ?? 0;

  useEffect(() => {
    const storedDirectory = localStorage.getItem('directory');

    if (storedDirectory) {
      const parsedDirectory = JSON.parse(storedDirectory);

      if (Array.isArray(parsedDirectory)) {
        setDirectory(parsedDirectory);
      }
    }
  }, []);

  useEffect(() => {
    localStorage.setItem('directory', JSON.stringify(directory));
    if (isDirty) {
      setDirectoryParams(directory[directory.length - 1]?.id);
    }

  }, [directory, isDirty]);

  const navigate = useNavigate();

  const onAddDirectory = (name: string, id: number) =>
    setDirectory((prev) => [...prev, { path: name, id }]);

  const onEdit = (id: number) => {
    navigate(`${baseData.url}/edit/${id}`, {
      state: {
        directory_id: directory[directory.length - 1]?.id
          ? directory[directory.length - 1]?.id
          : null,
      },
    });
  };

  return (
    <Content>
      <Content.Header
        title={
          directory.length === 0 ? (
            baseData.title
          ) : (
            <div>
              <section className="flex">
                <Icon.Folder className="w-5 mr-1 fill-primary" />
                <span>{directory[directory.length - 1].path}</span>
              </section>

              <section>
                <div className="breadcrumbs text-sm">
                  <ul>
                    <li onClick={() => setDirectory([])}>Bank Soal</li>

                    {directory.map((item, key) => {
                      // Variables
                      const isLastItem = key + 1 === directory.length;

                      return (
                        <li
                          key={key}
                          onClick={() => {
                            if (!isLastItem) {
                              // Variables
                              const items = [...directory];

                              items.splice(key + 1);
                              setDirectory(items);
                            }
                          }}
                        >
                          <div className={isLastItem ? "text-primary" : ""}>
                            {item.path}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </section>
            </div>
          )
        }
        rightElement={
          <CreatePopup
            directory_id={directory[directory.length - 1]?.id ?? null}
            onAddFolder={refetch}
          />
        }
      />

      <Content.Body>
        {/* <TableSection
          directory={directory[directory.length - 1]?.id ?? 0}
          onAddDirectory={(name, id) => setDirectory(prev => [...prev, { path: name, id }])}
        /> */}
        <Card>
          <Card.Header>
            <Card.Title>Daftar {baseData.title}</Card.Title>
          </Card.Header>

          <Card.Body>
            <Datatable>
              <Datatable.Table
                isLoading={isLoading}
                sort={{
                  field: params.sort_by!,
                  dir: params.sort_dir!,
                }}
                columns={[
                  {
                    title: "#",
                    field: (_, key) => (
                      <div className="text-center">{`${key + 1}.`}</div>
                    ),
                  },
                  {
                    title: "Judul",
                    sortField: "name",
                    field: (item: QuestionType, key) => {
                      if (item.type === "question") {
                        return (
                          <div key={key} className="flex">
                            <Icon.Document className="w-4 mr-1 fill-primary" />
                            <span>{item.name}</span>
                          </div>
                        );
                      } else {
                        return (
                          <div
                            key={key}
                            className="flex text-blue-400 cursor-pointer"
                            onClick={() => {
                              onAddDirectory(item.name, item.id);
                            }}
                          >
                            <Icon.Folder className="w-4 mr-1 fill-primary" />
                            <span>{item.name}</span>
                          </div>
                        );
                      }
                    },
                  },
                  {
                    title: "Tanggal Diubah",
                    tdProps: {
                      className: "!text-center",
                    },
                    sortField: "updated_at",
                    field: (item: QuestionType) => {
                      if (item.updated_at) {
                        return (
                          <DateString
                            date={item.updated_at}
                            format="MM-DD-YYYY"
                          />
                        );
                      } else {
                        return "[-]";
                      }
                    },
                  },
                  {
                    title: "Kesulitan",
                    tdProps: {
                      className: "!text-center",
                    },
                    sortField: "difficulty",
                    field: (item: QuestionType, key) => {
                      if (item.difficulty) {
                        return (
                          <div
                            key={key}
                            className="flex text-center justify-center"
                          >
                            <DifficultyBadge difficulty={item?.difficulty} />
                          </div>
                        );
                      } else {
                        return "[-]";
                      }
                    },
                  },
                  {
                    title: "Tingkatan",
                    tdProps: {
                      className: "!text-center",
                    },
                    sortField: "grade_id",
                    field: (item: QuestionType, key) => {
                      if (item.grade_name) {
                        return (
                          <div
                            key={key}
                            className="flex text-center justify-center"
                          >
                            {item.grade_name}
                          </div>
                        );
                      } else {
                        return "[-]";
                      }
                    },
                  },

                  {
                    title: "Aksi",
                    tdProps: {
                      className: "flex justify-center gap-x-1",
                    },
                    field: (item: QuestionType, key) => (
                      <Fragment key={key}>
                        {item.type === "question" && (
                          <Fragment>
                            <DetailRow item={item} />
                            <button
                              onClick={() => onEdit(item?.id)}
                              // href={`/bank-soal/edit/${item.id}`}
                            >
                              <Button.Icon action="edit" />
                            </button>
                            {/* <Button.Icon action="edit" /> */}
                          </Fragment>
                        )}
                        <DeleteRow item={item} type={item.type} id={item.id} />
                      </Fragment>
                    ),
                  },
                ]}
                data={data}
                onSortChange={(props) => {
                  setSort({
                    sort_by: props.field as SortField,
                    sort_dir: props.dir,
                  });
                }}
              />

              <Datatable.Pagination
                isLoading={isLoading}
                current_page={params.page}
                rows_per_page={params.per_page}
                data_length={{
                  from: dataResult.from,
                  to: dataResult.to,
                }}
                onLengthChange={setLength}
                onNextClick={(value) => dataResult.next && setPage(value)}
                onPreviousClick={(value) =>
                  dataResult.previous && setPage(value)
                }
              />
            </Datatable>
          </Card.Body>
        </Card>
      </Content.Body>
    </Content>
  );
}

function DetailRow(props: { item: QuestionType }) {
  // Hooks
  const [isOpen, setOpen] = useState(false);

  const toggle = () => setOpen((prev) => !prev);

  const ModalSection = () => {
    // Hooks
    const { data, isLoading } = useSingle(props.item.id);

    return (
      <Modal isOpen closeModal={toggle} title="Detail Soal">
        <Modal.Body className="flex flex-col gap-y-3">
          <Content>
            <Label>Judul Soal</Label>
            <Content.Body>{data?.name ? data.name : "[-]"}</Content.Body>
          </Content>

          {isLoading ? (
            <Fragment>
              <section>
                <Skeleton.Label />
                <Skeleton.List />
              </section>

              <section>
                <Skeleton.Label />
                <Skeleton.List />
                <Skeleton.List />
                <Skeleton.List />
              </section>
            </Fragment>
          ) : (
            data && (
              <Fragment>
                <Content>
                  <Label>Pelajaran:</Label>

                  <Content.Body>
                    {data.subject.name ? data.subject.name : "[-]"}
                  </Content.Body>
                </Content>
                <button
                  onClick={toggle}
                  className="ml-auto bg-[#FFF200] text-[#38393A] font-medium text-sm px-4 py-2 rounded-xl mt-6 block"
                >
                  Kembali
                </button>
              </Fragment>
            )
          )}
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <Fragment>
      <Button.Icon action="show" onButtonClick={toggle} />
      {isOpen && <ModalSection />}
    </Fragment>
  );
}
