import {FC, memo, useEffect, useMemo, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import {useParams} from "react-router-dom";
import toast from "react-hot-toast";
import useLiveClass from "../../../../../services/useLiveClass";
import {InputNumber, TextArea, UploadImage} from "../../../../../components";
import {Form, Formik, FormikValues} from "formik";
import moment from "moment";
import { currencyConverter, priceByDiscount } from "utilities";
import { DatePicker } from "components/datepicker";
import { TimePicker } from "components/timepicker";
import { services } from "services";

interface DetailTabProps {
  onRefresh: () => void;
}

const DetailTab: FC<DetailTabProps> = ({ onRefresh }) => {
  const {id} = useParams();
  const {getDetail , updateDetail} = useLiveClass();
  const [menuOnEdit, setMenuOnEdit] = useState<string|null>(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<LiveClassDetail | null>(null);

  useEffect(()=>{
    setMenuOnEdit(null)
  },[])

  const onUpdate = (data:LiveClassUpdateParams) => {
    updateDetail(Number(id),data)
      .then((response) => {
        setMenuOnEdit(null)
        onRefresh()
      })
      .catch((err) => toast.error(err?.message || 'Gagal memuat data.'))
      .finally(() => setLoading(false))
  }

  useEffect(() => {
    if (id) {
      setLoading(true);
      getDetail(Number(id))
        .then((response) => setData(response?.data))
        .catch((err) => toast.error(err?.message || 'Gagal memuat data.'))
        .finally(() => setLoading(false))
    }
  }, [id, getDetail]);

  return(
    <div className='flex flex-1 space-x-4'>
      <ProgramInfoSection
        data={data}
        isEditEnabled={menuOnEdit==='info'}
        onPressEdit={()=>setMenuOnEdit('info')}
        onCancelEdit={()=>setMenuOnEdit(null)}
        onSaveEdit={onUpdate}
      />
      <div className='space-y-4'>
        <ProgramPriceSection
          data={data}
          isEditEnabled={menuOnEdit==='price'}
          onPressEdit={()=>setMenuOnEdit('price')}
          onCancelEdit={()=>setMenuOnEdit(null)}
          onSaveEdit={onUpdate}
        />
        <ProgramConfigurationSection
          data={data}
          isEditEnabled={menuOnEdit==='config'}
          onPressEdit={()=>setMenuOnEdit('config')}
          onCancelEdit={()=>setMenuOnEdit(null)}
          onSaveEdit={onUpdate}
        />
      </div>
    </div>
  )
}

type ProgramInfoSectionProps = {
  data: LiveClassDetail | null
  isEditEnabled: boolean
  onPressEdit: () => void
  onCancelEdit: () => void
  onSaveEdit: (data: FormikValues) => void
}

const ProgramInfoSection = ({data, isEditEnabled, onPressEdit, onCancelEdit, onSaveEdit}: ProgramInfoSectionProps) => {
  return (
    <div className='bg-white flex flex-col flex-1 rounded-md'>
      <Formik
        enableReinitialize
        initialValues={{
          ...data,
          "class_detail": {
            max_students: 50,
            "is_group": data?.is_group,
            "is_private": 1,
          }
        }}
        onSubmit={(values) => {
          onSaveEdit(values)
        }}
      >
        {({ values, setFieldValue, submitForm }) => {
          return (
            <>
              <div className='px-4 pt-4 pb-2 content-between flex items-center'>
                <span className='flex flex-1 font-semibold text-md text-black-100'>Informasi program</span>
                <div className='flex space-x-2'>
                  {isEditEnabled?
                    <>
                      <FontAwesomeIcon className='cursor-pointer' color='#F04438' icon={icon({name: 'close'})} onClick={onCancelEdit}/>
                      <FontAwesomeIcon className='cursor-pointer' color='#17B26A' icon={icon({name: 'save'})} onClick={submitForm}/>
                    </>
                    :
                    <FontAwesomeIcon className='cursor-pointer' color='#B5A300' icon={icon({name: 'edit'})} onClick={onPressEdit}/>
                  }
                </div>
              </div>
              <div className="divider my-0"/>
              <Form>
                <div className='px-4 flex flex-col space-y-4 py-3'>
                  <div className='flex flex-col'>
                    <span className='font-medium text-md text-gray-500'>Judul program</span>
                    {
                      isEditEnabled ?
                        <input type={"text"}
                              className="bg-transparent outline-none border px-3 rounded-lg w-full placeholder:text-[#9DA4B3] py-2 h-10"
                              value={values?.title}
                              onChange={(e) => setFieldValue("title", e.target.value)}
                        />
                        :
                        <span className='font-normal text-base text-gray-900'>{values?.title}</span>
                    }
                  </div>

                  <div className='flex flex-col'>
                    <span className='font-medium text-md text-gray-500'>Deskripsi</span>
                    {isEditEnabled ?
                      <TextArea
                        isRichText
                        value={values?.description}
                        onChange={(val) => setFieldValue("description", val)}
                      />
                      :
                      <p className='font-normal text-base text-gray-900 min-h-[24px]'
                        dangerouslySetInnerHTML={{__html: values?.description ?? ''}}/>
                    }
                  </div>

                  <div className='flex flex-col'>
                    <span className='font-medium text-md text-gray-500'>Thumbnail</span>
                    <UploadImage
                      imgSrc={`${values?.thumbnail_path}`}
                      onUpload={(data) => setFieldValue("thumbnail_path", data?.full_asset || "")}
                      onDelete={isEditEnabled ? () => setFieldValue("thumbnail_path", "") : null}
                    />
                  </div>

                </div>
              </Form>
            </>
          )
        }}
      </Formik>
    </div>
  )
}

type ProgramPriceSectionProps = {
  data: LiveClassDetail | null
  isEditEnabled: boolean
  onPressEdit: () => void
  onCancelEdit: () => void
  onSaveEdit: (data: FormikValues) => void
}

const ProgramPriceSection = (props: ProgramPriceSectionProps) => {
  const { data, onSaveEdit, isEditEnabled, onCancelEdit, onPressEdit } = props
  const finalPrice = (price: string, discount: string, is_flat_disc: boolean) => {
    if (is_flat_disc) {
      return currencyConverter(Number(price) - Number(discount));
    } else {
      return priceByDiscount(Number(price), Number(discount));
    }
  };
  return (
    <div className='bg-white flex-col rounded-md'>
      <Formik
        enableReinitialize
        initialValues={{
          ...data,
          "class_detail": {
            max_students: 50,
            "is_group": data?.is_group,
            "is_private": 1,
          }
        }}
        onSubmit={(values) => {
          onSaveEdit(values)
        }}
      >
        {({ values, setFieldValue, submitForm }) => {
          return (
      <>
        <div className='px-4 pt-4 pb-2 content-between flex items-center space-x-2'>
          <span className='flex flex-1 font-semibold text-md text-black-100'>Harga</span>
          {isEditEnabled?
            <>
              <FontAwesomeIcon className='cursor-pointer' color='#F04438' icon={icon({name: 'close'})} onClick={onCancelEdit}/>
              <FontAwesomeIcon className='cursor-pointer' color='#17B26A' icon={icon({name: 'save'})} onClick={submitForm}/>
            </>
            :
            <FontAwesomeIcon className='cursor-pointer' color='#B5A300' icon={icon({name: 'edit'})} onClick={onPressEdit}/>
          }
        </div>
        <div className="divider my-0"/>
        <table className="table-auto border-spacing-5 border-separate">
          <tbody>
          <tr className='py-4'>
            <td className='text-gray-500 font-medium text-md'>Harga program</td>
            {
              isEditEnabled ?
                <InputNumber
                label=""
                className="px-3.5 py-2.5 border rounded-lg"
                placeholder="Masukkan harga"
                options={{
                  numeral: true,
                  numeralDecimalMark: ",",
                  delimiter: ".",
                }}
                value={values?.price}
                onChange={(e) => setFieldValue("price", Number(e.target.rawValue))}
              />
                :
                <td className='text-gray-900 font-normal text-md'>{currencyConverter(Number(values?.price))}</td>
            }
          </tr>
          <tr>
            <td className='text-gray-500 font-medium text-md'>Diskon</td>
            {isEditEnabled ? (
            <div className="w-52 px-3.5 py-2.5 border rounded-lg mt-1.5 flex">
              <InputNumber
                label=""
                className="w-full pr-3.5"
                placeholder="Masukkan diskon"
                options={{
                  numeral: true,
                  numeralDecimalMark: ",",
                  delimiter: ".",
                }}
                value={values?.discount}
                onChange={(e) => setFieldValue("discount", Number(e.target.rawValue))}
              />
              <select
                name="isFlatDiscount"
                id="isFlatDiscount"
                value={Number(values?.is_flat_disc)}
                onChange={(e) => {
                  const value = Number(e.target.value);
                  setFieldValue("is_flat_disc", Boolean(value))
                }}
              >
                <option value={1}>Rp</option>
                <option value={0}>%</option>
              </select>
            </div>
          ) : (
            <p className="text-red-500">
              {values?.is_flat_disc ? `${currencyConverter(Number(values?.discount))}` : `${values?.discount}%`}
            </p>
          )}
          </tr>
          <tr>
            <td className='text-gray-500 font-medium text-md'>Harga final</td>
            <td className='text-gray-900 font-bold text-md'>{finalPrice(String(values?.price), String(values?.discount), Boolean(values?.is_flat_disc))}</td>
          </tr>
          <tr/>
          <tr>
            <td colSpan={2} className='text-gray-500 font-normal text-xs'>* Harga final akan ditampilkan pada mobile dan web app</td>
          </tr>
          </tbody>
        </table></>
        )}}
      </Formik>
    </div>
  )
}

type ProgramConfigSectionProps = {
  data: LiveClassDetail | null
  isEditEnabled: boolean
  onPressEdit: () => void
  onCancelEdit: () => void
  onSaveEdit: (data: FormikValues) => void
}

const ProgramConfigurationSection = (props: ProgramConfigSectionProps) => {
  const { data, onSaveEdit, isEditEnabled, onCancelEdit, onPressEdit } = props
  const [grades, setGrades] = useState<any[]>([]);
  const [subjects, setSubjects] = useState<any[]>([]);

  async function GetGrade() {
    services
      .get("dropdown/grade")
      .then((resp) => {
        const data = resp?.data?.data;

        setGrades(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }
  async function GetSubject() {
    services
      .get("dropdown/subject")
      .then((resp) => {
        const data = resp?.data?.data;
        setSubjects(data);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    GetGrade();
    GetSubject();
  }, []);

  return (
    <div className='bg-white flex-col rounded-md'>
      <Formik
        enableReinitialize
        initialValues={{
          ...data,
          "class_detail": {
            max_students: 50,
            "is_group": data?.is_group,
            "is_private": 1,
          }
        }}
        onSubmit={(values) => {
          onSaveEdit(values)
        }}
      >
        {({ values, setFieldValue, submitForm }) => {
          return (
      <>
        <div className='px-4 pt-4 pb-2 content-between flex items-center space-x-2'>
          <span className='flex flex-1 font-semibold text-md text-black-100'>Konfigurasi</span>
          {isEditEnabled?
            <>
              <FontAwesomeIcon className='cursor-pointer' color='#F04438' icon={icon({name: 'close'})} onClick={onCancelEdit}/>
              <FontAwesomeIcon className='cursor-pointer' color='#17B26A' icon={icon({name: 'save'})} onClick={submitForm}/>
            </>
            :
            <FontAwesomeIcon className='cursor-pointer' color='#B5A300' icon={icon({name: 'edit'})} onClick={onPressEdit}/>
          }
        </div>
        <div className="divider my-0"/>
        <table className="table-auto border-spacing-5 border-separate">
          <tbody>
          <tr>
            <td className='text-gray-500 font-medium text-md'>Jenis live class</td>
            {
              isEditEnabled?
                <div className='space-x-6 flex flex-row'>
                  <label className={`flex cursor-pointer gap-2 items-center`}>
                    <input
                      checked={values?.is_group}
                      type="radio"
                      className="radio checked:bg-primary checked:border-none"
                      onChange={() => setFieldValue("is_group", true)}
                    />
                    <span className="font-medium text-base text-gray-700">Group</span>
                  </label>
                  <label className={`flex cursor-pointer gap-2 items-center`}>
                    <input
                      checked={!values?.is_group}
                      type="radio"
                      className="radio checked:bg-primary checked:border-none"
                      onChange={() => setFieldValue("is_group", false)}
                    />
                    <span className="font-medium text-base text-gray-700">{values?.is_group ? "group" : "private"}</span>
                  </label>
                </div>
                :
                <td className='text-gray-900 font-normal text-md'>{values?.is_group ? 'Group' : 'Private'}</td>
            }
          </tr>
          <tr>
            <td className='text-gray-500 font-medium text-md'>Periode program</td>
            { isEditEnabled? 
              <div>
                <DatePicker
                  placeholder="Pilih jadwal"
                  label="Periode mulai pendaftaran"
                  value={values?.program_start_period ? values?.program_start_period.slice(0,10) : ""}
                  onChange={(val) => {
                    setFieldValue('program_start_period', val)
                    }
                  }
                />
                <DatePicker
                  placeholder="Pilih jadwal"
                  label="Periode akhir pendaftaran"
                  value={values?.program_end_period ? values?.program_end_period.slice(0,10) : ""}
                  onChange={(val) => {
                    setFieldValue('program_end_period', val)
                    }
                  }
                />
                <div className="mt-3">

                </div>
              </div>
              : <td className='text-gray-900 font-normal text-md'>{`${values?.program_start_period ? values?.program_start_period.slice(0, 10) : ""} - ${values?.program_end_period ? values?.program_end_period.slice(0, 10) : ""}`}</td>
            }
          </tr>
          <tr>
            <td className='text-gray-500 font-medium text-md'>Strata</td>
            {isEditEnabled? 
            <div>
              <select
                value={values?.grade_id ?? ''}
                onChange={(e) => {
                  const value = (e.target.value);
                  setFieldValue("grade_id", value)
                }}
              >
                {grades ? (
                  grades.map((grade) => (
                    <option key={grade.id} value={grade.id}>
                      {grade.name}
                    </option>
                  ))
                ) : (
                  <option value="-">No Options</option>
                )}
              </select>
            </div>
            : <td className='text-gray-900 font-normal text-md'>{values?.grade_name}</td>
            }
          </tr>
          <tr>
            <td className='text-gray-500 font-medium text-md'>Mata pelajaran</td>
            {
              isEditEnabled? 
              <div>
                <select
                  value={values?.subject_id ?? ''}
                  onChange={(e) => {
                    const value = (e.target.value);
                    setFieldValue("subject_id", value)
                  }}
                >
                  {subjects ? (
                    subjects.map((subject) => (
                      <option key={subject.id} value={subject.id}>
                        {subject.name}
                      </option>
                    ))
                  ) : (
                    <option value="-">No Options</option>
                  )}
                </select>
              </div>
              : <td className='text-gray-900 font-normal text-md'>{values?.subject_name}</td>

            }
          </tr>
          </tbody>
        </table>
      </>
        )}}
      </Formik>
    </div>
  )
}

export default memo(DetailTab)
