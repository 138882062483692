import { FC, memo } from "react";
import { useFormContext } from "react-hook-form";
import Info from "../../../fragment/TryOut/Detail/Info";
import Price from "../../../fragment/TryOut/Detail/Price";
import Config from "../../../fragment/TryOut/Detail/Config";

type Props = {
  onSave: (data: TryoutPayload) => void;
};

const DetailTab: FC<Props> = ({ onSave }) => {
  const { getValues } = useFormContext<TryoutPayload>();
  const currentValue = getValues();

  const handleSave = (params: Partial<TryoutPayload>) => {
    let payload = getValues();
    payload = {
      ...payload,
      ...params,
    };
    onSave(payload);
  };

  return (
    <div className="flex gap-x-8">
      <Info data={{ ...currentValue }} canEdit onSave={handleSave} />
      <div className="space-y-6 w-[25rem]">
        <Price data={{ ...currentValue }} canEdit onSave={handleSave} />
        <Config data={{ ...currentValue }} canEdit onSave={handleSave} />
      </div>
    </div>
  );
};

export default memo(DetailTab);
