import { useCallback, useEffect, useState } from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {icon} from '@fortawesome/fontawesome-svg-core/import.macro';
import {useNavigate} from 'react-router-dom';

import {Spinner} from 'components';
import Table from 'components/Table';
import Breadcrumbs from 'components/Breadcrumbs';
import InfoSyllabus from './fragment/Modal/Info';

import useSyllabus from 'services/useSyllabus';
import {getFormatDate, useDebounce} from 'utilities';

const columns = [
  {
    title: 'Nama',
    isSort: true,
    key: 'name'
  },
  {
    title: 'Strata',
    isSort: true,
    key: 'grade'
  },
  {
    title: 'Pertemuan',
    isLeft: true
  },
  {
    title: 'Diperbarui',
    isSort: true,
    key: 'updated_at'
  },
  {
    title: 'Aksi'
  }
];

const Syllabus = () => {
  const navigate = useNavigate();
  const {getDetail, getList} = useSyllabus();

  const defaultCrumbs: Crumb[] = [
    {
      label: 'Silabus',
      id: 0,
    }
  ];

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentLimit, setLimit] = useState(10);
  const [sortBy, setSortBy] = useState<SyllabusParams['sort_by']>('name');
  const [sortDir, setSortDir] = useState<SyllabusParams['order_by']>('asc');
  const [total, setTotal] = useState(0);
  const [lastPage, setLastPage] = useState(1);
  const [search, setSearch] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [detail, setDetail] = useState<SyllabusDetail | null>(null);
  const [crumbs, setCrumbs] = useState(defaultCrumbs);

  const debounceSearch = useDebounce(search);

  const getSyllabusData = useCallback(async (params?: SyllabusParams) => {
    setLoading(true);
    const response = await getList(params);
    
    setCurrentPage(params?.page || currentPage);
    setLimit(params?.per_page || currentLimit);
    setTotal(response?.total || 0);
    setLastPage(response?.last_page || lastPage);
    setData(response?.data || []);
    setLoading(false);
  }, [currentLimit, currentPage, lastPage, getList]);

  useEffect(() => {
    if (debounceSearch.length >= 3) {
      getSyllabusData({page: 1, keyword: debounceSearch});
    }

    if (!debounceSearch.length) {
      getSyllabusData({page: 1, keyword: ''});
    }
  }, [debounceSearch]);

  const handlePagination = async (ctrl: 'next' | 'prev') => {
    const isNext = ctrl === 'next';
    const page = isNext ? currentPage + 1 : currentPage - 1;
    
    if (page > 0 && page <= lastPage) {
      await getSyllabusData({page});
    }
  };

  const handleLimit = async (limit: string) => {
    await getSyllabusData({page: 1, per_page: Number(limit)});
  };

  const clearSearch =async () => {
    setSearch('');
    await getSyllabusData({page: 1, keyword: ''})
  };

  const handleSort =async (params: string) => {
    let direction: SyllabusParams['order_by'] = sortDir === 'asc' ? 'desc' : 'asc';

    if (params !== sortBy) {
      direction = 'asc';
    }
    
    setSortBy(params as SyllabusParams['sort_by']);
    setSortDir(direction);
    await getSyllabusData({
      page: 1,
      sort_by: params as SyllabusParams['sort_by'],
      order_by: direction
    });
  };

  const handleModal = async (data: SyllabusItem) => {
    const response = await getDetail(data.id);

    setShowModal(true);
    setDetail(response);
  };

  const onClickFolder = async (item: Crumb, isCrumb = false) => {
    let updatedCrumb: Crumb[] = JSON.parse(JSON.stringify(crumbs));
    if (isCrumb) {
      const crumbIndex = updatedCrumb.findIndex((val) => val.id === item.id);
      updatedCrumb = updatedCrumb.slice(0, crumbIndex + 1);
    } else {
      updatedCrumb = [...updatedCrumb, {
        label: item.label,
        id: item.id
      }];
    }

    await getSyllabusData({page: 1, per_page: 10});
    setCrumbs(updatedCrumb);
  };

  return (
    <>
      <Breadcrumbs crumbs={crumbs} onClick={(item) => onClickFolder(item, true)} />
      <div className='flex justify-between items-center mb-5'>
        <div className='bg-white px-4 py-[0.625rem] rounded-xl w-72 flex gap-x-2 items-center'>
          <FontAwesomeIcon icon={icon({name: 'search'})} />
          <input
            className='w-full'
            placeholder='Search...'
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
          {Boolean(search.length) && (
            <FontAwesomeIcon
              icon={icon({name: 'close'})}
              onClick={clearSearch}
              className='cursor-pointer'
            />
          )}
        </div>
        <button
          onClick={() => navigate('tambah', {
            relative: 'path'
          })}
          className='bg-primary rounded-xl py-[0.625rem] pl-4 pr-5 flex items-center'
        >
          <FontAwesomeIcon icon={icon({name: 'plus'})} size='lg' />
          <span className='ml-[0.625rem] text-sm'>Tambah</span>
        </button>
      </div>
      <Table
        data={data}
        columns={columns}
        currentLimit={currentLimit}
        currentPage={currentPage}
        total={total}
        handleLimit={handleLimit}
        handlePagination={handlePagination}
        handleSort={handleSort}
      >
        <>
          {data.length ? data?.map((item: SyllabusItem) => {
            const {id, syllabus_name, total_silabus_packages, updated_at, grade_name} = item;
            const iconType = icon({name: 'file-lines', style: 'regular'});

            return (
              <tr key={id} className='border-b'>
                <td className='p-4'>
                  <div className='flex gap-x-2 items-baseline'>
                    <FontAwesomeIcon className='w-[0.875rem] h-[0.875rem]' icon={iconType} />
                    <span className='cursor-default text-start'>{syllabus_name}</span>
                  </div>
                </td>
                <td className='p-4'>{grade_name}</td>
                <td className='p-4'>{total_silabus_packages}</td>
                <td className='p-4'>{getFormatDate(updated_at, 'DD MMM YYYY')}</td>
                <td className='p-4 text-end'>
                  <FontAwesomeIcon 
                      icon={icon({name: 'info'})} 
                      onClick={() => handleModal(item)} 
                      className='cursor-pointer'
                      title='info'
                    />
                  <FontAwesomeIcon 
                    icon={icon({name: 'pen'})}
                    onClick={() => navigate(`ubah/${item.id}`, {
                      relative: 'path'
                    })}
                    className='cursor-pointer ml-3'
                    title='edit'
                  />
                </td>
              </tr>
            )
          }) : (
            <tr>
              <td
                colSpan={columns.length}
                className="italic opacity-40 text-center pt-3"
              >
                {loading ? (
                  <Spinner />
                ) : (
                  <span>Tidak ada data.</span>
                )}
              </td>
            </tr>
          )}
        </>
      </Table>
      <InfoSyllabus show={showModal} onClose={() => setShowModal(false)} data={detail} />
    </>
  )
};

export default Syllabus;
