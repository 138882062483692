import { FC, memo } from 'react'
import DetailProgram from '../fragment/DetailProgram';

const Summary: FC = () => {
  return (
    <div>
      <DetailProgram />
    </div>
  )
}

export default memo(Summary);