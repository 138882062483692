// React
import { PropsWithChildren } from "react"

function Card (props: PropsWithChildren<{}>): JSX.Element {
  return (
    <div className="px-4 py-3 bg-white rounded-xl flex flex-col gap-y-4">
      {props.children}
    </div>
  )
}

function Body (props: PropsWithChildren<{
  className?: string
}>): JSX.Element {
  return (
    <div className={props.className}>
      {props.children}
    </div>
  )
}

function Footer (props: PropsWithChildren<{
  className?: string
}>): JSX.Element {
  return (
    <div className={props.className}>
      {props.children}
    </div>
  )
}

function Header (props: PropsWithChildren<{}>): JSX.Element {
  return (
    <div className="justify-between item-center">
      {props.children}
    </div>
  )
}

function Title (props: PropsWithChildren<{}>): JSX.Element {
  return (
    <div className="text-2xl font-bold">
      {props.children}
    </div>
  )
}

Card.Body = Body
Card.Footer = Footer
Card.Header = Header
Card.Title = Title

export { Card }