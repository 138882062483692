import { icon } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ChangeEvent, FC, memo, useState } from "react";
import useAsset from "services/useAsset";
import { Spinner } from "./Spinner";

type Props = {
  imgSrc: string;
  onUpload: (file: UploadData | null) => void;
  onDelete?: (() => void) | null;
  height?: string;
  width?: string;
};

const UploadImage: FC<Props> = (props) => {
  const { upload } = useAsset();
  const { imgSrc, onDelete, onUpload, height, width } = props;

  const [loading, setLoading] = useState(false);

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const currentFile = e.target.files?.[0];

    if (currentFile) {
      setLoading((prev) => !prev);
      const response = await upload({
        file: currentFile,
      });

      if (response) {
        onUpload(response);
      }
      setLoading((prev) => !prev);
    }
  };

  return (
    <div className={`${height || "h-56"} ${width || "w-[21rem]"}`}>
      {imgSrc ? (
        <div className="relative w-[inherit] h-[inherit] flex justify-center rounded-lg overflow-hidden border border-gray-200">
          <img
            src={imgSrc}
            alt="thumbnail"
            className="absolute object-cover h-[inherit]"
          />
          {onDelete && (
            <div className="w-full h-full bg-black opacity-70 flex items-center justify-center">
              <FontAwesomeIcon
                className="cursor-pointer"
                icon={icon({ name: "trash-can" })}
                onClick={onDelete}
                color="#FFFFFF"
              />
            </div>
          )}
        </div>
      ) : (
        <div className="border-dashed border text-center h-full flex flex-col justify-center rounded-lg">
          {loading ? (
            <div>
              <Spinner />
            </div>
          ) : (
            <>
              <FontAwesomeIcon
                icon={icon({ name: "cloud-arrow-up" })}
                size="2x"
              />
              <p className="mt-4">
                <input
                  type="file"
                  id="addModule"
                  className="hidden"
                  accept="image/*"
                  onChange={handleFileChange}
                />
                <label
                  htmlFor="addModule"
                  className="text-blue-500 cursor-pointer"
                >
                  Pilih file
                </label>
                {" yang akan di unggah."}
              </p>
              <p className="italic text-sm text-gray-400">
                Format file: jpg, png
              </p>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default memo(UploadImage);
