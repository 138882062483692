import {memo, FC, useState, useEffect} from 'react';
import Modal from 'components/Modal';

import useModule from 'services/useModule';


type Props = {
  show: boolean
  directoryId?: number
  onClose: () => void
  onSuccess: (params: BrowseParams) => Promise<void>
  isEdit?: boolean
  name?: string
  id?: number
}

const AddFolder: FC<Props> = (props) => {
  const {show, directoryId, onClose, onSuccess, isEdit, name = '', id} = props;
  const {addFolder, editFolder} = useModule();
  const [title, setTitle] = useState(name);

  useEffect(() => {
    setTitle(name);
  }, [name]);

  const handleSubmit = async () => {
    if (title.length) {
      let success = false;

      if (isEdit && id) {
        success = await editFolder({
          name: title,
          parent: Number(directoryId),
          id: id
        });
      } else {
        success = await addFolder(title, directoryId);
      }

      if (success) {
        await onSuccess({directory_id: directoryId});
        setTitle('');
        onClose();
      }
    }
  };

  return (
    <Modal
      show={show}
      title={`${isEdit ? 'Edit' : 'Tambah'} Folder`}
      onClose={onClose}
      customFooter={(
        <div className="float-right flex gap-x-2">
          <button
            onClick={onClose}
            className="px-4 py-[0.625rem] rounded-xl border border-primary"
          >
            Batal
          </button>
          <button
            onClick={handleSubmit}
            className="px-4 py-[0.625rem] rounded-xl bg-primary"
          >
            {isEdit ? 'Simpan' : 'Tambah'}
          </button>
        </div>
      )}
    >
      <p>
        Judul
        <span className="text-red-500"> *</span>
      </p>
      <div className="py-2 border-b border-gray-400">
        <input
          type="text"
          placeholder="Masukan judul folder..."
          className="w-full"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
    </Modal>
  )
}

export default memo(AddFolder);
