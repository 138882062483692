import { useCallback } from "react";
import { services } from "services";
import { API_URL } from "constant";
import { AxiosError } from "axios";

const useWithdrawal = () => {
  const getSummary =
    useCallback(async (): Promise<WithdrawalSummaryResponse> => {
      try {
        const response = await services.get(`${API_URL.withdraw}/summary`);
        return response?.data?.data;
      } catch (error) {
        const err = error as AxiosError;
        throw err.response?.data;
      }
    }, []);

  const getList = useCallback(
    async (
      params: WithdrawalListParams
    ): Promise<WithdrawalListResponse> => {
      try {
        const response = await services.get(`${API_URL.withdraw}`, {
          params,
        });

        return response?.data?.data;
      } catch (error) {
        const err = error as AxiosError;
        throw err.response?.data;
      }
    },
    []
  );
  const patchApproval = useCallback(
    async (
      id: string, body: WithdrawalPatchBody
    ): Promise<WithdrawalListResponse> => {
      try {
        const response = await services.patch(`${API_URL.withdraw}/${id}/approval`, body,{
          headers: {
            "Content-Type": "application/json",
          },
        });
        return response?.data;
      } catch (error) {
        const err = error as AxiosError;
        throw err.response?.data;
      }
    },
    []
  );
  const getDetailById =
    useCallback(async (id: string): Promise<WithdrawalDetailResponse> => {
      try {
        const response = await services.get(`${API_URL.withdraw}/${id}`);
        return response?.data?.data;
      } catch (error) {
        const err = error as AxiosError;
        throw err.response?.data;
      }
    }, []);


  return {
    getSummary,
    getList,
    patchApproval,
    getDetailById,
  };
};

export default useWithdrawal;
