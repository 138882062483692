// React
import { Fragment, useState } from "react"

// API
import { services } from "services"

// Components
import { Button, Icon, Input, Modal } from "components"

// Form
import * as yup from "yup"
import { Controller, useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"

// Utilities
import { baseData } from "../utilities"

// Types
import { ErrorFetch, SuccessFetch } from "types"

// Third-Party Libraries
import toast from "react-hot-toast"

export function CreateSection(props: {
  directory_id: null | number
  onAddFolder: () => void
}) {
  // Hooks
  const [isOpen, setOpen] = useState(false)

  const toggle = () => setOpen(prev => !prev)

  return (
    <Fragment>
      <Button.Create
        isDropdown
        items={[
          {
            title: (
              <div className="flex flex-wrap">
                <Icon.Folder className="w-4 mr-1 fill-primary" />
                <span>Tambah Folder</span>
              </div>
            ),
            onItemClick: toggle
          },
          {
            linkTo: `${baseData.url}/tambah`,
            state: { directory_id: props.directory_id },
            title: (
              <div className="flex flex-wrap">
                <Icon.Cash className="w-4 mr-1 fill-primary" />
                <span>Tambah Silabus</span>
              </div>
            )
          }
        ]}
      />

      {isOpen && <FolderSection directory_id={props.directory_id} onAddFolder={props.onAddFolder} toggle={toggle} />}
    </Fragment>
  )
}

function FolderSection(props: {
  directory_id: null | number
  onAddFolder: () => void
  toggle: () => void
}) {
  type Form = {
    name: string
  }

  // Form
  const defaultValues: Form = {
    name: ""
  }
  const validationSchema = yup.object().shape({
    name: yup.string().label("Name").required()
  })
  const onSubmit = (data: Form) => {
    return new Promise<void>((resolve) => {
      toast.promise(
        services.post("/directory", {
          ...data,
          parent_id: props.directory_id
        }),
        {
          loading: "Loading...",
          success: (res: SuccessFetch<{message: string}>) => {
            props.toggle()
            props.onAddFolder()
            return res.data.message
          },
          error: (err: ErrorFetch) => err.response.data.message
        }
      ).catch(() => {}).finally(resolve)
    })
  }
  const { control, formState, handleSubmit } = useForm<Form>({
    defaultValues,
    resolver: yupResolver(validationSchema)
  })

  return (
    <Modal isOpen title="Tambah Folder" closeModal={props.toggle}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Modal.Body>
          <Controller
            control={control}
            name="name"
            render={({ field, fieldState }) => (
              <Input
                {...field}
                label="Nama Folder*"
                ref={null}
                placeholder="Masukkan nama folder"
                errors={fieldState.error?.message}
              />
            )}
          />
        </Modal.Body>

        <Modal.Footer className="flex justify-end gap-x-2">
          <Button type="submit" color="outline-primary" isLoading={formState.isSubmitting} onButtonClick={handleSubmit(onSubmit)}>Buat Folder</Button>
          <Button type="button" color="outline-error" onButtonClick={props.toggle}>Batal</Button>
        </Modal.Footer>
      </form>
    </Modal>
  )
}