// React
import { DetailedHTMLProps, SelectHTMLAttributes, useId } from "react"

// Components
import { ErrorText, Label } from "components/texts"

interface Type extends Omit<DetailedHTMLProps<SelectHTMLAttributes<HTMLSelectElement>, HTMLSelectElement>, "size"> {
  isloading?: boolean | 1 | 0
  label?: string
  layout?: "line" | "rounded"
  options: {
    label: string | number
    value?: string | number
  }[]
  size?: "xs" | "sm" | "md" | "lg" | "xl"
  errors?: string | undefined
  required?: boolean
}

export function Select(props: Type): JSX.Element {
  // Variables
  const { size, ...prop } = props
  const fieldSize = {
    xs: "select-xs",
    sm: "select-sm",
    md: "select-md",
    lg: "select-lg",
    xl: "select-xl",
  }
  const layout = {
    line: "bg-transparent outline-none border-b border-[#9DA4B3] w-full placeholder:text-[#9DA4B3] py-2 h-10",
    rounded: `select pr-8 ${fieldSize[props.size ?? "md"]}`
  }

  // Hooks
  const id = useId()

  return (
    <div>
      {props.label && (
        <Label id={id}>
          {props.label}
          {props.required && (
            <span className="ml-1 text-red-500">*</span>
          )}
        </Label>
      )}

      {props.isloading ? (
        <div className="skeleton h-8 mt-3 pb-2" />
      ) : (
        <select
          {...prop}
          id={id}
          title={props.label}
          className={`${layout[props.layout ?? "rounded"]} ${props.className}`}
        >
          {props.options.map((item, key) => (
            <option key={key} value={item.value} className="bg-white dark:bg-slate-600">
              {item.label}
            </option>
          ))}
        </select>
      )}

      {props.errors && <ErrorText>{props.errors}</ErrorText>}
    </div>
  )
}
