import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";

const Modules = ({ data = [] } : {data?: LiveClassModules[]}) => {
  return(
    <div className='p-5 bg-white rounded-md flex flex-col space-y-2'>
      <p className='text-md font-bold text-gray-900'>Materi</p>
      {data.map((item)=>(
        <div className='flex flex-1 justify-between py-2 rounded-md items-center'>
          <div className='space-x-3'>
            <FontAwesomeIcon size='1x' color='#666666' icon={icon({name: 'file-alt'})}/>
            <span key={item.title}>{item.title}</span>
          </div>
          <FontAwesomeIcon icon={icon({name: 'search'})} color="#667085"/>
        </div>
      ))}
    </div>
  )
}

export default Modules
