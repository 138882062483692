// React
import { Fragment, useState, useEffect } from "react";

// Components
import { Button, Modal } from "components";
import { services } from "services";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {icon} from "@fortawesome/fontawesome-svg-core/import.macro";
import {QuestionType} from "../List/types";

type Directory = "directory";
type Question = "question";

type GenericDataTypes = Directory | Question;

type DeleteProps = {
  item: QuestionType;
  id: number;
  type: GenericDataTypes;
};

export function DeleteRow(props: DeleteProps): JSX.Element {
  const { item, id, type } = props;
  // Hooks
  const [isOpen, setOpen] = useState(false);
  const [currentStatus, setCurrentStatus] = useState<'init'|'deleted'|'failed'>('init')

  useEffect(()=>{
    setCurrentStatus('init')
  },[isOpen])

  function handleDelete() {
    try {
      if (type === 'directory') {
        deleteDirectory(id);
      } else if (type === 'question') {
        deleteQuestion(id);
      }
    } catch (error) {}
  }

  async function deleteQuestion(question_id: number) {
    services
      .delete(`/question-package/${question_id}`)
      .then(() => {
        setCurrentStatus('deleted')
      })
      .catch(() => {
        setCurrentStatus('failed')
      });
  }

  async function deleteDirectory(directory_id: number) {
    services
      .delete(`/admin/directory/${directory_id}`)
      .then(() => {
        setCurrentStatus('deleted')
      })
      .catch(() => {
        setCurrentStatus('failed')
      });
  }

  const toggle = () => setOpen((prev) => !prev);

  return (
    <Fragment>
      <Button.Icon action="delete" onButtonClick={toggle} />

      {isOpen && (
        <Modal
          isOpen
          closeModal={toggle}
          title={''}
        >
          <div className="px-6 py-4 flex flex-col items-center">
            <DeleteModalItem
              status={currentStatus}
              name={item?.name}
              toggle={toggle}
              handleDelete={handleDelete}
              type={type}
            />
          </div>
        </Modal>
      )}
    </Fragment>
  );
}

const DeleteModalItem = ({status, name, toggle, handleDelete, type}: {status:'init'|'deleted'|'failed', name: string, toggle: ()=>void, handleDelete: ()=>void, type: GenericDataTypes}) => {
  switch(status){
    case 'deleted':
      return(
        <>
          <FontAwesomeIcon
            icon={icon({name: 'trash-alt'})}
            size={'3x'}
            color={'#F04438'}
          />
          <div className="px-4 pt-6 pb-8 space-y-2">
            <p className="text-center font-bold text-base	">
              {`${name} berhasil dihapus!`}
            </p>
            <p className="text-center text-sm	text-[#666666]">
              Folder/modul yang telah dihapus tidak dapat dipulihkan kembali.
            </p>
          </div>
          <div className="flex gap-x-2">
            <button
              onClick={()=>window.location.reload()}
              className="px-4 py-[0.625rem] rounded-xl bg-primary"
            >
              Tutup
            </button>
          </div>
        </>
      )
    case 'failed':
      return (
        <>
          <FontAwesomeIcon
            icon={icon({name: 'times-circle'})}
            size={'3x'}
            color={'#F04438'}
          />
          <div className="px-4 pt-6 pb-8 space-y-2">
            <p className="text-center text-sm	text-[#666666]">
              { type === 'directory' ? ` Folder ${name} tidak bisa dihapus karena masih terdapat folder, modul atau soal didalamnya, silahkan sesuaikan terlebih dahulu sebelum menghapus` :`${name} tidak bisa dihapus karena masih di alokasikan ke dalam modul / silabus, silahkan sesuaikan terlebih dahulu sebelum menghapus`}
            </p>
          </div>
          <div className="flex gap-x-2">
            <button
              onClick={toggle}
              className="px-4 py-[0.625rem] rounded-xl bg-primary"
            >
              Tutup
            </button>
          </div>
        </>
      )
    default:
      return(
        <>
          <FontAwesomeIcon
            icon={icon({name: 'info-circle'})}
            size={'3x'}
            color={'#F04438'}
          />
          <div className="px-4 pt-6 pb-8 space-y-2">
            <p className="text-center font-bold text-base	">
              Anda yakin?
            </p>
            <p className="text-center text-sm	text-[#666666]">
              {`Anda akan menghapus soal ${name} beserta dengan seluruh pertanyaan dan aset yang sudah di masukkan kedalamnya. Soal tidak bisa di hapus bila sudah di alokasikan ke dalam modul atau silabus. Apakah anda yakin?`}
            </p>
          </div>
          <div className="flex gap-x-2">
            <button
              onClick={toggle}
              className="px-4 py-[0.625rem] rounded-xl border border-primary"
            >
              Batal
            </button>
            <button
              onClick={handleDelete}
              className="px-4 py-[0.625rem] rounded-xl bg-primary"
            >
              Hapus
            </button>
          </div>
        </>
      )
  }
}
